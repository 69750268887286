import colors from "assets/theme/base/colors";
import { Box, Typography, Button } from "@mui/material";
import Modal from "@mui/material/Modal";

import { useNavigate } from "react-router-dom";
import GradientIcon from "components/Custom/GradientIcon/index";

import QueueOutlinedIcon from "@mui/icons-material/QueueOutlined";
const JobStartModal = (props) => {
  const navigate = useNavigate();

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal_container_small">
        <div className="" style={{ textAlign: "left" }}>
          <Box className="align_center_row" mb={2}>
            {" "}
            <GradientIcon>
              <QueueOutlinedIcon className="lightbulb_icon" />
            </GradientIcon>{" "}
            <Typography variant="h6" sx={{ color: "#fff" }}>
              Upgrade to Plus to Queue Multiple Jobs
            </Typography>
          </Box>
          <Box className="align_center_row" mb={2}>
            {" "}
            <Typography variant="body2" className="c_iconenabled f_mid" sx={{ color: "#fff" }}>
              Get Polyhive Plus for unrestricted downloads, concurrent job queuing, commercial
              rights to textured assets, and more!
            </Typography>
          </Box>

          <Box className="btn_container">
            <Button
              onClick={props.handleClose}
              variant="text"
              className="default_btn c_white f_medium fw_500"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                navigate("/buy");
              }}
              variant="text"
              className="default_btn bg_hivegradient c_white f_mid fw_500"
            >
              Upgrade to Polyhive Plus
            </Button>
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default JobStartModal;
