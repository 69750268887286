import React from "react";
const GradientIcon = (props) => {
  return (
    <>
      <svg width={0} height={0}>
        <linearGradient id="hiveGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="10%" stopColor="rgba(0, 196, 255, 0.792)" />
          <stop offset="120%" stopColor="rgba(210, 50, 255, 0.892)" />
        </linearGradient>
      </svg>
      {React.cloneElement(props.children, { sx: { fill: "url(#hiveGradient)" } })}
    </>
  );
};

export default GradientIcon;
