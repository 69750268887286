import VuiBox from "../../../../../components/VuiBox";
import VuiTypography from "../../../../../components/VuiTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import DataTable from "examples/Tables/DataTable";
import VuiSnackbar from "components/VuiSnackbar";
// import dataTableData from "layouts/applications/security/data/tableHeaders";
import ActionCell from "../ActionCell";
import { useEffect, useState } from "react";
import VuiButton from "../../../../../components/VuiButton";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "layouts/authentication/AuthFunctions";
import Cookies from "universal-cookie";
import { useVisionUIController } from "context";
import amplitude from "amplitude-js";
import colors from "assets/theme/base/colors";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";
import React from "react";
import HelpIcon from "@mui/icons-material/Help";
import { HtmlTooltip } from "layouts/dashboards/default/components/CustomTooltip/index";

// Get current date in mm/dd/yyyy format
const getCurrentDate = () => {
  const date = new Date();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

const getCurrentDateTime = () => {
  var m = new Date();
  var dateString =
    m.getUTCFullYear() +
    "/" +
    ("0" + (m.getUTCMonth() + 1)).slice(-2) +
    "/" +
    ("0" + m.getUTCDate()).slice(-2) +
    " " +
    ("0" + m.getUTCHours()).slice(-2) +
    ":" +
    ("0" + m.getUTCMinutes()).slice(-2) +
    ":" +
    ("0" + m.getUTCSeconds()).slice(-2);
  return dateString;
};

const APIKey = () => {
  const cookies = new Cookies();
  const [controller, dispatch] = useVisionUIController();
  const { activeOrg } = controller;

  const [apiKeys, setApiKeys] = useState([]);
  const [dataTableData, setDataTableData] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const toggleSnackbar = () => setShowSnackbar(!showSnackbar);
  const navigate = useNavigate();

  useEffect(() => {
    if (activeOrg !== "") {
      fetchApiKeys();
    }
  }, [activeOrg]);

  // convert date from 2022-12-16T00:00:00.000Z to 12/16/2022
  const convertDate = (date) => {
    const dateObj = new Date(date);
    const month = dateObj.getMonth() + 1;
    const monthString = month.toString().length === 1 ? "0" + month : month;
    const day = dateObj.getDate();
    const dayString = day.toString().length === 1 ? "0" + day : day;

    const year = dateObj.getFullYear();

    return `${monthString}/${dayString}/${year}`;
  };

  useEffect(() => {
    // Create new dataTableData and push rows to it for each api key in fetchedKeys
    const newRows = [];
    //console.log("apiKeys is", apiKeys);
    apiKeys.forEach((key) => {
      //
      const newRow = {
        name: key.name,
        creationDate: convertDate(key.creation_date),
        restrictions: "Read/Write",
        key: key.api_key,
        actions: {
          name: key.name,
          creationDate: convertDate(key.creation_date),
          restrictions: "Read/Write",
          key: key.api_key,
        },
      };
      newRows.push(newRow);
    });
    setDataTableData(newRows);
  }, [apiKeys]);

  const getApiKeyDefaultName = () => {
    return "API Key " + getCurrentDateTime();
  };

  const fetchApiKeys = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/developer/apiKeyForOrg`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 403) {
          logoutUser(navigate);
        }
        const fetchedKeys = response.json();
        return fetchedKeys;
      })
      .then((keys) => {
        //console.log(keys);
        setApiKeys(keys);
      });
  };

  const onDelete = (apiKey) => {
    //console.log("onDelete called with apiKey", apiKey);
    // delete from backend

    setApiKeys(apiKeys.filter((key) => key.api_key !== apiKey));
  };

  const onCreateClicked = () => {
    logAuthorizedWombatEvent("createAPIKeyClicked", {});

    fetch(`${process.env.REACT_APP_BACKEND_URL}/developer/apiKeyForOrg`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ creationDate: getCurrentDate(), name: getApiKeyDefaultName() }),
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          logoutUser(navigate);
        }
        return response.json();
      })
      .then((newKey) => {
        //console.log("newKey is", newKey);
        setApiKeys(apiKeys.concat(newKey));
        toggleSnackbar();
      });
  };

  const table = {
    columns: [
      { Header: "NAME", accessor: "name", width: "30%" },
      { Header: "CREATION DATE", accessor: "creationDate", width: "30%" },
      { Header: "RESTRICTIONS", accessor: "restrictions", width: "30%" },
      {
        Header: "ACTIONS",
        accessor: "actions",
        width: "10%",
        Cell: ({ value }) => <ActionCell dataFields={value} onDelete={onDelete} />,
      },
    ],

    rows: dataTableData,
  };

  // const onKeyLoaded = (data) => {
  //   dataTableData.rows.push({
  //     name: data.name,
  //     creationDate: data.creationDate,
  //     restrictions: data.restrictions,
  //     actions: data,
  //   });
  // };

  // onKeyLoaded({
  //   name: "API key 2",
  //   creationDate: "Oct 19, 2022",
  //   restrictions: "Read/Write",
  //   key: "1234567890",
  // });

  // onKeyLoaded({
  //   name: "API key 5",
  //   creationDate: "Oct 19, 2022",
  //   restrictions: "Read/Write",
  //   key: "21442142141",
  // });

  return (
    <>
      <Grid item xs={12} lg={8}>
        <VuiBox mt={6} mb={1} textAlign="center">
          <VuiBox mb={1}>
            <VuiTypography variant="h3" fontWeight="bold" color="white">
              Security Settings
            </VuiTypography>
          </VuiBox>
        </VuiBox>

        <VuiBox
          mt={2}
          sx={{
            backgroundColor: colors.background.secondary,
            borderRadius: "10px",
            padding: "20px",
            paddingTop: "10px",
          }}
        >
          <VuiBox p={2}>
            <VuiBox width="100%" display="flex" justifyContent="space-between">
              <VuiBox sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <VuiTypography
                  variant="body2"
                  sx={{
                    color: colors.icon.enabled,
                    fontWeight: 450,
                    opacity: "1 important",
                    fontSize: "1.5rem",
                  }}
                  mb={1}
                >
                  {" "}
                  API Keys
                </VuiTypography>
                <VuiBox>
                  <HtmlTooltip
                    placement="top"
                    title={
                      <React.Fragment>
                        <VuiTypography color="inherit">API Keys</VuiTypography> A security measure
                        used to control access to the Polyhive Game Engine SDK. To obtain an API
                        key, please refer to the documentation for detailed instructions. Once
                        generated, your API key will be hidden, so be sure to store it in a safe
                        location. If you lose your API key, you can generate a new one, but be sure
                        to update any references to the API key in your game.
                      </React.Fragment>
                    }
                  >
                    <HelpIcon className="info_icon ml1" />
                  </HtmlTooltip>
                </VuiBox>
              </VuiBox>
              <VuiButton
                variant="contained"
                color={colors.highlight.main}
                onClick={onCreateClicked}
                sx={{
                  backgroundColor: colors.highlight.submain,
                  border: "1px solid" + colors.highlight.main,
                  color: colors.highlight.main,
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: colors.highlight.submain,
                  },
                }}
              >
                Create API Key
              </VuiButton>
            </VuiBox>
            <DataTable table={table} showTotalEntries={false} entriesPerPage={false} />
          </VuiBox>
        </VuiBox>
      </Grid>
      <VuiSnackbar
        color="success"
        icon="notifications"
        title="Make sure to copy and store the key now. You won't be able to see it again! "
        content="If you lose this key, you can always create a new one."
        open={showSnackbar}
        close={toggleSnackbar}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </>
  );
};

export default APIKey;
