import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard PRO React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Authentication layout components
// import footer from home components
import Footer from "../../layout/footer/Footer.jsx";

// Vision UI Dashboard PRO React page layout routes

import pageRoutes from "page.routes";

// Vision UI Dashboard PRO React theme functions
import colors from "assets/theme/base/colors";

// Vision UI Dashboard PRO React theme functions
import tripleLinearGradient from "assets/theme/functions/tripleLinearGradient";
import HomeImage from "assets/images/homegraphic-hexagon.png";
import HomeImage2 from "assets/images/homegraphic2.png";
import Button from "@mui/material/Button";
import Mock from "assets/images/mock.png";
import SupportedChains from "assets/images/agnosticgraphic.png";
import SupportedGameEngines from "assets/images/supportedgameengines.png";
import { useParams } from "react-router-dom";

import { sendUTMToWombat, logWombatEvent } from "util/UTMFunctions";
import { useNavigate, Link } from "react-router-dom";

import { Suspense, lazy, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import { Box, Typography } from "@mui/material";

import * as THREE from "three";

import VuiInput from "components/VuiInput";
import Typing from "react-typing-animation";
import FaithRinggoldShirt from "../../models/faith_ringgold_shirt.glb";
import GaryPanterShirt from "../../models/gary_panter_shirt.glb";
import TieDyeShirt from "../../models/tye_dye.glb";
import HieroglyphicsShirt from "../../models/hieroglyphics.glb";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import GradientIcon from "components/Custom/GradientIcon/index";
import ModelGallery from "../../components/ModelGallery/index";
const heroAssets = [
  {
    file: FaithRinggoldShirt,
    prompt: "A shirt by Faith Ringgold",
  },
  {
    file: GaryPanterShirt,
    prompt: "A shirt by Gary Panter",
  },
  {
    file: TieDyeShirt,
    prompt: "A tie-dye shirt",
  },
  {
    file: HieroglyphicsShirt,
    prompt: "A shirt with hieroglyphics",
  },
];

// generate a random number to pick a random hero asset
const randomIndex = Math.floor(Math.random() * heroAssets.length);

const Model = (props) => {
  // add callback for when file
  const gltf = useLoader(GLTFLoader, props.file);
  const scene = new THREE.Scene();
  const group = new THREE.Group();
  const camera = props.camera;
  gltf.scene.position.y = -0.1;
  // gltf.scene.rotation.y = Math.PI;

  const rendererElement = document.getElementById("homeCanvasId1");
  // Initialize new environment map from default cube map

  // const pmremGenerator = new THREE.PMREMGenerator.fromCubemap(texture);

  // texture.encoding = THREE.sRGBEncoding;

  useEffect(() => {
    // fitCameraToObject(camera, gltf.scene, 1.25, null);

    // gltf.scene.traverse((c) => {
    //   if (c instanceof THREE.Mesh) {
    //     //console.log("setting texture");
    //     c.material.envMap = texture;
    //     c.material.envMapIntensity = 1;
    //     // c.material.color.convertSRGBToLinear();
    //   }
    // });

    group.add(gltf.scene);
    scene.add(group);
  }, []);

  const resetCamera = (camera) => {
    camera.rotation.x = 0;
    camera.rotation.y = 0;
    camera.rotation.z = 0;
    camera.position.x = 0;
    camera.position.y = 0;
    camera.position.z = 0;
  };

  const fitCameraToObject = function (camera, object, offset, controls) {
    offset = offset || 1.25;

    resetCamera(camera);

    let oldBoundingBox = new THREE.Box3();
    oldBoundingBox.setFromObject(object);

    let size = new THREE.Vector3(
      oldBoundingBox.max.x - oldBoundingBox.min.x,
      oldBoundingBox.max.y - oldBoundingBox.min.y,
      oldBoundingBox.max.z - oldBoundingBox.min.z
    );

    let oldcenter = new THREE.Vector3(
      (oldBoundingBox.max.x + oldBoundingBox.min.x) / 2,
      (oldBoundingBox.max.y + oldBoundingBox.min.y) / 2,
      (oldBoundingBox.max.z + oldBoundingBox.min.z) / 2
    );

    // Move the anchor position of the object to the center of the object
    object.position.x = object.position.x - oldcenter.x;
    object.position.y = object.position.y - oldcenter.y;
    object.position.z = object.position.z - oldcenter.z;

    let boundingBox = new THREE.Box3();
    boundingBox.setFromObject(object);
    let center = new THREE.Vector3(
      (boundingBox.max.x + boundingBox.min.x) / 2,
      (boundingBox.max.y + boundingBox.min.y) / 2,
      (boundingBox.max.z + boundingBox.min.z) / 2
    );

    // get the max side of the bounding box (fits to width OR height as needed )
    let maxDim = Math.max(size.x, size.y, size.z);
    let fov = camera.fov * (Math.PI / 180);
    let cameraZ = maxDim / 2 / Math.tan(fov / 2);

    cameraZ *= offset; // zoom out a little so that objects don't fill the screen

    camera.position.z = center.z + cameraZ;

    let minZ = boundingBox.min.z;
    let cameraToFarEdge = minZ < 0 ? -minZ + cameraZ : cameraZ - minZ;

    camera.far = cameraToFarEdge * 3;
    camera.lookAt(oldcenter);
    // controls.target.set(center.x, center.y, center.z);
    // camera.position.z = 1;
    camera.updateProjectionMatrix();
  };

  return (
    <>
      <primitive object={scene} scale={4} />
    </>
  );
};

const AI = () => {
  const navigate = useNavigate();
  const camera = new THREE.PerspectiveCamera(45, 1, 0.0001, 5000);
  const queryParameters = new URLSearchParams(window.location);
  const type = queryParameters.get("type");
  const name = queryParameters.get("name");
  const { gradients } = colors;
  const gridItemStyle = {
    padding: "40px",
    position: "relative",
    margin: "auto",
  };

  const section1Style = {};
  const section2Style = {
    background: "#FCFCFC",
  };

  useEffect(() => {
    // sendUTMToWombat("ai");
  }, []);

  return (
    <PageLayout background={colors.white.bright}>
      {/* centered title header with name of company */}
      <DefaultNavbar
        routes={pageRoutes}
        action={{
          type: "external",
          route: "/",
        }}
        transparent
      />

      <VuiBox width="100%" background="#fff" sx={section1Style}>
        <VuiBox
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          mt={4}
          sx={{ margin: "150px auto 0px", width: { sm: "100%", md: "1000px", lg: "1200px" } }}
        >
          <VuiTypography
            variant="body2"
            className="c_textblack"
            sx={{ fontSize: "3.5rem", fontWeight: 450, lineHeight: "1.2" }}
          >
            <span className="c_hivegradient_animated">Community Showcase</span>
          </VuiTypography>
          <VuiTypography
            mt={2}
            variant="body2"
            className="c_textblack"
            sx={{ fontSize: "1.3rem" }}
            fontWeight="normal"
          >
            Thousands of creatives have used Polyhive's free Alpha.
          </VuiTypography>
          <br />
          <Box className="mauto d_flex d_justify_center d_align_center mb_4">
            <div className="h_btn_wrap d_flex d_justify_left d_align_center mt_0">
              {/* <Link to="/generate">
                <Button
                  className="c_quaternaryfocusdark br_1px br_quaternaryfocus bg_white br_white hero_btn f_small fw_500"
                  endIcon={<ArrowForwardRoundedIcon />}
                >
                  Start Free Trial
                </Button>
              </Link> */}
              <Link to="/contact">
                <Button
                  className="bg_quaternaryfocusdark br_hivegradient c_white hero_btn f_small fw_500"
                  endIcon={<CalendarMonthOutlinedIcon />}
                >
                  Contact Sales
                </Button>
              </Link>
            </div>
          </Box>
          <ModelGallery />

          <br />
        </VuiBox>
      </VuiBox>

      <Footer />
    </PageLayout>
  );
};

export default AI;
