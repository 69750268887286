import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";

import { Box, Typography } from "@mui/material";

import colors from "assets/theme/base/colors";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Grid from "@mui/material/Grid";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";
import ShirtThumb from "assets/images/default-thumbnails/shirtthumb.png";
import ShedThumb from "assets/images/default-thumbnails/shedthumb.png";
import ChestThumb from "assets/images/default-thumbnails/chestthumb.png";
import TruckThumb from "assets/images/default-thumbnails/truckthumb.png";
import HammerThumb from "assets/images/default-thumbnails/hammerthumb.png";
import HelmetThumb from "assets/images/default-thumbnails/helmetthumb.png";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import Button from "@mui/material/Button";
import { convertBytesToMB, generateUUID, getFileType } from "util/UtilFunctions";
import { useNavigate } from "react-router-dom";
import { useVisionUIController } from "context/index";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { HtmlTooltip } from "layouts/dashboards/default/components/CustomTooltip/index";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import CreditIcon from "assets/icons/crediticon.png";
import GradientIcon from "components/Custom/GradientIcon/index";
import Toggler from "../Toggler/index";
import FormatPaintOutlinedIcon from "@mui/icons-material/FormatPaintOutlined";
import TextureIcon from "@mui/icons-material/Texture";
import InputSlider from "../InputSlider/index";

const TiledForm = forwardRef((props, ref) => {
  const fileInput = React.useRef(null);
  const navigate = useNavigate();

  const [controller, dispatch] = useVisionUIController();
  const [patternImage, setPatternImage] = useState(null);
  const { activeOrg, developerInfo } = controller;
  const [selectedRes, setSelectedRes] = useState("Standard");

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];

    if (convertBytesToMB(fileUploaded.size) > 100) {
      props.startSnackbar({
        message: "File size too large. Please upload an image smaller than 10MB",
        type: "error",
      });
      logAuthorizedWombatEvent("tiledFormUploadError", {
        message: "File size too large. Please upload an image smaller than 10MB",
      });
      return;
    }
    logAuthorizedWombatEvent("patternImageUploadedToTiledForm", {
      name: fileUploaded.name,
      type: getFileType(fileUploaded.name),
    });
    // props.handleUploadedFile(fileUploaded);
    setPatternImage(fileUploaded);
    // reset event target value so that the same file can be uploaded again
    event.target.value = null;
  };

  const handleFileButtonClick = () => {
    fileInput.current.click();
  };

  const handleResChange = (event, newRes) => {
    console.log("prompting_handleResChange", newRes);
    if (newRes !== null) setSelectedRes(newRes);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (props.runningJobs.length > 0 && props.subscription.name !== "polyhive_plus_plan") {
      // pop a modal, then return
      props.handleJobStartModalOpen();
      logAuthorizedWombatEvent("freeUserAttemptedToQueueMultipleJobs", {});

      return;
    }

    const submitbutton = e.target.elements.submitbutton;
    // disable submit button for 2 seconds, then enable
    submitbutton.disabled = true;
    console.log("submit button disabled");
    setTimeout(() => {
      submitbutton.disabled = false;
      console.log("submit button enabled");
    }, 2000);

    console.log("submitbutton", submitbutton);

    logAuthorizedWombatEvent("tiledAISubmitButtonClicked", {});

    console.log("form submitted", e);
    const prompt = e.target.elements.prompt.value;
    // if (prompt === "") {
    //   logAuthorizedWombatEvent("inpaintAISubmitError", {
    //     error: "Please enter a prompt.",
    //   });
    //   props.startSnackbar({
    //     message: "Please enter a prompt.",
    //     type: "error",
    //   });
    //   return;
    // }

    const seed = e.target.elements.seed.value;
    const metalness = e.target.elements.metalness.value;
    const roughness = e.target.elements.roughness.value;
    const height = e.target.elements.height.value;
    // const preserve_uvs = e.target.elements.preserveuvs.checked;
    // const material_maps = e.target.elements.materialmaps.checked;
    // const zy_symmetry = e.target.elements.bilateralsymmetry.checked;
    const job_params = {
      seed: seed,
      metalness: metalness,
      roughness: roughness,
      height: height,
      special_model: selectedRes,
    };

    if (job_params.seed === "") {
      job_params.seed = Math.floor(Math.random() * 10000000) + "";
    } else if (parseInt(job_params.seed) < 1 || parseInt(job_params.seed) > 10000000) {
      job_params.seed = Math.floor(Math.random() * 10000000) + "";
    }

    // Form validation
    // if (!/^[a-zA-Z0-9, ]+$/.test(prompt)) {
    if (prompt.includes("{") || prompt.includes("}")) {
      logAuthorizedWombatEvent("generateAISubmitError", {
        error: "Invalid prompt. Curly braces are not allowed.",
      });
      props.startSnackbar({
        message: "Invalid prompt. Curly braces are not allowed.",
        type: "error",
      });
      return;
    }
    let imageUUID = "";
    if (patternImage) {
      imageUUID = generateUUID();
      await props.uploadImage(patternImage, imageUUID);
    }

    console.log("job_params", job_params);
    props.handleSubmitJob({ prompt: prompt, job_params: job_params, version_id: imageUUID });
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="file"
        ref={fileInput}
        style={{ display: "none" }}
        multiple={true}
        onChange={handleChange}
        accept=".png, .jpg, .jpeg"
      />
      <Box
        className="retexture_form tiled_form"
        sx={{
          display: props.open ? "flex" : "none",
        }}
      >
        <Box className="retexture_form_head">
          <Box className="retexture_form_header">
            <Box className="header_flex">
              <GradientIcon>
                <TextureIcon className="retexture_form_header_icon" />
              </GradientIcon>

              <Typography className="retexture_form_header_text c_white">PBR Generate</Typography>
            </Box>
            <Box className="header_flex">
              <CloseRoundedIcon
                className="retexture_form_header_icon_btn c_white"
                onClick={props.handleClose}
              />
            </Box>
          </Box>
          <Typography className="retexture_form_subheader">
            Generate tileable PBR material maps
          </Typography>
          <hr className="rt_form_hr" />
        </Box>
        <Box className="retexture_form_body" mt={1}>
          {/* Text Prompt Area */}

          <Box className="align_center_row" mb={1} mt={1}>
            <Box className="title_tooltip_container">
              <Typography className="f_small c_white fw_500">Structure Image</Typography>
              <HtmlTooltip
                title={
                  <Box width={200}>
                    <Typography className="f_small c_white fw_500">Structure Image</Typography>
                    <Typography className="f_small c_iconenabled fw_400">
                      Upload an image that will guide the structure of the generated texture.
                    </Typography>
                  </Box>
                }
                placement="top"
              >
                <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
              </HtmlTooltip>
            </Box>
            {patternImage === null && (
              <Button
                className="default_btn bg_hivegradient align_center_row justify_center c_white f_small fw_500 ml_1"
                onClick={handleFileButtonClick}
              >
                Upload
                <FileUploadRoundedIcon className="icon_medium c_white" />
              </Button>
            )}

            {patternImage && (
              <Box className="align_center_row">
                <Typography className="f_small c_white fw_500 ml_1 text_nowrap">
                  {patternImage.name}
                </Typography>
                <CloseRoundedIcon
                  className="icon_small c_white ml_1 br_100 bg_lightblack icon_medium"
                  onClick={() => {
                    setPatternImage(null);
                  }}
                />
              </Box>
            )}
          </Box>
          <Box className="retexture_form_ta" mb={1} mt={1}>
            <Box className="title_tooltip_container">
              <Typography className="f_small c_white fw_500">Prompt</Typography>
              <HtmlTooltip
                title={
                  <Box width={200}>
                    <Typography className="f_small c_white fw_500">Prompt</Typography>
                    <Typography className="f_small c_iconenabled fw_400">
                      Describe your texture using text. It is recommended to be as descriptive as
                      possible.
                    </Typography>
                  </Box>
                }
                placement="top"
              >
                <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
              </HtmlTooltip>
            </Box>
            <Box className="retexture_form_textarea_container">
              <textarea
                className="retexture_form_textarea"
                placeholder="Describe your texture in a few words..."
                id="prompt"
                name="prompt"
              />
            </Box>
          </Box>

          {/* <hr className="rt_form_hr" /> */}

          <Box mb={2} className="retexture_form_ta" mt={1}>
            <Box className="title_tooltip_container">
              <Typography className="f_small c_white fw_500">Seed</Typography>
              <HtmlTooltip
                title={
                  <Box width={200}>
                    <Typography className="f_small c_white fw_500">Seed</Typography>
                    <Typography className="f_small c_iconenabled fw_400">
                      A seed is a number used to introduce randomness into the AI generation. This
                      is useful for generating different variations with the same prompt.
                    </Typography>
                  </Box>
                }
                placement="top"
              >
                <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
              </HtmlTooltip>
            </Box>
            <Box className="retexture_form_input_container">
              <input
                type="number"
                className="retexture_form_input"
                placeholder="A number between 1 and 100000"
                id="seed"
                name="seed"
                autoComplete="off"
              />
            </Box>
          </Box>

          <InputSlider
            title="Metalness"
            name="metalness"
            tooltipString="Metalness is the measure of how metallic a surface is. 
            A value of 0.0 means the surface is completely dielectric (non-metallic), 
            and a value of 1.0 means the surface is a perfect metal. Values in between are for blending between the two. 
            A value of 0.5 means the surface is half metal and half dielectric."
            min={0}
            max={1}
            step={0.01}
            defaultValue={0}
          />
          <InputSlider
            title="Smoothness"
            name="roughness"
            tooltipString="
          Smoothness is the measure of how smooth a surface is. A value of 0.0 means the surface is completely rough,
            and a value of 1.0 means the surface is a perfectly smooth and will reflect light. Values in between are for blending between the two."
            min={0}
            max={1}
            step={0.01}
            defaultValue={0}
          />
          <InputSlider
            title="Height"
            name="height"
            tooltipString="Height is the measure of how much the surface is displaced from the base surface."
            min={0}
            max={1}
            step={0.01}
            defaultValue={0}
          />
          {props.permissions.special_model && props.permissions.special_model === "Civitas" && (
            <Box className="retexture_form_header mt_2 mb_0">
              <Box className="header_flex">
                <Box className="title_tooltip_container">
                  <Typography className="f_small c_white fw_500">Model</Typography>
                  <HtmlTooltip
                    title={
                      <Box width={300}>
                        <Typography className="f_small c_white fw_500">Texture Quality</Typography>
                        <Typography className="f_small c_iconenabled fw_400">
                          Select the AI model you would like to use to generate your texture.
                          Polyhive HD will result in a higher quality texture but will take longer
                          to generate.
                        </Typography>
                      </Box>
                    }
                    placement="top"
                  >
                    <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
                  </HtmlTooltip>
                </Box>
              </Box>
              <Box className="header_flex">
                <Toggler
                  options={["Standard", "Civitas"]}
                  select={selectedRes}
                  handleChange={handleResChange}
                />
              </Box>
            </Box>
          )}
          {/*  */}
        </Box>
        <Box className="retexture_form_footer">
          <hr className="rt_form_hr" />
          <Button
            className={
              props.runningJobs.length === 0
                ? "rt_form_btn"
                : props.subscription && props.subscription.name === "polyhive_plus_plan"
                ? "rt_form_btn"
                : "rt_form_btn"
            }
            type="submit"
            size="large"
            name="submitbutton"
          >
            Generate
          </Button>
          <Box className="align_center_row mauto mt_1 d_justify_center">
            <Typography className="f_small c_iconenabled fw_400 mr_05 ta_center">
              This generation will use a total of{" "}
              {typeof props.permissions["512_tiled_generate"] !== "undefined"
                ? props.permissions["512_tiled_generate"].credit_cost
                : 0}
            </Typography>
            <img src={CreditIcon} alt="credits" className="icon_small" />
          </Box>
        </Box>
      </Box>
    </form>
  );
});

export default TiledForm;
