import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard PRO React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Authentication layout components
// import footer from home components
import Footer from "../../components/Footer";

// Vision UI Dashboard PRO React page layout routes

import pageRoutes from "page.routes";

// Vision UI Dashboard PRO React theme functions
import colors from "assets/theme/base/colors";

// Vision UI Dashboard PRO React theme functions
import tripleLinearGradient from "assets/theme/functions/tripleLinearGradient";
import HomeImage from "assets/images/homegraphic.png";
import HomeImage2 from "assets/images/homegraphic2.png";
import Button from "@mui/material/Button";
import Mock from "assets/images/mock.png";
import SupportedChains from "assets/images/agnosticgraphic.png";
import SupportedGameEngines from "assets/images/supportedgameengines.png";

import { Suspense, lazy, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import myGLB from "layouts/applications/home/models/DamagedHelmet.glb";
import * as THREE from "three";
import VuiInput from "components/VuiInput";

const Model = (props) => {
  // add callback for when file
  const gltf = useLoader(GLTFLoader, props.file);
  const scene = new THREE.Scene();
  const group = new THREE.Group();
  const camera = props.camera;
  gltf.scene.position.y = -0.1;
  // gltf.scene.rotation.y = Math.PI;

  const rendererElement = document.getElementById("homeCanvasId1");
  //console.log("rendererElement", rendererElement);
  // Initialize new environment map from default cube map

  // const pmremGenerator = new THREE.PMREMGenerator.fromCubemap(texture);

  // texture.encoding = THREE.sRGBEncoding;

  useEffect(() => {
    //console.log("called useeffect", gltf.scene);
    fitCameraToObject(camera, gltf.scene, 1.25, null);

    // gltf.scene.traverse((c) => {
    //   if (c instanceof THREE.Mesh) {
    //     //console.log("setting texture");
    //     c.material.envMap = texture;
    //     c.material.envMapIntensity = 1;
    //     // c.material.color.convertSRGBToLinear();
    //   }
    // });

    group.add(gltf.scene);
    scene.add(group);
  }, []);

  const fitCameraToObject = function (camera, object, offset, controls) {
    offset = offset || 1.25;

    const oldBoundingBox = new THREE.Box3();
    //console.log(object);
    // get bounding box of object - this will be used to setup controls and camera
    oldBoundingBox.setFromObject(object);
    //console.log(oldBoundingBox);

    // Set size to Vector3 of bounding box sizes
    const size = new THREE.Vector3(
      oldBoundingBox.max.x - oldBoundingBox.min.x,
      oldBoundingBox.max.y - oldBoundingBox.min.y,
      oldBoundingBox.max.z - oldBoundingBox.min.z
    );

    // Get center point of bounding box
    const oldcenter = new THREE.Vector3(
      (oldBoundingBox.max.x + oldBoundingBox.min.x) / 2,
      (oldBoundingBox.max.y + oldBoundingBox.min.y) / 2,
      (oldBoundingBox.max.z + oldBoundingBox.min.z) / 2
    );
    //console.log("object.position", object.position);
    //console.log("oldcenter", oldcenter);

    // Move the anchor position of the object to the center of the object
    // object.position.x = object.position.x - oldcenter.x;
    // object.position.y = object.position.y - oldcenter.y;
    // object.position.z = object.position.z - oldcenter.z;

    const boundingBox = new THREE.Box3();
    boundingBox.setFromObject(object);
    const center = new THREE.Vector3(
      (boundingBox.max.x + boundingBox.min.x) / 2,
      (boundingBox.max.y + boundingBox.min.y) / 2,
      (boundingBox.max.z + boundingBox.min.z) / 2
    );

    // get the max side of the bounding box (fits to width OR height as needed )
    const maxDim = Math.max(size.x, size.y, size.z);
    const fov = camera.fov * (Math.PI / 180);
    let cameraZ = maxDim / 2 / Math.tan(fov / 2);

    cameraZ *= offset; // zoom out a little so that objects don't fill the screen

    camera.position.z = center.z + cameraZ;

    const minZ = boundingBox.min.z;
    const cameraToFarEdge = minZ < 0 ? -minZ + cameraZ : cameraZ - minZ;

    camera.far = cameraToFarEdge * 3;
    camera.lookAt(center);
    camera.updateProjectionMatrix();
  };

  return (
    <>
      <primitive object={scene} scale={props.scale} />
    </>
  );
};

const FailedLogin = () => {
  const camera = new THREE.PerspectiveCamera(45, 1, 0.0001, 5000);

  const { gradients } = colors;
  const gridItemStyle = {
    padding: "40px",
    position: "relative",
    margin: "auto",
  };

  const section1Style = {};
  const section2Style = {
    background: "#FCFCFC",
  };

  return (
    <PageLayout
      background={tripleLinearGradient(
        gradients.cover.main,
        gradients.cover.state,
        gradients.cover.stateSecondary,
        gradients.cover.angle
      )}
    >
      <DefaultNavbar
        routes={pageRoutes}
        action={{
          type: "external",
          route: "/",
        }}
        transparent
      />
      <VuiBox width="100%" background="#fff" sx={section1Style}>
        <VuiBox
          alignItems="center"
          justifyContent="center"
          mt={40}
          sx={{ margin: "200px auto 0px", width: { sm: "100%", md: "1000px", lg: "1200px" } }}
        >
          <Grid container spacing={0} sx={{ margin: "0 60" }}>
            <Grid item xs={12} sm={12} md={6} sx={gridItemStyle}>
              <VuiTypography variant="h2" color="white" fontWeight="bold" mb={5}>
                Unfortunately, you are not currently authorized to access our dashboard.
              </VuiTypography>
              <VuiTypography variant="h4" color="white" fontWeight="normal">
                If you would like to request early access, please use this form or send an email to
                rahul@polyhive.ai
              </VuiTypography>
              <br />
              <form action="https://formspree.io/f/meqdnlbp" method="POST">
                <VuiTypography variant="button" color="white" fontWeight="normal">
                  Full Name
                </VuiTypography>
                <VuiInput
                  sx={{ borderRadius: "5px", marginTop: "3px", marginBottom: "8px" }}
                  name="name"
                />
                <VuiTypography variant="button" color="white" fontWeight="normal">
                  Email
                </VuiTypography>
                <VuiInput
                  sx={{ borderRadius: "5px", marginTop: "3px", marginBottom: "10px" }}
                  name="email"
                />
                <VuiTypography variant="button" color="white" fontWeight="normal">
                  Company
                </VuiTypography>
                <VuiInput
                  sx={{ borderRadius: "5px", marginTop: "3px", marginBottom: "10px" }}
                  name="company"
                />
                <VuiTypography variant="button" color="white" fontWeight="normal">
                  Message
                </VuiTypography>
                <VuiInput
                  placeholder=""
                  multiline
                  rows={5}
                  sx={{ borderRadius: "5px", marginTop: "10px", marginBottom: "14px" }}
                  name="message"
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    borderRadius: "5px",
                    marginTop: "3px",
                    marginBottom: "10px",
                    width: "100%",
                    color: "#fff",
                    fontSize: "12px",
                  }}
                >
                  Submit
                </Button>
              </form>

              <br />
            </Grid>
            <Grid item xs={12} sm={12} md={6} sx={gridItemStyle}>
              <img
                src={HomeImage}
                alt="homeimage"
                style={{ width: "100%", paddingLeft: "50px", paddingRight: "50px" }}
              />
              <VuiBox
                sx={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  top: "-30px",
                  left: "10px",
                  "z-index": 1,
                }}
              >
                <Canvas>
                  <Suspense fallback={null}>
                    <OrbitControls autoRotate enableZoom={false} />
                    <Environment
                      background={false}
                      path="/"
                      files="royal_esplanade_1k.hdr"
                      blur={0.4}
                    />
                    <Model file={myGLB} scale={2} camera={camera} />
                  </Suspense>
                </Canvas>
              </VuiBox>
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>
    </PageLayout>
  );
};

export default FailedLogin;
