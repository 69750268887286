import * as React from "react";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";
import VolumeUp from "@mui/icons-material/VolumeUp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { HtmlTooltip } from "layouts/dashboards/default/components/CustomTooltip/index";

const Input = styled(MuiInput)`
  width: 42px;
`;

export default function InputSlider(props) {
  const [value, setValue] = React.useState(0);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < props.min) {
      setValue(props.min);
    } else if (value > props.max) {
      setValue(props.max);
    }
  };

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <Box className="retexture_form_header mt_1 mb_0">
      <Box className="header_flex">
        <Box className="title_tooltip_container">
          <Typography className="f_small c_white fw_500">{props.title}</Typography>
          <HtmlTooltip
            title={
              <Box width={300}>
                <Typography className="f_small c_white fw_500">{props.title}</Typography>
                <Typography className="f_small c_iconenabled fw_400">
                  {/* copy for L/R symmetry */}
                  {props.tooltipString}
                </Typography>
              </Box>
            }
            placement="top"
          >
            <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
          </HtmlTooltip>
        </Box>
      </Box>
      <Box className="header_flex ml_1">
        <Slider
          value={typeof value === "number" ? value : props.min}
          onChange={handleSliderChange}
          aria-labelledby="input-slider mr_1"
          className="input_slider"
          marks
          step={props.step}
          min={props.min}
          max={props.max}
          color="secondary"
        />
        <Box width={"50px"} className="ml_2">
          <input
            type="number"
            className="retexture_form_input"
            id="input-slider"
            name={props.name}
            value={value}
            onChange={handleInputChange}
            onBlur={handleBlur}
            aria-labelledby="input-slider"
            step={props.step}
            min={props.min}
            max={props.max}
          />
        </Box>
      </Box>
    </Box>
  );
}
