import colors from "assets/theme/base/colors";
import { Box, Typography, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import PolyhiveLogoWhite from "assets/icons/polyhivelogowhite.png";
import { useNavigate } from "react-router-dom";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import GradientIcon from "components/Custom/GradientIcon/index";
import CreditIcon from "assets/icons/crediticon.png";
import { useState, useEffect, useRef } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FoundationOutlinedIcon from "@mui/icons-material/FoundationOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { HtmlTooltip } from "layouts/dashboards/default/components/CustomTooltip/index";
import InputSlider from "../InputSlider/index";
import ReactCrop from "react-image-crop";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";
import { convertBytesToMB, getFileType } from "util/UtilFunctions";
import { fabric } from "fabric";
import { RotatingTriangles } from "react-loader-spinner";
import TestPreview from "assets/demo_assets/table_concept_output.png";
import Grid from "@mui/material/Grid";
import demo_image1 from "assets/demo_assets/render_chair_0.png";
import demo_image2 from "assets/demo_assets/render_chair_30.png";
import demo_image3 from "assets/demo_assets/render_chair_60.png";
import demo_image4 from "assets/demo_assets/render_chair_90.png";
import demo_image5 from "assets/demo_assets/render_chair_120.png";
import demo_image6 from "assets/demo_assets/render_chair_150.png";
import demo_image7 from "assets/demo_assets/render_chair_180.png";
import demo_image8 from "assets/demo_assets/render_chair_210.png";
import demo_image9 from "assets/demo_assets/render_chair_240.png";
import demo_image10 from "assets/demo_assets/render_chair_270.png";
import demo_image11 from "assets/demo_assets/render_chair_300.png";
import demo_image12 from "assets/demo_assets/render_chair_330.png";

import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import SubdirectoryArrowRightRoundedIcon from "@mui/icons-material/SubdirectoryArrowRightRounded";

const ESTIMATED_MESH_TIME = 10; // 60 seconds

const demo_grid = [
  demo_image1,
  demo_image2,
  demo_image3,
  demo_image4,
  demo_image5,
  demo_image6,
  demo_image7,
  demo_image8,
  demo_image9,
  demo_image10,
  demo_image11,
  demo_image12,
];

const MeshDemoModal = (props) => {
  const refImgFileInput = useRef(null);
  const [referenceImage, setReferenceImage] = useState(null);
  const canvasRef = useRef(null);
  const fabricCanvasRef = useRef(null);

  const [crop, setCrop] = useState({
    unit: "%", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
    aspect: 1 / 1,
  });
  const cropRef = useRef(null);
  cropRef.current = crop;
  const imgRef = useRef(null);

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("meshdemo_props.open", props.open);
    if (props.open === true) {
    }
  }, [props.open]);

  useEffect(() => {
    console.log("meshdemo_canvasRef.current_useEffect", canvasRef.current);
    console.log("meshdemo_canvasRef.current", canvasRef);
    const canvas = new fabric.Canvas(canvasRef.current, {
      isDrawingMode: true,
    });

    fabricCanvasRef.current = canvas; // Store the canvas instance

    canvas.freeDrawingBrush.width = (5 * 512) / 200;
    canvas.freeDrawingBrush.color = "rgba(0, 0, 0, 1)";

    // Set the canvas dimensions
    canvas.setDimensions(
      {
        width: 600,
        height: 512,
      },
      { backstoreOnly: true }
    );

    let lowerCanvasElement = canvas.lowerCanvasEl;
    lowerCanvasElement.style.width = "600px";
    lowerCanvasElement.style.height = "512px";

    // Modify the CSS of the upper canvas (used for interactions)
    let upperCanvasElement = canvas.upperCanvasEl;
    upperCanvasElement.style.width = "600px";
    upperCanvasElement.style.height = "512px";
    // Add a simple rectangle as an example
  }, [canvasRef.current]);

  const handleCanvasDispose = () => {
    if (fabricCanvasRef.current) {
      fabricCanvasRef.current.dispose();
    }
  };

  const handleRefImgFileButtonClick = () => {
    refImgFileInput.current.click();
  };

  const handleRefImgChange = (event) => {
    const fileUploaded = event.target.files[0];

    // props.handleUploadedFile(fileUploaded);
    setReferenceImage(fileUploaded);

    // get image dimensions and use that to set a square crop using % units
    const image = new Image();
    image.src = URL.createObjectURL(fileUploaded);
    image.onload = () => {
      const imageWidth = image.width;
      const imageHeight = image.height;

      // Reduce the size by 10% to add a 5% padding on each side
      const size = Math.min(imageWidth, imageHeight) * 0.9;
      const x = (imageWidth - size) / 2;
      const y = (imageHeight - size) / 2;

      // Convert these to percentages
      setCrop({
        unit: "%",
        x: (x / imageWidth) * 100,
        y: (y / imageHeight) * 100,
        width: (size / imageWidth) * 100,
        height: (size / imageHeight) * 100,
      });
    };

    event.target.value = null;
  };

  const renderStep0 = () => {
    return (
      <>
        <Box className="modal_large_form_container" mt={1}>
          <input
            type="file"
            ref={refImgFileInput}
            style={{ display: "none" }}
            multiple={true}
            onChange={handleRefImgChange}
            accept=".png, .jpg, .jpeg"
          />

          {/* Text Prompt Area */}
          <Box mb={2} className="retexture_form_ta" mt={1}>
            <Box className="title_tooltip_container">
              <Typography className="f_small c_white fw_500">
                What is the object you want to generate?
              </Typography>
              <HtmlTooltip
                title={
                  <Box width={350}>
                    <Typography className="f_small c_white fw_500">Object Name</Typography>
                    <Typography className="f_small c_iconenabled fw_400">
                      Please provide the name or type of object you're trying to texture. This helps
                      our system better understand the specific details of your 3D model, allowing
                      for more accurate and realistic texturing.
                    </Typography>
                  </Box>
                }
                placement="top"
              >
                <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
              </HtmlTooltip>
            </Box>
            <Box className="retexture_form_input_container">
              <input
                className="retexture_form_input"
                placeholder="For example: helmet, shirt, etc. (required)"
                id="object_name"
                name="object_name"
                autoComplete="off"
              />
            </Box>
          </Box>
          {/* <hr className="rt_form_hr" /> */}
          <Box className="retexture_form_ta" mb={1}>
            <Box className="title_tooltip_container">
              <Typography className="f_small c_white fw_500">Describe your desired mesh</Typography>
              <HtmlTooltip
                title={
                  <Box width={350}>
                    <Typography className="f_small c_white fw_500">Mesh Description</Typography>
                    <Typography className="f_small c_iconenabled fw_400">
                      Please describe the mesh you would like to create. Use descriptive language to
                      convey details such as color, material, pattern, and surface quality. The more
                      specific you are, the more accurately our system can generate the desired
                      texture.
                    </Typography>
                  </Box>
                }
                placement="top"
              >
                <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
              </HtmlTooltip>
            </Box>
            <Box className="retexture_form_textarea_container">
              <textarea
                className="retexture_form_textarea"
                placeholder="For example: “I want a viking styled helmet made of rusted steel with horns made of ivory”"
                id="object_description"
                name="object_description"
              />
            </Box>
          </Box>

          <Box className="align_center_row" mb={0} mt={1}>
            <Box className="title_tooltip_container">
              <Typography className="f_small c_white fw_500">Reference Image</Typography>
              <HtmlTooltip
                title={
                  <Box width={200}>
                    <Typography className="f_small c_white fw_500">Reference Image</Typography>
                    <Typography className="f_small c_iconenabled fw_400">
                      Upload an image that will guide the style of the generated texture.
                    </Typography>
                  </Box>
                }
                placement="top"
              >
                <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
              </HtmlTooltip>
            </Box>
            {referenceImage === null && (
              <Button
                className="default_btn bg_hivegradient align_center_row justify_center c_white f_small fw_500 ml_1"
                onClick={handleRefImgFileButtonClick}
              >
                Upload
                <FileUploadRoundedIcon className="icon_medium c_white" />
              </Button>
            )}

            {referenceImage && (
              <Box className="align_center_row">
                <Typography className="f_small c_white fw_500 ml_1 text_nowrap">
                  {referenceImage.name}
                </Typography>
                <CloseRoundedIcon
                  className="icon_small c_white ml_1 br_100 bg_lightblack icon_medium"
                  onClick={() => {
                    setReferenceImage(null);
                  }}
                />
              </Box>
            )}
          </Box>
          {referenceImage && (
            <>
              <Box className="align_center_row" mb={1} mt={0}>
                <ReactCrop aspect={1 / 1} crop={crop} onChange={(newCrop) => setCrop(newCrop)}>
                  <img
                    ref={imgRef}
                    className="demo_ref_img"
                    src={URL.createObjectURL(referenceImage)}
                  />
                </ReactCrop>
              </Box>
              <Box mb={1}>
                <InputSlider
                  title="Reference Weight"
                  name="reference_weight"
                  tooltipString="The reference weight controls how much the reference image will influence the generated texture. A higher reference weight will result in a texture that more closely resembles the reference image."
                  min={0}
                  max={1}
                  step={0.1}
                  defaultValue={0.3}
                />
              </Box>
            </>
          )}
        </Box>

        {/* end of body, start of footer */}
        <Box className="prompting_form_footer">
          <hr className="rt_form_hr" />
          <Button
            className={step === 0 ? "rt_form_btn" : "rt_form_btn_demo"}
            onClick={() => {
              setLoading(true);
              setTimeout(() => {
                handleCanvasDispose(); // Dispose of the canvas
                setLoading(false);
                setStep(1); // Change to the next step
              }, 5000);
            }}
            startIcon={
              step === 0 ? (
                <ArrowForwardRoundedIcon className="c_white" />
              ) : (
                <RefreshRoundedIcon className="c_white" />
              )
            }
            size="large"
            name="submitbutton"
          >
            {step === 0 ? "Generate Concept Art" : "Regenerate Concept Art"}
          </Button>

          {/* <hr className="rt_form_hr" /> */}
        </Box>
      </>
    );
  };

  const renderStep1 = () => {
    return (
      <>
        <Box className="modal_large_form_container" mt={1}>
          <input
            type="file"
            ref={refImgFileInput}
            style={{ display: "none" }}
            multiple={true}
            onChange={handleRefImgChange}
            accept=".png, .jpg, .jpeg"
          />

          {/* Text Prompt Area */}

          {/* <hr className="rt_form_hr" /> */}
          <Typography className="f_small c_white fw_500">
            Continue to generate viewpoint images
          </Typography>
        </Box>

        {/* end of body, start of footer */}
        <Box className="prompting_form_footer">
          <hr className="rt_form_hr" />
          <Button
            className="rt_form_btn"
            onClick={() => {
              setLoading(true);
              setTimeout(() => {
                setLoading(false);
                setStep(2); // Change to the next step
              }, 5000);
            }}
            size="large"
            name="submitbutton"
          >
            Generate Viewpoint Images
          </Button>
        </Box>
      </>
    );
  };

  const renderStep2 = () => {
    return (
      <>
        <Box className="modal_large_form_container" mt={1}>
          <input
            type="file"
            ref={refImgFileInput}
            style={{ display: "none" }}
            multiple={true}
            onChange={handleRefImgChange}
            accept=".png, .jpg, .jpeg"
          />

          {/* Text Prompt Area */}

          {/* <hr className="rt_form_hr" /> */}
          <Typography className="f_small c_white fw_500 mb_2">
            Continue to generate 3D textured mesh
          </Typography>

          <Grid container spacing={2}>
            {demo_grid.map((image, index) => {
              return (
                <Grid item xs={3} sm={3}>
                  <Box className="mesh_grid_item">
                    <>
                      <img src={image} alt="sword" className="thumb_img" />
                    </>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>

        {/* end of body, start of footer */}
        <Box className="prompting_form_footer">
          <hr className="rt_form_hr" />
          <Button
            className="rt_form_btn"
            onClick={() => {
              const job_params = { seed: 2957782 };
              // set to the url of the image with the id of selectedImageId
              job_params.image_s3_path = TestPreview;
              job_params.texture_resolution = 4096;
              props.addJob({
                job_id: "nopoll",
                job_name: "mesh",
                version_id: "3929c807-6795-4f7c-af63-52e5ffbf0c0a",
                job_params: job_params,
                text_prompt: "A blue wooden table, stylized game art",
                //
              });

              // start a timer thread that will wait for 120 seconds, then log "hello world"
              setTimeout(() => {
                props.updateDemoJob("nopoll");
              }, ESTIMATED_MESH_TIME * 1000); // 120 seconds in milliseconds
              // setStep(3);

              props.startSnackbar({
                message: "Job submitted successfully.",
                type: "success",
              });

              props.handleClose();
            }}
            startIcon={<ArrowForwardRoundedIcon className="c_white" />}
            size="large"
            name="submitbutton"
          >
            Generate Mesh
          </Button>
        </Box>
      </>
    );
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal_container_large">
        {/* Header */}
        <Box className="align_center_row modal_large_header bg_focus">
          <Box className="align_center_row">
            <FoundationOutlinedIcon className="c_white lightbulb_icon" />
            <Typography variant="h6" sx={{ color: "#fff" }}>
              Mesh Generate
            </Typography>
          </Box>
        </Box>

        {/* Content */}
        <Box className="d_flex modal_large_content">
          <Box className="modal_large_content_box">
            {step === 0 ? (
              renderStep0()
            ) : step === 1 ? (
              renderStep0()
            ) : step === 2 ? (
              renderStep2()
            ) : (
              <></>
            )}
          </Box>
          <Box
            className={
              step === 2
                ? "modal_large_content_box demo_canvas_container mt_2"
                : "modal_large_content_box demo_canvas_container mt_2 bg_iconenabled mr_1"
            }
          >
            <canvas ref={canvasRef} className="mesh_demo_canvas" />
            {loading && (
              <Box className="demo_loading_overlay">
                <Spinner />
                <Typography className="mt_4 c_white f_medium fw_500">
                  {step === 0 ? "Generating Concept Image..." : "Generating Viewpoint Images..."}
                </Typography>
              </Box>
            )}
            {step === 1 || step === 2 ? (
              <>
                <img className="w100 mesh_demo_image" src={TestPreview} />
                <>
                  <Button
                    className={step === 1 ? "rt_form_btn" : "rt_form_btn_demo"}
                    onClick={() => {
                      setLoading(true);
                      setTimeout(() => {
                        setLoading(false);
                        setStep(2); // Change to the next step
                      }, 5000);
                    }}
                    startIcon={
                      step === 1 ? (
                        <ArrowForwardRoundedIcon className="c_white" />
                      ) : (
                        <RefreshRoundedIcon className="c_white" />
                      )
                    }
                    size="large"
                    name="submitbutton"
                  >
                    {step === 1 ? "Generate Viewpoint Images" : "Regenerate Viewpoint Images"}
                  </Button>
                </>
              </>
            ) : (
              <></>
            )}
          </Box>
        </Box>

        {/* Footer */}
        <Box className="align_center_row modal_large_footer">
          <Box className="btn_container">
            {/* Your existing button code */}{" "}
            <Button
              onClick={props.handleClose}
              variant="text"
              className="default_btn c_white f_medium fw_500"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

const Spinner = () => {
  return (
    <RotatingTriangles
      visible={true}
      height="80"
      width="80"
      ariaLabel="rotating-triangels-loading"
      wrapperStyle={{
        position: "absolute",
        // top: "100px",
        left: "50%",
        transform: "translate(-50%, -50%)",
        marginTop: "-100px",
      }}
      wrapperClass="rotating-triangels-wrapper"
    />
  );
};

export default MeshDemoModal;
