import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import colors from "assets/theme/base/colors";
import { version } from "dropzone";
import { Button, Grid, Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import amplitude from "amplitude-js";
import Tooltip from "@mui/material/Tooltip";
import DeleteVersionModal from "../DeleteVersionModal";
import DarkTooltip from "../../CustomTooltip/index";
import ChangeVersionModal from "../ChangeVersionModal/index";
import { getFileType, parseDateUTC } from "util/UtilFunctions";
import { useVisionUIController } from "context";
import Thumbnailer from "../Thumbnailer/index";
import { logoutUser } from "layouts/authentication/AuthFunctions";
import { ProgressBar, Oval } from "react-loader-spinner";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";

const Spinner = () => {
  return (
    <Oval
      ariaLabel="loading-indicator"
      height={30}
      width={30}
      strokeWidth={5}
      strokeWidthSecondary={2}
      color={colors.icon.disabled}
      secondaryColor="white"
      wrapperStyle={{
        position: "absolute",
        left: "27px",
        top: "20px",
        margin: "auto",
      }}
    />
  );
};

const MODEL_FILE_TYPES = ["gltf", "glb", "fbx", "obj"];

const VersionItem = (props) => {
  const selectedVersionRef = useRef();
  const [controller] = useVisionUIController();

  const { activeOrg } = controller;

  selectedVersionRef.current = props.selectedVersion;

  const versionRef = useRef();
  versionRef.current = props.version;

  const [isEditing, setIsEditing] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [versionModalOpen, setVersionModalOpen] = React.useState(false);
  const [aiTextPrompt, setAiTextPrompt] = React.useState("");
  const [thumbnailerArray, setThumbnailerArray] = React.useState([]);
  const [versionJob, setVersionJob] = React.useState(null);

  useEffect(() => {
    // if props.version.aiJobId is not null, then we need to make a call to the server to get the status of the job
    if (props.version.aiJobId) {
      getJobDataForVersion();
    }
    if (props.version.thumbnail === "" && MODEL_FILE_TYPES.includes(props.version.fileType)) {
      console.log("missing version thumbnail", props.version);
      // take a new thumbnail
      const copyArray = [];
      copyArray.push({
        url: props.version.filePath,
        file: {
          name: props.version.fileName,
        },
        headers: {
          "ASSOCIATED-UUID": props.version.versionId,
        },
      });

      setThumbnailerArray([...copyArray]);
    }
  }, [props.version]);

  const getJobDataForVersion = async () => {
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/textureJobInfo`);
    const params = new URLSearchParams();
    params.set("jobId", props.version.aiJobId);
    url.search = params.toString();
    const resp = fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
    });
    const data = await resp.then((res) => res.json());
    console.log("version item job data", data);

    const setJob = {
      jobId: data.jobId,
      textPrompt: data.textPrompt,
      jobName: data.jobName,
      job_params: data.jobParams,
    };
    setVersionJob(setJob);
  };

  const setCurrentVersion = () => {
    logAuthorizedWombatEvent("setCurrentVersionClicked", { versionId: props.version.versionId });
    props.setCurrentVersion(props.version.versionId);
  };

  const applyRename = () => {
    const newVersionName = document.getElementById(props.version.versionId + "input").value;

    logAuthorizedWombatEvent("versionRenameConfirmed", {
      versionId: props.version.versionId,
    });

    props.applyRename(props.version.versionId, newVersionName, setIsEditing);
    // and set isEditing to false
  };

  const downloadVersion = () => {
    logAuthorizedWombatEvent("versionDownloadClicked", {
      versionId: props.version.versionId,
    });
    // if (props.subscription.name !== "polyhive_plus_plan") {
    //   props.handleDownloadModalOpen();
    //   logAuthorizedWombatEvent("freeUserAttemptedToDownloadFromVersionItem", {});

    //   return;
    // }

    fetch(props.version.filePath).then((response) => {
      //console.log("response", response);
      if (response.status === 200) {
        // amplitude.getInstance().logEvent("versionDownloadSuccess", {
        //   assetId: props.version.versionId,
        // });
        logAuthorizedWombatEvent("versionDownloadSuccess", {
          versionId: props.version.versionId,
        });

        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = props.version.fileName;
          alink.click();
        });
      } else {
        // amplitude.getInstance().logEvent("versionDownloadFailed", {
        //   assetId: props.version.versionId,
        //   error: response.statusText,
        // });
        logAuthorizedWombatEvent("setCurrentVersionClicked", {
          versionId: props.version.versionId,
          error: response.statusText,
        });
      }
    });
  };

  useEffect(() => {
    setIsEditing(false);
  }, [props.currentObject]);

  const deleteVersion = () => {
    logAuthorizedWombatEvent("versionDeleteConfirmed", {
      versionId: props.version.versionId,
    });

    props.deleteVersion(props.version.versionId);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleVersionModalOpen = () => {
    setVersionModalOpen(true);
  };
  const handleVersionModalClose = () => {
    //console.log("rename modal close");
    setVersionModalOpen(false);
  };

  const handleFinishedScreenshot = (screenshot) => {
    console.log("handleFinishedScreenshot called in VersionItem", screenshot);
    handleBlob({ thumbnailURL: screenshot.thumbnailURL, thumbnailFile: screenshot.thumbnail });
  };

  const handleBlob = (obj) => {
    const headers = {
      "Content-Type": "application/octet-stream",
      "ASSOCIATED-UUID": props.version.modelId,
      "FILE-SIZE": props.version.fileSize.toString(),
      "X-ORG-ID": activeOrg,
      "VERSION-ID": props.version.versionId,
    };
    const response_thumbnail_upload = fetch(
      `${process.env.REACT_APP_BACKEND_URL}/upload/uploadThumbnailForVersion`,
      {
        body: obj.thumbnailFile,
        credentials: "include",
        headers: headers,
        method: "POST",
      }
    )
      .then((response) => {
        //console.log("thumbnail response", response);
        return response;
      })
      .then((response) => {
        if (response.status === 200) {
          props.startSnackbar({
            message: "Thumbnail successfully changed.",
            type: "success",
          });
          props.version.thumbnail = obj.thumbnailURL;
          // const updatedVersionArray = versionArray.map((version) => {
          //   if (
          //     version.versionId === currentObject.versionId ||
          //     version.versionId === currentObject.modelId
          //   ) {
          //     version.thumbnail = obj.thumbnailURL;
          //   }
          //   version.modelId = headers["ASSOCIATED-UUID"];
          //   return version;
          // });
          // setVersionArray(updatedVersionArray);

          if (
            typeof props.version.isCurrentVersion === "undefined" ||
            props.version.isCurrentVersion === true
          ) {
            console.log("set new version in frontend", {
              thumbnail: obj.thumbnailURL,
              modelId: props.version.versionId,
            });
          }
        } else if (response.status === 403) {
          logoutUser(navigate);
        } else {
          props.startSnackbar({
            message: response.statusText,
            type: "error",
          });
          //console.log("thumbnail upload failed, cancelling grid item addition");
        }
      });

    // this is a folder
    return;
  };
  return (
    <>
      <Grid item xs={12}>
        <VuiBox
          mt={1}
          mb={0}
          width="100%"
          height={isEditing ? "160px" : "120px"}
          className="bg_lightblack"
          sx={{
            borderRadius: "4px",
            border:
              selectedVersionRef.current === props.version.versionId
                ? "1px solid " + colors.icon.quaternaryfocusdark
                : "1px solid rgba(0,0,0,0)",
            "&:hover": {
              border:
                selectedVersionRef.current === props.version.versionId
                  ? "1px solid " + colors.icon.quaternaryfocusdark
                  : "1px solid " + colors.highlight.light,
            },
          }}
          padding="4px"
          position="relative"
          onClick={() => {
            if (props.defaultAssets) {
              if (props.defaultAssets.includes(props.version.versionId)) {
                console.log("default version clicked", props.version);
                logAuthorizedWombatEvent("defaultVersionClicked", {
                  versionId: props.version.versionId,
                });
              } else {
                if (props.version.aiJobId !== null) {
                  console.log("non default version clicked", props.version);
                  logAuthorizedWombatEvent("nonDefaultAIVersionClicked", {
                    versionId: props.version.versionId,
                  });
                } else {
                  logAuthorizedWombatEvent("nonDefaultVersionClicked", {
                    versionId: props.version.versionId,
                  });
                }
              }
            }

            props.setSelectedVersion(versionRef.current.versionId);
            //console.log("setting currentObject to ", props.version);
            props.setCurrentObject(versionRef.current);
          }}
        >
          {" "}
          <VuiBox
            sx={{
              width: "100%",
              // height: "120px",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            {isEditing ? (
              <></>
            ) : props.version.isCurrentVersion ? (
              <VuiTypography
                variant="body2"
                sx={{
                  fontWeight: 450,
                  fontSize: "0.8rem",
                  color: colors.highlight.light,
                  textAlign: "center",
                  marginTop: "12px",
                  marginRight: "20px",
                  position: "absolute",
                  right: "0",
                  top: "0",
                }}
              >
                Current
              </VuiTypography>
            ) : selectedVersionRef.current === props.version.versionId ? (
              <>
                <Button
                  variant="text"
                  textTransform="none"
                  sx={{
                    border: "1.5px solid " + colors.icon.quaternaryfocusdark,
                    color: colors.icon.quaternaryfocus,
                    fontSize: "0.7rem",
                    width: "90px",
                    height: "25px",
                    marginRight: "12px",
                    marginTop: "12px",
                    fontWeight: "400",
                    right: "0",
                    top: "0",
                    position: "absolute",
                    lineHeight: "1",
                    textTransform: "none",
                    borderRadius: "5px",

                    "&:hover": {
                      color: "#fff",
                      border: "1.5px solid " + colors.highlight.light,
                    },
                    "&:focus": {
                      color: "#fff",
                      border: "1.5px solid " + colors.icon.quaternaryfocusdark,
                    },
                  }}
                  onClick={handleVersionModalOpen}
                >
                  Set Current
                </Button>
              </>
            ) : (
              <></>
            )}
          </VuiBox>
          <VuiBox
            sx={{
              width: "100%",
              height: "100%",
              //   alignItems: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <VuiBox
              sx={{
                width: "70px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                // overflow: "hidden",
                // set background image to src={
              }}
            >
              {["vrm", "gltf", "glb", "fbx", "obj"].includes(getFileType(props.version.fileName)) &&
              props.version.thumbnail === "" ? (
                <VuiBox
                  sx={{
                    height: "70px",
                    // width: "70px",
                    minHeight: "70px",
                    minWidth: "70px",
                    borderRadius: "5%",
                    marginTop: "5px",
                    marginLeft: "6px",
                  }}
                >
                  <Spinner />
                </VuiBox>
              ) : (
                <>
                  <img
                    src={
                      ["vrm", "gltf", "glb", "fbx", "obj"].includes(
                        getFileType(props.version.fileName)
                      )
                        ? props.version.thumbnail
                        : props.version.filePath
                    }
                    alt="image description"
                    // width="70px"
                    style={{
                      // position: "relative",
                      // top: "50%",
                      height: "70px",
                      // width: "70px",
                      minHeight: "70px",
                      minWidth: "70px",
                      borderRadius: "5%",
                      marginTop: "5px",
                      marginLeft: "6px",
                      objectFit: "cover",
                      // transform: "translate(20%, -100%)",
                    }}
                  />
                </>
              )}

              <VuiBox
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  marginLeft: "8px",
                }}
              >
                <CloudDownloadOutlinedIcon
                  sx={{
                    color: colors.icon.enabled,
                    fontSize: "1.2rem",
                    margin: "5px auto",
                    "&:hover": {
                      color: colors.highlight.light,
                    },
                  }}
                  onClick={downloadVersion}
                />
                {props.version.isCurrentVersion ? (
                  <Tooltip title={"Current version cannot be deleted"} placement="bottom">
                    <DeleteForeverOutlinedIcon
                      sx={{
                        color: colors.icon.disabled,
                        fontSize: "1.2rem",
                        margin: "5px auto",
                        "&:hover": {
                          color: colors.icon.disabled,
                        },
                      }}
                    />
                  </Tooltip>
                ) : (
                  <DeleteForeverOutlinedIcon
                    sx={{
                      color: colors.icon.enabled,
                      fontSize: "1.2rem",
                      margin: "5px auto",
                      "&:hover": {
                        color: colors.error.main,
                      },
                    }}
                    onClick={handleOpenDeleteModal}
                  />
                )}
                {props.version.isCurrentVersion ? (
                  <Tooltip
                    title={"Current version must be renamed from the content view."}
                    placement="bottom"
                  >
                    <DriveFileRenameOutlineOutlinedIcon
                      sx={{
                        color: colors.icon.disabled,
                        fontSize: "1.2rem",
                        margin: "5px auto",
                      }}
                      onClick={() => {}}
                    />
                  </Tooltip>
                ) : (
                  <DriveFileRenameOutlineOutlinedIcon
                    sx={{
                      color: colors.icon.enabled,
                      fontSize: "1.2rem",
                      margin: "5px auto",
                      "&:hover": {
                        color: colors.icon.quaternaryfocus,
                      },
                    }}
                    onClick={() => {
                      setIsEditing(true);
                      //console.log("edit", versionRef.current.fileName);
                    }}
                  />
                )}
              </VuiBox>
            </VuiBox>
            <VuiBox
              sx={{
                height: "100%",
                marginLeft: "10px",
                marginTop: "7px",
                // alignItems: "top",
                // position: "relative",
                // top: "0px",
              }}
            >
              <>
                <VuiBox
                  sx={{
                    width: "100%",
                    flexDirection: "column",
                    display: isEditing ? "flex" : "none",
                    marginLeft: "10px",
                  }}
                >
                  <input
                    variant="outlined"
                    id={props.version.versionId + "input"}
                    key={props.version.versionId + "input"}
                    style={{
                      width: "100%",
                      marginBottom: "6px",
                      background: colors.border.main,
                      border: "none",
                      color: "#fff",
                      borderRadius: "4px",
                      padding: "0px 10px",
                      height: "30px",
                    }}
                    defaultValue={props.version.fileName}
                  />
                  <VuiBox
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      variant="text"
                      textTransform="none"
                      sx={{
                        border: "1.5px solid " + colors.icon.disabled,
                        color: "#fff",
                        fontSize: "0.7rem",
                        width: "60px",
                        height: "30px",
                        // margin: "0px !important",
                        padding: "0px !important",
                        fontWeight: "400",
                        lineHeight: "1",
                        textTransform: "none",
                        borderRadius: "5px",
                        marginRight: "8px",
                      }}
                      onClick={() => {
                        setIsEditing(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="text"
                      textTransform="none"
                      sx={{
                        border: "1px solid " + colors.icon.quaternaryfocus,
                        color: colors.icon.quaternaryfocus,
                        fontSize: "0.7rem",
                        width: "60px",
                        height: "30px",
                        // margin: "0px !important",
                        padding: "0px !important",
                        fontWeight: "400",
                        lineHeight: "1",
                        textTransform: "none",
                        borderRadius: "5px",
                        marginRight: "24px",
                      }}
                      onClick={applyRename}
                    >
                      Update
                    </Button>
                  </VuiBox>
                </VuiBox>
              </>
              <Box className={isEditing ? "" : "mb_05"}>
                <DarkTooltip title={props.version.fileName}>
                  <VuiTypography
                    variant="body2"
                    sx={{
                      fontWeight: 450,
                      fontSize: "0.9rem",
                      color: "#fff",
                      width:
                        selectedVersionRef.current === props.version.versionId
                          ? props.version.isCurrentVersion
                            ? "165px"
                            : "130px"
                          : props.version.isCurrentVersion
                          ? "165px"
                          : "205px",

                      textAlign: "left",
                      marginLeft: "10px",
                      // marginBottom: "20px",
                      marginTop: "0px",
                      display: isEditing ? "none" : "flex",

                      // display: "flex", alignItems: "center", justifyContent: "center"
                      // position: "relative",
                      // top: "50%",
                      // transform: "translate(0%, -100%)",
                    }}
                  >
                    <span
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {props.version.fileName}
                    </span>
                  </VuiTypography>
                </DarkTooltip>
              </Box>
              <Box className="mb_05">
                {props.version.aiJobId !== null && typeof props.version.aiJobId !== "undefined" ? (
                  // <DarkTooltip title={"Prompt: " + '"' + aiTextPrompt + '" \n' + "Seed"}>
                  <VuiBox
                    sx={{
                      width: "150px",
                      height: "20px",
                      display: "flex",
                    }}
                  >
                    <VuiBox
                      sx={{
                        width: "100px",
                        height: "20px",
                        display: "flex",
                        marginLeft: "10px",
                        marginBottom: "5px",
                        borderRadius: "4px",
                        border: "1px solid " + colors.icon.quaternaryfocus,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <VuiTypography
                        variant="body2"
                        sx={{
                          fontWeight: 450,
                          fontSize: "0.8rem",
                          color: colors.icon.quaternaryfocus,
                          textAlign: "left",
                        }}
                      >
                        AI Retextured
                      </VuiTypography>
                    </VuiBox>
                    <VuiBox
                      sx={{
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        marginLeft: "5px",
                        marginBottom: "5px",
                        borderRadius: "4px",
                        // border: "1px solid " + colors.icon.tertiaryfocus,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <InfoOutlinedIcon
                        sx={{
                          color: colors.icon.quaternaryfocus,
                          fontSize: "1rem",
                          marginLeft: "5px",
                          "&:hover": {
                            color: colors.white.main,
                          },
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          logAuthorizedWombatEvent("aiJobDetailsInfoClicked", {
                            versionId: props.version.versionId,
                            aiJobId: props.version.aiJobId,
                          });

                          props.openJobDetails(versionJob);
                        }}
                      />
                    </VuiBox>
                  </VuiBox>
                ) : (
                  // </DarkTooltip>
                  <VuiBox
                    sx={{
                      width: "40px",
                      height: "20px",
                      display: "flex",
                      background: "none",
                      borderRadius: "50%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  ></VuiBox>
                )}
              </Box>
              <VuiTypography
                variant="body2"
                sx={{
                  fontWeight: 450,
                  fontSize: "0.8rem",
                  color: colors.icon.enabled,
                  textAlign: "left",
                  marginLeft: "10px",
                  // display: "flex", alignItems: "center", justifyContent: "center"
                  // position: "relative",
                  // top: "50%",
                  // transform: "translate(0%, -100%)",
                }}
              >
                {parseDateUTC(props.version.lastUpdated)} by
              </VuiTypography>
              <VuiTypography
                variant="body2"
                sx={{
                  fontWeight: 450,
                  fontSize: "0.8rem",
                  color: colors.icon.quaternaryfocus,
                  textAlign: "left",
                  marginLeft: "10px",

                  // display: "flex", alignItems: "center", justifyContent: "center"
                  // position: "relative",
                  // top: "50%",
                  // transform: "translate(0%, -100%)",
                }}
              >
                {props.version.username}
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        </VuiBox>
      </Grid>
      <DeleteVersionModal
        handleDelete={deleteVersion}
        open={isDeleteModalOpen}
        handleClose={handleCloseDeleteModal}
      />
      <ChangeVersionModal
        handleChange={setCurrentVersion}
        open={versionModalOpen}
        handleClose={handleVersionModalClose}
      />
      <Thumbnailer
        data={thumbnailerArray}
        handleFinishedScreenshot={handleFinishedScreenshot}
        startSnackbar={props.startSnackbar}
      />
    </>
  );
};

export default VersionItem;
