import { forwardRef, useEffect, useRef, useState } from "react";
import VRMLoader from "../VRMLoader";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import data from "layouts/pages/rtl/components/Projects/data/index";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import * as THREE from "three";
import { getFileType } from "util/UtilFunctions";

// props  = data: {url: fileURL for VRMLoading,
//           headers: headers for VRMLoading,
//           file: file to return in callback,
//           },
//           callback: function to call on completed screenshot,
const VRMScreenshot = forwardRef((props, ref) => {
  const childRef = useRef(null);

  // create 3 character random string to use as identifier for this component
  const id = useState(Math.random().toString(36).substring(2, 3))[0];

  //create screenshotcomplete state
  const [screenshotComplete, setScreenshotComplete] = useState(false);

  const loaderCallback = (data) => {
    props.callback({
      headers: props.data.headers,
      thumbnail: data.thumbnailFile,
      thumbnailURL: data.thumbnailURL,
      file: props.data.file,
      url: props.data.url,
    });
    setScreenshotComplete(true);
    //console.log("VRMScreenshot loaderCallback called for id: ", id);
  };

  useEffect(() => {
    console.log("VRMScreenshot Instantiated for id ", id);
  }, []);

  useEffect(() => {
    //console.log("VRMScreenshot url changed for id ", id);
    // reset screenshotComplete state
    if (["png", "jpg", "jpeg"].includes(getFileType(props.data.file.name))) {
      // const thumbnail = URL.createObjectURL(props.data.file);

      props.callback({
        headers: props.data.headers,
        thumbnail: props.data.file,
        thumbnailURL: props.data.file,
        file: props.data.file,
        url: props.data.url,
      });
      setScreenshotComplete(true);
    } else {
      setScreenshotComplete(false);
    }

    // load new model

    // childRef.current.loadModel(props.data.url);
  }, [props.data.url]);

  useEffect(() => {
    console.log("screenshot complete: ", props.data.complete);
  }, [props.data.complete]);

  return (
    <>
      {screenshotComplete ||
      ["png", "jpg", "jpeg"].includes(getFileType(props.data.file.name)) ? null : (
        <Grid item xs={4}>
          <VuiBox
            sx={{
              position: "absolute",
              left: "-10000px",
              bottom: "300px",
              background: "none",
              borderRadius: "20px",
              textAlign: "center",
              height: "255px",
              width: "255px",
              zIndex: "-1",
            }}
          >
            {/* {props.data.complete === true ? null : ( */}
            <VRMLoader
              ref={childRef}
              callback={loaderCallback}
              url={props.data.url}
              errorCB={props.errorCB}
              fileType={getFileType(props.data.file.name)}
              assetId={props.data.headers["ASSOCIATED-UUID"]}
              assetName={props.data.file.name}
              texture={props.texture}
            />
            {/* )} */}
          </VuiBox>
        </Grid>
      )}
    </>
  );
});

export default VRMScreenshot;
