import { Box, Typography, Grid } from "@mui/material";
import GradientIcon from "components/Custom/GradientIcon/index";
import FormatPaintOutlinedIcon from "@mui/icons-material/FormatPaintOutlined";
import FormatColorFillOutlinedIcon from "@mui/icons-material/FormatColorFillOutlined";
import HighlightOutlinedIcon from "@mui/icons-material/HighlightOutlined";
import BrowseGalleryOutlinedIcon from "@mui/icons-material/BrowseGalleryOutlined";
import TuneIcon from "@mui/icons-material/Tune";
import AnimationIcon from "@mui/icons-material/Animation";
import Button from "@mui/material/Button";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import dim2 from "assets/home/test1.png";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import colors from "assets/theme/base/colors";

import { Link } from "react-router-dom";

const grid_contents = [
  {
    title: <>Reduce manual 3D creation time by {">"} 90%</>,
    description:
      "Create production quality assets in minutes, not hours. Supercharge your 3D workflow with Polyhive.",
    icon: BrowseGalleryOutlinedIcon,
  },
  {
    title: "Generate 3D assets with total control and precision",
    description:
      "Use the AI Canvas, Preview Process, and 3D Inpainting features to leverage generative AI while maintaining creative control.",
    icon: TuneIcon,
  },
  {
    title: "Customize Polyhive for your art style and graphics pipeline",
    description:
      "With custom AI models, broad filetype support, and a powerful API, Polyhive can easily integrate into any professional graphics workflows.",
    icon: AnimationIcon,
  },
];

const barChartDataVertical = [
  {
    name: "Hours to texture",
    data: [
      {
        x: "Manual Modeling",
        y: 2000,
        fillColor: colors.error.main,
      },
      {
        x: "With Polyhive",
        y: 167,
        fillColor: colors.success.main,
      },
    ],
  },
];

const barChartOptionsVertical = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      fontSize: "12px",
      fontFamily: undefined,
    },
    onDatasetHover: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
    },
    theme: "dark",
  },
  xaxis: {
    categories: ["Manual Creation", "With Polyhive"],
    show: true,
    labels: {
      show: true,
      style: {
        colors: ["#575a6b", colors.icon.quaternaryfocusdark],
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Plus Jakarta Display",
      },
    },
    axisBorder: {
      show: true,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    color: "black",
    labels: {
      show: true,
      style: {
        colors: "#575a6b",
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Plus Jakarta Display",
      },
    },
    axisBorder: {
      show: true,
    },
  },
  grid: {
    strokeDashArray: 0,
    borderColor: "#56577A",
    yaxis: {
      lines: {
        show: false,
      },
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    // type: "solid",
    colors: [colors.icon.quaternaryfocus, "#000"],
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      columnWidth: "40px",
    },
  },
};

const Content1 = () => {
  return (
    <div className="content1_section">
      <div className="section">
        <div className="hl d_flex d_flex_column w100">
          <Typography className="c_textblack fw_600 f_xxlarge lh_xxlarge ls_1 ta_center mb_1">
            <span
              style={{
                height: "62px",
              }}
              className="c_hivegradient_animated"
            >
              Polyhive has saved thousands of hours for 3D professionals
            </span>
          </Typography>
          {/* <Typography className="c_textblack fw_400 f_mediumlarge ta_center">
            Reduce 3D content creation time and increase artist productivity with Polyhive’s
            AI-powered texturing suite.
          </Typography> */}
          <div className="align_center_row mt_10">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <div className="">
                  <VerticalBarChart
                    title="Hours to generate 1000 assets"
                    description="Polyhive saves 90% of the time it takes to manually model and texture 3D assets."
                    data={barChartDataVertical}
                    options={barChartOptionsVertical}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className="align_center_row mr_3 h100">
                  <div className="hl d_flex d_flex_column w100">
                    <Typography className="c_textblack fw_600 f_xlarge lh_large ls_1">
                      Automate your organization's 3D workflows with Polyhive today.
                    </Typography>
                    <Typography className="c_textblack fw_400 f_mediumlarge mt_3">
                      With Polyhive's Studio Plan, you can reduce 3D content creation time and
                      increase artist productivity at scale.
                    </Typography>
                    <Link to="/contact">
                      <Button
                        className="bg_quaternaryfocusdark c_brightwhite hero_btn_2 f_mid fw_600 mt_3"
                        endIcon={
                          //   <GradientIcon>
                          <CalendarMonthOutlinedIcon />
                          //   </GradientIcon>
                        }
                      >
                        Contact Sales
                      </Button>
                    </Link>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={10} className="mt_1">
            {grid_contents.map((item, index) => {
              return (
                <Grid item xs={12} sm={12} md={4}>
                  <Box className="content5_grid_item">
                    <GradientIcon>
                      <item.icon className="icon_xxxxlarge" />
                    </GradientIcon>
                    <Typography className="c_textblack fw_500 f_large ls_1 mb_1 mt_1 lh_mid">
                      {item.title}
                    </Typography>
                    <Typography className="c_icondisabled fw_400 f_medium">
                      {item.description}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Content1;
