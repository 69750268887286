import { Box, Typography, Grid } from "@mui/material";
import dim1 from "assets/images/ecommerce/dimensions.avif";
import dim2 from "assets/images/ecommerce/dim2.avif";
import dim3 from "assets/images/ecommerce/dim3.avif";
import ChestTextured from "assets/images/chest_render_textured_1.png";
import ChestUntextured from "assets/images/chest_render_untextured_1.png";
import FormatPaintOutlinedIcon from "@mui/icons-material/FormatPaintOutlined";
import FormatColorFillOutlinedIcon from "@mui/icons-material/FormatColorFillOutlined";
import HighlightOutlinedIcon from "@mui/icons-material/HighlightOutlined";
import ImageSlider from "./components/ImageSlider/index";
import GradientIcon from "components/Custom/GradientIcon/index";
import DeviceHubOutlinedIcon from "@mui/icons-material/DeviceHubOutlined";
import FilterBAndWOutlinedIcon from "@mui/icons-material/FilterBAndWOutlined";
import ModelTrainingOutlinedIcon from "@mui/icons-material/ModelTrainingOutlined";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import BlurOnOutlinedIcon from "@mui/icons-material/BlurOnOutlined";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import ImagesearchRollerOutlinedIcon from "@mui/icons-material/ImagesearchRollerOutlined";

const grid_contents = [
  {
    title: "Automatic UVs",
    description:
      "Use Polyhive to texture your 3D assets and you’ll never need to unwrap your UV’s ever again.",
    icon: FilterBAndWOutlinedIcon,
  },
  {
    title: "AI Canvas",
    description: "Use the integrated AI Canvas to ideate and iterate on your designs before texturing.",
    icon: ImagesearchRollerOutlinedIcon,
  },
  {
    title: "API and SDK integration",
    description:
      "Integrate seamlessly with existing production workflows through Polyhive's REST API, Unity, and Unreal Engine SDK’s.",
    icon: IntegrationInstructionsOutlinedIcon,
  },
  {
    title: "Train custom generators",
    description:
      "Train custom generators with your own assets to ensure your creations perfectly match your art style.",
    icon: ModelTrainingOutlinedIcon,
  },
  {
    title: "Fine-grained control",
    description:
      "Use our best-in-class preview process, or a separate concept image to bring exactly what you want to life in 3D.",
    icon: SettingsSuggestOutlinedIcon,
  },
  {
    title: "Tileable PBR materials",
    description:
      "Generate materials in seconds with a concept image to guide the style of your ideal material or simply text.",
    icon: BlurOnOutlinedIcon,
  },
];

const Content5 = () => {
  return (
    <div className="content1_section">
      <div className="section align_center_row">
        <Grid container spacing={10} className="">
          {grid_contents.map((item, index) => {
            return (
              <Grid item xs={12} sm={12} md={4}>
                <Box className="content5_grid_item">
                  <GradientIcon>
                    <item.icon className="icon_xxxxlarge" />
                  </GradientIcon>
                  <Typography className="c_textblack fw_600 f_large lh_xxlarge ls_1 mb_1 mt_1">
                    {item.title}
                  </Typography>
                  <Typography className="c_icondisabled fw_500 f_medium">
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default Content5;
