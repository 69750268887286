import Grid from "@mui/material/Grid";
import VuiTypography from "components/VuiTypography";
import React, { useEffect, useState, useMemo } from "react";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProgressDoughnutChart from "examples/Charts/DoughnutCharts/ProgressDoughnutChart";
import { BsGlobe } from "react-icons/bs";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import colors from "assets/theme/base/colors";
import { lineChartOptionsGradient } from "layouts/pages/charts/data/gradientLineChartData";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import { logoutUser } from "layouts/authentication/AuthFunctions";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Cookies from "universal-cookie";
import { useVisionUIController } from "context";
import { getFileType, convertBytesToGB, formatBytes } from "util/UtilFunctions";
import HelpIcon from "@mui/icons-material/Help";
import { HtmlTooltip } from "layouts/dashboards/default/components/CustomTooltip/index";

const Usage = () => {
  const cookies = new Cookies();

  const [apiCalls, setApiCalls] = useState({});
  const [storage, setStorage] = useState({});
  const [bandwidth, setBandwidth] = useState({});
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [rowCountState, setRowCountState] = React.useState(rowCount);

  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  const navigate = useNavigate();
  const [controller, dispatch] = useVisionUIController();
  const { activeOrg } = controller;

  const columns = [
    { field: "callTime", headerName: "Call Time (UTC)", flex: 1 },
    { field: "assetID", headerName: "Asset ID", flex: 1 },
    { field: "call", headerName: "Call", flex: 1 },
    { field: "bandwidthUsed", headerName: "Bandwidth Used", flex: 0.5 },
  ];
  const doughnutChartOptions = {
    series: [50, 15],
    labels: ["Used", "Remaining"],
    colors: [colors.info.main, "#123"],
    chart: {
      width: "100%",
    },
    stroke: {
      show: false,
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    fill: {
      colors: [colors.info.main, colors.background.main],
    },
    tooltip: {
      enabled: false,
      theme: "dark",
    },
  };
  const doughnutChartData = [50, 15];

  const [storageChart, setStorageChart] = useState(
    <DefaultDoughnutChart title="Storage Used" data={[0, 100]} options={doughnutChartOptions} />
  );
  const [apiCallsChart, setApiCallsChart] = useState(
    <DefaultDoughnutChart
      title="API Credits Used"
      data={[0, 1000]}
      options={doughnutChartOptions}
    />
  );
  const [bandwidthChart, setBandwidthChart] = useState(
    <DefaultDoughnutChart title="Bandwidth Used" data={[0, 5]} options={doughnutChartOptions} />
  );
  const lineChartData = [
    {
      name: "API Calls",
      data: [10, 15, 5, 2, 6, 6, 2, 6, 2, 1, 4, 3],
    },
  ];

  // Format time stamp like 2022-12-15T04:06:26.774Z to 2022-12-15 04:06:26 UTC with 0s if needed
  const formatTimeStamp = (timeStamp) => {
    const date = new Date(timeStamp);
    const year = date.getFullYear();

    const month = date.getMonth() + 1;
    const monthString = month < 10 ? `0${month}` : month;
    const day = date.getDate();
    const dayString = day < 10 ? `0${day}` : day;

    // if hours, minutes, seconds are less than 10, add 0 in front

    const hours = date.getHours();
    const hoursString = hours < 10 ? `0${hours}` : hours;

    const minutes = date.getMinutes();
    const minutesString = minutes < 10 ? `0${minutes}` : minutes;

    const seconds = date.getSeconds();
    const secondsString = seconds < 10 ? `0${seconds}` : seconds;

    return `${year}-${monthString}-${dayString} ${hoursString}:${minutesString}:${secondsString}`;
  };

  const fetchNextPage = (pageX) => {
    //console.log("fetching next page", pageX);
    setIsLoading(true);
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/usage/apiCallsForOrg`);
    const params = new URLSearchParams();
    params.set("page", pageX + 1);
    params.set("pageSize", 5);
    url.search = params.toString();
    const response = fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        //console.log("/usage/apiCalls endpoint", data);
        // convert multiple arrays within "data" that share index into one array of objects by iterating through the first array
        const mrows = [];
        for (let i = 0; i < data.calls.length; i++) {
          const pushObj = {
            id: rowCount + i,
            callTime: formatTimeStamp(data.call_times[i]),
            assetID: data.asset_ids[i],
            call: data.calls[i],
            bandwidthUsed: formatBytes(data.bandwidth_used_b[i]),
          };
          mrows.push(pushObj);

          // append pushObj to rows
          // setRows((prevRows) => [...prevRows, pushObj]);
          setRowCount((prevRowCount) => prevRowCount + 1);
        }
        setRows(mrows);
        //console.log("rows", mrows);

        // append mrows to rows
        setIsLoading(false);
      });
  };
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    if (activeOrg !== "") {
      const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/usage/limitsForOrg`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-ORG-ID": activeOrg,
        },
      })
        .then((response) => {
          if (response.status === 403) {
            logoutUser(navigate);
          }
          return response.json();
        })
        .then((data) => {
          //   setApiUsage(data);
          // setApiCreditsUsed(data.api_credits_used);
          // setDownloadUsedBytes(data.download_used_bytes);
          //console.log("/usage endpoint", data);
          setStorage({
            amount: convertBytesToGB(data.storage_used_bytes),
            max: convertBytesToGB(data.max_storage_bytes),
          });
          setApiCalls({
            amount: data.api_credits_used === null ? 0 : data.api_credits_used,
            max: data.api_credits_max,
          });
          setBandwidth({
            amount: convertBytesToGB(data.bandwidth_used),
            max: convertBytesToGB(data.max_download_bytes),
          });

          setStorageChart(
            <DefaultDoughnutChart
              title="Storage Used"
              data={[
                convertBytesToGB(data.storage_used_bytes) * 100,
                (convertBytesToGB(data.max_storage_bytes) -
                  convertBytesToGB(data.storage_used_bytes)) *
                  100,
              ]}
              options={doughnutChartOptions}
            />
          );
          const apiChartUsed = (data.api_credits_used / data.api_credits_max) * 100;
          const apiChartRemaining = 100 - apiChartUsed;
          setApiCallsChart(
            <DefaultDoughnutChart
              title="API Credits Used"
              data={[apiChartUsed, apiChartRemaining]}
              options={doughnutChartOptions}
            />
          );
          setBandwidthChart(
            <DefaultDoughnutChart
              title="Bandwidth Used"
              data={[
                convertBytesToGB(data.bandwidth_used) * 10,
                (convertBytesToGB(data.max_download_bytes) -
                  convertBytesToGB(data.bandwidth_used)) *
                  10,
              ]}
              options={doughnutChartOptions}
            />
          );
        });
      fetchNextPage(page);
    }

    // fetchNextPage(page);
  }, [activeOrg]);

  useMemo(() => {
    //console.log("page changed", page);
    if (activeOrg !== "") {
      fetchNextPage(page);
    }
  }, [page]);

  // fetch next 15 elements

  return (
    <>
      {/* <DashboardNavbar /> */}
      <VuiBox className="mt_2">
        {" "}
        <Grid container columnSpacing={3}>
          <Grid item xs={12}>
            <VuiTypography className="c_white fw_600 f_large mb_2">Usage</VuiTypography>
          </Grid>
          <Grid
            item
            md={12}
            xl={4}
            sx={{ maxHeight: "350px", margin: "0 auto", marginBottom: "20px", width: "100%" }}
          >
            <VuiBox sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <VuiTypography variant="body2" className="c_iconenabled f_medium fw_500" mb={1}>
                {" "}
                API Credits Used
              </VuiTypography>
              <VuiBox>
                <HtmlTooltip
                  placement="top"
                  title={
                    <React.Fragment>
                      <VuiTypography color="inherit">API Credits</VuiTypography>
                      The amount of credits used when using one of the Polyhive Game Engine SDKs.
                      These credits are only consumed if you use one of our SDKs to access our API.
                      Keep an eye on your credit usage to ensure you don't exceed your limit.
                    </React.Fragment>
                  }
                >
                  <HelpIcon className="info_icon ml1" />
                </HtmlTooltip>
              </VuiBox>
            </VuiBox>
            <VuiBox
              sx={{
                position: "relative",
                width: "100%",
                height: "40px",
                maxHeight: "350px",
                textAlign: "center",
                justifyContent: "center",
                alignContent: "center",
                backgroundColor: colors.background.quaternary,
                borderRadius: "10px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <VuiBox
                sx={{
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  height: "100%",
                  width: `${(apiCalls.amount / apiCalls.max) * 100}%`,
                  backgroundColor: colors.icon.focusdark,
                  borderRadius: "10px",
                  borderBottomRightRadius: "0px",
                  borderTopRightRadius: "0px",
                }}
              ></VuiBox>
              <VuiTypography
                variant="body2"
                color="white"
                sx={{
                  position: "relative",
                  margin: "0 auto",
                  // bottom: "200px",
                  textAlign: "center",
                  marginBottom: "0px",
                  fontSize: "1.2rem",
                }}
              >
                {apiCalls.amount} / {apiCalls.max}
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid
            item
            md={12}
            xl={4}
            sx={{ maxHeight: "350px", margin: "0 auto", marginBottom: "20px", width: "100%" }}
          >
            <VuiBox sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <VuiTypography variant="body2" className="c_iconenabled f_medium fw_500" mb={1}>
                Storage Used
              </VuiTypography>
              <VuiBox>
                <HtmlTooltip
                  placement="top"
                  title={
                    <React.Fragment>
                      <VuiTypography color="inherit">Storage Used</VuiTypography>
                      The amount of storage space used by your uploaded assets or assets generated
                      using our AI retexturing tool. You can free up storage space by deleting
                      assets in the Content page. Keep an eye on your storage usage to ensure you
                      have enough space for future uploads.
                    </React.Fragment>
                  }
                >
                  <HelpIcon className="info_icon ml1" />
                </HtmlTooltip>
              </VuiBox>
            </VuiBox>

            <VuiBox
              sx={{
                position: "relative",
                width: "100%",
                height: "40px",
                maxHeight: "350px",
                textAlign: "center",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                display: "flex",
                backgroundColor: colors.background.quaternary,
                borderRadius: "10px",
              }}
            >
              <VuiBox
                sx={{
                  position: "absolute",
                  height: "100%",
                  bottom: "0",
                  left: "0",
                  width: `${(storage.amount / storage.max) * 100}%`,
                  backgroundColor: colors.icon.focusdark,
                  borderRadius: "10px",
                  borderBottomRightRadius: "0px",
                  borderTopRightRadius: "0px",
                }}
              ></VuiBox>
              <VuiTypography
                variant="body2"
                color="white"
                sx={{
                  position: "relative",
                  margin: "0 auto",
                  // bottom: "200px",
                  textAlign: "center",
                  marginBottom: "0px",
                  fontSize: "1.2rem",
                }}
              >
                {storage.amount} GB / {storage.max} GB
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid
            item
            md={12}
            xl={4}
            sx={{ maxHeight: "350px", margin: "0 auto", marginBottom: "20px", width: "100%" }}
          >
            <VuiBox sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <VuiTypography variant="body2" className="c_iconenabled f_medium fw_500" mb={1}>
                Bandwidth Used
              </VuiTypography>
              <VuiBox>
                <HtmlTooltip
                  placement="top"
                  title={
                    <React.Fragment>
                      <VuiTypography color="inherit">Bandwidth Used</VuiTypography>
                      The amount of data transmitted when streaming assets into your game using one
                      of the Polyhive Game Engine SDKs. Keep an eye on your bandwidth usage to
                      ensure you don't exceed your limit.
                    </React.Fragment>
                  }
                >
                  <HelpIcon className="info_icon ml1" />
                </HtmlTooltip>
              </VuiBox>
            </VuiBox>
            <VuiBox
              sx={{
                position: "relative",
                width: "100%",
                height: "40px",
                maxHeight: "350px",
                textAlign: "center",
                justifyContent: "center",
                alignContent: "center",
                backgroundColor: colors.background.quaternary,
                borderRadius: "10px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <VuiBox
                sx={{
                  position: "absolute",
                  height: "100%",
                  bottom: "0",
                  left: "0",
                  width: `${(bandwidth.amount / bandwidth.max) * 100}%`,
                  backgroundColor: colors.icon.focusdark,
                  borderRadius: "10px",
                  borderBottomRightRadius: "0px",
                  borderTopRightRadius: "0px",
                }}
              ></VuiBox>
              <VuiTypography
                variant="body2"
                color="white"
                sx={{
                  position: "relative",
                  margin: "0 auto",
                  // bottom: "200px",
                  textAlign: "center",
                  marginBottom: "0px",
                  fontSize: "1.2rem",
                }}
              >
                {bandwidth.amount} GB / {bandwidth.max} GB
              </VuiTypography>
            </VuiBox>
          </Grid>
          {/* <Grid item xs={12}>
            {useMemo(
              () => (
                <GradientLineChart
                  title="Usage History"
                  data={lineChartData}
                  options={lineChartOptionsGradient}
                />
              ),
              [lineChartData, lineChartOptionsGradient]
            )}
          </Grid> */}
          <Grid item xs={12} mt={2}>
            <VuiTypography variant="body2" className="c_iconenabled f_medium fw_500 mb_1">
              Usage History
            </VuiTypography>
            <div style={{ height: 368, width: "100%", color: "#fff" }}>
              <DataGrid
                sx={{
                  border: "none",
                  backgroundColor: colors.background.main,
                  color: "#fff",
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.background.main,
                    color: "#fff",
                  },
                  "& .MuiTablePagination-root": {
                    borderRadius: "30px",

                    backgroundColor: colors.background.main,
                    color: "#fff",
                  },
                  // set disabled state color differe
                  "& .MuiTablePagination-actions button:disabled": {
                    color: "#a0a0a0",
                  },
                  "& .MuiDataGrid-iconSeparator": {
                    height: 0,
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderColor: "#3b3b3b",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    borderColor: "#3b3b3b",
                  },
                  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
                    borderBottom: "1px solid #3b3b3b",
                  },
                }}
                rows={rows}
                rowCount={apiCalls.amount}
                loading={isLoading}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                pagination
                paginationMode="server"
                onPageChange={(newPage) => setPage(newPage)}
                page={page}
                localeText={{
                  noRowsLabel: "No API calls yet, start using the API to see your usage history",
                }}
                disableSelectionOnClick
                disableColumnFilter
                disableColumnMenu
              />
            </div>
          </Grid>
        </Grid>
      </VuiBox>
    </>
  );
};

export default Usage;
