/**

 =========================================================
 * Vision UI PRO React - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com/)

 * Design and Coded by Simmmple & Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

 */

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import APIKey from "layouts/applications/security/components/APIKey";
import colors from "assets/theme/base/colors";
import DashboardNav from "../../dashboards/default/components/DashboardNav";
import Sidenav from "examples/Sidenav";

const Security = () => {
  return (
    <DashboardLayout>
      <DashboardNav />
      <Sidenav />

      {/* <DashboardNavbar /> */}
      <VuiBox pt={3} pb={8}>
        <Grid container justifyContent="center">
          <APIKey />
        </Grid>
      </VuiBox>
    </DashboardLayout>
  );
};

export default Security;
