import React, { useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import { Box, Typography, Button } from "@mui/material";
import UndoRoundedIcon from "@mui/icons-material/UndoRounded";
import RedoRoundedIcon from "@mui/icons-material/RedoRounded";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { fabric } from "util/fabric.min.js";
import _ from "lodash";

const CanvasCanvas = forwardRef(
  (
    {
      imgSrc,
      show2DCanvas,
      sendCanvasToGenerate,
      handleCanvasRetry,
      startSnackbar,
      selectedPreviewId,
      handleSelectedPreviewId,
      handleCanvasFormFields,
      paintbrushSize,
    },
    ref
  ) => {
    const canvasRef = useRef(null);
    const maskCanvasRef = useRef(null);

    const fabricCanvasRef = useRef(null);
    const maskFabricCanvasRef = useRef(null);

    const [history, setHistory] = React.useState([]);
    const historyRef = useRef([]);

    const [redoStack, setRedoStack] = React.useState([]);
    const redoStackRef = useRef([]);
    const [currentStep, setCurrentStep] = React.useState(-1);
    const currentStepRef = useRef(-1);

    useEffect(() => {
      console.log("canvas_useEffect", imgSrc, show2DCanvas);
      if (!imgSrc || !show2DCanvas) return;

      Promise.all([loadImg(imgSrc[0]), loadImg(imgSrc[1])])
        .then(([img1, img2]) => {
          const displayWidth = 1024;
          const displayHeight = 512;

          console.log("canvas_loading_images with dimensions", img1.width, img1.height);
          // Calculate the canvas dimensions
          const canvasWidth = img1.width * 2;
          const canvasHeight = img1.height;

          // Create a new Fabric canvas

          if (fabricCanvasRef.current) {
            console.log("update canvas with new src", imgSrc);

            fabricCanvasRef.current.clear();
            fabricCanvasRef.current.add(
              new fabric.Image(img1, {
                left: 0,
                top: 0,
                erasable: false,
              })
            );
            fabricCanvasRef.current.add(
              new fabric.Image(img2, {
                left: img1.width,
                top: 0,
                erasable: false,
              })
            );
            saveToHistory({}, "loading images");

            // set drawing mode to true
            fabricCanvasRef.current.isDrawingMode = true;

            return;
          }

          console.log("Instantiating Fabric Canvas");

          fabricCanvasRef.current = new fabric.Canvas(canvasRef.current, {
            isDrawingMode: true,
          });

          // Set the canvas dimensions
          fabricCanvasRef.current.setDimensions(
            {
              width: canvasWidth,
              height: canvasHeight,
            },
            { backstoreOnly: true }
          );

          let lowerCanvasElement = fabricCanvasRef.current.lowerCanvasEl;
          lowerCanvasElement.style.width = "1024px";
          lowerCanvasElement.style.height = "512px";

          // Modify the CSS of the upper canvas (used for interactions)
          let upperCanvasElement = fabricCanvasRef.current.upperCanvasEl;
          upperCanvasElement.style.width = "1024px";
          upperCanvasElement.style.height = "512px";

          // Modify the CSS of the canvas container
          let canvasContainer = fabricCanvasRef.current.wrapperEl; // Access the canvas container
          canvasContainer.style.width = "1024px";
          canvasContainer.style.height = "512px";

          // Set the drawing brush properties
          fabricCanvasRef.current.freeDrawingBrush.width = (paintbrushSize * 512) / 200;
          fabricCanvasRef.current.freeDrawingBrush.color = "rgba(36, 175, 230, 1)";

          // Add the images without any scaling
          fabricCanvasRef.current.add(
            new fabric.Image(img1, {
              left: 0,
              top: 0,
              erasable: false,
            })
          );
          fabricCanvasRef.current.add(
            new fabric.Image(img2, {
              left: img1.width,
              top: 0,
              erasable: false,
            })
          );

          saveToHistory({}, "loading images");

          fabricCanvasRef.current.on("path:created", function (options) {
            saveToHistory({}, "painting");
            // console.log("should save", options);
          });
        })
        .catch((error) => {
          console.error("Failed to load one or both images", error);
        });

      // return () => {
      //   if (fabricCanvasRef.current) {
      //     fabricCanvasRef.current.dispose();
      //     fabricCanvasRef.current = null;
      //   }
      // };
    }, [imgSrc, show2DCanvas]);

    useImperativeHandle(ref, () => ({
      saveToHistory: saveToHistory,

      getCanvasImage: async () => {
        // Extract the mask
        const maskDataURL = await extractMask(fabricCanvasRef.current);

        if (maskDataURL === null) {
          return;
        }

        // Create a temporary Image element from the mask data URL
        const img = new Image();
        img.src = maskDataURL;
        await new Promise((r) => (img.onload = r)); // Ensure the image is fully loaded

        // Create canvases for the left and right halves
        const leftCanvas = document.createElement("canvas");
        const rightCanvas = document.createElement("canvas");
        leftCanvas.width = fabricCanvasRef.current.width / 2;
        leftCanvas.height = fabricCanvasRef.current.height;
        rightCanvas.width = fabricCanvasRef.current.width / 2;
        rightCanvas.height = fabricCanvasRef.current.height;

        const leftCtx = leftCanvas.getContext("2d");
        const rightCtx = rightCanvas.getContext("2d");

        // Draw the mask image onto the two canvases
        leftCtx.drawImage(
          img,
          0,
          0,
          fabricCanvasRef.current.width / 2,
          fabricCanvasRef.current.height,
          0,
          0,
          fabricCanvasRef.current.width / 2,
          fabricCanvasRef.current.height
        );
        rightCtx.drawImage(
          img,
          fabricCanvasRef.current.width / 2,
          0,
          fabricCanvasRef.current.width / 2,
          fabricCanvasRef.current.height,
          0,
          0,
          fabricCanvasRef.current.width / 2,
          fabricCanvasRef.current.height
        );

        // Convert the canvases to blobs
        const canvasToBlob = async (canvas) => {
          return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
              if (blob) {
                resolve(blob);
              } else {
                reject("Blob creation failed");
              }
            });
          });
        };

        const leftBlob = await canvasToBlob(leftCanvas);
        const rightBlob = await canvasToBlob(rightCanvas);

        // downloadBlob(leftBlob, "leftBlob.png");
        // downloadBlob(rightBlob, "rightBlob.png");

        // Create Files from the Blobs
        const leftFile = new File([leftBlob], "leftFileName.png", { type: "image/png" });
        const rightFile = new File([rightBlob], "rightFileName.png", { type: "image/png" });

        return [leftFile, rightFile];
      },
      handlePaintbrushSize: (size) => {
        if (fabricCanvasRef.current)
          fabricCanvasRef.current.freeDrawingBrush.width = (size * 512) / 200;
      },
      handlePaintbrushType: (type) => {
        if (fabricCanvasRef.current) {
          if (type === "eraser") {
            fabricCanvasRef.current.freeDrawingBrush = new fabric.EraserBrush(
              fabricCanvasRef.current
            );
            fabricCanvasRef.current.freeDrawingBrush.width = (paintbrushSize * 512) / 200;
            fabricCanvasRef.current.freeDrawingBrush.color = "rgba(36, 175, 230, 0)";
          } else if (type === "brush") {
            fabricCanvasRef.current.freeDrawingBrush = new fabric.PencilBrush(
              fabricCanvasRef.current
            );
            fabricCanvasRef.current.freeDrawingBrush.width = (paintbrushSize * 512) / 200;
            fabricCanvasRef.current.freeDrawingBrush.color = "rgba(36, 175, 230, 1)";
          }
        }
      },
      clearHistory: () => {
        historyRef.current = [];
        redoStackRef.current = [];
        currentStepRef.current = -1;
      },
    }));

    const undo = () => {
      console.log("canvas_undo", historyRef.current, currentStepRef.current);

      if (currentStepRef.current > 0) {
        const lastState = historyRef.current.pop(); // Pop the last state off of the history
        console.log(
          "canvas_undo",
          lastState,
          "history",
          historyRef.current,
          "currentStep",
          currentStepRef.current
        );

        redoStackRef.current = [...redoStackRef.current, lastState];
        currentStepRef.current = currentStepRef.current - 1;
        console.log("setting prev submission id", historyRef.current[currentStepRef.current]);
        handleSelectedPreviewId(historyRef.current[currentStepRef.current].prevSubmissionId);

        if (lastState.action === "pre-generate") {
        }

        restoreState(historyRef.current[currentStepRef.current]);
      } else {
        startSnackbar({ type: "error", message: "No more actions to undo!" });
      }
    };

    const redo = () => {
      if (redoStackRef.current.length > 0) {
        const nextState = redoStackRef.current.pop();
        historyRef.current = [...historyRef.current, nextState];
        currentStepRef.current = currentStepRef.current + 1;
        // Restore canvas state
        if (nextState) {
          handleSelectedPreviewId(nextState.prevSubmissionId);

          console.log("canvas_redo_nextState", nextState);

          restoreState(nextState);
        } else {
          startSnackbar({ type: "error", message: "No more actions to redo!" });
        }
      } else {
        startSnackbar({ type: "error", message: "No more actions to redo!" });
      }
    };

    const restoreState = (state) => {
      console.log("canvas_restoreState", state);
      fabricCanvasRef.current.clear();

      fabricCanvasRef.current.loadFromJSON(state.canvasState, function () {
        fabricCanvasRef.current.renderAll();
      });

      if (state.requestBody.text_prompt) {
        handleCanvasFormFields(state.requestBody);
      }
    };

    const loadImg = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = reject;
      });
    };

    const saveToHistory = (requestBody, action) => {
      console.log("canvas_saveToHistory_imperative", historyRef.current);
      const canvasState = fabricCanvasRef.current.toJSON();

      let prevSubmissionId = "";
      // look for previous submission id
      if (historyRef.current.length > 0) {
        for (let i = historyRef.current.length - 1; i >= 0; i--) {
          console.log("current history item", historyRef.current[i]);
          if (historyRef.current[i].requestBody.submission_id) {
            console.log(
              "found previous submission id",
              historyRef.current[i].requestBody.submission_id
            );
            prevSubmissionId = historyRef.current[i].requestBody.submission_id;
            break;
          } else if (historyRef.current[i].prevSubmissionId) {
            prevSubmissionId = historyRef.current[i].prevSubmissionId;
            console.log("no previous submission id found in loop", historyRef.current[i]);
          }
        }
      } else {
        console.log("no previous submission id found, setting to ", selectedPreviewId);
        prevSubmissionId = selectedPreviewId;
      }

      const newState = {
        canvasState: canvasState,
        requestBody: requestBody,
        prevSubmissionId: prevSubmissionId,
        action: action,
      };

      historyRef.current = historyRef.current.slice(0, currentStepRef.current + 1);
      historyRef.current.push(newState);

      redoStackRef.current = [];
      currentStepRef.current = currentStepRef.current + 1;
    };

    const downloadBlob = (blob, filename) => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(url); // Free up memory
    };

    if (!show2DCanvas) {
      return null;
    }

    function sendLeftHalfOfCanvas(canvas) {
      // Create a new canvas and get its context
      const newCanvas = document.createElement("canvas");
      const newCtx = newCanvas.getContext("2d");

      // Set the width and height of the new canvas
      newCanvas.width = canvas.width / 2;
      newCanvas.height = canvas.height;

      // Draw the left half of the original canvas onto the new canvas
      newCtx.drawImage(
        canvas,
        0,
        0,
        canvas.width / 2,
        canvas.height,
        0,
        0,
        canvas.width / 2,
        canvas.height
      );

      // Send the new canvas data URL
      sendCanvasToGenerate(newCanvas.toDataURL());
    }

    const dataURLtoBlob = (dataURL) => {
      const byteString = atob(dataURL.split(",")[1]);
      const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }
      return new Blob([arrayBuffer], { type: mimeString });
    };

    async function extractMask(canvas) {
      // 1. Filter out images from the canvas objects
      if (canvas === null) {
        return null;
      }
      fabricCanvasRef.current.freeDrawingBrush = new fabric.PencilBrush(fabricCanvasRef.current);
      fabricCanvasRef.current.freeDrawingBrush.width = (paintbrushSize * 512) / 200;
      fabricCanvasRef.current.freeDrawingBrush.color = "rgba(36, 175, 230, 1)";
      const userDrawnObjects = canvas.getObjects().filter((object) => object.type !== "image");
      console.log("canvas objects", canvas.getObjects());
      console.log("userDrawnObjects", userDrawnObjects);

      // 2. Create a temporary canvas
      const tempCanvas = new fabric.Canvas(document.createElement("canvas"), {
        isDrawingMode: true,
      });
      tempCanvas.setDimensions({
        width: canvas.width,
        height: canvas.height,
      });

      // 3. Add filtered objects to the temporary canvas
      let pathExists = false;
      userDrawnObjects.forEach((obj) => {
        if (obj.type === "path") {
          pathExists = true;
          // Clone the path and set its color to white
          console.log("obj.type", obj.type);

          const newPath = _.cloneDeep(obj);
          // newPath.stroke = "rgba(255,255,255,1)";
          newPath.fill = null;

          tempCanvas.add(newPath);
        }
      });

      if (!pathExists) {
        return null;
      }

      const standardCanvas = document.createElement("canvas");
      standardCanvas.width = tempCanvas.width;
      standardCanvas.height = tempCanvas.height;
      const ctx = standardCanvas.getContext("2d");

      const processImage = new Promise((resolve, reject) => {
        // Draw the Fabric.js tempCanvas onto the standard canvas
        const fabricImage = new Image();
        fabricImage.onload = function () {
          try {
            ctx.drawImage(fabricImage, 0, 0);

            // Get the image data from the standard canvas
            let imageData = ctx.getImageData(0, 0, standardCanvas.width, standardCanvas.height);
            let data = imageData.data;

            // Loop and modify the image data as before
            for (let i = 0; i < data.length; i += 4) {
              if (data[i + 3] !== 0) {
                data[i] = 255;
                data[i + 1] = 255;
                data[i + 2] = 255;
              }
            }

            // Put the modified image data back onto the standard canvas
            ctx.putImageData(imageData, 0, 0);

            // Extract the data URL from the standard canvas
            const dataURL = standardCanvas.toDataURL("image/png");
            resolve(dataURL); // Resolving the Promise with dataURL
          } catch (err) {
            reject(err); // Rejecting the Promise in case of any errors
          }
        };
        fabricImage.src = tempCanvas.toDataURL("image/png");
      });

      try {
        const dataURL = await processImage; // Awaiting the Promise to be resolved
        return dataURL;
      } catch (err) {
        console.error("Error processing the image:", err);
        return null;
      }
    }

    const getMasks = async () => {
      // download mask from fabric canvas and mask canvas and download
      const maskCanvasUrl = await extractMask(fabricCanvasRef.current);
      console.log("maskCanvasUrl", maskCanvasUrl);
      const maskCanvasBlob = dataURLtoBlob(maskCanvasUrl);
      downloadBlob(maskCanvasBlob, "maskCanvas.png");

      const fabricCanvasUrl = fabricCanvasRef.current.toDataURL();
      const fabricCanvasBlob = dataURLtoBlob(fabricCanvasUrl);
      downloadBlob(fabricCanvasBlob, "fabricCanvas.png");
    };

    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="93vh"
        flexDirection="column"
        mt={2}
        zIndex={100000}
      >
        <Typography className="f_medium c_white mb_1">
          {imgSrc[0] === null
            ? "To use the Preview Editor, first generate a preview using the Guided Generation form"
            : "Draw a masked region on the canvas"}
        </Typography>
        <canvas
          ref={canvasRef}
          className="br_hivegradient mauto"
          style={{ zIndex: 100000, width: "1024px", height: "512px" }}
        />

        <Box zIndex={100001} className="align_center_row mt_2">
          <Button
            className="f_small c_white fw_500 default_btn bg_icondisabled mr_2"
            disabled={imgSrc[0] === null}
            onClick={undo}
            startIcon={<UndoRoundedIcon />}
          >
            Undo
          </Button>
          <Button
            className="f_small c_white fw_500 default_btn bg_icondisabled mr_2"
            disabled={imgSrc[0] === null}
            onClick={redo}
            startIcon={<RedoRoundedIcon />}
          >
            Redo
          </Button>

          {/* <Button
            className="f_small c_white fw_500 default_btn bg_icondisabled mr_2"
            disabled={imgSrc[0] === null}
            onClick={getMasks}
            startIcon={<RedoRoundedIcon />}
          >
            DL Mask
          </Button> */}

          <Button
            className="f_small c_white fw_500 default_btn bg_quaternaryfocusdark mr_2"
            onClick={() => {
              handleCanvasRetry();
            }}
            disabled={imgSrc[0] === null}
            startIcon={<ReplayRoundedIcon />}
            zIndex={100001}
          >
            Retry Last Inpaint
          </Button>
          <Button
            className="f_small c_white fw_500 default_btn bg_hivegradient"
            disabled={imgSrc[0] === null}
            onClick={() => {
              sendLeftHalfOfCanvas(canvasRef.current);
            }}
            startIcon={<SendRoundedIcon />}
            zIndex={100001}
          >
            Send to Generate
          </Button>
        </Box>
      </Box>
    );
  }
);

export default CanvasCanvas;
