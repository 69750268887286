/*

Polyhive Dashboard

*/

import * as ReactDOM from "react-dom";
import { HashRouter, Router, BrowserRouter } from "react-router-dom";
import { config as dotEnvConfig } from "dotenv";
import { StytchProvider } from "@stytch/stytch-react";
import { StytchUIClient } from "@stytch/vanilla-js";
import App from "App";

// Vision UI Context Provider
import { VisionUIControllerProvider } from "context";

// Load ENV variables
dotEnvConfig({
  path: process.env.NODE_ENV === "production" ? ".env.production" : ".env.development",
});

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
  console.warn = () => {};
}
// Initialize Stytch UI Client
const stytch = new StytchUIClient("public-token-live-e8592939-eb2d-4062-87ea-d20e5d787942");

ReactDOM.render(
  <BrowserRouter>
    <VisionUIControllerProvider>
      <StytchProvider stytch={stytch}>
        <App />
      </StytchProvider>
    </VisionUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
