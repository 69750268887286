/**

 =========================================================
 * Vision UI PRO React - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com/)

 * Design and Coded by Simmmple & Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

 */

import { useState, useEffect, useMemo } from "react";
import { Fragment, default as React } from "react";

// react-router components
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import Default from "layouts/dashboards/default";

// Vision UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Vision UI Dashboard PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Vision UI Dashboard PRO React routes
import routes from "routes";

import { PrivateRoute } from "components/Custom";
// Vision UI Dashboard PRO React contexts
import { useVisionUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Plugins custom css
import "assets/theme/base/plugins.css";
import SignInAQ from "./layouts/authentication/sign-in/aqCustom";
import { IoHome } from "react-icons/io5";

import amplitude from "amplitude-js";
import { logoutUser } from "layouts/authentication/AuthFunctions";
import "./App.css";

import { sendUTMToWombat, logWombatEvent } from "util/UTMFunctions";
// helmet from react-helmet
import { Helmet } from "react-helmet";

export default function App() {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const location = useLocation();
  const { loggedIn } = false;
  const [pageTitle, setPageTitle] = useState("");
  const navigate = useNavigate();

  // amplitude.getInstance().init("");
  // amplitude.getInstance().logEvent("newSessionss");
  // track("newSession");

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {}, []);

  // parse document.cookies for userID
  const parseCookies = () => {
    const cookies = document.cookie.split(";");
    const parsedCookies = {};
    cookies.forEach((cookie) => {
      const [key, value] = cookie.split("=");
      parsedCookies[key.trim()] = value;
    });
    return parsedCookies;
  };

  // get login state from cookies
  const getLoginState = () => {
    const cookies = parseCookies();
    return cookies.userId == null ? false : true;
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 and checking auth when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    let pageName;
    // change above to switch statement
    switch (pathname) {
      case "/":
        pageName = "Home";
        break;
      case "/sign-in":
        pageName = "Login";
        break;
      default:
        const pathArray = pathname.split("/");
        // turn path array into camel case string
        const camelCasePath = pathArray.map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        pageName = camelCasePath.join("");
    }
    console.log("pathname", pathname);
    sendUTMToWombat(pageName);

    const loginState = true;
    if (
      !loginState &&
      pathname !== "/" &&
      pathname !== "/contact" &&
      pathname !== "/tos" &&
      pathname !== "/loginfailure" &&
      pathname !== "/gallery" &&
      pathname !== "/s" &&
      pathname !== "/blog" &&
      pathname !== "/features" &&
      pathname !== "/s/error" 
    ) {
      navigate("/sign-in", { replace: true });
    } else if (loginState && pathname === "/sign-in") {
      // navigate("/manage/content");
      // navigate("/dashboards/default");
    }

    // react helmet for page title
    switch (pathname) {
      case "/":
        setPageTitle("Spectral | Intelligent 3D Design");
        break;
      case "/gallery":
        setPageTitle("Polyhive | Community Showcase");
        break;
      case "/features":
        setPageTitle("Polyhive | Features");
        break;
      case "/contact":
        setPageTitle("Spectral | Contact Us");
        break;
      case "/manage":
        setPageTitle("Polyhive Dashboard");
        break;
      case "/manage/content":
        setPageTitle("Polyhive Dashboard");
        break;
      case "/setup":
        setPageTitle("Polyhive | Setup");
        break;
      case "/sign-in":
        setPageTitle("Polyhive | Sign In");
        break;
      case "/generate":
        setPageTitle("Polyhive | Generate");
        break;
      case "/s":
        setPageTitle("");
        break;
      default:
        setPageTitle("Polyhive | Generative AI for 3D Professionals");
    }
  }, [pathname]);

  useEffect(() => {
    const ogUrl = window.location.href;
    console.log("ogUrl", ogUrl);
    document.querySelector('meta[property="og:url"]').setAttribute("content", ogUrl);
  }, [location]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (route.name === "Basic") {
          return <Route exact path={route.route} element={<route.component />} key={route.key} />;
        } else if (route.key === "home") {
          layout === "default" && route.layout === "default";
          return <Route exact path={route.route} element={<route.component />} key={route.key} />;
        } else {
          return <Route exact path={route.route} element={<route.component />} key={route.key} />;
        }
      }

      return null;
    });

  const allRoutes = getRoutes(routes);

  const configsButton = (
    <VuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="white"
      sx={({ palette: { info } }) => ({ cursor: "pointer", backgroundColor: info.main })}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </VuiBox>
  );

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        {pageTitle !== "" ? <title>{pageTitle}</title> : ""}
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <LargeImageLCPHack />
      <CssBaseline />
      {layout === "dashboard" && (
        <Fragment>
          {pathname === "/" ||
          pathname === "/tos" ||
          pathname === "/contact" ||
          pathname === "/loginfailure" ||
          pathname === "/gallery" ||
          pathname === "/s" ||
          pathname === "/blog" ||
          pathname === "/features" ||
          pathname === "/s/error" ? (
            <></>
          ) : (
            <></>
          )}

          {/* <Configurator /> */}
        </Fragment>
      )}
      {/* {layout === "vr" && <Configurator />} */}
      <Routes>{getRoutes(routes)}</Routes>
    </ThemeProvider>
  );
}

const LargeImageLCPHack = () => (
  <img
    width="99999"
    height="99999"
    style={{
      pointerEvents: "none",
      position: "absolute",
      top: 0,
      left: 0,
      width: "99vw",
      height: "99vh",
      maxWidth: "99vw",
      maxHeight: "99vh",
    }}
    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI5OTk5OXB4IiBoZWlnaHQ9Ijk5OTk5cHgiIHZpZXdCb3g9IjAgMCA5OTk5OSA5OTk5OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48ZyBzdHJva2U9Im5vbmUiIGZpbGw9Im5vbmUiIGZpbGwtb3BhY2l0eT0iMCI+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9Ijk5OTk5IiBoZWlnaHQ9Ijk5OTk5Ij48L3JlY3Q+IDwvZz4gPC9zdmc+"
    alt="LCP"
  />
);
