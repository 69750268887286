import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import colors from "assets/theme/base/colors";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FolderIcon from "@mui/icons-material/Folder";
import React, { useEffect } from "react";
const Crumb = (props) => {
  const [draggedOver, setDraggedOver] = React.useState(false);
  useEffect(() => {
    //console.log("what is folder", props.folder);
  }, [props.folder]);

  useEffect(() => {
    //console.log("should be instantiated once");
    const folder = document.getElementById(props.folder.modelId);
    folder.addEventListener("drop", handleDrop);
    folder.addEventListener("dragover", handleDragOver);
    folder.addEventListener("dragleave", handleDragLeave);
    folder.addEventListener("dragend", handleDragLeave);

    return () => {
      folder.removeEventListener("drop", handleDrop);
    };
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDraggedOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDraggedOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDraggedOver(false);
    props.setBarDragged(false);
    //console.log("dropped", e);
    //console.log("dragging id in drop", e.dataTransfer.getData("text"));
    //console.log("current folder", props.currentFolder);
    if (e.target.parentElement.classList.contains("folder")) {
      //console.log("landed on a folder with id", e.target.parentElement.id);
      //   if (e.target.parentElement.id !== props.currentFolder) {
      //console.log("move to folder", e.target.parentElement.id);
      props.moveToFolder(e.target.parentElement.id, e.dataTransfer.getData("text"));
      //   }
    } else if (e.target.classList.contains("folder")) {
      //console.log("landed on a folder with id", e.target.id);
      //   if (e.target.id !== props.currentFolder) {
      //console.log("move to folder", e.target.id);
      props.moveToFolder(e.target.id, e.dataTransfer.getData("text"));
      //   }
    } else if (e.target.parentElement.parentElement.classList.contains("folder")) {
      //console.log("landed on a folder with id", e.target.parentElement.parentElement.id);
      //   if (e.target.parentElement.parentElement.id !== props.currentFolder) {
      //console.log("move to folder", e.target.parentElement.parentElement.id);
      props.moveToFolder(e.target.parentElement.parentElement.id, e.dataTransfer.getData("text"));
      //   }
    }
  };

  return (
    <>
      <VuiBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          transition: "all 0.3s ease-in-out",
          cursor: "pointer",
        }}
        onClick={() => {
          props.setCurrentFolder(props.folder.modelId);
          //console.log("current folder settter", props.folder.modelId);
        }}
        id={props.folder.modelId}
        className="folder"
      >
        {/* {props.draggingId} */}
        <ChevronRightIcon
          sx={{
            margin: "10px",
            marginRight: "0px",
            marginLeft: "0px",
            marginTop: "12px",
            // marginLeft: "5px",
            width: "17px",
            height: "17px",
            color: colors.icon.enabled,
          }}
        />
        <VuiBox
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            border: draggedOver ? "1px solid " + colors.icon.focus : "none",
            borderRadius: "5px",
            paddingRight: "10px",
          }}
        >
          <FolderIcon
            sx={{
              margin: "10px",
              marginRight: "5px",
              marginTop: "12px",
              width: props.barDragged ? "30px" : "17px",
              height: props.barDragged ? "30px" : "17px",
              transition: "all 0.3s ease-in-out",
              color: colors.icon.enabled,
            }}
          />
          <VuiTypography
            variant="body2"
            sx={{
              color: colors.icon.enabled,
              fontSize: "0.8rem",
              fontWeight: 500,
              "&:hover": {
                color: colors.icon.focus,
              },
            }}
          >
            {props.folder.name}
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </>
  );
};

export default Crumb;
