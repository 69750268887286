import UAParser from "ua-parser-js";
import { version } from "dropzone";
const userAgent = navigator.userAgent;
const parsedUserAgent = parseUserAgent(userAgent);
function parseUserAgent(userAgent) {
  const parser = new UAParser(userAgent);
  const result = parser.getResult();

  return {
    browser: {
      name: result.browser.name,
      version: result.browser.version,
    },
    device: {
      model: result.device.model,
      type: result.device.type,
      vendor: result.device.vendor,
    },
    os: {
      name: result.os.name,
      version: result.os.version,
    },
  };
}

export function logWombatEvent(eventName, params) {
  try {
    const id = getCookie("utmid");
    console.log("document.cookie", document.cookie);

    // create a newParams object that contains the params passed in and the parsedUserAgent
    const newParams = { ...params, ...parsedUserAgent };

    if (!id) {
      console.log("no utmid, generating one");
      const uuid = generateUUID();
      document.cookie = `utmid=${uuid}; path=/; domain=.polyhive.ai`;
    }

    console.log("logging", {
      "DEVICE-ID": getCookie("utmid"),
      "Content-Type": "application/json",
      "X-EVENT-TYPE": eventName,
      "X-METADATA": newParams,
    });
    fetch(`${process.env.REACT_APP_BACKEND_URL}/wombat/loggedOut`, {
      method: "POST",
      headers: {
        "DEVICE-ID": getCookie("utmid"),
        "Content-Type": "application/json",
        "X-EVENT-TYPE": eventName,
        "X-METADATA": JSON.stringify(newParams),
      },
    }).catch((err) => console.log(err));
  } catch (err) {
    console.log(err);
  }
}

export function sendUTMToWombat(pageName) {
  try {
    const utmid = getCookie("utmid");
    console.log("document.cookie", document.cookie);
    if (!utmid) {
      console.log("no utmid, generating one");
      const uuid = generateUUID();
      document.cookie = `utmid=${uuid}; path=/; domain=.polyhive.ai`;
    }

    const params = getParamsFromLocation();
    if (!params.utm_source) {
      console.log("no utm, logging page loaded", params);
      // capitalize first letter of pageName
      const capitalizedPageName = pageName.charAt(0).toUpperCase() + pageName.slice(1);
      const referrer = document.referrer;

      if (referrer) {
        logWombatEvent("navigatedTo" + capitalizedPageName + "Page", {
          referrer: referrer,
        });
      } else if (params.ref) {
        logWombatEvent("navigatedTo" + capitalizedPageName + "Page", {
          referrer: params.ref,
        });
      } else {
        logWombatEvent("navigatedTo" + capitalizedPageName + "Page", {
          referrer: "direct",
        });
      }
    } else {
      // lowercase first letter of pageName
      const lowercasePageName = pageName.charAt(0).toLowerCase() + pageName.slice(1);
      console.log(lowercasePageName + "PageLoadedUTM", params);
      const newParams = { ...params, ...parsedUserAgent };

      fetch(`${process.env.REACT_APP_BACKEND_URL}/wombat/loggedOut`, {
        method: "POST",
        headers: {
          "DEVICE-ID": getCookie("utmid"),
          "Content-Type": "application/json",
          "X-EVENT-TYPE": lowercasePageName + "PageLoadedUTM",
          "X-METADATA": JSON.stringify(newParams),
        },
      }).catch((err) => console.log(err));
    }
  } catch (err) {
    console.log(err);
  }
}

export function logAuthorizedWombatEvent(eventName, params) {
  try {
    const newParams = { ...params, ...parsedUserAgent };
    console.log("wombat: logging event", eventName, newParams);

    fetch(`${process.env.REACT_APP_BACKEND_URL}/wombat`, {
      method: "POST",
      credentials: "include",
      headers: {
        "DEVICE-ID": getCookie("utmid"),
        "Content-Type": "application/json",
        "X-EVENT-TYPE": eventName,
        "X-METADATA": JSON.stringify(newParams),
      },
    }).catch((err) => console.log(err));
  } catch (err) {
    console.log(err);
  }
}

// Helper function to get cookie
function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function getDeviceId() {
  return getCookie("utmid");
}

// Helper function to get params from url
function getParamsFromLocation() {
  const hash = window.location.search;
  console.log(hash);
  if (hash.indexOf("?") === -1) return {};
  const params = hash.substring(hash.indexOf("?") + 1);
  console.log("params", params);
  const paramParts = params.split("&");
  const paramsMap = {};
  paramParts.forEach((part) => {
    const item = part.split("=");
    paramsMap[item[0]] = decodeURIComponent(item[1]);
  });
  return paramsMap;
}

// Helper function to generate UUID
function generateUUID() {
  // Public Domain/MIT
  let d = new Date().getTime(); //Timestamp
  let d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}
