import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import RetextureForm from "./components/RetextureForm";
import RetextureForm2 from "./components/RetextureForm2";

import MeshGenerateForm from "./components/MeshGenerateForm";
import { useVisionUIController, setCredits, setSubscription, setPermissions } from "context/index";

import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import JobList from "./components/JobList/index";
import VuiSnackbar from "components/VuiSnackbar/";

import { logAuthorizedWombatEvent } from "util/UTMFunctions";

import DisclaimerModal from "./components/DisclaimerModal/index";
import ModelViewer from "./components/ModelViewer/index";
import UploadDisableModal from "./components/UploadDisableModal/index";
import Toolbar from "./components/Toolbar/index";
import { useLocation } from "react-router-dom";
import DashboardNav from "../../dashboards/default/components/DashboardNav";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import { Box, Typography, Button, Grid } from "@mui/material";
import ShirtModel from "assets/models/Shirt.glb";
import ShedModel from "assets/models/Shed.glb";
import TruckModel from "assets/models/Truck.glb";
import ChestModel from "assets/models/Chest.glb";
import HammerModel from "assets/models/Hammer.glb";
import HelmetModel from "assets/models/Helmet.glb";
import TestCube from "assets/models/testcube.glb";
import DemoTable from "assets/demo_assets/Table_Blue.glb";
import DemoThumb from "assets/demo_assets/blue_thumbnail.png";
// import THREE
import * as THREE from "three";
import Dock from "./components/Dock/index";
import {
  generateUUID,
  convertBytesToMB,
  getFileTypeFromS3Url,
  getCreditsForDownload,
} from "util/UtilFunctions";
import { logoutUser } from "layouts/authentication/AuthFunctions";
import ShareModal from "./components/ShareModal/index";
import JobInspector from "./components/JobInspector/index";
import PromptingForm from "./components/PromptingForm/index";
import DownloadModal from "./components/DownloadModal/index";
import InsufficientCreditsModal from "./components/InsufficientCreditsModal/index";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import BurstModeOutlinedIcon from "@mui/icons-material/BurstModeOutlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import AutoAwesomeMosaicOutlinedIcon from "@mui/icons-material/AutoAwesomeMosaicOutlined";

import Tour from "reactour";
import GradientIcon from "components/Custom/GradientIcon/index";
import JobStartModal from "./components/JobStartModal/index";
import InpaintingForm from "./components/InpaintingForm/index";
import TiledForm from "./components/TiledForm/index";
import CanvasForm from "./components/CanvasForm/index";
import CanvasCanvas from "./components/CanvasCanvas/index";

import Albedo from "assets/demo_assets/spaceship_diffuse.png";
import Normal from "assets/test_materials/normal.jpg";
import Roughness from "assets/test_materials/roughness.jpg";
import Metalness from "assets/test_materials/metallic.png";
import Height from "assets/test_materials/height.jpg";
import envMap1 from "assets/envmaps/fishermans_bastion/posx.jpg";
import envMap2 from "assets/envmaps/fishermans_bastion/negx.jpg";
import envMap3 from "assets/envmaps/fishermans_bastion/posy.jpg";
import envMap4 from "assets/envmaps/fishermans_bastion/negy.jpg";
import envMap5 from "assets/envmaps/fishermans_bastion/posz.jpg";
import envMap6 from "assets/envmaps/fishermans_bastion/negz.jpg";

import textTex from "assets/images/test_tex.png";

import TestPreview from "assets/test_materials/preview_test.png";
import TestPreview2 from "assets/test_materials/preview_test2.png";

import testPNG from "assets/test_materials/image_preview_test.png";

import MeshDemoModal from "./components/MeshDemoModal/index";

const JSZip = require("jszip");
const TourSteps = [
  {
    selector: "",
    content: (
      <>
        <Typography className="c_white f_large fw_600" display="inline">
          Welcome to
        </Typography>
        <Typography className="c_hivegradient f_large fw_600" display="inline">
          {" "}
          Polyhive!
        </Typography>
        <Typography className="c_iconenabled f_mid fw_500 mt_1">
          This tour will show you around the interface and help you get started with your Free
          Trial!
        </Typography>
      </>
    ),
  },
  {
    selector: ".sidenav",
    content: (
      <>
        <Typography className="c_white f_large fw_600">Sidebar</Typography>
        <Typography className="c_iconenabled f_mid fw_500">
          You can use this to switch between the Classic{" "}
          <GradientIcon>
            <ViewInArOutlinedIcon className="mr_05 icon_mid mb_1pxneg as_center" />
          </GradientIcon>
          and Guided{" "}
          <GradientIcon>
            <BurstModeOutlinedIcon className="mr_05 icon_mid mb_1pxneg as_center" />
          </GradientIcon>{" "}
          generation experiences. You can also access your Asset Library{" "}
          <AutoAwesomeMosaicOutlinedIcon className="mr_05 icon_mid mb_1pxneg as_center c_tertiaryfocus" />{" "}
          here.
        </Typography>
      </>
    ),
  },
  {
    selector: ".prompting_form",
    content: (
      <>
        <Typography className="c_white f_large fw_600">Generate Form</Typography>
        <Typography className="c_iconenabled f_mid fw_500">
          To texture assets, upload or select a 3D mesh and fill out the Object Name and Description
          fields. Then, click Start to begin the guided process. <br />
          <br />
          <em>
            For the free tier,{" "}
            <b>
              any assets that you upload and texture may be used by Polyhive strictly to improve our
              systems and for marketing purposes.
            </b>{" "}
            Please only use Polyhive with assets where you have all rights required to use, modify,
            display, and distribute. To keep your results private, subscribe to Polyhive Plus or
            contact us at rahul@polyhive.ai.
          </em>
        </Typography>
      </>
    ),
  },
  {
    selector: ".gen_dock",
    content: (
      <>
        <Typography className="c_white f_large fw_600">Dock</Typography>

        <Typography className="c_iconenabled f_mid fw_500">
          You can view your running and completed texture jobs here.
        </Typography>
      </>
    ),
  },
  {
    selector: ".gen_toolbar",
    content: (
      <>
        <Typography className="c_white f_large fw_600">Toolbar</Typography>

        <Typography className="c_iconenabled f_mid fw_500">
          The toolbar contains buttons to adjust how you view your model, as well as buttons to
          download your model and share it with others.
        </Typography>
      </>
    ),
  },
  {
    selector: ".credits_container",
    content: (
      <>
        <Typography className="c_white f_large fw_600">Credit Balance</Typography>

        <Typography className="c_iconenabled f_mid fw_500">
          Credits are used to run texturing jobs. You can purchase more credits by clicking the +
          button or by upgrading to Polyhive Plus, which gives you 2000 credits per month along with
          other benefits.
        </Typography>
      </>
    ),
  },
  {
    selector: "",
    content: (
      <>
        <Typography className="c_white f_large fw_600" display="inline">
          You're all set!
        </Typography>

        <Typography className="c_iconenabled f_mid fw_500 mt_1">
          Your free trial has been activated and you have been given 200 credits to use. You can
          start texturing by uploading a 3D mesh and filling out the Object Name and Description
          fields. Then, click Start to begin the guided process.
        </Typography>
      </>
    ),
  },
];

const presetPrompts = [
  {
    prompt:
      "treasure box, concept art, asset, hd detailed , 8k , items, concept art trending artstation",
    object_desc: "a treasure box in the style of concept art trending on artstation",
    name: "treasure chest",
    mesh: 1,
  },
  {
    prompt: "viking helmet, symmetrical, bilateral symmetry, 8k, high quality, 3d, material",
    object_desc: "a viking helmet from the video game Skyrim",
    name: "viking helmet",
    mesh: 3,
  },
  {
    prompt: "elemental war hammer with runes",
    object_desc: "a war hammer with runes on it in the style of the video game League of Legends",
    name: "war hammer",
    mesh: 2,
  },
  {
    prompt: "a tshirt by gary panter",
    object_desc: "a tshirt by gary panter",
    name: "tshirt",
    mesh: 0,
  },

  {
    prompt: "a tshirt with egyptian hieroglyphics",
    object_desc: "a tshirt with egyptian hieroglyphics",
    name: "tshirt",
    mesh: 0,
  },
];

const TEXTURE_JOB_ETC = 540;
const IMAGE_JOB_ETC = 60;

const Generate = () => {
  const [jobArray, setJobArray] = useState([]);
  const jobArrayRef = useRef(null);
  jobArrayRef.current = jobArray;

  const [runningJobs, setRunningJobs] = useState([]);
  const runningJobsRef = useRef(null);
  runningJobsRef.current = runningJobs;

  const [controller, dispatch] = useVisionUIController();
  const { activeOrg, developerInfo, permissions, credits, subscription } = controller;
  const childRef = useRef(null);

  // Snackbar
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");

  // Modals
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showPromptingForm, setShowPromptingForm] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showJobStartModal, setShowJobStartModal] = useState(false);
  const [showInsufficientCreditsModal, setShowInsufficientCreditsModal] = useState(false);
  const [showMeshDemoModal, setShowMeshDemoModal] = useState(false);
  const [showContentModal, setShowContentModal] = useState(false);
  // Forms
  const [showRetextureForm, setShowRetextureForm] = useState(true);
  const promptingFormRef = useRef(null);
  promptingFormRef.current = showPromptingForm;
  const [showInpaintingForm, setShowInpaintingForm] = useState(false);
  const [showTiledForm, setShowTiledForm] = useState(false);
  const [showCanvasForm, setShowCanvasForm] = useState(false);
  const canvasFormRef = useRef(null);
  const [showMeshGenerateForm, setShowMeshGenerateForm] = useState(false);

  const [defaultAssets, setDefaultAssets] = useState({});
  const [uploadedFile, setUploadedFile] = useState(null);
  const [currentVersionId, setCurrentVersionId] = useState("");
  const currentVersionIdRef = useRef(null);
  currentVersionIdRef.current = currentVersionId;
  const [currentJob, setCurrentJob] = useState({}); // this is the job that is being viewed in the model viewer
  const currentJobRef = useRef(null);
  currentJobRef.current = currentJob;

  // 0: shirt, 1: treasure chest, 2: war hammer, 3: viking helmet, 4: tiled texture, 5: canvas inpaint, -2: custom, -3: none
  const [selectedMesh, setSelectedMesh] = useState(-3); // this is the mesh that is being viewed in the model viewer
  const [notificationPermission, setNotificationPermission] = useState(false);

  const [currentTime, setCurrentTime] = useState(new Date());

  const [renderer, setRenderer] = useState(null);
  const [scene, setScene] = useState(null);
  const [camera, setCamera] = useState(null);
  const rendererRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  rendererRef.current = renderer;
  sceneRef.current = scene;
  cameraRef.current = camera;

  const location = useLocation();

  const dockRef = useRef(null);
  const promptingFormElementRef = useRef(null);
  const retextureFormElementRef = useRef(null);
  const canvasCanvasRef = useRef(null);
  const modelViewerRef = useRef(null);
  const jobInspectorRef = useRef(null);

  const [shareLoading, setShareLoading] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [shareOpen, setShareOpen] = useState(false);

  const [jobInspectorOpen, setJobInspectorOpen] = useState(false);
  const [wireframe, setWireframe] = useState(false);
  const [materialValues, setMaterialValues] = useState(true);
  const [prevMetalness, setPrevMetalness] = useState(0);
  const [texture, setTexture] = useState(true);
  const [prevTexture, setPrevTexture] = useState(null);

  const [oldTextureMap, setOldTextureMap] = useState(null);
  const [prevOldTextureMap, setPrevOldTextureMap] = useState(null);
  const [prevMaskedTextureMap, setPrevMaskedTextureMap] = useState(null);

  const [tourOpen, setTourOpen] = useState(false);
  const [togglerOptions, setTogglerOptions] = useState(["Standard", "HD"]);

  // Retexture Form State
  const [selectedRes, setSelectedRes] = useState("Standard");

  const [canvasImgSrc, setCanvasImgSrc] = useState([null, null]);
  const [selectedPreviewId, setSelectedPreviewId] = useState("");
  const [parameterImageId, setParameterImageId] = useState("");

  const [jobStartParams, setJobStartParams] = useState(null);
  const [savedUploadedFile, setSavedUploadedFile] = useState(null);
  const [savedSelectedMesh, setSavedSelectedMesh] = useState(-3);

  const [paintbrushSize, setPaintbrushSize] = useState(10);
  const [paintbrushColor, setPaintbrushColor] = useState("#FF00FE");
  const [paintbrushType, setPaintbrushType] = useState("brush");
  const [jobInspectorTab, setJobInspectorTab] = useState(true);

  // if location state has a versionid and a path, then set the appropriate variables
  const { versionId, path } = location.state || {};
  const { onboarding } = location.state || false;
  const { jobFields } = location.state || {};

  // SOCKETS
  const wsRef = useRef(null); // This will hold our WebSocket object

  useEffect(() => {
    if (activeOrg !== "") {
      wsRef.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);

      wsRef.current.onopen = () => {
        console.log("WebSocket connected");
        wsRef.current.send(JSON.stringify({ type: "register", clientId: activeOrg }));
      };

      wsRef.current.onmessage = function (event) {
        const data = JSON.parse(event.data);
        console.log("RECEIVED SOCKET UPDATE", data);
        const jobRef = jobArrayRef.current.find((j) => j.jobId === data.job_id);

        if (data.type === "job_progress_obj") {
          // Handle job update
          if (jobRef.applied_obj) {
            console.log(
              "socket_update: applied_obj is true, currentJob jobid is: '",
              currentJobRef.current.jobId,
              "', data.job_id is: '",
              data.job_id,
              "'"
            );
            if (currentJobRef.current.jobId === data.job_id) {
              console.log("socket_update: the currentJob is the updated job, updating texture");
              handleNewInpaintTexture(data.texture_map_s3_path, true);
            }
          } else {
            modelViewerRef.current.handleProgressUpdate(data, handleNewInpaintTexture);
            // set the input_asset_path for the element in jobArrayRef.current to the input_asset_path from data
            jobRef.input_asset_path = data.obj_path;
            jobRef.applied_obj = true;
            setCurrentJob(jobRef);
            handleView(jobRef);
          }
        }
      };

      wsRef.current.onclose = () => {
        console.log("WebSocket disconnected");
        wsRef.current = null;
      };
    }

    // Cleanup WebSocket on component unmount
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [activeOrg]); // Empty array for initializing the WebSocket only once

  useEffect(() => {
    console.log("currentJob useEffect", currentJob);
    if (currentJob.textPrompt) setJobInspectorOpen(true);
  }, [currentJob]);

  useEffect(() => {
    console.log("tiledObjecT: UPLOADED FILE CHANGED");
  }, [uploadedFile]);

  useEffect(() => {
    // update symmetry flags
    console.log("showPromptingForm_useEffect", showPromptingForm);
    try {
      if (showPromptingForm === true) {
        if (document.getElementById("bilateralsymmetry_prompting").checked === true) {
          handleSymmetry(true);
        } else {
          console.log("checked is false");
          handleSymmetry(false);
        }
      } else {
        if (document.getElementById("bilateralsymmetry").checked === true) {
          handleSymmetry(true);
        } else {
          handleSymmetry(false);
        }
      }
    } catch (e) {
      console.log("error in symmetry", e);
    }
  }, [showPromptingForm]);

  useEffect(() => {
    if (permissions.face_count_level) {
      if (permissions.special_model && permissions.special_model !== "") {
        setTogglerOptions(["Standard", "HD", permissions.special_model]);
      }
    }
  }, [permissions]);

  useEffect(() => {
    // set uploadedFile to testcube
    if (showTiledForm === true) {
      handleSymmetry(false);
      setSelectedMesh(4);
    } else {
      setSelectedMesh(-3);
      setUploadedFile(null);
    }
  }, [showTiledForm]);

  // the above function, but for canvas
  useEffect(() => {
    if (showCanvasForm === true) {
      setSavedUploadedFile(uploadedFile);
      console.log("saved selected mesh", selectedMesh);
      setSavedSelectedMesh(selectedMesh);

      handleSymmetry(false);
      setSelectedMesh(5);
      setJobInspectorOpen(true);
      setJobInspectorTab(false);
    } else {
      // setSelectedMesh(-3);
      // setUploadedFile(null);
    }
  }, [showCanvasForm]);

  const spendCredits = async (cost) => {
    console.log("handleCreditSpend", cost);
    if (credits > 0) {
      setCredits(dispatch, credits - cost);
    }
  };

  const refreshCredits = async () => {
    // const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/subscription/info`, {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "X-ORG-ID": activeOrg,
    //   },
    // });
    // if (response.status === 200) {
    //   const data = await response.json();
    //   setCredits(dispatch, parseInt(data.credits));
    // } else {
    //   console.log("error fetching subscription info", response);
    // }
  };

  const handleResChange = (event, newRes) => {
    console.log("prompting_handleResChange", newRes);
    if (newRes !== null) setSelectedRes(newRes);
  };

  const handleSelectedMesh = (mesh) => {
    setWireframe(false);
    setMaterialValues(true);
    setTexture(true);
    setSelectedMesh(mesh);
  };

  const handleNormSelectedMesh = (mesh) => {
    setSelectedMesh(mesh);
  };

  const errorCB = (error) => {
    startSnackbar({
      type: "error",
      message: error,
    });
    setSelectedMesh(2);
  };

  const handleSymmetry = async (
    symmetry,
    nscene = scene,
    nrenderer = renderer,
    ncamera = camera
  ) => {
    console.log("hsymm: symmetry", symmetry);
    console.log("hsymm: scene", nscene);
    let model = null;

    // search scene for child of type Object3D
    try {
      nscene.traverse((child) => {
        if (child.type === "Object3D") {
          console.log("hsymm: found model");
          model = child;
        }
      });

      if (model === null) {
        console.log("hsymm: no model found");
        return;
      }

      // remove the plane if it already exists
      const plane = nscene.getObjectByName("planeYZ");
      if (plane) {
        console.log("hsymm: removing plane");
        nscene.remove(plane);
      }

      if (symmetry) {
        // add vertical plane across symmetry axis to scene

        // make a material for the plane that creates a grid pattern
        const material = new THREE.MeshBasicMaterial({
          color: 0x00999b,
          side: THREE.DoubleSide,
          transparent: true,
          opacity: 0.5,
        });
        const geometry = new THREE.PlaneGeometry(1, 1);

        const box = new THREE.Box3().setFromObject(model);
        const size = new THREE.Vector3();

        box.getSize(size);
        const position = new THREE.Vector3();
        box.getCenter(position);
        geometry.scale(size.z * 1.2, size.y * 1.2, size.z * 1.2);

        const plane = new THREE.Mesh(geometry, material);
        plane.name = "planeYZ";
        plane.rotation.y = Math.PI / 2;
        plane.position.x = position.x;
        plane.position.y = position.y;
        plane.position.z = position.z;
        // plane.position.y = 0.01;
        console.log("hsymm: adding plane to scene");
        nscene.add(plane);
        // update scene
        await nrenderer.render(nscene, ncamera);
      } else {
        // remove plane from scene
        nscene.remove(nscene.getObjectByName("planeYZ"));
        // update scene
        await nrenderer.render(nscene, ncamera);
      }
    } catch (error) {
      console.log("hsymm: error", error);
    }
  };

  const handleWireframe = () => {
    if (selectedMesh === -3) return;

    let model = null;

    // search scene for child of type Object3D
    scene.traverse((child) => {
      if (child.type === "Object3D") {
        console.log("hsymm: found model");
        model = child;
      }
    });

    if (model === null) {
      console.log("hsymm: no model found");
      return;
    }
    console.log("scene", scene);
    model.traverse((child) => {
      if (child.isMesh) {
        console.log("child", child);
        // if material is an array, then it is a multi-material
        if (Array.isArray(child.material)) {
          child.material.forEach((material) => {
            material.wireframe = !wireframe;
          });
        } else {
          child.material.wireframe = !wireframe;
        }
      }
    });
    setWireframe(!wireframe);
    renderer.render(scene, camera);
  };
  const handleVerticalFlip = () => {
    if (selectedMesh === -3) return;

    let model = null;
    // search scene for child of type Object3D
    scene.traverse((child) => {
      if (child.type === "Object3D") {
        console.log("hsymm: found model");
        model = child;
      }
    });

    if (model === null) {
      console.log("hsymm: no model found");
      return;
    }

    console.log("scene", scene);

    model.traverse((child) => {
      if (child.isMesh) {
        console.log("child", child);
        child.rotation.x += Math.PI; // rotate by 180 degrees (pi radians)
      }
    });

    renderer.render(scene, camera);
  };

  const handleRotationalAxis = () => {
    if (selectedMesh === -3) return;

    let model = null;
    // search scene for child of type Object3D
    scene.traverse((child) => {
      if (child.type === "Object3D") {
        console.log("hsymm: found model");
        model = child;
      }
    });
    
    if (model === null) {
      console.log("hsymm: no model found");
      return;
    }

    console.log("scene", scene);

    model.traverse((child) => {
      if (child.isMesh) {
        console.log("child", child);
        child.rotation.z += Math.PI/2; // rotate by 90 degrees (pi radians)
      }
    });

    renderer.render(scene, camera);
  };

  const handleMaterialValues = () => {
    if (selectedMesh === -3) return;

    let model = null;
    let dlight = null;
    // search scene for child of type Object3D
    console.log("scene", scene);
    scene.traverse((child) => {
      if (child.type === "Object3D" || child.name === "tiledObject") {
        console.log("hsymm: found model");
        model = child;
      } else if (child.type === "DirectionalLight") {
        dlight = child;
      }
    });

    console.log("scene", scene);

    if (model === null) {
      console.log("hsymm: no model found");
      return;
    }

    model.traverse((child) => {
      if (child.isMesh) {
        const mesh = child;
        const materials = Array.isArray(mesh.material) ? mesh.material : [mesh.material];
        materials.forEach((material) => {
          console.log("material", material.metalness, "materialValues", materialValues);
          if (materialValues === true) {
            material.metalness = 0;
            material.envMapIntensity = 0;
            // add a light to offset the dark environment map
            const light = new THREE.AmbientLight(0xffffff, 0.6);

            // remove dlight from scene
            dlight.intensity = 0;
            // name the light so we can remove it later
            light.name = "noEnvLight";
            light.position.set(0, 0, 0);
            scene.add(light);
          } else {
            material.metalness = prevMetalness;
            material.envMapIntensity = 1;

            // add dlight to scene
            dlight.intensity = 1;
            // remove the light
            scene.remove(scene.getObjectByName("noEnvLight"));
          }

          material.needsUpdate = true;
        });
      }
    });

    setMaterialValues(!materialValues);

    renderer.render(scene, camera);
  };

  const constructDirectionalLight = (scene2) => {
    const light = new THREE.DirectionalLight(0x55aaff, 1);
    light.position.set(-33.74229152340369, 44.98972203120492, 67.48458304680739);
    light.layers.set(1);
    light.castShadow = true;
    light.shadow.camera.near = 0.5;
    light.shadow.camera.far = 500;
    light.shadow.camera.left = -5;
    light.shadow.camera.right = 5;
    light.shadow.camera.top = 5;
    light.shadow.camera.bottom = -5;
    light.shadow.mapSize.width = 1024;
    light.shadow.mapSize.height = 1024;
    light.shadow.bias = -0.001;
    scene2.add(light);
  };

  const handleTexture = () => {
    if (selectedMesh === -3) return;

    let model = null;

    // search scene for child of type Object3D
    scene.traverse((child) => {
      if (child.type === "Object3D") {
        console.log("hsymm: found model");
        model = child;
      }
    });

    console.log("scene", scene);

    if (model === null) {
      console.log("hsymm: no model found");
      return;
    }

    model.traverse((child) => {
      if (child.isMesh) {
        const mesh = child;

        if (texture === true) {
          // texture is currently on, turn it off
          mesh.material = new THREE.MeshStandardMaterial({ color: 0xffffff });
        } else {
          // texture is currently off, turn it on
          mesh.material = prevTexture;
        }
      }
    });
    handleWireframe();

    setTexture(!texture);

    renderer.render(scene, camera);
  };

  const handlePrevTexture = (texture) => {
    setPrevTexture(texture);
  };

  const handlePrevMetalness = (metalness) => {
    setPrevMetalness(metalness);
  };

  const toggleSnackbar = () => {
    setSnackbar(!snackbar);
  };
  const startSnackbar = (data) => {
    //console.log("snackbar data", data);
    setSnackbarMessage(data.message);
    setSnackbarType(data.type);

    setSnackbar(true);
  };

  useEffect(() => {
    const cookieExists = document.cookie.split(";").some((cookie) => {
      return cookie.trim().startsWith("disclaimer3=true");
    });
    console.log("cookieExists", cookieExists);
    if (!cookieExists) {
      document.cookie = "disclaimer3=true; max-age=31536000"; // Set cookie for 1 year
      setShowDisclaimer(true);
    }

    if (versionId && path) {
      console.log("versionId and path", versionId, path);
      setModel({ id: versionId, path: path });
      setSelectedMesh(-2);
    }
  }, []);

  useEffect(() => {
    if (jobFields) {
      console.log("pulling jobFields", jobFields);
      // retextureFormElementRef.current.applyFieldsforJob(jobFields);
      const prompt = document.getElementById("prompt");
      const negativePrompt = document.getElementById("negative-prompt");
      const seed = document.getElementById("seed");
      // const preserveuvs = document.getElementById("preserveuvs");
      // const materialmaps = document.getElementById("materialmaps");

      prompt.value = jobFields.textPrompt;
      negativePrompt.value = jobFields.job_params.negative_prompt;
      seed.value = jobFields.job_params.seed;
      // preserveuvs.checked = true;
      // materialmaps.checked = true;
      // preserveuvs.checked = jobFields.job_params.preserve_uvs;
      // materialmaps.checked = jobFields.job_params.material_maps;
    } else {
      console.log("pulling, something went wrong");
    }
    console.log("pulling RetextureFormElementRef useEffect", retextureFormElementRef.current);
  }, [retextureFormElementRef.current]);

  useEffect(() => {
    if (activeOrg !== "") {
      getJobData();
      // getDefaultAssets();
    }
  }, [activeOrg]);

  useEffect(() => {}, [scene]);

  // 0 = shirt, 1 = chest, 2 = hammer, 3 = helmet, 4 = cube, -2 = custom, -3 = none
  useEffect(() => {
    console.log("selectedMesh", selectedMesh);
    console.log("currentJob, in selectedMesh useEffect", currentJob);
    console.log("currentJob, currentVersionId", currentVersionIdRef.current);

    // if theres a defined object in the scene called tiledObject, remove it
    console.log("should remove tiledObject");
    if (scene && scene.getObjectByName("tiledObject")) {
      console.log("removing tiledObject");
      scene.remove(scene.getObjectByName("tiledObject"));
      // update the scene
      renderer.render(scene, camera);
    }

    switch (selectedMesh) {
      case 0:
        setUploadedFile(ShirtModel);
        if (currentVersionIdRef.current === "") setCurrentJob({});
        break;
      case 1:
        setUploadedFile(ChestModel);
        if (currentVersionIdRef.current === "") setCurrentJob({});
        break;
      case 2:
        setUploadedFile(HammerModel);
        if (currentVersionIdRef.current === "") setCurrentJob({});
        break;
      case 3:
        setUploadedFile(HelmetModel);
        if (currentVersionIdRef.current === "") setCurrentJob({});
        break;
      case 4:
        console.log("setting uploaded file to cube: tiledObject");
        console.log("permissions", permissions);
        if (!permissions["512_model_generate"]) {
          startSnackbar({
            message: "Please wait for the page to load",
            type: "error",
          });
          break;
        }
        setUploadedFile(TestCube);
        // if (currentVersionIdRef.current === "") setCurrentJob({});
        break;
      case 5:
        console.log("setting uploaded file to cube: tiledObject");
        console.log("permissions", permissions);
        if (!permissions["512_model_generate"]) {
          startSnackbar({
            message: "Please wait for the page to load",
            type: "error",
          });
          break;
        }
        setUploadedFile(TestCube);

        // code block to remove any existing 3D mesh and display 2D canvas
        break;
    }
  }, [selectedMesh]);

  useEffect(() => {
    if (currentVersionId !== "") {
      console.log("selected meshie");
      console.log("currentJobRef.current", currentJob);
      switch (currentJob.jobName) {
        case "shirt":
          setSelectedMesh(0);
          break;
        case "treasure_chest":
          setSelectedMesh(1);
          break;
        case "war_hammer":
          setSelectedMesh(2);
          break;
        case "helmet":
          setSelectedMesh(3);
          break;
        case "custom":
          setSelectedMesh(-2);
          break;
        default:
          console.log("setting selected mesh to -1");
          setSelectedMesh(-2);
      }

      // setSelectedMesh(4);
    }
  }, [currentVersionId]);

  useEffect(() => {
    console.log("runningJobs", runningJobs);

    // if a member of runningJobs has status failed, completed, or cancelled, remove it
    runningJobs.forEach((job) => {
      if (job.status === "Failed" || job.status === "Completed" || job.status === "Cancelled") {
        refreshCredits();

        const filteredList = runningJobs.filter((j) => j.jobId !== job.jobId);
        setRunningJobs([...filteredList]);
      }
    });

    runningJobs.forEach((job) => {
      let isJobTooOld = false;
      // if job is older than a day old, remove it
      if (job.queuedTime) {
        const startedTime = new Date(job.queuedTime);
        const nowTime = new Date();
        const diff = nowTime - startedTime;
        const diffHours = Math.floor(diff / 1000 / 60 / 60);
        console.log("jobAge_diffHours", diffHours);
        if (diffHours > 24) {
          isJobTooOld = true;
        }
      }

      if (job.polling !== true && !isJobTooOld) {
        job.polling = true;
        console.log("starting interval for job with id", job.jobId, "and status", job.status);
        if (job.jobId !== "nopoll") fetchJob(job);

        const interval = setInterval(() => {
          if (job.status === "Failed" || job.status === "Completed") {
            clearInterval(interval);
            const filteredList = runningJobs.filter((j) => j.jobId !== job.jobId);
            setRunningJobs([...filteredList]);
            console.log("clearing interval for job with id", job.jobId, "and status", job.status);
            console.log("view job", job.jobId);
            if (job.status === "Failed") {
              refreshCredits();
            }
          } else if (job.status === "Cancelled") {
            refreshCredits();
            clearInterval(interval);
          } else {
            if (job.status === "In progress" || job.status === "Queued") {
              // if runningJobs doesn't contain a job with the same id, add it
            }
            if (job.jobId !== "nopoll") fetchJob(job);
          }
        }, 3000);
        const timeInterval = setInterval(() => {
          if (job.status === "Failed" || job.status === "Completed") {
            const filteredList = runningJobs.filter((j) => j.jobId !== job.jobId);
            setRunningJobs([...filteredList]);
            clearInterval(timeInterval);
          } else if (job.status === "Cancelled") {
            clearInterval(interval);
          } else {
            console.log("polling job", runningJobs);
            if (!runningJobs.some((j) => j.jobId === job.jobId)) {
              console.log("adding job to running jobs");
              runningJobs.push(job);
              setRunningJobs([...runningJobs]);
            }
            setCurrentTime(new Date());
          }
        }, 3000);
        return () => {
          clearInterval(interval);
          clearInterval(timeInterval);
        };
      }
    });
  }, [runningJobs]);

  useEffect(() => {
    // console.log("props.jobs", props.jobs);

    if (jobArray.length > 0) {
      jobArray.forEach((job) => {
        if (job.status === "In progress" || job.status === "Queued") {
          let isJobTooOld = false;
          // if job is older than a day old, remove it
          if (job.queuedTime) {
            const queuedTime = new Date(job.queuedTime);
            const nowTime = new Date();
            const diff = nowTime - queuedTime;
            const diffHours = Math.floor(diff / 1000 / 60 / 60);
            console.log("jobAge_diffHours", diffHours);
            if (diffHours > 24) {
              isJobTooOld = true;
            }
          }
          // setCurrentJob(job);
          if (!runningJobs.some((j) => j.jobId === job.jobId) && !isJobTooOld) {
            runningJobs.push(job);
            setRunningJobs([...runningJobs]);
            // getProgressUpdate(job, true);
          }
        }
      });
    }
  }, [jobArray]);

  const addJob = (job) => {
    console.log("adding job", job);
    // jobArray.push(job);
    setJobArray([job, ...jobArray]);
  };

  // job: {job_id, job_name, version_id, job_params, text_prompt}
  const addJobProp = (job) => {
    const pushJob = {
      key: jobArray.length,
      completedTime: null,
      errorMsg: null,
      jobId: job.job_id,
      jobName: job.job_name,
      queuedTime: new Date().toISOString(),
      startedTime: null,
      assetId: job.version_id,
      // TODO: fill job_params
      input_asset_path: uploadedFile,
      job_params: job.job_params,
      textPrompt: job.text_prompt,
      versionId: job.version_id,
      res_thumbnail: null,
      status: "Queued",
      upvoteStatus: "",
      eta: null,
      etc: TEXTURE_JOB_ETC,
      unlocked_for_download: false,
      private: subscription.name === "polyhive_plus_plan" ? true : false,
      material_maps_paths: job.material_maps_paths,
      step: null,
    };

    if (job.job_id === "nopoll") {
      pushJob.status = "In progress";
      pushJob.startedTime = new Date().toISOString();
      // set the eta to 2 minutes from now
      const eta = new Date();
      eta.setMinutes(eta.getMinutes() + 2);
      pushJob.eta = eta.toISOString();
    }

    console.log("pushJob", pushJob);
    setJobArray([pushJob, ...jobArray]);
  };

  const addCompletedJobProp = (job) => {
    // job: {job_id, job_name, version_id, job_params, text_prompt}
    const pushJob = {
      key: jobArray.length,
      completedTime: new Date().toISOString(),
      errorMsg: null,
      jobId: job.job_id,
      jobName: job.job_name,
      queuedTime: new Date().toISOString(),
      startedTime: new Date().toISOString(),
      assetId: job.version_id,
      resVersionId: job.resVersionId,
      res_asset_path: job.res_asset_path,
      // TODO: fill job_params
      input_asset_path: uploadedFile,
      job_params: job.job_params,
      textPrompt: job.text_prompt,
      versionId: job.version_id,
      res_thumbnail: null,
      status: "Completed",
      upvoteStatus: "",
      eta: null,
      etc: TEXTURE_JOB_ETC,
      unlocked_for_download: false,
      private: subscription.name === "polyhive_plus_plan" ? true : false,
      material_maps_paths: job.material_maps_paths,
      step: null,
    };
    console.log("pushJob COMPLETED", pushJob);
    setJobArray([pushJob, ...jobArray]);
  };

  const setModel = (data) => {
    console.log("setting model", data.path);
    console.log("childRef", childRef.current);
    setWireframe(false);
    setMaterialValues(true);
    setTexture(true);
    setUploadedFile(data.path);
    setCurrentVersionId(data.id);
  };

  const setJobs = (jobs) => {
    setJobArray([...jobs]);
  };

  const refreshJobs = () => {
    setJobArray([...jobArray]);
  };

  const handleUploadedFile = (file) => {
    setUploadedFile(file);
    console.log("currentJob: handling preset");
    setCurrentVersionId("");
    setCurrentJob({});
    currentVersionIdRef.current = "";
  };

  const getJobData = async () => {
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/retextureJobs`);
    const params = new URLSearchParams();
    // params.set("asset_id", selectedObject.modelId);
    url.search = params.toString();

    if (activeOrg !== "") {
      fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-ORG-ID": activeOrg,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("job Data ", data);
          logAuthorizedWombatEvent("pullingGeneratePage", {});
          if (onboarding === true) {
            setTourOpen(true);
            window.history.replaceState({}, document.title);
          }

          const jobArray = [];
          for (let i = 0; i < data.job_names.length; i++) {
            // assetPath -> filePath,
            // thumbnailPath -> thumbnail
            // lastUpdatedTime -> lastUpdated
            // set data.job_statuses[i] to first letter capitalized

            const status =
              data.job_statuses[i].charAt(0).toUpperCase() + data.job_statuses[i].slice(1);

            const job = {
              key: i,
              completedTime: data.completed_times[i],
              errorMsg: data.error_msgs[i],
              jobId: data.job_ids[i],
              jobName: data.job_names[i],
              queuedTime: data.queued_times[i],
              job_params: data.job_params[i],
              startedTime: data.started_times[i],
              textPrompt: data.text_prompts[i],
              versionId: data.version_ids[i],
              resVersionId: data.res_version_ids[i],
              assetId: data.asset_ids[i],
              status: status,
              upvoteStatus: data.upvote_statuses[i],
              input_asset_path: data.input_asset_paths[i],
              res_thumbnail: data.res_thumbnail_paths[i],
              res_asset_path: data.res_asset_paths[i],
              unlocked_for_download: data.unlocked_for_download[i],
              private: data.private_job[i],
              material_maps_paths: data.material_maps_paths[i],
              eta: null,
              etc: TEXTURE_JOB_ETC,
              step: null,
            };
            // console.log("job", job, i);
            if (
              i === data.job_names.length - 1 &&
              (status === "In progress" || status === "Queued")
            ) {
              handleJobSelect(job);
            }

            const timestamp = "2023-03-03T22:52:58.130Z";
            const givenDate = new Date(timestamp);

            if (new Date(job.queuedTime) < givenDate) {
              // dont push
            } else {
              jobArray.push(job);
            }
          }
          setJobArray([...jobArray.reverse()]);
        })
        .catch((error) => {
          // startSnackbar({ type: "error", message: "Error fetching jobs" });
          console.error("Error fetching jobs:", error);
        });
    }
  };

  const getDefaultAssets = async () => {
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/upload/defaultAssets`);
    // needs headers activeOrg
    try {
      const resp = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-ORG-ID": activeOrg,
        },
      });
      const data = await resp.json();
      console.log("defaultAssets", data);

      const dAssets = {};
      for (var i = 0; i < data.file_names.length; i++) {
        if (data.file_names[i] === "Shirt.glb") {
          dAssets.shirt = data.version_ids[i];
        }
        if (data.file_names[i] === "Shed.glb") {
          dAssets.shed = data.version_ids[i];
        }
        if (data.file_names[i] === "Chest.glb") {
          dAssets.chest = data.version_ids[i];
        }
        if (data.file_names[i] === "Truck.glb") {
          dAssets.truck = data.version_ids[i];
        }
        if (data.file_names[i] === "Hammer.glb") {
          dAssets.hammer = data.version_ids[i];
        }
        if (data.file_names[i] === "Helmet.glb") {
          dAssets.helmet = data.version_ids[i];
        }
      }
      setDefaultAssets(dAssets);
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleDisclaimerClose = () => {
    setShowDisclaimer(false);
  };

  const handleInsufficientCreditsOpen = () => {
    setShowInsufficientCreditsModal(true);
  };

  const handleInsufficientCreditsClose = () => {
    setShowInsufficientCreditsModal(false);
  };

  const handleDownloadModalClose = () => {
    setShowDownloadModal(false);
  };
  const handleDownloadModalOpen = () => {
    setShowDownloadModal(true);
  };

  const handleJobStartModalClose = () => {
    setShowJobStartModal(false);
  };
  const handleJobStartModalOpen = () => {
    setShowJobStartModal(true);
  };

  const handleMeshDemoModalClose = () => {
    setShowMeshDemoModal(false);
  };
  const handleMeshDemoModalOpen = () => {
    setShowMeshDemoModal(true);
  };

  const handleContentModalClose = () => {
    setShowContentModal(false);
  };
  const handleContentModalOpen = () => {
    setShowContentModal(true);
  };

  const handleTourClose = () => {
    setTourOpen(false);
  };

  const getVersionsForAsset = async (assetId, jobId) => {
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/upload/versionsForAsset`);
    const params = new URLSearchParams();
    params.set("asset_id", assetId);
    url.search = params.toString();

    try {
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-ORG-ID": activeOrg,
          "ASSET-ID": assetId,
        },
      });
      const data = await response.json();
      console.log("getVersionsForAsset data", data);
      for (let i = 0; i < data.fileNames.length; i++) {
        if (data.aiJobIds[i] === jobId) {
          console.log("found a match", data.fileNames[i], data.assetPaths[i]);
          return {
            path: data.assetPaths[i],
            name: data.fileNames[i],
            id: data.versionIds[i],
            jobId: jobId,
          };
        }
      }
      return;
    } catch (error) {
      console.log("getVersionsForAsset error", error);
    }
  };

  const getAssetMetadata = async (versionId) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/upload/assetMetadata`;

    try {
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-ORG-ID": activeOrg,
          "VERSION-ID": versionId,
        },
      });
      const data = await response.json();
      console.log("getAssetMetadata data", data);
      return {
        path: data.asset_s3_path,
        name: data.file_name,
        id: data.asset_id,
        thumbnail: data.thumbnail_s3_path,
      };
    } catch (error) {
      console.log("getAssetMetadata error", error);
    }
  };

  const handleJobSelect = async (job) => {
    handleView(job);
    setCurrentJob(job);
    console.log("handleJobSelect job", job);
  };

  const handleView = async (job) => {
    try {
      if (job.jobName === "image") {
        // this is a material maps job, we need to set tiled form to true and apply the maps
        setShowTiledForm(true);
        setShowCanvasForm(false);
        setShowPromptingForm(false);
        setShowInpaintingForm(false);
        setShowRetextureForm(false);
        setShowMeshGenerateForm(false);

        if (job.status === "Completed") {
          handleNewMaterialMaps(job.material_maps_paths);
          setCurrentJob(job);
          return;
        } else {
          startSnackbar({
            type: "error",
            message: "This job is still in progress. Please wait for it to complete.",
          });
          return;
        }
      } else if (showTiledForm === true) {
        setShowTiledForm(false);
        setShowPromptingForm(true);
      }

      console.log("handleView job", job);
      if (job.status === "Queued" || job.status === "In progress" || job.status === "Failed") {
        setModel({ path: job.input_asset_path, id: job.versionId });
        setCurrentJob(job);
        // childRef.current.setPrompts(
        //   job.textPrompt,
        //   job.job_params.negative_prompt,
        //   job.job_params.seed
        // );

        return;
      }

      logAuthorizedWombatEvent("viewedCompletedJobGeneratePage", job);
      setModel({ path: job.res_asset_path, id: job.resVersionId });
      setCurrentJob(job);
    } catch (e) {
      console.log("error", e);
      startSnackbar({
        type: "error",
        message: "Failed to view, this asset may have been deleted",
      });
    }
  };

  const handleCloseRetextureForm = () => {
    setShowRetextureForm(false);
  };

  const handleClosePromptingForm = () => {
    setShowPromptingForm(false);
  };

  const handleCloseInpaintingForm = () => {
    setShowInpaintingForm(false);
  };

  const handleCloseTiledForm = () => {
    setShowTiledForm(false);
  };

  const handleCloseCanvasForm = () => {
    setShowCanvasForm(false);
  };

  const handleCloseMeshGenerateForm = () => {
    setShowMeshGenerateForm(false);
  };

  const handleJobInspectorOpen = () => {
    setJobInspectorOpen(true);
  };
  const handleJobInspectorClose = () => {
    setJobInspectorOpen(false);
  };

  const toggleJobInspector = () => {
    setJobInspectorOpen(!jobInspectorOpen);
  };

  const setRendererContext = (scene, camera, renderer) => {
    setScene(scene);
    setCamera(camera);
    renderer.setPixelRatio(window.devicePixelRatio);

    setRenderer(renderer);
  };

  const handleSubmitJob = async (params) => {
    const requestBody = {
      version_id: "",
      text_prompt: params.prompt,
      job_name: "",
      job_params: params.job_params,
    };
    switch (selectedMesh) {
      case 0:
        requestBody.job_name = "shirt";
        requestBody.version_id = defaultAssets.shirt;
        startJob(requestBody);
        break;
      case 1:
        requestBody.job_name = "treasure_chest";
        requestBody.version_id = defaultAssets.chest;
        startJob(requestBody);
        break;
      case 2:
        requestBody.job_name = "war_hammer";
        requestBody.version_id = defaultAssets.hammer;
        startJob(requestBody);
        break;
      case 3:
        requestBody.job_name = "helmet";
        requestBody.version_id = defaultAssets.helmet;
        startJob(requestBody);
        break;
      case -1:
        requestBody.job_name = "custom";
        const uuid = generateUUID();
        requestBody.version_id = uuid;

        await uploadFile(uuid);
        await takeScreenshot(uuid, uploadedFile.size.toString());
        setSelectedMesh(-2);
        setCurrentVersionId(uuid);

        startJob(requestBody);
        break;

      case -2:
        requestBody.job_name = "custom";
        requestBody.version_id = currentVersionId;
        startJob(requestBody);
        break;
    }
    if (requestBody.job_name === "") {
      startSnackbar({
        type: "error",
        message: "Please select or upload an asset to generate a texture for",
      });
    }
  };

  async function getPixelDataFromBlob(blob) {
    return new Promise((resolve, reject) => {
      const image = new Image();

      image.onload = () => {
        const tempCanvas = document.createElement("canvas");
        tempCanvas.width = image.naturalWidth;
        tempCanvas.height = image.naturalHeight;

        const ctx = tempCanvas.getContext("2d");
        ctx.drawImage(image, 0, 0);

        const imageData = ctx.getImageData(0, 0, tempCanvas.width, tempCanvas.height);
        const pixelData = imageData.data;

        resolve(pixelData);
      };

      image.onerror = () => {
        reject(new Error("Error loading image"));
      };

      image.src = URL.createObjectURL(blob);
    });
  }

  const handleInpaintSubmit = async (params, demo = false) => {
    console.log("RENDERING DEPTH MAP");

    // create an a tag and click it to download the file
    console.log("handleInpaintSubmit", scene);
    let mesh = null;
    scene.traverse(function (object) {
      if (object.type === "Mesh" && object.name !== "planezy" && object.name !== "planexy") {
        // console.log("found mesh", object);
        mesh = object;
      }
    });

    // get texture map from mesh
    let canvas = null;

    try {
      canvas = mesh.material[0].map.source.data;
      // maskMap is a dataurl of the canvas
    } catch (e) {
      console.log("error getting texture map from mesh", e);
      startSnackbar({
        type: "error",
        message: "Mesh does not have a texture map to edit",
      });
      return;
    }
    let blob;
    try {
      blob = await new Promise((resolve) => canvas.toBlob(resolve));
    } catch (error) {
      console.error("Error converting canvas to blob:", error);
      // Handle the error case, e.g., set a default blob or take appropriate action
      blob = null; // or set a default blob
    }

    if (!blob) {
      startSnackbar({
        type: "error",
        message: "Draw a mask on the 3D model to inpaint the texture",
      });
      return;
    }

    const maskUUID = generateUUID();
    // await uploadImage(blob, maskUUID);
    console.log("oldTextureMap", oldTextureMap);
    const oldTextureBlob = await fetch(oldTextureMap).then((r) => r.blob());

    const texUUID = generateUUID();

    const promises = [];
    promises.push(uploadImage(blob, maskUUID));
    promises.push(uploadImage(oldTextureBlob, texUUID));

    await Promise.all(promises);

    console.log("uploaded both files");

    // // object world matrix
    // const worldMatrix = mesh.matrixWorld;
    // console.log("object worldMatrix", worldMatrix);

    // camera world matrix
    const cameraWorldMatrix = camera.matrixWorld;
    console.log("camera worldMatrix", cameraWorldMatrix);

    // convert camera coordinates to spherical coordinates and log them
    const spherical = new THREE.Spherical();
    spherical.setFromCartesianCoords(camera.position.x, camera.position.y, camera.position.z);

    console.log(
      "TEXTURE INPAINTING: CAMERA VIEWPOINT ANGLE: Phi:",
      spherical.theta,
      " Theta: ",
      spherical.phi,
      "r: ",
      spherical.radius
    );

    const cameraPosition = camera.position.clone();
    const cameraTarget = mesh.position.clone();

    // Calculate the camera's direction vector
    const cameraDirection = cameraPosition.clone().sub(cameraTarget).normalize();

    // Calculate the radius (distance) from the camera to the target
    const radius = cameraPosition.distanceTo(cameraTarget);

    // // Calculate the elevation angle
    const elevation = Math.asin(cameraDirection.y);

    // // Calculate the azimuth angle
    const azimuth = Math.atan2(cameraDirection.x, cameraDirection.z);

    // // Convert the angles from radians to degrees (optional)
    const elevationDegrees = elevation * (180 / Math.PI) * -1;
    const azimuthDegrees = azimuth * (180 / Math.PI);

    // const radius = 1
    // covert camera coordinates to camera world matrix

    console.log("handleInpaintSubmit_currentVersionId", currentVersionId);
    let useId = currentVersionId;
    if (currentVersionId === "") {
      // startSnackbar({
      //   type: "error",
      //   message:
      //     "3D Inpaint jobs can only be run on assets that have been textured by Polyhive first",
      // });
      const uuid = generateUUID();

      await uploadFile(uuid);
      useId = uuid;
      setCurrentVersionId(uuid);
      return;
    }

    const requestBody = {
      texture_map_version_id: texUUID,
      masked_texture_map_version_id: maskUUID,
      camera_world_matrix: cameraWorldMatrix,
      radius: radius,
      elevation: elevationDegrees,
      azimuth: azimuthDegrees,
      asset_version_id: useId,
      text_prompt: params.prompt,
      job_params: params.job_params,
    };

    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/submit3DInpaint`);
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
      body: JSON.stringify(requestBody),
    });
    console.log("submit3DInpaint response", response);

    const jsonData = await response.json();
    console.log("submit3DInpaint response", jsonData);

    setPrevMaskedTextureMap(canvas);
    setPrevOldTextureMap(oldTextureMap);
    //  get texture url from jsonData
    const newJob = {
      job_ids: jsonData.job_id,
      queued_times: new Date().toISOString(),
      text_prompts: params.prompt,
      job_params: params.job_params,
      job_names: "3dinpaint",
      job_statuses: "in progress",
      res_asset_paths: null,
    };

    // jobInspectorRef.current.addInpaintJob(newJob);

    return jsonData.job_id;
  };

  const handleMeshSubmit = async (params) => {
    if (params.job_params.reference_image_id === "") {
      startSnackbar({
        type: "error",
        message: "Please select a reference image",
      });
      return;
    }

    const requestBody = {
      reference_image_id: params.job_params.reference_image_id,
      job_params: params.job_params,
    };

    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/submitMeshJob`);
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
      body: JSON.stringify(requestBody),
    });
    console.log("submitMeshGenerate response", response);

    const jsonData = await response.json();
    console.log("submitMeshGenerate response", jsonData);
// if the params.job_params.input_conditioning exists then the name should be "Point Cloud Input", otherwise it should be "Image Input"

    const newName = params.job_params.input_conditioning ? "Point Cloud Input" : "Image Input";
    const job = {
      key: jobArray.length,
      completedTime: null,
      errorMsg: null,
      jobId: jsonData.job_id,
      jobName: "mesh",
      queuedTime: new Date().toISOString(),
      startedTime: new Date().toISOString(),
      assetId: generateUUID(),
      // TODO: fill job_params
      input_asset_path: null,
      job_params: { seed: 0 },
      textPrompt: newName,
      versionId: generateUUID(),
      unlocked_for_download: false,
      res_thumbnail: null,
      status: "In progress",
      upvoteStatus: "",
      etc: IMAGE_JOB_ETC,
      private: subscription.name === "polyhive_plus_plan" ? true : false,
      material_maps_paths: null,
      step: null,
    };
    addJob(job);
    return jsonData.job_id;
  };

  const undoInpaint = () => {
    // For this to work we need to save the masked texture map and the original texture map
    // when we make the inpaint request.
    // then we can revert the state of the original texture map
    // and apply the masked texture map to the mesh
    console.log("undoInpaint");
    console.log("prevMaskedTextureMap", prevMaskedTextureMap);
    console.log("prevOldTextureMap", prevOldTextureMap);
    handleOldTextureMap(prevOldTextureMap, true);
    // since handleNewInpaintTexture uses textureUrl, we cant use it. We need to set the canvas of the texture map to be prevMaskedTextureMap
    let target = null;
    console.log("progressupdate: new texture target", target, scene);

    scene.traverse(function (object) {
      console.log(
        "progressupdate: checking object",
        object,
        "against conditions",
        object.type,
        object.name
      );
      if (object.type === "Mesh" && object.name !== "planeYZ" && object.name !== "planeYX") {
        console.log("progressupdate: found mesh", object);
        target = object;
      }
    });

    target.material[0].map.source.data = prevMaskedTextureMap;
    // Mark the material's map as needing an update
    target.material[0].map.needsUpdate = true;

    // If you want to render the scene and see the changes, you can call the renderer.render function
    renderer.render(scene, camera);
  };

  const updateInpaintJobList = (id, status, path) => {
    console.log("updateInpaintJobList", id, status);
    jobInspectorRef.current.updateInpaintJobList(id, status, path);
  };

  const handleNewInpaintTexture = async (
    textureUrl,
    tryRecursive = false,
    retryAttemptsRemaining = 3
  ) => {
    let target = null;
    console.log("progressupdate: new texture target", target, scene);

    scene.traverse(function (object) {
      console.log(
        "progressupdate: checking object",
        object,
        "against conditions",
        object.type,
        object.name
      );
      if (object.type === "Mesh" && object.name !== "planeYZ" && object.name !== "planeYX") {
        console.log("progressupdate: found mesh", object);
        target = object;
      }
    });

    console.log(
      "progressupdate: is target null?",
      target === null,
      "is tryRecursive",
      tryRecursive
    );
    // If target is null and recursion is allowed, wait for 4 seconds and call the function again
    if (target === null && tryRecursive) {
      console.log("Target not found, retrying in 4 seconds...");
      if (retryAttemptsRemaining === 0) {
        console.log("progressupdate: retry attempts exceeded, aborting");
        return;
      }
      setTimeout(
        () => handleNewInpaintTexture(textureUrl, tryRecursive, retryAttemptsRemaining - 1),
        4000
      );
      return;
    }

    const anisotropy = renderer.capabilities.getMaxAnisotropy();
    const textureLoader = new THREE.TextureLoader();

    let loadedTexture;
    textureLoader.load(textureUrl, (texture) => {
      loadedTexture = texture;
      console.log("progressupdate: texture being applied", loadedTexture);
      loadedTexture.wrapS = THREE.RepeatWrapping;
      loadedTexture.wrapT = THREE.RepeatWrapping;
      loadedTexture.magFilter = THREE.LinearFilter;
      loadedTexture.minFilter = THREE.LinearMipMapLinearFilter;

      // create a new meshbasicmaterial with the texture
      const material = new THREE.MeshBasicMaterial({
        map: loadedTexture,
        side: THREE.DoubleSide,
        transparent: false,
        opacity: 1,
      });
      // Assign the loaded texture to the target material's map property
      console.log("new texture target", target);
      target.material[0] = material;
      // Mark the material's map as needing an update
      target.material[0].map.needsUpdate = true;

      // If you want to render the scene and see the changes, you can call the renderer.render function
      renderer.render(scene, camera);
    });

    // You can set texture options if needed (e.g., repeat, filtering, etc.)
  };

  const handleOldTextureMap = (map, isSrc = false) => {
    console.log("handleOldTextureMap", map);
    if (isSrc) {
      setOldTextureMap(map);
    } else {
      setOldTextureMap(map.data.currentSrc);
    }
  };

  const handleTiledSubmit = async (params) => {
    console.log("handleTiledSubmit", params);
    // we need to hit the ai/{endpoint}
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/tiledTextureJob`);
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      timeout: 60000,
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
      body: JSON.stringify(params),
    });
    const jsonDataId = await response.json();
    if (response.ok === false) {
      let message = "Failed to submit job";
      if (response.status === 402) {
        message = "Insufficient credits";
      }
      console.log(response);
      startSnackbar({ type: "error", message: message });
      return;
    } else {
      startSnackbar({ type: "success", message: "Job submitted successfully" });

      // eta var that is 30 seconds from now
      const eta = new Date();
      eta.setSeconds(eta.getSeconds() + IMAGE_JOB_ETC);
      console.log("adding job with job_id", jsonDataId.job_id);

      const job = {
        key: jobArray.length,
        completedTime: null,
        errorMsg: null,
        jobId: jsonDataId.job_id,
        jobName: "image",
        queuedTime: new Date().toISOString(),
        startedTime: new Date().toISOString(),
        assetId: generateUUID(),
        // TODO: fill job_params
        input_asset_path: null,
        job_params: params.job_params,
        textPrompt: params.prompt,
        versionId: generateUUID(),
        unlocked_for_download: false,
        res_thumbnail: null,
        status: "In progress",
        upvoteStatus: "",
        eta: eta,
        etc: IMAGE_JOB_ETC,
        private: subscription.name === "polyhive_plus_plan" ? true : false,
        material_maps_paths: null,
        step: null,
      };
      addJob(job);
      setCurrentJob(job);
    }
    const job_id = jsonDataId.job_id;
    console.log("tiledTextureJob output", jsonDataId);
    const imageUrl = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/imageJob`);
    const responseImage = await fetch(imageUrl, {
      method: "GET",
      credentials: "include",
      timeout: 60000,
      headers: {
        "Content-Type": "application/json",
        "JOB-ID": job_id,
        "USE-DEV": process.env.NODE_ENV !== "production",
        "X-ORG-ID": activeOrg,
      },
    });

    if (responseImage.ok === false) {
      startSnackbar({ type: "error", message: jsonData.error });
    } else {
      const jsonData = await responseImage.json();

      console.log("tiled_job_result", jsonData);
      const job = jobArrayRef.current.find((job) => job.jobId === job_id);
      const runningJob = runningJobs.find((job) => job.jobId === job_id);

      if (!jsonData.output_map_paths) {
        startSnackbar({ type: "error", message: "Failed to generate texture" });
        // remove job from jobArray and runningJob from runningJobs
        jobArrayRef.current = jobArrayRef.current.filter((job) => job.jobId !== job_id);
        setJobArray([...jobArrayRef.current]);
        const newRunningJobs = runningJobs.filter((job) => job.jobId !== job_id);
        setRunningJobs([...newRunningJobs]);
        return;
      }
      // get job with job_id
      console.log("FOUND JOB?", job);
      job.status === "Completed";
      job.res_thumbnail = jsonData.output_map_paths.albedoURL;
      job.material_maps_paths = jsonData.output_map_paths;
      setJobArray([...jobArrayRef.current]);
      // get the job in runningJobs, set status to completed and update runningJobs
      runningJob.status = "Completed";
      setRunningJobs([...runningJobs]);

      setCurrentJob(job);

      console.log("imageJob result: ", jsonData);

      handleNewMaterialMaps(jsonData.output_map_paths);
    }
    // setJobCompleteParams(jsonData);
  };

  async function loadTexture(url, name) {
    return new Promise((resolve, reject) => {
      // fetch asset blob from url then load

      const loader = new THREE.TextureLoader();
      loader.setCrossOrigin("anonymous");
      console.log("LOADING TEXTURE FROM URL: ", url, " NAME: ", name);
      loader.load(url + "&pls-no-cache-chrome", resolve, undefined, function (error) {
        console.error("Error loading texture", error);
        return new THREE.Texture();
      });
    });
  }

  const loadAllTextures = async (jsonData, tiles) => {
    const textureNames = ["albedo", "normal", "roughness", "metalness", "height"];

    // Create an array of promises
    const texturePromises = textureNames.map((name) => loadTexture(jsonData[`${name}URL`], name));

    // Wait for all promises to resolve
    const textures = await Promise.all(texturePromises);

    // Set properties for each texture
    textures.forEach((texture, index) => {
      texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(tiles * 2, tiles);
    });

    return textures;
  };

  const handleNewMaterialMaps = async (jsonData) => {
    console.log("handleNewMaterialMaps", jsonData);

    // remove all objects from scene with the name "tiledObject"
    const objectsToRemove = [];
    scene.traverse(function (object) {
      if (object.name === "tiledObject") {
        objectsToRemove.push(object);
      }
    });
    objectsToRemove.forEach((object) => {
      scene.remove(object);
    });

    const envMap = new THREE.CubeTextureLoader().load([
      envMap1,
      envMap2,
      envMap3,
      envMap4,
      envMap5,
      envMap6,
    ]);
    envMap.mapping = THREE.CubeReflectionMapping;

    const tiles = 2;

    const [albedo, normal, roughness, metalness, height] = await loadAllTextures(jsonData, tiles);

    // height.repeat.set(4, 4);

    const sphereMaterial = new THREE.MeshStandardMaterial({
      map: albedo,
      normalMap: normal,
      roughnessMap: roughness,
      metalnessMap: metalness,
      metalness: 1,
      // roughness: 0.1,
      displacementMap: height,
      displacementScale: 0.5,
      side: THREE.DoubleSide, // Apply the material to both sides of the plane
      envMap: envMap,
      envMapIntensity: 1,
    });

    sphereMaterial.needsUpdate = true;

    // Create a sphere and apply the material we defined earlier
    const sphereGeometry = new THREE.SphereGeometry(5, 256, 256);
    const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
    sphere.position.set(0, 0, 0);
    // name the sphere tiledSphere so we can find it later
    sphere.name = "tiledObject";
    console.log("adding new tiledObject");
    scene.add(sphere);
    renderer.render(scene, camera);
  };

  const uploadFile = async (uuid) => {
    console.log("uploadFile", uploadedFile);
    const headers = {
      "Content-Type": "application/octet-stream",
      "ASSOCIATED-UUID": uuid,
      "FILE-SIZE": uploadedFile.size.toString(),
      "CHAIN-NAME": "abc",
      "CONTRACT-ADDRESS": "",
      "TOKEN-ID": "",
      FILENAME: uploadedFile.name,
      "X-ORG-ID": activeOrg,
      "PARENT-ID": "",
    };

    if (convertBytesToMB(uploadedFile.size) > 1000) {
      startSnackbar({
        message: "File size must be less than 1GB.",
        type: "error",
      });
      return;
    }
    try {
      const response_main_upload = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/upload/uploadModelForOrg`,
        {
          body: uploadedFile,
          credentials: "include",
          headers: headers,
          method: "POST",
        }
      )
        .then((response) => {
          //console.log("response", response);
          return response;
        })
        .then((response) => {
          if (response.status === 200) {
            //console.log("file uploaded");
            // take screenshot
          } else if (response.status === 403) {
            logoutUser(navigate);
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const uploadImage = async (image, uuid) => {
    console.log("uploadImage", image);
    const headers = {
      "Content-Type": "application/octet-stream",
      "ASSOCIATED-UUID": uuid,
      "FILE-SIZE": image.size.toString(),
      "CHAIN-NAME": "abc",
      "CONTRACT-ADDRESS": "",
      "TOKEN-ID": "",
      FILENAME: image.name ? image.name : "texture.png",
      "X-ORG-ID": activeOrg,
      "PARENT-ID": "",
    };

    if (convertBytesToMB(image.size) > 100) {
      startSnackbar({
        message: "File size must be less than 100MB.",
        type: "error",
      });
      return;
    }
    try {
      const response_main_upload = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/upload/uploadModelForOrg`,
        {
          body: image,
          credentials: "include",
          headers: headers,
          method: "POST",
        }
      )
        .then((response) => {
          //console.log("response", response);
          return response;
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("image uploaded", image.name);
            // take screenshot
          } else if (response.status === 403) {
            logoutUser(navigate);
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const takeScreenshot = async (uuid, fileSize) => {
    const myCanvasWrapper = document.getElementById("retexture-model-viewer");
    const myCanvas = myCanvasWrapper.querySelector("canvas");
    // render a frame
    renderer.render(scene, camera);

    myCanvas.toBlob(async (blob) => {
      await saveBlob(
        blob,
        `screencapture-${myCanvas.width}x${myCanvas.height}.png`,
        uuid,
        fileSize
      );
    });
  };

  const saveBlob = async (blob, fileName, uuid, fileSize) => {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    await handleBlob({ thumbnailURL: url, thumbnailFile: blob }, uuid, fileSize);
  };
  const handleBlob = async (obj, uuid, fileSize) => {
    const headers = {
      "Content-Type": "application/octet-stream",
      "ASSOCIATED-UUID": uuid,
      "FILE-SIZE": fileSize,
      "X-ORG-ID": activeOrg,
      "VERSION-ID": uuid,
    };
    const response_thumbnail_upload = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/upload/uploadThumbnailForVersion`,
      {
        body: obj.thumbnailFile,
        credentials: "include",
        headers: headers,
        method: "POST",
      }
    )
      .then((response) => {
        return response;
      })
      .then((response) => {
        if (response.status === 200) {
        } else if (response.status === 403) {
          logoutUser(navigate);
        } else {
          startSnackbar({
            message: response.statusText,
            type: "error",
          });
          //console.log("thumbnail upload failed, cancelling grid item addition");
        }
      });

    // this is a folder
    return;
  };
  const requestNotificationPermission = async () => {
    if (Notification.permission === "granted") {
      logAuthorizedWombatEvent("notificationPermissionGranted", {});
      setNotificationPermission(true);
      return;
    }

    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      logAuthorizedWombatEvent("notificationPermissionGranted", {});
      setNotificationPermission(true);
      return;
    }
    logAuthorizedWombatEvent("notificationPermissionDenied", {});

    console.log("Notification permission denied");
  };

  const startJob = async (requestBody) => {
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/retexture`);
    logAuthorizedWombatEvent("generateAISubmitAttemptFrontend", {
      requestBody: requestBody,
    });

    console.log("requestBody", requestBody);
    const response = fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.status === 200) {
          startSnackbar({
            message: "Job successfully started.",
            type: "success",
          });
          requestNotificationPermission();
          // spend credits
          refreshCredits();
          logAuthorizedWombatEvent("generateAISubmitSuccessFrontend", {
            requestBody: requestBody,
          });

          return response.json();
        } else if (response.status === 403) {
          logAuthorizedWombatEvent("generateAISubmitFailureForbiddenFrontend", {
            message: response.statusText,
          });
          console.log("received 403 when attempting to submit retexture job");
          logoutUser(navigate);
        } else if (response.status === 402) {
          logAuthorizedWombatEvent("generateAISubmitFailureInsufficientFunds", {
            message: response.statusText,
          });
          handleInsufficientCreditsOpen();
        } else {
          logAuthorizedWombatEvent("generateAISubmitFailureFrontend", {
            message: response.statusText,
          });
          console.log("job start failed", response);
          startSnackbar({
            message: response.statusText,
            type: "error",
          });
        }

        return { status: response.status };
      })
      .then((response) => {
        console.log(response);
        if (response.status != null && response.status !== "200") {
          return;
        }

        // find the job in props.jobs that has the same job_id as the response
        // use props.addJob to add the job to the list of jobs
        const job = {
          key: jobArray.length,
          completedTime: null,
          errorMsg: null,
          jobId: response.job_id,
          jobName: requestBody.job_name,
          queuedTime: new Date().toISOString(),
          startedTime: null,
          assetId: requestBody.version_id,
          // TODO: fill job_params
          input_asset_path: uploadedFile,
          job_params: requestBody.job_params,
          textPrompt: requestBody.text_prompt,
          versionId: requestBody.version_id,
          unlocked_for_download: false,
          res_thumbnail: null,
          status: "Queued",
          upvoteStatus: "",
          eta: null,
          etc: TEXTURE_JOB_ETC,
          private: subscription.name === "polyhive_plus_plan" ? true : false,
          material_maps_paths: null,
        };
        addJob(job);
        // start polling for job status

        // console.log("job", job);
        // set to current job
        // setCurrentJob(job);
        handleJobSelect(job);
      })
      .catch((error) => {
        logAuthorizedWombatEvent("generateAISubmitFailureFrontend", {
          message: error,
        });
        console.log("job start failed", error);
        startSnackbar({
          type: "error",
          message: "Job failed to start.",
        });
      });
  };

  const cancelJob = (job) => {
    logAuthorizedWombatEvent("attemptedJobCancellationGeneratePage", {
      jobId: job.jobId,
    });
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/killRetextureJob`);
    const response = fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
      body: JSON.stringify({ job_id: job.jobId }),
    })
      .then((res) => {
        console.log("cancel job response", res);

        if (res.status === 200) {
          logAuthorizedWombatEvent("successfulJobCancellationGeneratePage", {
            jobId: job.jobId,
          });
          refreshCredits();
          // fetchJob(job);
          const filteredList = runningJobs.filter((j) => j.jobId !== job.jobId);
          setRunningJobs([...filteredList]);

          const jobItem = jobArray.find((j) => j.jobId === job.jobId);
          jobItem.status = "Cancelled";
          setJobArray([...jobArray]);
          // setCurrentJob(null);

          startSnackbar({
            type: "success",
            message: "Job successfully cancelled",
          });
          if (job.jobName === "custom") {
            setSelectedMesh(-2);
            setCurrentVersionId(job.versionId);
          }
        } else if (res.status === 420) {
          // job in progress, can only cancel jobs that are queued
          logAuthorizedWombatEvent("failedJobCancellationGeneratePage", {
            error: 420,
            jobId: job.jobId,
          });
          startSnackbar({
            type: "error",
            message: "Error: Job is already in progress, cannot cancel",
          });
        } else {
          logAuthorizedWombatEvent("failedJobCancellationGeneratePage", {
            jobId: job.jobId,
          });
          startSnackbar({ type: "error", message: "Error: Failed to cancel job" });
        }
        return res;
      })

      .catch((err) => {
        console.log("error", err);
      });
  };

  const showNotification = (message) => {
    logAuthorizedWombatEvent("attemptingBrowserNotification", {
      message: message,
      pageFocused: document.hasFocus(),
      permission: notificationPermission,
    });
    const notification = new Notification("Polyhive", {
      body: message,
      icon: "favicon.png",
    });
    notification.onclick = () => {
      logAuthorizedWombatEvent("userClickedJobNotification", { message: message });
      window.focus();
    };
  };

  const fetchJob = async (job) => {
    try {
      const jobRef = jobArray.find((j) => j.jobId === job.jobId);

      if (job.jobName === "image") {
        jobRef.etc = IMAGE_JOB_ETC;
        return;
      } else {
        jobRef.etc = TEXTURE_JOB_ETC;
      }

      const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/retextureStatus`);
      const params = new URLSearchParams();
      params.set("jobId", job.jobId);
      url.search = params.toString();
      const resp = fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-ORG-ID": activeOrg,
        },
      });
      const data = await resp.then((res) => res.json());

      console.log(
        "polling job",
        job.jobId,
        "with status",
        job.status,
        data.job_status,
        "data",
        data
      );

      const dataStatus = data.job_status.charAt(0).toUpperCase() + data.job_status.slice(1);
      // find job in props.jobs using jobId

      jobRef.eta = data.job_eta;
      jobRef.completedTime = data.completed_time;
      jobRef.startedTime = data.started_time;
      jobRef.step = data.step;
      if (jobRef.status !== dataStatus) {
        jobRef.status = dataStatus;
        // props.setJobs(jobsRef.current);
      }

      if (data.job_status === "completed") {
        // we need to fetch the versions for the asset at currentJob.versionId
        // and then set the latest version as the uploadedFile
        showNotification("AI Retexture Job Completed");
        jobRef.res_asset_path = data.output_s3_path;
        jobRef.resVersionId = data.res_version_id;
        //   props.startSnackbar({ type: "success", message: "Retexture Job Completed" });
        setCurrentJob(jobRef);

        handleView(jobRef);
        // try {
        //   dockRef.current.refreshDock();
        // } catch (e) {
        //   console.log("error refreshing dock", e);
        //   startSnackbar({
        //     type: "error",
        //     message:
        //       "We've encountered an error, please refresh the page. If the problem persists, contact us on Discord or at team@polyhive.ai",
        //   });
        // }

        //
        //   await takeScreenshot(currentJob.versionId, uploadedFile.size.toString());
      }
      if (
        data.job_status === "completed" ||
        data.job_status === "failed" ||
        data.job_status === "cancelled"
      ) {
        const status = data.job_status.charAt(0).toUpperCase() + data.job_status.slice(1);

        if (data.completed_time !== null) {
        } else {
          jobRef.completedTime = new Date().toISOString();
        }
      } else if (data.error_message !== null) {
        currentJob.errorMsg = data.error_message;
      }
    } catch (e) {
      console.log("error", e);
    }

    // setJobArray([...jobArray]);
  };

  const handleDownload = async (job = currentJob) => {
    // CODE BLOCK TO DISABLE DOWNLOADS FOR FREE USERS
    // if (subscription.name !== "polyhive_plus_plan") {
    //   handleDownloadModalOpen();
    //   logAuthorizedWombatEvent("freeUserAttemptedToDownload", {});

    //   return;
    // }

    const material_maps_paths = job.material_maps_paths;

    if (material_maps_paths && material_maps_paths !== null) {
      const imageUrls = Object.values(material_maps_paths);

      const imagePromises = imageUrls.map(async (url) => {
        const response = await fetch(url + "&pls-no-cache-chrome");
        const blob = await response.blob();
        const urlObject = new URL(url);
        const filename = urlObject.pathname.split("/").pop();
        if (filename === "image_preview.png") {
          return { filename: "albedo.png", blob };
        } else if (filename === "smoothness.png") {
          return { filename: "roughness.png", blob };
        }
        return { filename, blob };
      });

      const images = await Promise.all(imagePromises);

      const zip = new JSZip();
      images.forEach(({ filename, blob }) => {
        zip.file(filename, blob);
      });

      const zipBlob = await zip.generateAsync({ type: "blob" });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(zipBlob);
      downloadLink.download = "Polyhive_Tiled_Texture.zip";
      downloadLink.click();
      return;
    }

    console.log("downloading", job);

    try {
      downloadAsset({
        path: job.res_asset_path + "&pls-no-cache-chrome",
        name: job.jobName + "." + getFileTypeFromS3Url(job.res_asset_path),
      });
    } catch (e) {
      console.log("error", e);
      startSnackbar({
        type: "error",
        message: "Failed to download, this asset may have been deleted",
      });
    }
  };

  const downloadAsset = async (data) => {
    try {
      const response = await fetch(data.path).then((response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          const fileName = data.name;
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = fileName;
          alink.click();
          logAuthorizedWombatEvent("assetDownloadSuccess", {});
          handleDownloadModalClose();
        });
      });
    } catch (error) {
      logAuthorizedWombatEvent("assetDownloadFailed", {
        jobId: job.jobId,
        error: error,
      });
      console.log("downloadAsset error", error);
    }
  };

  const handleShare = async (job = currentJob) => {
    // hit share endpoint for link

    try {
      handleShareOpen();
      console.log("job", job);
      await createShareLink(job.resVersionId);
    } catch (e) {
      console.log("error", e);
      startSnackbar({
        type: "error",
        message: "Failed to generate share link, this asset may have been deleted",
      });
      handleShareClose();
    }
  };

  const createShareLink = async (versionId) => {
    setShareLoading(true);

    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/share/`);
    console.log("SHARING VERSION ID ", versionId);
    try {
      const resp = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-ORG-ID": activeOrg,
          "VERSION-ID": versionId,
        },
      });

      const data = await resp.json();
      console.log("share data", data);
      setShareLink(data.share_id);
      setShareLoading(false);
    } catch (e) {
      setShareLoading(false);
      console.log("error", e);
      startSnackbar({
        type: "error",
        message: "Error: Failed to generate share link",
      });
      handleShareClose();
    }
  };

  const handleShareOpen = async () => {
    setShareOpen(true);
  };
  const handleShareClose = () => {
    setShareOpen(false);
  };

  const handleCurrentVersionId = (versionId) => {
    setCurrentVersionId(versionId);
  };

  const handleNewScene = (nscene, nrender, ncamera) => {
    console.log("scene changed", nscene);
    if (nscene !== null && nscene.children) {
      if (promptingFormRef.current === true) {
        if (
          document.getElementById("bilateralsymmetry_prompting") &&
          document.getElementById("bilateralsymmetry_prompting").checked === true
        ) {
          handleSymmetry(true, nscene, nrender, ncamera);
        } else {
          handleSymmetry(false, nscene, nrender, ncamera);
        }
      } else {
        if (
          document.getElementById("bilateralsymmetry") &&
          document.getElementById("bilateralsymmetry").checked === true
        ) {
          handleSymmetry(true, nscene, nrender, ncamera);
        } else {
          handleSymmetry(false, nscene, nrender, ncamera);
        }
      }
    }
  };

  const sendPreviewToCanvas = (preview, preview_back, id, fromPreview) => {
    setShowCanvasForm(true);
    setShowPromptingForm(false);
    const newSrc = [preview, preview_back];
    setCanvasImgSrc(newSrc);
    setSelectedPreviewId(id);
    if (fromPreview) {
      canvasCanvasRef.current.clearHistory();
      setParameterImageId(id);
    }
  };

  const sendCanvasToGenerate = async (canvas, parameterId) => {
    console.log("");
    setShowCanvasForm(false);
    setShowPromptingForm(true);
    promptingFormElementRef.current.handleCanvasResult(canvas, selectedPreviewId, parameterId);
    // should also bring back the uploaded file and selectedMesh
    setUploadedFile(savedUploadedFile);
    console.log("setting saved selectedMesh to ", savedSelectedMesh);
    setSelectedMesh(savedSelectedMesh);
  };

  const handleCanvasImage = async () => {
    const masks = await canvasCanvasRef.current.getCanvasImage();
    console.log("mask", masks);
    return masks;
    // const maskBlob = await mask.toBlob();
    // const maskFile = new File([maskBlob], "mask.png", { type: "image/png" });
    // return maskFile;
  };
  // const handleNSceneSymmetry = (symmetry, nscene) => {

  const handleSelectedPreviewId = (id) => {
    setSelectedPreviewId(id);
  };

  const handleJobStartParams = (params) => {
    setJobStartParams(params);
  };

  const handlePaintbrushSize = (size) => {
    setPaintbrushSize(size);
    if (showCanvasForm === true) {
      canvasCanvasRef.current.handlePaintbrushSize(size);
    }
  };

  const handlePaintbrushColor = (color) => {
    setPaintbrushColor(color);
  };

  const handlePaintbrushType = (type) => {
    setPaintbrushType(type);
    if (showCanvasForm === true) {
      canvasCanvasRef.current.handlePaintbrushType(type);
    }
  };

  const getProgressUpdate = async (job, firstUpdate) => {
    // find job in jobArrayRef.current using jobId
    // and check if it's status is completed
    // if it is, then we can stop recursively polling
    const jobRef = jobArrayRef.current.find((j) => j.jobId === job.jobId);
    if (
      jobRef.status === "Completed" ||
      jobRef.status === "Failed" ||
      jobRef.status === "Cancelled"
    ) {
      return;
    } else {
      console.log("getProgressUpdate", job);
      const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/textureMap`);
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-ORG-ID": activeOrg,
          "JOB-ID": job.jobId,
        },
      });

      console.log("getProgressUpdate response", response);

      if (response.status === 404) {
        console.log("job not found");
        return;
      }

      const data = await response.json();

      console.log("getProgressUpdate data", data);
      if (firstUpdate === true) {
        modelViewerRef.current.handleProgressUpdate(data, handleNewInpaintTexture);
        // set the input_asset_path for the element in jobArrayRef.current to the input_asset_path from data
        jobRef.input_asset_path = data.obj_s3_path;
        setCurrentJob(jobRef);
        handleView(jobRef);
      } else {
        if (currentJobRef.current.jobId === job.jobId) {
          handleNewInpaintTexture(data.texture_map_s3_path, true);
        }
      }

      getProgressUpdate(job, false);
    }
  };

  const handleCanvasRetry = () => {
    canvasFormRef.current.handleRetry();
  };

  const saveToHistory = (requestBody, action) => {
    canvasCanvasRef.current.saveToHistory(requestBody, action);
  };

  const handleCanvasFormFields = (fields) => {
    canvasFormRef.current.handleFields(fields);
  };

  const handleJobInspectorTab = (tab) => {
    setJobInspectorTab(tab);
  };

  const updateDemoJob = (id) => {
    // we need to set the job to completed and attach the model file as the res_asset_path
    // then we need to handleView the job
    const job = jobArrayRef.current.find((j) => j.jobId === id);
    job.status = "Completed";
    job.res_asset_path = DemoTable;
    job.completedTime = new Date().toISOString();
    job.res_thumbnail = DemoThumb;
    setCurrentJob(job);
    handleSelectedMesh(-2);
    setUploadedFile(DemoTable);
  };

  const DockElement = (
    <Dock
      jobs={jobArray}
      getAssetMetadata={getAssetMetadata}
      handleView={handleView}
      currentVersionId={currentVersionId}
      handleCancel={cancelJob}
      activeOrg={activeOrg}
      currentJob={currentJob}
      startSnackbar={startSnackbar}
      handleSelectedMesh={handleSelectedMesh}
      ref={dockRef}
      permissions={permissions}
      refreshCredits={refreshCredits}
      handleContentModalOpen={handleContentModalOpen}
    />
  );

  return (
    <DashboardLayout>
      <DashboardNav />
      <Toolbar
        runningJobs={runningJobsRef.current}
        currentTime={currentTime}
        handleDownload={handleDownload}
        handleShare={handleShare}
        currentJob={currentJobRef.current}
        jobInspectorOpen={jobInspectorOpen}
        toggleJobInspector={toggleJobInspector}
        handleWireframe={handleWireframe}
        handleVerticalFlip={handleVerticalFlip}
        handleRotationalAxis={handleRotationalAxis}
        handleMaterialMaps={handleMaterialValues}
        materialMaps={materialValues}
        wireframe={wireframe}
        handleTexture={handleTexture}
        texture={texture}
        startSnackbar={startSnackbar}
      />
      {/* <Sidenav
        permissions={permissions}
        showRetextureForm={showRetextureForm}
        handleRetextureForm={(val) => {
          setShowRetextureForm(val);
        }}
        showPromptingForm={showPromptingForm}
        handlePromptingForm={(val) => {
          setShowPromptingForm(val);
        }}
        showInpaintingForm={showInpaintingForm}
        handleInpaintingForm={(val) => {
          setShowInpaintingForm(val);
        }}
        showTiledForm={showTiledForm}
        handleTiledForm={(val) => {
          setShowTiledForm(val);
        }}
        showCanvasForm={showCanvasForm}
        handleCanvasForm={(val) => {
          setShowCanvasForm(val);
        }}
        showMeshGenerateForm={showMeshGenerateForm}
        handleMeshGenerateForm={(val) => {
          setShowMeshGenerateForm(val);
        }}
        showMeshDemoModal={showMeshDemoModal}
        handleMeshDemoModal={(val) => {
          setShowMeshDemoModal(val);
        }}
      /> */}

      <RetextureForm2
        open={showRetextureForm}
        ref={retextureFormElementRef}
        handleClose={handleCloseRetextureForm}
        startSnackbar={startSnackbar}
        defaultAssets={defaultAssets}
        handleUploadedFile={handleUploadedFile}
        selectedMesh={selectedMesh}
        handleSelectedMesh={handleSelectedMesh}
        handleCurrentVersionId={handleCurrentVersionId}
        handleSymmetry={handleSymmetry}
        handleSubmitJob={handleSubmitJob}
        runningJobs={runningJobs}
        permissions={permissions}
        refreshCredits={refreshCredits}
        handleInsufficientCreditsOpen={handleInsufficientCreditsOpen}
        handleResChange={handleResChange}
        selectedRes={selectedRes}
        subscription={subscription}
        handleJobStartModalOpen={handleJobStartModalOpen}
        togglerOptions={togglerOptions}
        uploadImage={uploadImage}
        DockElement={DockElement}
        setModel={setModel}
        handleInpaintingForm={(val) => {
          setShowInpaintingForm(val);
        }}
        handleSubmitInpaintJob={handleInpaintSubmit}
        paintbrushSize={paintbrushSize}
        handlePaintbrushSize={handlePaintbrushSize}
        paintbrushColor={paintbrushColor}
        handlePaintbrushColor={handlePaintbrushColor}
        paintbrushType={paintbrushType}
        handlePaintbrushType={handlePaintbrushType}
        handleNewInpaintTexture={handleNewInpaintTexture}
        handleOldTextureMap={handleOldTextureMap}
        oldTextureMap={oldTextureMap}
        addCompletedJob={addCompletedJobProp}
        undoInpaint={undoInpaint}
        handleMeshSubmit={handleMeshSubmit}
      />
      <PromptingForm
        open={showPromptingForm}
        ref={promptingFormElementRef}
        handleClose={handleClosePromptingForm}
        startSnackbar={startSnackbar}
        defaultAssets={defaultAssets}
        handleUploadedFile={handleUploadedFile}
        selectedMesh={selectedMesh}
        handleSelectedMesh={handleSelectedMesh}
        handleNormSelectedMesh={handleNormSelectedMesh}
        handleCurrentVersionId={handleCurrentVersionId}
        handleSymmetry={handleSymmetry}
        runningJobs={runningJobs}
        currentVersionId={currentVersionId}
        uploadedFile={uploadedFile}
        uploadFile={uploadFile}
        takeScreenshot={takeScreenshot}
        addJob={addJobProp}
        permissions={permissions}
        refreshCredits={refreshCredits}
        handleInsufficientCreditsOpen={handleInsufficientCreditsOpen}
        subscription={subscription}
        handleJobStartModalOpen={handleJobStartModalOpen}
        togglerOptions={togglerOptions}
        sendPreviewToCanvas={sendPreviewToCanvas}
        jobStartParams={jobStartParams}
        handleJobStartParams={handleJobStartParams}
        selectedPreviewId={selectedPreviewId}
        parameterImageId={parameterImageId}
        uploadImage={uploadImage}
      />
      <MeshGenerateForm
        open={showMeshGenerateForm}
        // ref={promptingFormElementRef}
        handleClose={handleCloseMeshGenerateForm}
        startSnackbar={startSnackbar}
        defaultAssets={defaultAssets}
        handleUploadedFile={handleUploadedFile}
        selectedMesh={selectedMesh}
        handleSelectedMesh={handleSelectedMesh}
        handleNormSelectedMesh={handleNormSelectedMesh}
        handleCurrentVersionId={handleCurrentVersionId}
        handleSymmetry={handleSymmetry}
        runningJobs={runningJobs}
        currentVersionId={currentVersionId}
        uploadedFile={uploadedFile}
        uploadFile={uploadFile}
        takeScreenshot={takeScreenshot}
        addJob={addJobProp}
        permissions={permissions}
        refreshCredits={refreshCredits}
        handleInsufficientCreditsOpen={handleInsufficientCreditsOpen}
        subscription={subscription}
        handleJobStartModalOpen={handleJobStartModalOpen}
        togglerOptions={togglerOptions}
        sendPreviewToCanvas={sendPreviewToCanvas}
        jobStartParams={jobStartParams}
        handleJobStartParams={handleJobStartParams}
        selectedPreviewId={selectedPreviewId}
        parameterImageId={parameterImageId}
        uploadImage={uploadImage}
        updateDemoJob={updateDemoJob}
      />
      <MeshDemoModal
        open={showMeshDemoModal}
        handleClose={handleMeshDemoModalClose}
        addJob={addJobProp}
        updateDemoJob={updateDemoJob}
        startSnackbar={startSnackbar}
      />

      {/* <InpaintingForm
        open={showInpaintingForm}
        handleClose={handleCloseInpaintingForm}
        startSnackbar={startSnackbar}
        defaultAssets={defaultAssets}
        handleUploadedFile={handleUploadedFile}
        selectedMesh={selectedMesh}
        handleSelectedMesh={handleSelectedMesh}
        handleCurrentVersionId={handleCurrentVersionId}
        handleSymmetry={handleSymmetry}
        handleSubmitJob={handleInpaintSubmit}
        runningJobs={runningJobs}
        permissions={permissions}
        refreshCredits={refreshCredits}
        handleInsufficientCreditsOpen={handleInsufficientCreditsOpen}
        handleResChange={handleResChange}
        selectedRes={selectedRes}
        subscription={subscription}
        handleJobStartModalOpen={handleJobStartModalOpen}
        handleNewInpaintTexture={handleNewInpaintTexture}
        handleOldTextureMap={handleOldTextureMap}
        updateInpaintJobList={updateInpaintJobList}
      /> */}
      <TiledForm
        open={showTiledForm}
        handleClose={handleCloseTiledForm}
        startSnackbar={startSnackbar}
        defaultAssets={defaultAssets}
        handleUploadedFile={handleUploadedFile}
        selectedMesh={selectedMesh}
        handleSelectedMesh={handleSelectedMesh}
        handleCurrentVersionId={handleCurrentVersionId}
        handleSymmetry={handleSymmetry}
        handleSubmitJob={handleTiledSubmit}
        runningJobs={runningJobs}
        permissions={permissions}
        refreshCredits={refreshCredits}
        handleInsufficientCreditsOpen={handleInsufficientCreditsOpen}
        handleResChange={handleResChange}
        selectedRes={selectedRes}
        subscription={subscription}
        handleJobStartModalOpen={handleJobStartModalOpen}
        uploadImage={uploadImage}
        togglerOptions={togglerOptions}
      />
      <CanvasForm
        ref={canvasFormRef}
        open={showCanvasForm}
        handleClose={handleCloseCanvasForm}
        startSnackbar={startSnackbar}
        defaultAssets={defaultAssets}
        handleUploadedFile={handleUploadedFile}
        selectedMesh={selectedMesh}
        handleSelectedMesh={handleSelectedMesh}
        handleCurrentVersionId={handleCurrentVersionId}
        currentVersionId={currentVersionId}
        handleSymmetry={handleSymmetry}
        handleCanvasImage={handleCanvasImage}
        runningJobs={runningJobs}
        permissions={permissions}
        refreshCredits={refreshCredits}
        handleInsufficientCreditsOpen={handleInsufficientCreditsOpen}
        handleResChange={handleResChange}
        selectedRes={selectedRes}
        subscription={subscription}
        handleJobStartModalOpen={handleJobStartModalOpen}
        uploadImage={uploadImage}
        selectedPreviewId={selectedPreviewId}
        handleSelectedPreviewId={handleSelectedPreviewId}
        jobStartParams={jobStartParams}
        sendPreviewToCanvas={sendPreviewToCanvas}
        saveToHistory={saveToHistory}
        parameterImageId={parameterImageId}
      />
      <CanvasCanvas
        imgSrc={canvasImgSrc}
        show2DCanvas={showCanvasForm}
        sendCanvasToGenerate={sendCanvasToGenerate}
        ref={canvasCanvasRef}
        handleCanvasRetry={handleCanvasRetry}
        startSnackbar={startSnackbar}
        selectedPreviewId={selectedPreviewId}
        handleSelectedPreviewId={handleSelectedPreviewId}
        handleCanvasFormFields={handleCanvasFormFields}
        paintbrushSize={paintbrushSize}
        paintbrushType={paintbrushType}
      />
      {/* <JobInspector
        ref={jobInspectorRef}
        open={jobInspectorOpen}
        handleClose={handleJobInspectorClose}
        refreshJobs={refreshJobs}
        activeOrg={activeOrg}
        job={currentJob}
        paintbrushSize={paintbrushSize}
        handlePaintbrushSize={handlePaintbrushSize}
        paintbrushColor={paintbrushColor}
        handlePaintbrushColor={handlePaintbrushColor}
        paintbrushType={paintbrushType}
        handlePaintbrushType={handlePaintbrushType}
        jobInspectorTab={jobInspectorTab}
        handleJobInspectorTab={handleJobInspectorTab}
        handleNewInpaintTexture={handleNewInpaintTexture}
      /> */}

      <ShareModal
        open={shareOpen}
        onClose={handleShareClose}
        link={shareLink}
        loading={shareLoading}
      />
      <Box className="form_canvas center_column">
        <ModelViewer
          ref={modelViewerRef}
          url={uploadedFile}
          setRendererContext={setRendererContext}
          errorCB={errorCB}
          handleNewScene={handleNewScene}
          handlePrevMetalness={handlePrevMetalness}
          handlePrevTexture={handlePrevTexture}
          permissions={permissions}
          showInpaintingForm={showInpaintingForm}
          showTiledForm={showTiledForm}
          handleOldTextureMap={handleOldTextureMap}
          showCanvasForm={showCanvasForm}
          paintbrushSize={paintbrushSize}
          paintbrushColor={paintbrushColor}
          paintbrushType={paintbrushType}
        />
        {selectedMesh === -3 ? (
          <Box className="prompt_container">
            {/* <Typography className="f_mid c_iconenabled fw_400" mb={2}>
              Select a mesh from the left to retexture or try out one of these example prompts!
            </Typography>
            <Grid container spacing={1}>
              {presetPrompts.map((prompt, index) => {
                return (
                  <Grid item key={index}>
                    <Box
                      className="preset_prompt"
                      onClick={() => {
                        try {
                          if (showPromptingForm === true) {
                            logAuthorizedWombatEvent("presetPromptClicked", prompt);
                            document.getElementById("object_description").value =
                              prompt.object_desc;
                            document.getElementById("bilateralsymmetry_prompting").checked = true;

                            document.getElementById("object_name").value = prompt.name;

                            setSelectedMesh(prompt.mesh);
                          } else {
                            logAuthorizedWombatEvent("presetPromptClicked", prompt);
                            document.getElementById("prompt").value = prompt.prompt;
                            document.getElementById("bilateralsymmetry").checked = true;
                            setSelectedMesh(prompt.mesh);
                          }
                        } catch (e) {
                          console.log("error", e);
                          startSnackbar({
                            type: "error",
                            message: "An error occured, failed to select preset prompt",
                          });
                        }
                      }}
                    >
                      <Typography className="preset_prompt_text fw_500">
                        {showPromptingForm ? prompt.object_desc : prompt.prompt}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid> */}
          </Box>
        ) : (
          <></>
        )}
      </Box>
      {/* <DisclaimerModal open={showDisclaimer} handleClose={handleDisclaimerClose} /> */}
      <DownloadModal open={showDownloadModal} handleClose={handleDownloadModalClose} />
      <InsufficientCreditsModal
        open={showInsufficientCreditsModal}
        handleClose={handleInsufficientCreditsClose}
      />

      <JobStartModal open={showJobStartModal} handleClose={handleJobStartModalClose} />
      <Tour
        steps={TourSteps}
        isOpen={tourOpen}
        onRequestClose={handleTourClose}
        showNumber={false}
        lastStepNextButton={
          <Button className="default_btn bg_hivegradient c_white f_mid fw_500">Close Tour</Button>
        }
        showNavigation={false}
        maskClassName=""
        className="bg_darkbg c_iconenabled br_8"
        nextButton={
          <Button className="default_btn bg_hivegradient c_white f_mid fw_500">Next</Button>
        }
        prevButton={<Button className="default_btn bg_lightbg c_white f_mid fw_500">Back</Button>}
      />

      <VuiSnackbar
        color={snackbarType}
        icon="notifications"
        title="Polyhive"
        content={snackbarMessage}
        dateTime="Just now"
        open={snackbar}
        close={toggleSnackbar}
        onClose={toggleSnackbar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
      />
    </DashboardLayout>
  );
};

export default Generate;
