import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";

import { Box, Typography } from "@mui/material";

import colors from "assets/theme/base/colors";
import BurstModeOutlinedIcon from "@mui/icons-material/BurstModeOutlined";

import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Grid from "@mui/material/Grid";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";
import ShirtThumb from "assets/images/default-thumbnails/shirtthumb.png";
import ShedThumb from "assets/images/default-thumbnails/shedthumb.png";
import ChestThumb from "assets/images/default-thumbnails/chestthumb.png";
import TruckThumb from "assets/images/default-thumbnails/truckthumb.png";
import HammerThumb from "assets/images/default-thumbnails/hammerthumb.png";
import HelmetThumb from "assets/images/default-thumbnails/helmetthumb.png";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import Button from "@mui/material/Button";
import {
  convertBytesToMB,
  getFileType,
  generateUUID,
  getTimeDifferenceInSeconds,
} from "util/UtilFunctions";
import { useNavigate } from "react-router-dom";
import { useVisionUIController } from "context/index";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { HtmlTooltip } from "layouts/dashboards/default/components/CustomTooltip/index";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { ColorRing } from "react-loader-spinner";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import CustomStepper from "../Stepper/index";
import Toggler from "../Toggler/index";
import CasinoIcon from "@mui/icons-material/Casino";
import { Dice } from "react-ionicons";
import CreditIcon from "assets/icons/crediticon.png";
import TestPreview from "assets/demo_assets/table_concept_output.png";
import TestPreview2 from "assets/test_materials/preview_test2.png";

import FirstPreview from "assets/demo_assets/first_preview.png";

import demo_image1 from "assets/demo_assets/render_chair_0.png";
import demo_image2 from "assets/demo_assets/render_chair_30.png";
import demo_image3 from "assets/demo_assets/render_chair_60.png";
import demo_image4 from "assets/demo_assets/render_chair_90.png";
import demo_image5 from "assets/demo_assets/render_chair_120.png";
import demo_image6 from "assets/demo_assets/render_chair_150.png";
import demo_image7 from "assets/demo_assets/render_chair_180.png";
import demo_image8 from "assets/demo_assets/render_chair_210.png";
import demo_image9 from "assets/demo_assets/render_chair_240.png";
import demo_image10 from "assets/demo_assets/render_chair_270.png";
import demo_image11 from "assets/demo_assets/render_chair_300.png";
import demo_image12 from "assets/demo_assets/render_chair_330.png";

import GradientIcon from "components/Custom/GradientIcon/index";
import InputSlider from "../InputSlider/index";
import ReactCrop from "react-image-crop";

const ESTIMATED_PREVIEW_TIME = 100; // 20 seconds
const ESTIMATED_PROCESSING_TIME = 14; // 7 seconds
const TIMEOUT_MS = 1600000; // 60 seconds
const PROCESSING_TIMOUT_MS = 5000; // 20 seconds
const ESTIMATED_MESH_TIME = 10; // 60 seconds

const demo_grid = [
  demo_image1,
  demo_image2,
  demo_image3,
  demo_image4,
  demo_image5,
  demo_image6,
  demo_image7,
  demo_image8,
  demo_image9,
  demo_image10,
  demo_image11,
  demo_image12,
];

const MeshGenerateForm = forwardRef((props, ref) => {
  const fileInput = React.useRef(null);

  const refImgFileInput = React.useRef(null);
  const [referenceImage, setReferenceImage] = useState(null);
  const navigate = useNavigate();

  const [selectedImageId, setSelectedImageId] = useState("");
  const selectedImageIdRef = useRef(null);
  selectedImageIdRef.current = selectedImageId;

  const [stepImages, setStepImages] = useState([]);
  const stepImagesRef = useRef(null);
  stepImagesRef.current = stepImages;
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());
  const [jobCompleteParams, setJobCompleteParams] = useState({});
  const [loadingJobStart, setLoadingJobStart] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [targetDate, setTargetDate] = useState(new Date());
  const [selectedRes, setSelectedRes] = useState("Standard");
  const [isFromCanvas, setIsFromCanvas] = useState(false);
  const [selectedMapResolution, setSelectedMapResolution] = useState("1K");

  const [crop, setCrop] = useState({
    unit: "%", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
    aspect: 1 / 1,
  });
  const cropRef = useRef(null);
  cropRef.current = crop;
  const imgRef = useRef(null);

  const [stepIds, setStepIds] = useState({
    1: "",
    2: "",
    3: "",
  });

  const [previewImage, setPreviewImage] = useState(null);

  const [controller, dispatch] = useVisionUIController();
  const { activeOrg, developerInfo } = controller;
  const [step, setStep] = useState(0); // 0: upload form, 1: gallery1, 2: gallery2, 3: gallery3, 4: submission form
  const stepRef = useRef(null);
  stepRef.current = step;

  useEffect(() => {
    if (step > 0 && step < 2) {
      console.log("prompting_stepIds at step ", step, stepIds[step]);
      handleNewStepImages();
    } else if (step === 2) {
      handleFinalStep();
    } else if (step === 0) {
      // apply job name and description from jobStartParams to fields
      if (
        props.jobStartParams &&
        props.jobStartParams.object_name &&
        props.jobStartParams.object_description
      ) {
        document.getElementById("object_name").value = props.jobStartParams.object_name;
        document.getElementById("object_description").value =
          props.jobStartParams.object_description;
      }
    }
  }, [step]);

  // use imperative handle to expose functions to parent component
  useImperativeHandle(ref, () => ({}));

  useEffect(() => {
    console.log("prompting_useEffect_open_resetState");
    if (selectedImageIdRef.current !== props.selectedPreviewId) resetState();
  }, [props.open]);

  useEffect(() => {
    console.log("prompting_stepImages useEffect ", stepImages);
  }, [stepImages]);

  useEffect(() => {
    console.log("selectedImageId changed", selectedImageId, stepImagesRef.current);
    if (isFromCanvas) {
      setStep(3);
    }
  }, [selectedImageId]);

  useEffect(() => {
    console.log("prompting_targetDate changed", targetDate);
    // new step initiated, need to have a timer updating current date every second
    const interval = setInterval(() => {
      console.log("prompting_interval");
      setCurrentDate(new Date());
      // if current date is past target date then clear interval
      if (new Date() > targetDate) {
        console.log("prompting_interval_clear");
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [targetDate]);

  const handleResChange = (event, newRes) => {
    console.log("prompting_handleResChange", newRes);
    if (newRes !== null) setSelectedRes(newRes);
  };

  const handleMapResolutionChange = (event, newRes) => {
    console.log("prompting_handleMapResolutionChange", newRes);
    if (newRes !== null) setSelectedMapResolution(newRes);
  };

  const resetState = () => {
    console.log("prompting_resetState");
    setStep(0);
    setStepImages([]);
    setSelectedImageId("");
    setStepIds({
      1: "",
      2: "",
      3: "",
    });
    // setJobStartParams({});
    setJobCompleteParams({});
    setAbortController(new AbortController());
    setLoadingJobStart(false);
    setLoadingOptions(false);
    setCurrentDate(new Date());
    setTargetDate(new Date());
    props.handleSymmetry(false);
    setIsFromCanvas(false);

    // console.log("prompting_resetState_error", e);
  };

  const onCropComplete = async (file) => {
    console.log("onCropComplete", file, crop);

    const createImage = (url) =>
      new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", (error) => reject(error));
        image.src = url;
      });

    if (file && crop.width && crop.height) {
      const objectUrl = URL.createObjectURL(file);
      try {
        const image = await createImage(objectUrl);
        const croppedBlob = await getCroppedImg(image, cropRef.current, "newFile.jpeg");
        console.log("onCropComplete croppedBlob", croppedBlob);
        return croppedBlob;
      } catch (e) {
        console.error("Failed to create image", e);
      } finally {
        URL.revokeObjectURL(objectUrl);
      }
    }
  };

  const getCroppedImg = async (image, crop, fileName) => {
    const canvas = document.createElement("canvas");

    // These scale factors should remain the same for both 'px' and '%'
    const scaleX = image.naturalWidth / imgRef.current.width;
    const scaleY = image.naturalHeight / imgRef.current.height;

    let cropX = crop.x;
    let cropY = crop.y;
    let cropWidth = crop.width;
    let cropHeight = crop.height;

    // Convert to pixels if the unit is in percentages
    if (crop.unit === "%") {
      cropX = (crop.x / 100) * imgRef.current.width;
      cropY = (crop.y / 100) * imgRef.current.height;
      cropWidth = (crop.width / 100) * imgRef.current.width;
      cropHeight = (crop.height / 100) * imgRef.current.height;
    }

    // Scale to natural dimensions
    cropX *= scaleX;
    cropY *= scaleY;
    cropWidth *= scaleX;
    cropHeight *= scaleY;

    // Set canvas dimensions to natural dimensions
    canvas.width = cropWidth;
    canvas.height = cropHeight;

    const ctx = canvas.getContext("2d");

    ctx.drawImage(image, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error("Canvas is empty"));
            return;
          }
          blob.name = fileName;
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  };

  const handleFinalStep = async () => {
    // we need to hit the ai/imageJob endpoint with the selectedId to get the final job params
    if (selectedImageId === "canvasResult") {
      console.log("prompting_handleFinalStep_canvasResult");
      return;
    }

    const useId = props.parameterImageId !== "" ? props.parameterImageId : selectedImageId;

    // const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/meshJobInfo`);
    // const response = await fetch(url, {
    //   method: "GET",
    //   credentials: "include",
    //   timeout: 60000,
    //   headers: {
    //     "Content-Type": "application/json",
    //     "JOB-ID": useId,
    //     "X-ORG-ID": activeOrg,
    //   },
    // });
    // const jsonData = await response.json();
    // let paramObj = jsonData.job_params;

    // if (typeof jsonData.image_s3_path === "undefined") {
    //   paramObj.image_s3_path = "";
    // }
    // console.log("prompting_finalStepJsonData", jsonData);
    const paramObj = {
      negative_prompt: "",
    };

    setJobCompleteParams({ job_params: paramObj, prompt: "" });
  };

  const handleNewStepImages = async () => {
    console.log("prompting_handleNewStepImages", stepImagesRef.current, selectedImageId, step);
    if (step === 1 || step === 2) {
      // we need to go through stepImages and fetch each image using Promise.all
      // then set the stepImages urls to the new images
      const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/imageJob`);
      const promises = [];
      try {
        for (let i = 0; i < stepImages.length; i++) {
          console.log("prompting_fetching image", stepImagesRef.current[i].id);
          promises.push(
            fetch(url, {
              method: "GET",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
                "JOB-ID": stepImagesRef.current[i].id,
                "X-ORG-ID": activeOrg,
              },
              signal: abortController.signal,
            })
          );
        }

        setLoadingOptions(true);
        const timeoutPromise = new Promise((resolve, reject) => {
          setTimeout(() => {
            reject(new Error("Timeout"));
          }, TIMEOUT_MS);
        });
        const responses = await Promise.race([Promise.all(promises), timeoutPromise]);
        console.log("prompting_image_responses", responses);
        const jsonData = await Promise.all(responses.map((response) => response.json()));
        console.log("jsonData", jsonData);
        const newStepImages = [];
        for (let i = 0; i < jsonData.length; i++) {
          if (step === 1) {
            // if (i === 0) {
            //   newStepImages.push({
            //     id: stepImagesRef.current[i].id,
            //     url: FirstPreview,
            //   });
            // } else {
            newStepImages.push({
              id: stepImagesRef.current[i].id,
              url: jsonData[i].output_s3_path,
              second_url: jsonData[i].second_output_s3_path,
            });
            // }
          } else if (step === 2) {
            if (stepImagesRef.current[i].id === stepIds[step]) {
              newStepImages.push(stepImagesRef.current[i]);
            } else {
              newStepImages.push({
                id: stepImagesRef.current[i].id,
                url: jsonData[i].output_s3_path,
                second_url: jsonData[i].second_output_s3_path,
              });
            }
          }
        }
        setStepImages([...newStepImages]);
        setLoadingOptions(false);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("prompting_fetch aborted");
          setLoadingOptions(false);
        } else if (error.message === "Timeout") {
          console.log("prompting_fetch timeout");
          abortController.abort();
          logAuthorizedWombatEvent("promptingAIPreviewTimeoutError", {
            step: step,
            selectedImageId: selectedImageId,
            stepImages: stepImagesRef.current,
          });
          props.startSnackbar({ type: "error", message: "Request timed out. Please try again." });
          resetState();
        } else {
          console.log(error);
          setLoadingOptions(false);
        }
      }
    }
  };

  const setStepImagesFunc = async () => {};

  const handleChange = (event) => {
    props.handleSelectedMesh(-1);
    const fileUploaded = event.target.files[0];

    if (convertBytesToMB(fileUploaded.size) > 100) {
      props.startSnackbar({
        message: "File size too large. Please upload a file less than 10MB",
        type: "error",
      });
      logAuthorizedWombatEvent("promptingAIUploadFileSizeError", {
        message: "File size too large. Please upload a file less than 10MB",
      });
      return;
    }
    logAuthorizedWombatEvent("fileUploadedToGeneratePagePrompting", {
      name: fileUploaded.name,
      type: getFileType(fileUploaded.name),
    });
    props.handleUploadedFile(fileUploaded);
    // reset event target value so that the same file can be uploaded again
    event.target.value = null;
  };

  const handleFileButtonClick = () => {
    fileInput.current.click();
  };

  const handleStepChange = async (step) => {
    setStep(step);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // for now, just change the step and return
    // in the future, we will generate a submission id and submit the job with the object_name, object_desc, and version_id (of the selected/uploaded asset)

    if (props.runningJobs.length > 0 && props.subscription.name !== "polyhive_plus_plan") {
      props.handleJobStartModalOpen();
      logAuthorizedWombatEvent("freeUserAttemptedToQueueMultipleJobs", {});
      return;
    }

    const submitbutton = e.target.elements.submitbutton;
    if (!e.target.elements.bilateralsymmetry) return;
    const zy_symmetry = e.target.elements.bilateralsymmetry.checked;

    // disable submit button for 2 seconds, then enable
    submitbutton.disabled = true;
    console.log("submit button disabled");
    setTimeout(() => {
      submitbutton.disabled = false;
      console.log("submit button enabled");
    }, 2000);

    console.log("submitbutton", submitbutton);

    logAuthorizedWombatEvent("promptingAIStartButtonClicked", {});

    console.log("form submitted", e);

    const object_name = e.target.elements.object_name.value;
    const object_desc = e.target.elements.object_description.value;
    console.log("object desc", object_desc);

    if (object_name === "") {
      logAuthorizedWombatEvent("promptingAIStartError", {
        error: "Please enter an object name.",
      });
      props.startSnackbar({
        message: "Please enter an object name.",
        type: "error",
      });
      return;
    }

    if (object_desc === "") {
      logAuthorizedWombatEvent("promptingAIStartError", {
        error: "Please enter an object description.",
      });
      props.startSnackbar({
        message: "Please enter an object description.",
        type: "error",
      });
      return;
    }

    const submitObj = {
      object_name: object_name,
      object_description: object_desc,
      asymmetric: !zy_symmetry,
    };

    handleSubmitJob(submitObj);
    return;
  };

  // function for starting step 1 (upload form)
  const handleSubmitJob = async (params) => {
    const s_uuid = generateUUID();
    const requestBody = {
      submission_id: s_uuid,
      asymmetric: params.asymmetric,
      object_name: params.object_name,
      object_description: params.object_description,
      sd_output_resolution: 1024,
      special_model: selectedRes,
      version_id: "",
      step_number: 1,
      job_name: "",
    };

    let imageUUID = "";
    if (referenceImage) {
      imageUUID = generateUUID();
      const croppedImageBlob = await onCropComplete(referenceImage);
      // convert blob to File using referenceImage name

      console.log("croppedImageBlob", croppedImageBlob);

      if (!croppedImageBlob) {
        logAuthorizedWombatEvent("generateAISubmitError", {
          error: "Images must be cropped to a square.",
        });
        props.startSnackbar({
          message: "Images must be cropped to a square.",
          type: "error",
        });
        return;
      }

      const croppedImageFile = new File([croppedImageBlob], referenceImage.name);
      // use a tag to download croppedImageBlob
      const cropURL = URL.createObjectURL(croppedImageBlob);
      await props.uploadImage(croppedImageFile, imageUUID);

      requestBody.reference_image_path = cropURL;
      requestBody.reference_image_id = imageUUID;
    }

    // blob url
    console.log("reference image is: ", referenceImage);
    // convert reference image File to blob url
    // const blobUrl = URL.createObjectURL(referenceImage);

    // try {
    //   const dataURL = await readFileAsDataURL(referenceImage);
    //   console.log(dataURL);
    //   requestBody.reference_image_path = dataURL;
    // } catch (error) {
    //   console.error("An error occurred:", error);
    // }

    requestBody.job_name = "mesh";
    startJob(requestBody);

    props.handleJobStartParams(requestBody);
  };

  const startJob = async (requestBody, refresh = false) => {
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/submitNewMeshGenerate`);
    logAuthorizedWombatEvent("promptingAIStartAttemptFrontend", {
      requestBody: requestBody,
    });
    stepIds[requestBody.step_number] = requestBody.submission_id;

    console.log("prompting_requestBody", requestBody);
    setLoadingJobStart(true);
    const preFlightTarget = new Date();
    setCurrentDate(new Date());
    preFlightTarget.setSeconds(preFlightTarget.getSeconds() + ESTIMATED_PROCESSING_TIME);
    setTargetDate(preFlightTarget);

    try {
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error("Timeout"));
        }, PROCESSING_TIMOUT_MS);
      });
      const response = await Promise.race([
        fetch(url, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-ORG-ID": activeOrg,
          },
          body: JSON.stringify(requestBody),
        }),
        timeoutPromise,
      ]);
      console.log("prompting_response", response);
      if (response.status === 200) {
        logAuthorizedWombatEvent("promptingAIStartSuccessFrontend", {
          requestBody: requestBody,
        });
      } else if (response.status === 402) {
        setLoadingJobStart(false);
        logAuthorizedWombatEvent("promptingAIStartFailureInsufficientFunds", {
          message: response.statusText,
        });
        return { status: 402, message: "Insufficient credits" };
      } else if (response.status === 420) {
        setLoadingJobStart(false);
        // servers are overloaded
        logAuthorizedWombatEvent("promptingAIStartFailureOverloaded", {
          message: response.statusText,
        });
        return { status: 420, message: "Servers are overloaded" };
      } else if (response.status === 400) {
        logAuthorizedWombatEvent("promptingAIStartFailureInternalError", {
          message: response.statusText,
        });
        return { status: 400, message: "Internal server error" };
      } else if (response.status === 500) {
        logAuthorizedWombatEvent("promptingAIStartFailureInternalError", {
          message: response.statusText,
        });
        return { status: 500, message: "Internal server error" };
      } else {
        console.log("prompting_response_error", response);
        logAuthorizedWombatEvent("promptingAIStartErrorFrontend", {
          requestBody: requestBody,
          error: response.status,
        });
        return;
      }
      const data = await response.json();
      console.log("prompting_data", data);

      if (step === 3) {
        setLoadingJobStart(false);
        return data;
      }
      // set target date to new date + 15 seconds
      const target = new Date();
      setCurrentDate(new Date());
      target.setSeconds(target.getSeconds() + ESTIMATED_PREVIEW_TIME);
      setTargetDate(target);

      if (data.jobIds) {
        const newStepImages = [];
        if (selectedImageId !== "") {
          // get member of stepImages with id === selectedImageId
          if (refresh === false) {
            const selectedImage = stepImages.find((image) => image.id === selectedImageId);
            console.log("prompting_selectedImage", selectedImage);
            newStepImages.push(selectedImage);
          } else if (step === 2) {
            const selectedImage = stepImages.find((image) => image.id === stepIds[step]);
            console.log("prompting_stepIds[step]", stepIds[step]);
            newStepImages.push(selectedImage);
          }
        }
        for (let i = 0; i < data.jobIds.length; i++) {
          const image = {
            id: data.jobIds[i],
            url: "",
          };
          newStepImages.push(image);
        }
        setStepImages([...newStepImages]);
        console.log("prompting_setting new step images", newStepImages);
        handleStepChange(requestBody.step_number);
        setLoadingJobStart(false);
      }
    } catch (error) {
      if (error.message === "Timeout") {
        logAuthorizedWombatEvent("promptingAIStartTimeoutFrontend", {
          requestBody: requestBody,
        });
        if (step === 0) {
          setLoadingJobStart(false);
          setPreviewImage(TestPreview);
          setStep(1);
        }

        // resetState();
      } else {
        logAuthorizedWombatEvent("promptingAIStartErrorFrontend", {
          requestBody: requestBody,
          error: error,
        });
        props.startSnackbar({
          type: "error",
          message: "There was an error starting the job. Please try again later.",
        });
      }
      setLoadingJobStart(false);
    }
  };

  const handleSymmetry = (e) => {
    const symettry = e.target.checked;
    console.log("symettry", symettry);
    props.handleSymmetry(symettry);
  };

  const handleNextStep = async () => {
    // generate a new submission id and submit new job with selected preview id, step number,
    // on success, start polling submission status
    console.log("handleNextStep", step);
    logAuthorizedWombatEvent("promptingAINextStepFrontend", {
      current_step: step,
    });

    if (step === 1) {
      // we need to submit a new job with the selected preview id as the submission id
      console.log("handling Next Step to 2");
      setLoadingJobStart(true);
      const preFlightTarget = new Date();
      setCurrentDate(new Date());
      preFlightTarget.setSeconds(preFlightTarget.getSeconds() + ESTIMATED_PROCESSING_TIME);
      setTargetDate(preFlightTarget);
      setTimeout(() => {
        handleStepChange(2);
        setLoadingJobStart(false);
      }, PROCESSING_TIMOUT_MS);
    }
  };

  const handleRefresh = async () => {
    logAuthorizedWombatEvent("promptingAIRefreshFrontend", {
      current_step: step,
    });

    // same as handleNextStep but step_number is just step
    if (step === 1) {
      // we need to submit a new job with the selected preview id as the submission id
      setStepImages([]);
      setSelectedImageId("");
      await startJob(props.jobStartParams, true);
      handleNewStepImages();
    }
  };

  const handlePrevStep = async () => {
    // use submission id from stepIds to get previous step images using /ai/submission endpoint
    // set stepImages to previous step images
    // set step to step - 1

    // if step === 1, reset state
    logAuthorizedWombatEvent("promptingAIPrevStepFrontend", {
      current_step: step,
    });
    if (step === 1) {
      // abort any fetches that are still running
      abortController.abort();
      // populate fields with object_name and description from step 0
      console.log("going to prev step with ", props.jobStartParams);

      resetState();
      return;
    }
    abortController.abort();
    setAbortController(new AbortController());
    const submissionId = stepIds[step - 1];
    const data = await checkSubmission(submissionId, step - 1);

    if (step === 2) {
      if (data.length === 2) {
        setSelectedImageId("");
        setStepImages([...data]);
        handleStepChange(step - 1);
      }
    }

    if (step === 3) {
      if (data.length === 2) {
        // get element with id === selectedImageId
        console.log("prompting_handlePrevStep_stepImages", stepImages);
        console.log("prompting_handlePrevStep_stepIds", stepIds, "for step", step);

        // new step images is data with selected image prepended
        const newStepImages = [...data];
        setStepImages([...newStepImages]);
        handleStepChange(step - 1);
      }
    }
  };

  const checkSubmission = async (submissionId, step) => {
    console.log("prompting_checkSubmission_submissionId START", submissionId);
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/submission`);
    url.searchParams.append("submissionId", submissionId);
    url.searchParams.append("stepNumber", step);

    // url param is submission id
    try {
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-ORG-ID": activeOrg,
        },
      });
      const data = await response.json();
      console.log("prompting_checkSubmission_data", data);
      if (data) {
        const arr = convertObjectToArray(data.urls);
        console.log("prompting_checkSubmission_data", arr);

        return arr;
      } else return null;
    } catch (e) {
      console.log("prompting_checkSubmission_error", e);
    }
  };

  function convertObjectToArray(data) {
    return Object.entries(data).map(([id, values]) => ({
      id: id,
      url: values.front,
      second_url: values.back,
    }));
  }

  const handleCompleteSubmit = async () => {
    console.log("prompting_handleCompleteSubmit_jobStartParams", props.jobStartParams);
    // on success, do next line
    const job_params = jobCompleteParams.job_params;
    // set to the url of the image with the id of selectedImageId
    job_params.image_s3_path = TestPreview;
    job_params.texture_resolution = 4096;
    props.addJob({
      job_id: "nopoll",
      job_name: "mesh",
      version_id: "3929c807-6795-4f7c-af63-52e5ffbf0c0a",
      job_params: job_params,
      text_prompt: "A blue wooden table, stylized game art",
      //
    });

    // start a timer thread that will wait for 120 seconds, then log "hello world"
    setTimeout(() => {
      props.updateDemoJob("nopoll");
    }, ESTIMATED_MESH_TIME * 1000); // 120 seconds in milliseconds
    // setStep(3);

    props.startSnackbar({
      message: "Job submitted successfully.",
      type: "success",
    });
    // deduct amount of credits based on jobStartParams.sd_output_resolution
    // resetState();
    props.refreshCredits();
    props.handleClose();
    resetState();
  };

  const handleRefImgFileButtonClick = () => {
    refImgFileInput.current.click();
  };

  const handleRefImgChange = (event) => {
    const fileUploaded = event.target.files[0];

    if (convertBytesToMB(fileUploaded.size) > 100) {
      props.startSnackbar({
        message: "File size too large. Please upload an image smaller than 10MB",
        type: "error",
      });
      logAuthorizedWombatEvent("tiledFormUploadError", {
        message: "File size too large. Please upload an image smaller than 10MB",
      });
      return;
    }
    logAuthorizedWombatEvent("patternImageUploadedToTiledForm", {
      name: fileUploaded.name,
      type: getFileType(fileUploaded.name),
    });
    // props.handleUploadedFile(fileUploaded);
    setReferenceImage(fileUploaded);

    // get image dimensions and use that to set a square crop using % units
    const image = new Image();
    image.src = URL.createObjectURL(fileUploaded);
    image.onload = () => {
      const imageWidth = image.width;
      const imageHeight = image.height;

      // Reduce the size by 10% to add a 5% padding on each side
      const size = Math.min(imageWidth, imageHeight) * 0.9;
      const x = (imageWidth - size) / 2;
      const y = (imageHeight - size) / 2;

      // Convert these to percentages
      setCrop({
        unit: "%",
        x: (x / imageWidth) * 100,
        y: (y / imageHeight) * 100,
        width: (size / imageWidth) * 100,
        height: (size / imageHeight) * 100,
      });
    };

    event.target.value = null;
  };

  const renderStep0 = () => {
    return (
      <>
        <Box className="prompting_form_body" mt={1}>
          <input
            type="file"
            ref={refImgFileInput}
            style={{ display: "none" }}
            multiple={true}
            onChange={handleRefImgChange}
            accept=".png, .jpg, .jpeg"
          />

          {/* Text Prompt Area */}
          <Box mb={2} className="retexture_form_ta" mt={1}>
            <Box className="title_tooltip_container">
              <Typography className="f_small c_white fw_500">
                What is the object you want to generate?
              </Typography>
              <HtmlTooltip
                title={
                  <Box width={350}>
                    <Typography className="f_small c_white fw_500">Object Name</Typography>
                    <Typography className="f_small c_iconenabled fw_400">
                      Please provide the name or type of object you're trying to texture. This helps
                      our system better understand the specific details of your 3D model, allowing
                      for more accurate and realistic texturing.
                    </Typography>
                  </Box>
                }
                placement="top"
              >
                <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
              </HtmlTooltip>
            </Box>
            <Box className="retexture_form_input_container">
              <input
                className="retexture_form_input"
                placeholder="For example: helmet, shirt, etc. (required)"
                id="object_name"
                name="object_name"
                autoComplete="off"
              />
            </Box>
          </Box>
          {/* <hr className="rt_form_hr" /> */}
          <Box className="retexture_form_ta" mb={1}>
            <Box className="title_tooltip_container">
              <Typography className="f_small c_white fw_500">Describe your desired mesh</Typography>
              <HtmlTooltip
                title={
                  <Box width={350}>
                    <Typography className="f_small c_white fw_500">Mesh Description</Typography>
                    <Typography className="f_small c_iconenabled fw_400">
                      Please describe the mesh you would like to create. Use descriptive language to
                      convey details such as color, material, pattern, and surface quality. The more
                      specific you are, the more accurately our system can generate the desired
                      texture.
                    </Typography>
                  </Box>
                }
                placement="top"
              >
                <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
              </HtmlTooltip>
            </Box>
            <Box className="retexture_form_textarea_container">
              <textarea
                className="retexture_form_textarea"
                placeholder="For example: “I want a viking styled helmet made of rusted steel with horns made of ivory”"
                id="object_description"
                name="object_description"
              />
            </Box>
          </Box>
          <Box className="retexture_form_header mt_1 mb_0">
            <Box className="header_flex">
              <Box className="title_tooltip_container">
                <Typography className="f_small c_white fw_500">L/R Symmetry</Typography>
                <HtmlTooltip
                  title={
                    <Box width={300}>
                      <Typography className="f_small c_white fw_500">L/R Symmetry</Typography>
                      <Typography className="f_small c_iconenabled fw_400">
                        {/* copy for L/R symmetry */}
                        Enable left/right symmetry for your texture by selecting this checkbox. When
                        enabled, our AI algorithm will generate a symmetrical texture for your 3D
                        mesh, with identical details on both sides.
                      </Typography>
                    </Box>
                  }
                  placement="top"
                >
                  <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
                </HtmlTooltip>
              </Box>
            </Box>
            <Box className="header_flex">
              <label class="checkbox">
                <input
                  type="checkbox"
                  name="bilateralsymmetry"
                  id="bilateralsymmetry_prompting"
                  onChange={handleSymmetry}
                />
                <svg viewBox="0 0 21 18">
                  <symbol id="tick-path0" viewBox="0 0 21 18" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.22003 7.26C5.72003 7.76 7.57 9.7 8.67 11.45C12.2 6.05 15.65 3.5 19.19 1.69"
                      fill="none"
                      stroke-width="2.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </symbol>
                  <defs>
                    <mask id="tick0">
                      <use class="tick mask" href="#tick-path0" />
                    </mask>
                  </defs>
                  <use class="tick" href="#tick-path1" stroke="currentColor" />
                  <path
                    fill="none"
                    mask="url(#tick0)"
                    d="M18 9C18 10.4464 17.9036 11.8929 17.7589 13.1464C17.5179 15.6054 15.6054 17.5179 13.1625 17.7589C11.8929 17.9036 10.4464 18 9 18C7.55357 18 6.10714 17.9036 4.85357 17.7589C2.39464 17.5179 0.498214 15.6054 0.241071 13.1464C0.0964286 11.8929 0 10.4464 0 9C0 7.55357 0.0964286 6.10714 0.241071 4.8375C0.498214 2.39464 2.39464 0.482143 4.85357 0.241071C6.10714 0.0964286 7.55357 0 9 0C10.4464 0 11.8929 0.0964286 13.1625 0.241071C15.6054 0.482143 17.5179 2.39464 17.7589 4.8375C17.9036 6.10714 18 7.55357 18 9Z"
                  />
                </svg>
                <svg class="lines" viewBox="0 0 11 11">
                  <path d="M5.88086 5.89441L9.53504 4.26746" />
                  <path d="M5.5274 8.78838L9.45391 9.55161" />
                  <path d="M3.49371 4.22065L5.55387 0.79198" />
                </svg>
              </label>
            </Box>
          </Box>

          <Box className="align_center_row" mb={0} mt={1}>
            <Box className="title_tooltip_container">
              <Typography className="f_small c_white fw_500">Reference Image</Typography>
              <HtmlTooltip
                title={
                  <Box width={200}>
                    <Typography className="f_small c_white fw_500">Reference Image</Typography>
                    <Typography className="f_small c_iconenabled fw_400">
                      Upload an image that will guide the style of the generated texture.
                    </Typography>
                  </Box>
                }
                placement="top"
              >
                <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
              </HtmlTooltip>
            </Box>
            {referenceImage === null && (
              <Button
                className="default_btn bg_hivegradient align_center_row justify_center c_white f_small fw_500 ml_1"
                onClick={handleRefImgFileButtonClick}
              >
                Upload
                <FileUploadRoundedIcon className="icon_medium c_white" />
              </Button>
            )}

            {referenceImage && (
              <Box className="align_center_row">
                <Typography className="f_small c_white fw_500 ml_1 text_nowrap">
                  {referenceImage.name}
                </Typography>
                <CloseRoundedIcon
                  className="icon_small c_white ml_1 br_100 bg_lightblack icon_medium"
                  onClick={() => {
                    setReferenceImage(null);
                  }}
                />
              </Box>
            )}
          </Box>
          {referenceImage && (
            <>
              <Box className="align_center_row" mb={1} mt={0}>
                <ReactCrop aspect={1 / 1} crop={crop} onChange={(newCrop) => setCrop(newCrop)}>
                  <img ref={imgRef} src={URL.createObjectURL(referenceImage)} />
                </ReactCrop>
              </Box>
              <Box mb={1}>
                <InputSlider
                  title="Reference Weight"
                  name="reference_weight"
                  tooltipString="The reference weight controls how much the reference image will influence the generated texture. A higher reference weight will result in a texture that more closely resembles the reference image."
                  min={0}
                  max={1}
                  step={0.1}
                  defaultValue={0.3}
                />
              </Box>
            </>
          )}
        </Box>

        {/* end of body, start of footer */}
        <Box className="prompting_form_footer">
          <hr className="rt_form_hr" />
          <Button
            className={
              props.runningJobs.length === 0
                ? "rt_form_btn"
                : props.subscription && props.subscription.name === "polyhive_plus_plan"
                ? "rt_form_btn"
                : "rt_form_btn"
            }
            type="submit"
            size="large"
            name="submitbutton"
          >
            Start
          </Button>
          <Box className="align_center_row mauto mt_1 d_justify_center">
            <Typography className="f_small c_iconenabled fw_400 mr_05 ta_center">
              Starting the guided process is free. You will only be charged credits at the final
              confirmation step.
            </Typography>
          </Box>
        </Box>
      </>
    );
  };

  const renderStep1 = () => {
    // if step is 1, then all grid items should be loading
    // if step is 2 or 3, then all except the selected grid item should be loading
    return (
      <>
        <Box className="h_auto prompting_form_body">
          {/* 4 squares in a 2x2 grid each with an image */}
          <Box className="retexture_form_grid_container" mt={2}>
            <Grid container spacing={2}>
              {/* if all elements of stepImages contain a url property that is a non empty string, then map it, otherwise, show a loading icon */}

              {/* {stepImages.map((image, index) => {
                return (
                  
                );
              })} */}
              <Grid item xs={12} sm={12}>
                <Box className="mesh_gallery_item">
                  {previewImage === null ? (
                    <Box className="prompting_loading_container">
                      {/* <Spinner h={100} w={100} /> */}
                      {/* Make a progress bar where the width is a percentage determined by difference between currentDate and targetDate / 15seconds */}
                      <Box className="small_progress_container mb_1">
                        <Box
                          className="small_progress"
                          sx={{
                            width: `${
                              100 -
                                (getTimeDifferenceInSeconds(currentDate, targetDate) /
                                  ESTIMATED_PREVIEW_TIME) *
                                  100 >
                              100
                                ? 100
                                : 100 -
                                  (getTimeDifferenceInSeconds(currentDate, targetDate) /
                                    ESTIMATED_PREVIEW_TIME) *
                                    100
                            }%`,
                          }}
                        ></Box>
                      </Box>
                      <Typography className="f_medium fw_500 c_iconenabled">
                        Generating Preview
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      <img
                        src={previewImage}
                        alt="preview_thumbnail"
                        className="prompting_thumb_img"
                      ></img>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {renderFooter()}
      </>
    );
  };

  const renderStep4 = () => {
    // if step is 1, then all grid items should be loading
    // if step is 2 or 3, then all except the selected grid item should be loading
    return (
      <>
        <Box className="body_large prompting_form_body">
          {/* 4 squares in a 2x2 grid each with an image */}
          <Box className="retexture_form_grid_container" mt={2}>
            <Grid container spacing={2}>
              {demo_grid.map((image, index) => {
                return (
                  <Grid item xs={3} sm={3}>
                    <Box className="mesh_grid_item">
                      <>
                        <img src={image} alt="sword" className="thumb_img" />
                      </>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
        {/* {renderFooter()} */}
        <Box className="prompting_form_footer">
          <Button
            className={
              props.runningJobs.length === 0
                ? "rt_form_btn"
                : props.subscription && props.subscription.name === "polyhive_plus_plan"
                ? "rt_form_btn"
                : "rt_form_btn btn_disabled"
            }
            size="large"
            name="submitbutton2"
            onClick={() => {
              handleCompleteSubmit();
            }}
          >
            Generate
          </Button>
          <Box className="align_center_row mauto mt_1 d_justify_center">
            <Typography className="f_small c_iconenabled fw_400 mr_05 ta_center">
              This generation will use a total of{" "}
              {selectedRes === "Standard"
                ? props.permissions["512_model_generate"].credit_cost
                : selectedRes === "HD"
                ? props.permissions["768_model_generate"].credit_cost
                : 0}
            </Typography>
            <img src={CreditIcon} alt="credits" className="icon_small" />
          </Box>
        </Box>
      </>
    );
  };

  const renderFooter = () => {
    return (
      <Box className="prompting_form_footer">
        <hr className="rt_form_hr" />
        <Box className="prompting_step1_footer" mt={2}>
          <Button
            className={"default_btn c_white bg_focusbg fw_400 f_small"}
            name="submitbutton"
            onClick={() => {
              handlePrevStep();
            }}
          >
            <Box className="align_center_row mr_1 w100">
              <KeyboardArrowLeftOutlinedIcon className="mr_0 mt_1px" />
              Back
            </Box>
          </Button>
          <HtmlTooltip
            title={
              selectedImageId === "" ? (
                <Box width={200}>Select a preview to continue</Box>
              ) : (
                <Box>Continue to next step</Box>
              )
            }
            placement="top"
          >
            <Box className="btn_container2">
              <Button
                className={"default_btn c_white bg_focusbg fw_400 f_small"}
                name="submitbutton"
                onClick={() => {
                  handleNextStep();
                }}
                // disabled={selectedImageId === ""}
              >
                <Box className="align_center_row ml_1">
                  Continue <KeyboardArrowRightOutlinedIcon className="mr_0 mt_1px" />
                </Box>
              </Button>
            </Box>
          </HtmlTooltip>
          <Button
            className={"default_btn c_white bg_quaternaryfocusdark fw_400 f_small refresh_button"}
            name="submitbutton"
            onClick={() => {
              handleRefresh();
            }}
            disabled={loadingOptions}
          >
            <Box className="align_center_row ml_1" height="20px">
              Reroll Previews{" "}
              <Dice
                color={colors.white.main}
                height="20px"
                width="20px"
                className="ml_05 mr_0 mt_05 c_white"
              />
            </Box>
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="file"
        ref={fileInput}
        style={{ display: "none" }}
        multiple={true}
        onChange={handleChange}
        accept=".glb, .gltf, .fbx, .obj"
      />
      <Box
        className={
          "prompting_form" +
          (step === 1 || step === 2
            ? " prompting_form_tall"
            : step === 3
            ? " prompting_form_tall"
            : "")
        }
        sx={{
          display: props.open ? "flex" : "none",
        }}
      >
        <Box className="retexture_form_head">
          <Box className="retexture_form_header">
            <Box className="header_flex">
              <GradientIcon>
                <BurstModeOutlinedIcon className="retexture_form_header_icon" />
              </GradientIcon>
              {step > 0 ? (
                <></>
              ) : (
                <Typography className="retexture_form_header_text c_white">
                  Mesh Generate
                </Typography>
              )}
            </Box>
            <Box className="header_flex">{step > 0 ? <CustomStepper step={step} /> : null}</Box>
            <Box className="header_flex">
              <CloseRoundedIcon
                className="retexture_form_header_icon_btn c_white"
                onClick={props.handleClose}
              />
            </Box>
          </Box>
          <Typography className="retexture_form_subheader">
            {step === 0
              ? "Enter a prompt to begin the mesh generation process"
              : step === 1
              ? "We’ve generated your concept art, continue to the next step"
              : step === 2
              ? "Multiple different views of your concept have been generated"
              : null}
          </Typography>
          <hr className="rt_form_hr" />
        </Box>
        {loadingJobStart ? (
          <>
            <Box className="max_container">
              <Box
                className="small_progress mb_1"
                sx={{
                  width: `${
                    100 -
                      (getTimeDifferenceInSeconds(currentDate, targetDate) /
                        ESTIMATED_PROCESSING_TIME) *
                        100 >
                    100
                      ? 100
                      : 100 -
                        (getTimeDifferenceInSeconds(currentDate, targetDate) /
                          ESTIMATED_PROCESSING_TIME) *
                          100
                  }%`,
                }}
              ></Box>
              <Typography className="f_medium c_iconenabled fw_400">
                {stepRef.current === 0
                  ? "Generating Concept Image"
                  : stepRef.current === 1
                  ? "Generating View Angles"
                  : "Generating Mesh"}
              </Typography>
            </Box>
          </>
        ) : stepRef.current === 0 ? (
          renderStep0()
        ) : stepRef.current === 1 ? (
          renderStep1()
        ) : stepRef.current === 2 ? (
          renderStep4()
        ) : null}

        {}
      </Box>
    </form>
  );
});

const Spinner = (props) => {
  return (
    <ColorRing
      visible={true}
      height={props.h}
      width={props.w}
      ariaLabel="blocks-loading"
      wrapperStyle={{}}
      wrapperClass="blocks-wrapper"
      colors={[
        colors.icon.focus,
        colors.icon.secondaryfocus,
        colors.icon.focus,
        colors.icon.secondaryfocus,
        colors.icon.focus,
      ]}
    />
  );
};

export default MeshGenerateForm;
