import React from "react";

// ASSETS
import art_img from "assets/images/art_img.png";
import gamepadIcon from "assets/icons/gamepadIcon.png";
import manualIcon from "assets/icons/manualIcon.png";
import teamIcon from "assets/icons/teamIcon.png";
import VuiTypography from "components/VuiTypography/index";
import colors from "assets/theme/base/colors";
import Button from "@mui/material/Button";
import linearGradient from "assets/theme/functions/linearGradient";
import VuiBox from "components/VuiBox/index";
import VideogameAssetRoundedIcon from "@mui/icons-material/VideogameAssetRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupsIcon from "@mui/icons-material/Groups";
import { Suspense, useEffect } from "react";

import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import CubeMap2 from "assets/images/envmap2.jpg";
import FaithRinggoldShirt from "layouts/applications/home/models/faith_ringgold_shirt.glb";
import GaryPanterShirt from "layouts/applications/home/models/gary_panter_shirt.glb";
import TieDyeShirt from "layouts/applications/home/models/tye_dye.glb";
import HieroglyphicsShirt from "layouts/applications/home/models/hieroglyphics.glb";
import HomeImage from "assets/images/homegraphic-hexagon.png";
import { Link } from "react-router-dom";
import Typing from "react-typing-animation";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";

const heroAssets = [
  {
    file: FaithRinggoldShirt,
    prompt: "A shirt by Faith Ringgold",
  },
  {
    file: GaryPanterShirt,
    prompt: "A shirt by Gary Panter",
  },
  {
    file: TieDyeShirt,
    prompt: "A tie-dye shirt",
  },
  {
    file: HieroglyphicsShirt,
    prompt: "A shirt with hieroglyphics",
  },
];

// generate a random number to pick a random hero asset
const randomIndex = Math.floor(Math.random() * heroAssets.length);

const Model = (props) => {
  // add callback for when file

  // gltf.scene.rotation.y = Math.PI;

  // Initialize new environment map from default cube map
  var textureLoader = new THREE.TextureLoader();
  var texture = textureLoader.load(CubeMap2);
  texture.mapping = THREE.EquirectangularReflectionMapping;
  const scene = new THREE.Scene();

  // const pmremGenerator = new THREE.PMREMGenerator.fromCubemap(texture);

  // texture.encoding = THREE.sRGBEncoding;

  useEffect(() => {
    // fitCameraToObject(camera, gltf.scene, 1.25, null);

    // gltf.scene.traverse((c) => {
    //   if (c instanceof THREE.Mesh) {
    //     //console.log("setting texture");
    //     c.material.envMap = texture;
    //     c.material.envMapIntensity = 1;
    //     // c.material.color.convertSRGBToLinear();
    //   }
    // });

    const loader = new GLTFLoader();
    loader.load(
      props.file,
      (gltf) => {
        const group = new THREE.Group();
        // gltf.scene.position.y = -0.1;
        group.add(gltf.scene);
        scene.add(group);
      },
      undefined,
      (error) => {
        console.error(error);
      }
    );
  }, [props.file]);

  return (
    <>
      <primitive object={scene} scale={props.scale} />
    </>
  );
};

const Hero2 = () => {
  const camera = new THREE.PerspectiveCamera(45, 1, 0.0001, 5000);

  return (
    <div className="hero_section w_100">
      <div className="container d_flex d_justify_between d_align_center">
        <div className="hl d_flex d_flex_column">
          <VuiTypography
            variant="h1"
            sx={{
              background: "linear-gradient(" + colors.icon.focus + " 70% ,#016263 110%) ",
              color: "transparent",
              height: "62px",
              WebkitTextFillColor: "transparent",
              WebkitBackgroundClip: "text",
              fontWeight: "bold",
              fontSize: "3rem",
            }}
          >
            Supercharge
          </VuiTypography>
          <VuiTypography
            variant="h1"
            sx={{
              color: "#fff",
              fontWeight: "bold",
              fontSize: "3rem",
            }}
          >
            your art pipeline with <span style={{ color: colors.icon.secondaryfocus }}>AI.</span>
          </VuiTypography>
          <VuiTypography
            variant="p"
            sx={{
              marginTop: "1rem",
              color: colors.icon.enabled,
              marginBottom: "1rem",
            }}
          >
            Streamlined asset management for game studios to build and ship faster.
          </VuiTypography>
          <div className="h_btn_wrap d_flex d_justify_left d_align_center">
            <Link to="/generate">
              <Button
                sx={{
                  backgroundColor: colors.icon.focus,
                  color: colors.background.secondary,
                  fontSize: "0.8rem",
                  padding: "10px 15px !important",
                  borderRadius: "8px",
                  fontWeight: 450,
                  textTransform: "none",
                  marginRight: "10px",
                  "&:hover": {
                    backgroundColor: colors.icon.focusdark,
                    color: colors.background.secondary,
                  },
                }}
              >
                Get started for free
              </Button>
            </Link>
            <a href="https://docs.polyhive.ai/">
              <Button
                sx={{
                  backgroundColor: "#273357",
                  color: colors.icon.focus,
                  fontSize: "0.8rem",
                  padding: "10px 15px !important",
                  borderRadius: "8px",
                  fontWeight: 450,
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#273357",
                    color: colors.icon.focusdark,
                  },
                }}
              >
                Documentation
              </Button>
            </a>
          </div>
        </div>

        <VuiBox
          sx={{
            width: "400px",
            height: "400px",
            position: "relative",
            "@media (max-width: 1024px)": {
              display: "none",
            },
          }}
        >
          <img
            src={HomeImage}
            alt="homeimage"
            style={{
              height: "80%",
              paddingLeft: "50px",
              paddingRight: "0px",
              // paddingTop: "25px",
            }}
          />
          <VuiBox
            sx={{
              position: "absolute",
              height: "100%",
              width: "90%",
              top: "-20px",
              margin: "auto",
              left: "20px",
              "z-index": 1,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Canvas id="homeCanvasId1">
              <Suspense fallback={null}>
                <Environment
                  background={false}
                  path="/"
                  files="royal_esplanade_1k.hdr"
                  blur={0.4}
                />

                <OrbitControls
                  autoRotate
                  enableZoom={false}
                  enableRotate={true}
                  enablePan={false}
                />
                {/* <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
                    <spotLight position={[-10, -10, -10]} angle={0.15} penumbra={1} /> */}
                {/* <ambientLight intensity={0.5} /> */}
                <Model file={heroAssets[randomIndex].file} camera={camera} scale={3.4} />
              </Suspense>
            </Canvas>
          </VuiBox>
          <VuiBox
            sx={{
              width: "300px",
              height: "40px",
              backgroundColor: colors.background.secondary,
              borderRadius: "8px",
              marginLeft: "50px",
              padding: "5px 10px",
            }}
          >
            <VuiTypography
              variant="body2"
              sx={{ color: colors.icon.focus, fontSize: "1.2rem", opacity: "1.0 !important" }}
            >
              <Typing speed={50} startDelay={1000}>
                <span> "{heroAssets[randomIndex].prompt}" </span>
              </Typing>
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </div>
      <div className="container d_flex d_justify_between" style={{ marginTop: "50px" }}>
        <div className="h_card text_white">
          <div className="d_flex">
            <BoltOutlinedIcon
              sx={{
                color: colors.icon.tertiaryfocus,
                width: "30px",
                height: "30px",
                padding: "3px",
                borderRadius: "8px",
                marginRight: "10px",
                backgroundColor: colors.icon.background,
              }}
            />{" "}
            <VuiBox>
              <VuiTypography
                variant="h2"
                sx={{ fontWeight: 600, fontSize: "1.3rem", color: colors.white.main }}
              >
                Create game assets in minutes with AI
              </VuiTypography>
              <VuiTypography
                variant="body2"
                sx={{
                  color: colors.icon.enabled,
                  lineHeight: "1.6rem !important",
                  fontSize: "1rem",
                  opacity: "1 !important",
                }}
              >
                Leverage the power of generative AI to create textures for 3D objects using only
                text prompts. Produce unique skins and generate infinite asset variations. The only
                limit is your imagination.
              </VuiTypography>
            </VuiBox>
          </div>
        </div>
        <div className="h_card text_white">
          <div className="d_flex">
            <VideogameAssetRoundedIcon
              sx={{
                color: colors.icon.focus,
                width: "30px",
                height: "30px",
                padding: "3px",
                borderRadius: "8px",
                marginRight: "10px",
                backgroundColor: colors.icon.background,
              }}
            />
            <VuiBox>
              <VuiTypography
                variant="h2"
                sx={{ fontWeight: 600, fontSize: "1.3rem", color: colors.white.main }}
              >
                Reduce game development time and complexity.
              </VuiTypography>
              <VuiTypography
                variant="body2"
                sx={{
                  color: colors.icon.enabled,
                  lineHeight: "1.6rem !important",
                  fontSize: "1rem",
                  opacity: "1 !important",
                }}
              >
                Polyhive is built for artists and developers. Use features like automatic thumbnail
                generation, a browser model viewer, and deep game engine integration to increase
                team productivity.
              </VuiTypography>
            </VuiBox>
          </div>
        </div>

        <div className="h_card text_white">
          <div className="d_flex">
            <SettingsIcon
              sx={{
                color: colors.icon.secondaryfocus,
                width: "30px",
                height: "30px",
                padding: "3px",
                borderRadius: "8px",
                marginRight: "10px",
                backgroundColor: colors.icon.background,
              }}
            />{" "}
            <VuiBox>
              <VuiTypography
                variant="h2"
                sx={{ fontWeight: 600, fontSize: "1.3rem", color: colors.white.main }}
              >
                Eliminate manual process and friction
              </VuiTypography>
              <VuiTypography
                variant="body2"
                sx={{
                  color: colors.icon.enabled,
                  lineHeight: "1.6rem !important",
                  fontSize: "1rem",
                  opacity: "1 !important",
                }}
              >
                Never waste another minute searching for lost files or manually importing new asset
                versions into game engines. Use Polyhive to manage and version your assets to save
                hours of time.
              </VuiTypography>
            </VuiBox>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero2;
