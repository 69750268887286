/** 

=========================================================
* AQUEDUCT CONTENT PAGE
=========================================================
* Designed and Coded by Rohan Iyer
=========================================================

*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";
// Vision UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import Footer from "examples/Footer";
import Globe from "examples/Globe";
// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SalesTable from "examples/Tables/SalesTable";
// Data
import salesTableData from "layouts/dashboards/default/data/salesTableData";
import ActiveUsers from "./components/ActiveUsers";
import SalesOverview from "./components/SalesOverview";
import ModelGrid2 from "./components/ModelGrid/index2.js";

// icons
import { FaShoppingCart } from "react-icons/fa";
import { BsGlobe } from "react-icons/bs";
import { IoWallet, IoDocumentText } from "react-icons/io5";

function Default() {
  const { values } = breakpoints;

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <VuiBox py={3}>
        <ModelGrid2>
          <Grid item xs={1} lg={2}></Grid>
        </ModelGrid2>
      </VuiBox>
    </DashboardLayout>
  );
}

export default Default;
