import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import colors from "assets/theme/base/colors";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

const modalStyle = {
  width: "200px",
  height: "100%",
  position: "absolute",
  alignItems: "center",
  justifyContent: "center",
};
const DeleteVersionModal = (props) => {
  const handleDelete = () => {
    props.handleDelete();
    props.handleClose();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ modalStyle }}
    >
      <VuiBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: colors.background.main,
          // border: "2px solid #000",
          boxShadow: 24,
          justifyContent: "center",
          p: 3,
          borderRadius: "15px",
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="" style={{ textAlign: "left" }}>
          <VuiTypography variant="h6" sx={{ color: "#fff", marginBottom: "10px" }}>
            Are you sure you want to delete this version?
          </VuiTypography>
          <Button
            onClick={props.handleClose}
            variant="outlined"
            sx={{
              outline: "none",
              border: "1.5px solid " + colors.icon.disabled,
              color: colors.icon.enabled,
              borderRadius: "7px",
              height: "15px",
              // padding: "0px",
              margin: "0px",
              "&:hover": { border: "1.5px solid " + colors.icon.disabled },
            }}
          >
            Cancel
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            // onClick={props.handleCreateFolder}
            // type="submit"
            variant="contained"
            onClick={handleDelete}
            style={{
              color: "#fff",
              backgroundColor: colors.error.main,
              borderRadius: "7px",
            }}
          >
            Delete
          </Button>
        </div>
      </VuiBox>
    </Modal>
  );
};

/* Utility for generating a unique uuid for uploading a file. */
const generateUUID = () => {
  // Public Domain/MIT
  let d = new Date().getTime(); //Timestamp
  let d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};
export default DeleteVersionModal;
