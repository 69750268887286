// import React, { useState } from "react";

// ASSETS
// import art_img from "assets/images/art_img.png";
// import gamepadIcon from "assets/icons/gamepadIcon.png";
// import manualIcon from "assets/icons/manualIcon.png";
// import teamIcon from "assets/icons/teamIcon.png";
// import VuiTypography from "components/VuiTypography/index";
// import colors from "assets/theme/base/colors";
import Button from "@mui/material/Button";
// import linearGradient from "assets/theme/functions/linearGradient";
// import VuiBox from "components/VuiBox/index";
// import VideogameAssetRoundedIcon from "@mui/icons-material/VideogameAssetRounded";
// import SettingsIcon from "@mui/icons-material/Settings";
// import GroupsIcon from "@mui/icons-material/Groups";
import { Suspense, useEffect, useRef, forwardRef } from "react";

import { Box, Typography, Grid } from "@mui/material";

import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";

import * as THREE from "three";

import { useThree } from "@react-three/fiber";

// import CubeMap2 from "assets/images/envmap2.jpg";
// import DamagedHelmet from "./models/DamagedHelmet.glb";
import FaithRinggoldShirt from "./models/faith_ringgold_shirt.glb";
import GaryPanterShirt from "./models/gary_panter_shirt.glb";
import TieDyeShirt from "./models/tye_dye.glb";
import HieroglyphicsShirt from "./models/hieroglyphics.glb";
import InsectBot from "./models/insectbot_material_2.glb";
import PlateRemesh from "./models/plate_remesh_30k.obj";
// import HomeImage from "assets/images/homegraphic-hexagon.png";
import { Link, useNavigate } from "react-router-dom";
// import Typing from "react-typing-animation";
// import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
// import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
// import ModelGallery from "./components/ModelGallery/index";

import { useFrame } from "@react-three/fiber";

// import NvidiaIcon from "assets/icons/nvidia_inception.png";
// import UnityIcon from "assets/icons/unity_solution.png";
// // import FeatureImage from "assets/images/orc_render.png";
// import OctagonImage from "./components/OctagonImage/index";
// import HexagonImage from "./components/HexagonImage/index";
// import HexagonContainer from "./components/HexagonContainer/index";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CarRender from "assets/home/car_render.png";
import PlaneRender from "assets/home/plane_render.png";
import RobotRender from "assets/home/robot_render.png";
import {
  motion,
  useMotionValue,
  useTransform,
  useAnimation,
  MotionStyle,
  MotionValue,
} from "framer-motion";
import { useInView } from "react-intersection-observer";
import { filter } from "lodash";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const heroAssets = [
  {
    file: FaithRinggoldShirt,
    prompt: "A shirt by Faith Ringgold",
  },
  {
    file: GaryPanterShirt,
    prompt: "A shirt by Gary Panter",
  },
  {
    file: TieDyeShirt,
    prompt: "A tie-dye shirt",
  },
  {
    file: HieroglyphicsShirt,
    prompt: "A shirt with hieroglyphics",
  },
];

// generate a random number to pick a random hero asset
const randomIndex = Math.floor(Math.random() * heroAssets.length);

const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const text = "Intelligent 3D Design";
  const ctrls = useAnimation();
  const navigate = useNavigate();
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      ctrls.start("visible");
    }
  }, [ctrls, inView]);

  const wordAnimation = {
    hidden: {},
    visible: {},
  };

  const characterAnimation = {
    hidden: {
      opacity: 0,
      y: `0.25em`,
    },
    visible: {
      opacity: 1,
      y: `0em`,
      transition: {
        duration: 1,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };
  // useEffect(() => {
  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  return (
    <div className="w100">
      <div className="w100 center_column">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div
              className="d_flex d_flex_column d_justify_center d_align_center"
              style={{ height: isMobile ? "50vh" : "90vh", width: "100%" }}
            >
              <Model file={InsectBot} zoom={9} id="insectbot" wireframe={false} rotate={0} isMobile={isMobile} />
              <Typography className="f_alice1 f_large fw_400 c_black text_center lh_subtitle mt_2">
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.9, duration: 0.5 }}
                >
                  Generated by Spectral v1 Foundation Model
                </motion.p>
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              // height: "100vh",
            }} // Added styles
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="hero_section">
                  <div
                    className="d_flex d_flex_column d_justify_center d_align_center"
                    style={{ 
                      width: "520px",
                      marginLeft: "auto",
                      // marginRight: "50px",
                    }}
                  >
                    
                    {/* Measurement text and horizontal rule with tick marks */}
                    <div className="d_flex d_flex_column d_align_center w100">
                      <Typography className="f_alice f_small fw_400 c_black mb_05">
                        48mm
                      </Typography>
                      <div className="d_flex d_align_center w100">
                        <div style={{ 
                          width: '2px', 
                          height: '12px', 
                          backgroundColor: '#000'
                        }} />
                        <hr style={{ 
                          flex: 1,
                          margin: '0 0px',
                          border: 'none',
                          borderTop: '2px solid #000'
                        }} />
                        <div style={{ 
                          width: '2px', 
                          height: '12px', 
                          backgroundColor: '#000'
                        }} />
                      </div>
                    </div>

                    <Typography
                      className="f_alice f_title fw_400 c_black lh_1_2 text_right w100"
                      aria-label={text}
                    >
                      {text.split(" ").map((word, index) => (
                        <motion.span
                          ref={ref}
                          aria-hidden="true"
                          key={index}
                          initial="hidden"
                          animate={ctrls}
                          variants={wordAnimation}
                          transition={{
                            delayChildren: index * 0.1,
                            staggerChildren: 0.05,
                          }}
                          className="inline-block whitespace-nowrap ml_2"
                        >
                          {word.split("").map((character, index) => (
                            <motion.span
                              aria-hidden="true"
                              key={index}
                              variants={characterAnimation}
                              className="inline-block"
                            >
                              {character}
                            </motion.span>
                          ))}
                        </motion.span>
                      ))}
                    </Typography>

                    <Typography className="f_alice f_subtitle fw_400 c_black text_right lh_subtitle mt_4 w100">
                      <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.9, duration: 0.5 }}
                      >
                        We are a spatial intelligence company building novel foundation models for
                        the next generation of engineering design.
                      </motion.p>
                    </Typography>
                  </div>
                </div>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={6} lg={6}></Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const ModelLoader = forwardRef(({ file, wireframe, rotate }, ref) => {
  const modelRef = useRef();

  useEffect(() => {
    let loader;
    if (file.endsWith(".obj")) {
      loader = new OBJLoader();
    } else {
      loader = new GLTFLoader();
    }

    loader.load(
      file,
      (gltf) => {
        const scene = gltf.scene || gltf;
        scene.traverse((child) => {
          if (child.isMesh) {
            child.material.wireframe = wireframe;
            if (wireframe) {
              child.material.color.set("#555555");
            }
            const rotate_rad = rotate * (Math.PI / 180);
            child.rotation.y = rotate_rad;
          }
        });
        modelRef.current.add(scene);
      },
      undefined,
      (error) => {
        console.error("Error loading model:", error);
      }
    );
  }, [file]);

  useFrame((state, delta) => {
    if (modelRef.current) {
      modelRef.current.rotation.y += delta * 0.2;
    }
  });

  return <group ref={modelRef} />;
});

const Model = ({ file, zoom = 200, id, wireframe, rotate, isMobile }) => {
  return (
    <div className="canvas-container w100">
      <Canvas
        style={{ 
          width: "100%", 
          height: isMobile ? "30vh" : "75vh" 
        }}
        camera={{ position: [0, 9, zoom] }}
        onCreated={({ gl }) => {
          gl.domElement.id = id;
        }}
        gl={{ antialias: true }}
      >
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <OrbitControls enableDamping dampingFactor={0.25} />
        <ModelLoader file={file} wireframe={wireframe} rotate={rotate} />
      </Canvas>
      <InteractionHint />
    </div>
  );
};

const InteractionHint = () => (
  <motion.div
    className="interaction-hint"
    animate={{ x: ["-10px", "10px", "-10px"] }}
    transition={{ duration: 1, repeat: Infinity, ease: "easeInOut" }}
  >
    <div className="hint-circle" />
  </motion.div>
);

export default Hero;
