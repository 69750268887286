import * as React from "react";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";
import VolumeUp from "@mui/icons-material/VolumeUp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { HtmlTooltip } from "layouts/dashboards/default/components/CustomTooltip/index";

const Input = styled(MuiInput)`
  width: 42px;
`;
const marks = Array.from({ length: 11 }, (_, i) => ({ value: i, label: "" }));

export default function SliderNoInput(props) {
  const handleSliderChange = (event, newValue) => {
    // setValue(newValue);
    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  const handleInputChange = (event) => {
    props.onChange(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (props.value < props.min) {
      props.onChange(props.min);
    } else if (props.value > props.max) {
      props.onChange(props.max);
    }
  };

  useEffect(() => {
    props.onChange(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <Box className="retexture_form_header mb_0">
      <Box className="header_flex mr_1">
        <Slider
          value={typeof props.value === "number" ? props.value : props.min}
          onChange={handleSliderChange}
          aria-labelledby="input-slider mr_1"
          className="no_input_slider"
          step={props.step}
          min={props.min}
          max={props.max}
          marks={marks}
          color="secondary"
        />
        <Box width={"50px"} className="ml_2">
          <input
            type="number"
            className="retexture_form_input"
            id="input-slider"
            name={props.name}
            value={props.value}
            onChange={handleInputChange}
            onBlur={handleBlur}
            aria-labelledby="input-slider"
            step={props.step}
            min={props.min}
            max={props.max}
          />
        </Box>
      </Box>
    </Box>
  );
}
