import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import colors from "assets/theme/base/colors";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FolderIcon from "@mui/icons-material/Folder";
import { useVisionUIController } from "context";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Crumb from "./Crumb";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";
const Breadcrumbs = forwardRef((props, ref) => {
  const [controller] = useVisionUIController();
  const [draggedOver, setDraggedOver] = React.useState(false);

  const currentFolderRef = React.useRef();
  currentFolderRef.current = props.currentFolder;
  const selectedIdsRef = React.useRef();
  selectedIdsRef.current = props.selectedIds;
  const { activeOrg, orgs } = controller;
  const [localizedDraggingID, setLocalizedDraggingID] = useState(props.draggingId);
  useEffect(() => {
    // for (let i = 0; i < props.currentPath.length; i++) {
    //   const folder = document.getElementById(props.currentPath[i].modelId);
    //   //console.log("folder", folder);
    //   folder.addEventListener("drop", () => {
    //     //console.log("dropped on a folder");
    //     //console.log("dragging id in drop", localizedDraggingID);
    //     //console.log("dragging id in drop2", props.draggingId);
    //   });
    //   folder.addEventListener("dragover", handleDragOver);
    // }
    const rootfolder = document.getElementById("rootfolder");
    rootfolder.addEventListener("drop", handleDrop);
    rootfolder.addEventListener("dragover", handleDragOver);
    rootfolder.addEventListener("dragleave", handleDragLeave);
    rootfolder.addEventListener("dragend", handleDragLeave);

    return () => {
      rootfolder.removeEventListener("drop", handleDrop);
    };
  }, [props.currentPath]);

  useEffect(() => {
    //console.log("bar drag state changed");
    // setLocalizedDraggingID(props.draggingId);
  }, [props.barDragged]);

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDraggedOver(false);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    props.setBarDragged(false);
    setDraggedOver(false);
    //console.log("dropped", e);
    //console.log("dragging id in drop", localizedDraggingID);
    if (
      e.target.parentElement.classList.contains("rootfolder") ||
      e.target.classList.contains("rootfolder")
    ) {
      //console.log("landed on a root folder");
      if (props.currentFolder !== "") {
        //execute move to root folder from draggedId
        //console.log("move to root folder");
        moveToFolder("", e.dataTransfer.getData("text"));
      }
      //   moveToFolder("");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDraggedOver(true);
  };

  const moveToFolder = (folderId, assetId) => {
    // remove
    if (folderId !== currentFolderRef.current) {
      //console.log('in moveToFolder');
      //console.log("move", selectedIdsRef.current, " to folder", folderId);

      // case of multiple assets
      if (selectedIdsRef.current.length > 0) {
        logAuthorizedWombatEvent("movedMultipleAssetsToFolder", {});

        fetch(`${process.env.REACT_APP_BACKEND_URL}/upload/moveAssetToFolder`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({ asset_ids: selectedIdsRef.current, parent_id: folderId }),
          headers: {
            "Content-Type": "application/json",
            "X-ORG-ID": activeOrg,
          },
        }).then(async (response) => {
          if (response.status === 200) {
            //console.log("moved", selectedIdsRef.current);
            props.deleteCb(selectedIdsRef.current, "move");

            // setDraggingId("");
            // setItemDeleted(true);
          } else if (response.status === 401) {
            //console.log("not authorized");
            props.startSnackbar({
              message: "You are not authorized to move this asset",
              type: "error",
            });
          } else if (response.status === 400) {
            const detailedResponse = await response.json();
            //console.log('move ', detailedResponse.assetIdsToNotMove);
            props.deleteCb(
              [assetId].filter((x) => !detailedResponse.assetIdsToNotMove.includes(x)),
              "move"
            );
            props.startSnackbar({
              message:
                "Moving one of these folders causes a name conflict in the target directory.",
              type: "error",
            });
          }
        });
      } else {
        logAuthorizedWombatEvent("moveSingleAssetToFolder", {});

        fetch(`${process.env.REACT_APP_BACKEND_URL}/upload/moveAssetToFolder`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({ asset_ids: [assetId], parent_id: folderId }),
          headers: {
            "Content-Type": "application/json",
            "X-ORG-ID": activeOrg,
          },
        }).then(async (response) => {
          if (response.status === 200) {
            //console.log("moved", assetId);
            // setDraggingId("");
            props.deleteCb([assetId], "move");
            // setItemDeleted(true);
          } else if (response.status === 400) {
            const detailedResponse = await response.json();
            //console.log('move ', detailedResponse.assetIdsToNotMove);
            props.deleteCb(
              [assetId].filter((x) => !detailedResponse.assetIdsToNotMove.includes(x)),
              "move"
            );
            props.startSnackbar({
              message: "Moving this folder causes a name conflict in the target directory.",
              type: "error",
            });
          }
        });
      }
    }
  };

  return (
    <>
      {" "}
      <VuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          left: "10px",
          alignItems: "center",
        }}
      >
        <VuiBox
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
            transition: "all 0.3s ease-in-out",
            border: draggedOver ? "1px solid " + colors.icon.focus : "none",
            borderRadius: "5px",
            paddingRight: "10px",
          }}
          onClick={() => {
            props.setCurrentFolder("");
            props.resetSearch();
          }}
          id="rootfolder"
          className="rootfolder"
        >
          <HomeOutlinedIcon
            sx={{
              margin: "10px",
              marginRight: "5px",
              marginLeft: props.barDragged ? "20px" : "",
              transition: "all 0.3s ease-in-out",

              width: props.barDragged ? "30px" : "20px",
              height: props.barDragged ? "30px" : "20px",
              color: colors.icon.enabled,
              // scale it up if bar is dragged
            }}
          />

          <VuiTypography
            variant="body2"
            sx={{
              color: colors.icon.enabled,
              fontSize: "0.8rem",
              fontWeight: 500,
              "&:hover": {
                color: colors.icon.focus,
              },
            }}
          >
            Root Folder
          </VuiTypography>
        </VuiBox>

        {props.currentPath.map((folder) => {
          //console.log("folder", folder);
          return (
            <Crumb
              folder={folder}
              currentFolder={props.currentFolder}
              setCurrentFolder={props.setCurrentFolder}
              draggingId={props.draggingId}
              barDragged={props.barDragged}
              setBarDragged={props.setBarDragged}
              moveToFolder={moveToFolder}
            />
          );
        })}
      </VuiBox>
    </>
  );
});

export default Breadcrumbs;
