import VuiBox from "components/VuiBox";
//import react and usestate
import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useVisionUIController, setOrgs, setActiveOrg } from "context";

import amplitude from "amplitude-js";
import colors from "assets/theme/base/colors";

const OrgTab = (props) => {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, activeOrg } = controller;
  const [active, setActive] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    console.log("event logged");
    amplitude.getInstance().logEvent("orgTabClicked");
    amplitude.getInstance().init("39c2b5a0917d8c931a213116019e67ce");
    props.setActive(props.id);
    // window.location.reload();
  };

  useEffect(() => {
    // check whether props.id is equal to activeOrg
    // if so, set active to true
    if (props.id === activeOrg) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [activeOrg]);

  return (
    <VuiBox
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        color: active ? colors.icon.focus : colors.icon.enabled,
        padding: "4px",
        pointer: "cursor",
        fontSize: "0.8rem",
      }}
      onClick={handleClick}
    >
      {props.name}
    </VuiBox>
  );
};

export default OrgTab;
