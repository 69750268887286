import { forwardRef, useImperativeHandle, Suspense, useEffect, useState, useRef } from "react";
import React from "react";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { Modal, Box, Grid, Button } from "@mui/material";
import { useLoader, Canvas } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls, Html, useProgress } from "@react-three/drei";
import * as THREE from "three";
import VuiInput from "components/VuiInput";
import Tooltip from "@mui/material/Tooltip";
import VuiSnackbar from "components/VuiSnackbar/";
import colors from "assets/theme/base/colors";

// import "ContentCopy" icon from material-ui
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VuiProgress from "components/VuiProgress";
import AssetModel from "../AssetModel/index";
import UploadReplace from "../UploadReplace";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/CloudUpload";
import amplitude from "amplitude-js";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useVisionUIController, setCurrentlyUploading, setOnboardingIndex } from "context";
import { version } from "dropzone";
import FolderIcon from "@mui/icons-material/Folder";
import VersionItem from "./VersionItem";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { ProgressBar, Oval } from "react-loader-spinner";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ShareIcon from "@mui/icons-material/Share";
import { logoutUser } from "layouts/authentication/AuthFunctions";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

import { useNavigate } from "react-router-dom";

import ChangeVersionModal from "./ChangeVersionModal/index";
import {
  getFileType,
  formatBytes,
  parseDateUTC,
  getFileName,
  convertBytesToMB,
} from "util/UtilFunctions";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import AssistWalkerIcon from "@mui/icons-material/AssistWalker";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import DarkTooltip from "../CustomTooltip/index";
import JobItem from "./JobItem/index";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Thumbnailer from "./Thumbnailer/index";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
//  import style.css
import "layouts/dashboards/default/components/UploadModal/style/style.css";
import "./style.css";
import { Twitter } from "../../../../../../node_modules/@mui/icons-material/index";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";
import * as OV from "painting-3d-viewer";
import envMap1 from "assets/envmaps/fishermans_bastion/posx.jpg";
import envMap2 from "assets/envmaps/fishermans_bastion/negx.jpg";
import envMap3 from "assets/envmaps/fishermans_bastion/posy.jpg";
import envMap4 from "assets/envmaps/fishermans_bastion/negy.jpg";
import envMap5 from "assets/envmaps/fishermans_bastion/posz.jpg";
import envMap6 from "assets/envmaps/fishermans_bastion/negz.jpg";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { HtmlTooltip } from "layouts/dashboards/default/components/CustomTooltip/index";
import HelpIcon from "@mui/icons-material/Help";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import DownloadModal from "layouts/applications/generate/components/DownloadModal";

// props = {
//   open: bool,
//   selectedId: string,
//   imgArray: string,
// }

const Spinner = () => {
  return (
    <ProgressBar
      height="80"
      width="80"
      ariaLabel="progress-bar-loading"
      wrapperStyle={{}}
      wrapperClass="progress-bar-wrapper"
      borderColor="#fff"
      barColor={colors.info.main}
    />
  );
};
const Inspector = forwardRef((props, ref) => {
  // const camera = new THREE.PerspectiveCamera(45, 1, 0.01, 5000);

  const [tooltipText, setTooltipText] = React.useState("Click to copy to clipboard");
  const [copied, setCopied] = React.useState(false);

  const [open, setOpen] = React.useState(props.open);
  const [showNotif, setShowNotif] = React.useState(false);

  const [progress, setProgress] = React.useState(0);

  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");

  const [currentObject, setCurrentObject] = useState({});
  const [controller, dispatch] = useVisionUIController();

  // Version state
  const [versionData, setVersionData] = useState({});
  const [versionArray, setVersionArray] = useState([]);
  const versionArrayRef = useRef();
  versionArrayRef.current = versionArray;
  const [selectedVersion, setSelectedVersion] = useState("");
  const [selectedVersionObj, setSelectedVersionObj] = useState({});
  const [loadingVersions, setLoadingVersions] = useState(false);

  // Job state
  const [jobArray, setJobArray] = useState([]);
  const jobArrayRef = useRef();
  jobArrayRef.current = jobArray;
  const [loadingJobs, setLoadingJobs] = useState(false);

  const [folderSize, setFolderSize] = useState(0);
  const { activeOrg, developerInfo, onboardingIndex, subscription } = controller;
  const [loading, setLoading] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);
  const [showCameraIcon, setShowCameraIcon] = useState(false);
  const [versionTabOpen, setVersionTabOpen] = useState(true);

  const [jobModalOpen, setJobModalOpen] = useState(false);
  const [jobDetailsModalOpen, setJobDetailsModalOpen] = useState(false);
  const [inspectedJob, setInspectedJob] = useState({});
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const [currentVersionObj, setCurrentVersionObj] = useState({});

  const [reversedJobArray, setReversedJobArray] = useState([]);
  const reversedJobArrayRef = useRef();
  reversedJobArrayRef.current = reversedJobArray;

  const [filteredJobArray, setFilteredJobArray] = useState([]);

  const [jobsInProgress, setJobsInProgress] = useState(0);

  const [renderer, setRenderer] = useState(null);
  const [scene, setScene] = useState(null);
  const [camera, setCamera] = useState(null);

  const [shareOpen, setShareOpen] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [shareLoading, setShareLoading] = useState(false);
  const [shareCopied, setShareCopied] = useState(false);

  const [fullscreen, setFullscreen] = useState(false);

  const [defaultAssets, setDefaultAssets] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // set selectedversionobj to the version with id = selectedversion
    console.log("selectedVersion has changed", selectedVersion, versionArrayRef.current);
    if (selectedVersion) {
      const version = versionArrayRef.current.find(
        (version) => version.versionId === selectedVersion
      );
      console.log("seblescted version", version);
      if (version) setSelectedVersionObj(version);
    }
  }, [selectedVersion]);

  useEffect(() => {
    setShareCopied(false);
  }, [shareOpen]);

  const handleJobModalOpen = () => {
    setJobModalOpen(true);
  };
  const handleJobModalClose = () => {
    setJobModalOpen(false);
  };

  const handleDownloadModalClose = () => {
    setShowDownloadModal(false);
  };
  const handleDownloadModalOpen = () => {
    setShowDownloadModal(true);
  };

  const handleJobDetailsModalOpen = (job) => {
    setInspectedJob(job);
    setJobDetailsModalOpen(true);
  };
  const handleJobDetailsModalClose = () => {
    setJobDetailsModalOpen(false);
  };

  const selectedIdRef = useRef();
  selectedIdRef.current = props.selectedId;

  const modalStyle = {
    width: "300px",
    height: "100%",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
  };

  const style = {
    transition: "all 0.5s ease",
    // width: 400,
    width: 400,

    // height: "600px",
    height: "calc(100% - 64px)",
    bgcolor: colors.background.pentiary,
    // borderLeft: "1px solid " + colors.background.tertiary,
    // borderTop: "1px solid " + colors.background.tertiary,

    boxShadow: 24,
    justifyContent: "center",
    p: 3,
    paddingRight: "14px",
    "&:focus": {
      outline: "none",
    },
    position: "fixed",
    display: "block",
    top: "64px",
    right: props.open ? 0 : -400,
    overflow: "scroll",
    "overflow-x": "clip",
    "@media (max-width: 600px)": {
      width: 300,
      top: "0px",
      height: "100%",
    },
  };

  useEffect(() => {
    setVersionArray([]);
    setJobArray([]);

    getVersionData();
    // getJobData();
    // getJobData();
  }, [props.selectedId]);

  useEffect(() => {}, [props.open]);

  useEffect(() => {
    //console.log("version data", versionData);
  }, [versionData]);

  useEffect(() => {
    //console.log("looading", loading);
  }, [loading]);

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  useEffect(() => {
    // set the reversedJobArray state to the reversed jobArrayRef except filtered by the currentVersionObj.modelId
    const filteredArray = jobArrayRef.current.filter(
      (job) => job.assetId === currentVersionObj.modelId
    );
    setFilteredJobArray(filteredArray);

    setReversedJobArray([...jobArrayRef.current.slice().reverse()]);
    // get every job that is Queued or In progress and add it to the jobsInProgress state
  }, [jobArray]);

  useEffect(() => {
    // if a jobs is queued or in progress, add it to the jobsInProgress state
    const allJobsInProgress = filteredJobArray.filter(
      (job) => job.status === "Queued" || job.status === "In progress"
    );
    setJobsInProgress(allJobsInProgress.length);
  }, [filteredJobArray]);

  // add click event listener to the document to close the shareOpen when clicking outside of it
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (activeOrg !== "") {
      console.log("activeOrg", activeOrg);
      getDefaultAssets();
    }
  }, [activeOrg]);

  const getDefaultAssets = async () => {
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/upload/defaultAssets`);
    // needs headers activeOrg
    try {
      const resp = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-ORG-ID": activeOrg,
        },
      });
      const data = await resp.json();
      console.log("defaultAssets", data);

      const dAssets = [];
      for (var i = 0; i < data.file_names.length; i++) {
        dAssets.push(data.version_ids[i]);
      }
      setDefaultAssets(dAssets);
    } catch (err) {
      console.log("error", err);
    }
  };

  // useEffect(() => {
  //   const allJobsInProgress = jobArrayRef.current.filter(
  //     (job) =>
  //       (job.status === "Queued" || job.status === "In progress") &&
  //       job.assetId === selectedIdRef.current
  //   );
  //   console.log("allJobsInProgress", allJobsInProgress);
  //   setJobsInProgress(allJobsInProgress.length);
  // }, [jobArray, props.selectedId]);

  const handleFullscreen = () => {
    if (fullscreen === true) {
      // exit fullscreen
      try {
        if (document.fullscreenElement && document.exitFullscreen) {
          document.exitFullscreen();
        }
        setFullscreen(false);
      } catch (err) {
        console.log("error", err);
      }
    } else {
      const elem = document.getElementById("assetModelCanvas");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
      setFullscreen(true);
    }
  };

  const handleClickOutside = (event) => {
    console.log("event.target", event.target);
    console.log("event.target.classList", event.target.classList);
    if (!event.target.classList.contains("share-buttons")) {
      setShareOpen(false);
    }
  };

  const getVersionData = async () => {
    const selectedObject = props.imgArray.find((obj) => obj.modelId === props.selectedId);
    if (selectedObject) {
      setLoadingVersions(true);
      setCurrentObject(selectedObject);
      const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/upload/versionsForAsset`);
      const params = new URLSearchParams();
      params.set("asset_id", selectedObject.modelId);
      url.search = params.toString();
      console.log("activeOrg", activeOrg);
      if (activeOrg !== "") {
        fetch(url, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-ORG-ID": activeOrg,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            //console.log("versionData ", data);
            setVersionData(data);
            const versionArray = [];
            for (let i = 0; i < data.fileNames.length; i++) {
              // assetPath -> filePath,
              // thumbnailPath -> thumbnail
              // lastUpdatedTime -> lastUpdated
              const version = {
                assetType: "file",
                filePath: data.assetPaths[i],
                editorId: data.editorIds[i],
                modelId: selectedObject.modelId,
                fileName: data.fileNames[i],
                fileSize: data.fileSizes[i],
                lastUpdated: data.lastUpdatedTimes[i],
                thumbnail: data.thumbnailPaths[i],
                username: data.usernames[i],
                versionId: data.versionIds[i],
                isCurrentVersion: data.isCurrentVersion[i],
                fileType: getFileType(data.fileNames[i]),
                aiJobId: data.aiJobIds[i],
              };
              console.log("version: ", version);
              if (data.isCurrentVersion[i] === true) {
                setSelectedVersionObj(version);

                setSelectedVersion(data.versionIds[i]);
                setCurrentVersionObj(version);
              }

              if (data.thumbnailPaths[i] === "") {
                console.log("no thumbnail for ", data.fileNames[i]);
                // if no thumbnail, generate one
              }

              versionArray.push(version);
            }
            setLoadingVersions(false);
            setVersionArray([...versionArray]);
          })
          .catch((error) => {
            setLoadingVersions(false);
            startSnackbar({ type: "error", message: "Error fetching versions" });
            console.error("Error:", error);
          });

        if (selectedObject.assetType === "folder") {
          const folderSizeUrl = new URL(`${process.env.REACT_APP_BACKEND_URL}/upload/folderSize`);
          const folderQueryParams = new URLSearchParams();
          folderQueryParams.set("parent_id", selectedObject.modelId);
          folderSizeUrl.search = folderQueryParams.toString();

          fetch(folderSizeUrl, {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              "X-ORG-ID": activeOrg,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              //console.log("sizeData ", data);
              setFolderSize(data.folderSize);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      }
    }
  };

  const getJobData = async () => {
    const selectedObject = props.imgArray.find((obj) => obj.modelId === props.selectedId);
    if (selectedObject) {
      setLoadingJobs(true);
      setCurrentObject(selectedObject);
      const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/retextureJobs`);
      const params = new URLSearchParams();
      // params.set("asset_id", selectedObject.modelId);
      url.search = params.toString();

      if (activeOrg !== "") {
        fetch(url, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-ORG-ID": activeOrg,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("job Data ", data);
            const jobArray = [];
            for (let i = 0; i < data.job_names.length; i++) {
              // assetPath -> filePath,
              // thumbnailPath -> thumbnail
              // lastUpdatedTime -> lastUpdated
              // set data.job_statuses[i] to first letter capitalized

              const status =
                data.job_statuses[i].charAt(0).toUpperCase() + data.job_statuses[i].slice(1);

              const job = {
                completedTime: data.completed_times[i],
                errorMsg: data.error_msgs[i],
                jobId: data.job_ids[i],
                jobName: data.job_names[i],
                queuedTime: data.queued_times[i],
                job_params: data.job_params[i],
                startedTime: data.started_times[i],
                textPrompt: data.text_prompts[i],
                versionId: data.version_ids[i],
                assetId: data.asset_ids[i],
                status: status,
                eta: null,
              };

              // if one of the versions contains data.version_ids[i] then set the prompt to that version
              const version = versionArray.find((obj) => obj.versionId === data.version_ids[i]);
              if (version) {
                console.log("found version", version, "setting prompt to ", data.text_prompts[i]);
                version.textPrompt = data.text_prompts[i];
              }

              jobArray.push(job);
            }
            setLoadingJobs(false);
            setJobArray([...jobArray]);
          })
          .catch((error) => {
            setLoadingJobs(false);
            startSnackbar({ type: "error", message: "Error fetching jobs" });
            console.error("Error:", error);
          });
      }
    }
  };

  const fetchJob = async (jobId) => {
    console.log("polling job", jobId);
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/retextureStatus`);
    const params = new URLSearchParams();
    params.set("jobId", jobId);
    url.search = params.toString();
    const resp = fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
    });
    const data = await resp.then((res) => res.json());
    console.log("polled job data", data);
    const jobIndex = jobArray.findIndex((job) => job.jobId === jobId);
    // set data.job_status to upper case first letter
    jobArray[jobIndex].status = data.job_status.charAt(0).toUpperCase() + data.job_status.slice(1);
    jobArray[jobIndex].eta = data.job_eta;
    if (
      data.job_status === "completed" ||
      data.job_status === "failed" ||
      data.job_status === "cancelled"
    ) {
      const status = data.job_status.charAt(0).toUpperCase() + data.job_status.slice(1);

      if (data.completed_time !== null) {
        jobArray[jobIndex].completedTime = data.completed_time;
        jobArray[jobIndex].status = status;
        setJobsInProgress(0);
        getVersionData();
      } else {
        setJobsInProgress(0);
        jobArray[jobIndex].completedTime = new Date();
        jobArray[jobIndex].status = status;
      }
    } else if (data.error_message !== null) {
      setJobsInProgress(0);
      jobArray[jobIndex].errorMsg = data.error_message;
    } else if (
      (data.job_status === "queued" || data.job_status === "in progress") &&
      jobsInProgress === 0
    ) {
      setJobsInProgress(1);
    }
    // setJobArray([...jobArray]);
  };

  const addJob = (job) => {
    console.log("adding job", job);
    jobArray.push(job);
    setJobArray([...jobArray]);
  };

  // data = {message: "Copied to clipboard", type: "info"}
  const startSnackbar = (data) => {
    //console.log("snackbar data", data);
    setSnackbarMessage(data.message);
    setSnackbarType(data.type);

    setSnackbar(true);
  };
  const copyToClipboard = () => {
    startSnackbar({ message: "Copied to clipboard", type: "info" });

    logAuthorizedWombatEvent("assetIdCopiedToClipboard", {});

    // BUG: Can't write to state without three.js model disappearing
    navigator.clipboard.writeText(
      typeof currentObject.modelId === "string" ? currentObject.modelId : props.selectedId
    );
  };

  const handleClose = () => {
    setOpen(false);
  };
  const toggleSnackbar = () => {
    setSnackbar(!snackbar);
  };
  const loadingUpdate = (loading) => {
    //console.log("loadingUpdate", loading);
    setLoadingModel(loading);
  };

  const downloadModel = () => {
    logAuthorizedWombatEvent("assetDownloadClicked", { assetId: currentObject.modelId });
    if (subscription.name !== "polyhive_plus_plan") {
      handleDownloadModalOpen();
      logAuthorizedWombatEvent("freeUserAttemptedToDownload", {});

      return;
    }
    // if user is not subscribed, show modal
    fetch(currentObject.filePath).then((response) => {
      //console.log("response", response);
      if (response.status === 200) {
        logAuthorizedWombatEvent("assetDownloadSuccess", { assetId: currentObject.modelId });

        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = currentObject.fileName;
          alink.click();
        });
      } else {
        logAuthorizedWombatEvent("assetDownloadFailed", {
          assetId: currentObject.modelId,
          error: response.statusText,
        });
      }
    });
  };

  const handleReplace = (file) => {
    logAuthorizedWombatEvent("addVersionSuccess", {});

    //console.log("handleReplace", file);
    //console.log("versionArray", versionArray);
    // get credentials
    const creds = document.cookie.split("; ");
    //console.log("creds", creds);

    const userId = creds
      .find((cred) => {
        return cred.includes("userId");
      })
      .split("=")[1];

    //console.log("userID", userId);

    const newVersion = {
      assetType: "file",
      filePath: file.filePath,
      modelId: file.modelId,
      editorId: userId,
      fileName: file.fileName,
      fileSize: file.fileSize,
      isCurrentVersion: false,
      lastUpdated: new Date().toISOString(),
      thumbnail: file.thumbnail,
      username: developerInfo.username,
      versionId: file.versionId,
      fileType: getFileType(file.fileName),
    };
    if (typeof file.fileObj !== "undefined") {
      newVersion.fileObj = file.fileObj;
    }

    const selectedObject = props.imgArray.find((obj) => obj.modelId === props.selectedId);
    //console.log("handleReplace selected object is", selectedObject);
    //console.log("handleReplace currentObject is", currentObject);
    //console.log("developerInfo", developerInfo);
    //console.log("handleReplace newVersion", newVersion);
    if (currentObject && currentObject.modelId === file.modelId) {
      versionArray.unshift(newVersion);
      setVersionArray([...versionArray]);
    }
    // props.handleReplace(file);
    // refresh by closing and opening this modal
    // handleClose();

    startSnackbar({ message: "Version successfully uploaded", type: "success" });
  };

  const viewFolder = () => {
    logAuthorizedWombatEvent("openFolderButtonClicked", {});

    props.viewFolder(currentObject.modelId);
  };

  const applyNameForVersion = (versionId, name, cb) => {
    // get version from versionArray with versionId
    const version = versionArray.find((version) => {
      return version.versionId === versionId;
    });
    if (getFileType(version.fileName) === getFileType(name)) {
      const updateHeaders = {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      };
      const requestBody = {
        version_id: versionId,
        name: name,
      };

      const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/upload/renameVersion`);
      fetch(url, {
        method: "POST",
        credentials: "include",
        headers: updateHeaders,
        body: JSON.stringify(requestBody),
      }).then((response) => {
        if (response.status === 200) {
          //console.log("success renaming version");
          startSnackbar({ message: "Version successfully renamed", type: "success" });
          // update the versionArray
          const updatedVersionArray = versionArray.map((version) => {
            if (version.versionId === versionId) {
              version.fileName = name;
            }
            version.modelId = currentObject.modelId;
            return version;
          });
          setVersionArray(updatedVersionArray);
          //console.log("version.versionId rename", version.versionId);
          //console.log("currentObject.modelId rename", currentObject.modelId);
          if (version.isCurrentVersion) {
            props.handleRename({ name: name, assetId: version.versionId });
          }
          cb(false);
        } else {
          //console.log("error renaming version");
          startSnackbar({ message: "Error renaming version", type: "error" });
        }
      });
    } else {
      startSnackbar({ message: "File extension cannot be changed", type: "error" });
    }
  };

  // this function needs to make a call to the backend to set the current version
  // then it needs to update the versionArray by setting isCurrentVersion to true
  // for the versionId passed in and false for all others
  const setCurrentVersion = (versionId) => {
    //console.log("setCurrentVersion", versionId);
    const updateHeaders = {
      "Content-Type": "application/json",
      "X-ORG-ID": activeOrg,
      "ASSOCIATED-UUID": props.selectedId,
    };
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/upload/updateAssetToVersion`);

    fetch(url, {
      method: "POST",
      credentials: "include",
      headers: updateHeaders,
      body: JSON.stringify({ version_id: versionId }),
    }).then((response) => {
      if (response.status === 200) {
        // get the file from the versionArray
        const file = versionArray.find((version) => {
          return version.versionId === versionId;
        });
        file.modelId = props.selectedId;
        props.handleCurrentVersion(file);

        // update the versionArray
        const newVersionArray = versionArray.map((version) => {
          if (version.versionId === versionId) {
            version.isCurrentVersion = true;
            setCurrentVersionObj(version);
          } else {
            version.isCurrentVersion = false;
          }
          return version;
        });
        setVersionArray(newVersionArray);
        startSnackbar({ message: "Current version updated", type: "success" });
      } else {
        //console.log("error");
        startSnackbar({ message: "Error updating current version", type: "error" });
      }
    });
  };

  const deleteVersion = (versionId) => {
    //console.log("deleteVersion", versionId);
    const updateHeaders = {
      "Content-Type": "application/json",
      "X-ORG-ID": activeOrg,
      "ASSOCIATED-UUID": props.selectedId,
    };
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/upload/deleteVersionForAsset`);
    fetch(url, {
      method: "DELETE",
      credentials: "include",
      headers: updateHeaders,
      body: JSON.stringify({ version_id: versionId }),
    }).then((response) => {
      if (response.status === 200) {
        //console.log("success deleting version");
        startSnackbar({ message: "Version successfully deleted", type: "success" });
        // update the versionArray
        const updatedVersionArray = versionArray.filter((version) => {
          return version.versionId !== versionId;
        });
        setVersionArray(updatedVersionArray);
        //console.log("Updated Version Array", updatedVersionArray);
        setCurrentObject(updatedVersionArray[0]);
      } else if (response.status === 401) {
        //console.log("error deleting version");
        startSnackbar({
          type: "error",
          message: "You are not authorized to delete items in this org.",
        });
      } else {
        //console.log("error deleting version");
        startSnackbar({ message: "Error deleting version", type: "error" });
      }
    });
  };

  const setCurrObj = (obj) => {
    setCurrentObject(obj);
  };

  const handleLoading = (loading) => {
    setLoading(loading);
  };

  const handleShare = (e) => {
    e.stopPropagation();
    if (shareOpen === false) {
      // get share links, set shareLoading to true
      setShareLoading(true);

      logAuthorizedWombatEvent("clickedInspectorShareButton", {});

      const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/share/`);
      console.log("SHARING VERSION ID", selectedVersion);
      fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-ORG-ID": activeOrg,
          "VERSION-ID": selectedVersion,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => {
              //console.log("share data", data);
              setShareLoading(false);
              console.log("share data", data);
              setShareLink(data.share_id);
            });
          } else {
            setShareLoading(false);

            //console.log("error getting share links");
            startSnackbar({ message: "Error getting share links", type: "error" });
          }
        })
        .catch((err) => {
          setShareLoading(false);
          console.log(err);
        });
    }
    setShareOpen(!shareOpen);
  };

  const setRendererContext = (context) => {
    console.log("setRendererContext", context);
    setRenderer(context);
  };
  const setSceneContext = (context) => {
    console.log("setSceneContext", context);
    setScene(context);
  };
  const setCameraContext = (context) => {
    console.log("setCameraContext", context);
    setCamera(context);
  };
  const handleScreenshot = (screenshot) => {
    logAuthorizedWombatEvent("assetScreenshotButtonClicked", {});

    takeScreenshot();
  };

  const takeScreenshot = () => {
    const myCanvasWrapper = document.getElementById("assetModelCanvas");
    console.log("myCanvasWrapper", myCanvasWrapper);
    const myCanvas = myCanvasWrapper.querySelector("canvas");
    console.log("myCanvas", myCanvas);
    // render a frame
    renderer.render(scene, camera);

    myCanvas.toBlob((blob) => {
      saveBlob(blob, `screencapture-${myCanvas.width}x${myCanvas.height}.png`);
    });
  };
  const saveBlob = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    return function saveData(blob, fileName) {
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      const returnObj = {};
      handleBlob({ thumbnailURL: url, thumbnailFile: blob });
      // this needs to call upload thumbnail or versionThumbnail depending on
      // whether the currentObject isCurrentVersion or not
      // then needs to callback to update the thumbnail in the ModelGrid
      // handleBlob should update versionArray
      // a.click();
    };
  })();

  const handleBlob = (obj) => {
    //console.log("handleBlob", obj);
    //console.log("currentObject.isCurrentVersion", currentObject.isCurrentVersion);
    //console.log("currentObject", currentObject);

    //console.log("this is not the current version");
    const headers = {
      "Content-Type": "application/octet-stream",
      "ASSOCIATED-UUID": props.selectedId,
      "FILE-SIZE": currentObject.fileSize.toString(),
      "X-ORG-ID": activeOrg,
      "VERSION-ID": selectedVersion,
    };
    const response_thumbnail_upload = fetch(
      `${process.env.REACT_APP_BACKEND_URL}/upload/uploadThumbnailForVersion`,
      {
        body: obj.thumbnailFile,
        credentials: "include",
        headers: headers,
        method: "POST",
      }
    )
      .then((response) => {
        //console.log("thumbnail response", response);
        return response;
      })
      .then((response) => {
        if (response.status === 200) {
          startSnackbar({
            message: "Thumbnail successfully changed.",
            type: "success",
          });
          const updatedVersionArray = versionArray.map((version) => {
            if (
              version.versionId === currentObject.versionId ||
              version.versionId === currentObject.modelId
            ) {
              version.thumbnail = obj.thumbnailURL;
            }
            version.modelId = headers["ASSOCIATED-UUID"];
            return version;
          });
          setVersionArray(updatedVersionArray);

          if (
            typeof currentObject.isCurrentVersion === "undefined" ||
            currentObject.isCurrentVersion === true
          ) {
            if (typeof currentObject.isCurrentVersion === "undefined") {
              // update thumbnail in the modelGrid
              props.handleNewThumbnail({
                thumbnail: obj.thumbnailURL,
                modelId: currentObject.modelId,
              });
            } else if (currentObject.isCurrentVersion === true) {
              // update thumbnail in the modelGrid
              props.handleNewThumbnail({
                thumbnail: obj.thumbnailURL,
                modelId: currentObject.versionId,
              });
            }
          }
        } else if (response.status === 403) {
          logoutUser(navigate);
        } else {
          startSnackbar({
            message: response.statusText,
            type: "error",
          });
          //console.log("thumbnail upload failed, cancelling grid item addition");
        }
      });

    // this is a folder
    return;
  };

  const handleRetextureClick = () => {
    if (
      typeof currentVersionObj.fileName !== "undefined" &&
      (!["gltf", "glb", "obj", "fbx"].includes(getFileType(currentVersionObj.fileName)) ||
        convertBytesToMB(currentVersionObj.fileSize) > 100)
    ) {
    } else {
      if (onboardingIndex === 0) setOnboardingIndex(dispatch, 1);
      // handleJobModalOpen();
      // navigate to generate page, send params so that it receives it like   const { versionId, path } = location.state || {};
      navigate("/generate", {
        state: {
          versionId: currentVersionObj.versionId,
          path: currentVersionObj.filePath,
        },
      });

      console.log("aiRetextureButtonClicked", currentVersionObj);
      logAuthorizedWombatEvent("aiRetextureButtonClicked", {});
    }
  };

  const handleSendJobFields = () => {
    navigate("/generate", {
      state: {
        jobFields: inspectedJob,
      },
    });
  };

  let maybeShowType;
  let maybeShowTypeValue;
  let assetIdTitle;
  let assetIdBody;

  if (currentObject.assetType !== "folder") {
    maybeShowType = (
      <Grid item xs={4} md={4}>
        <VuiTypography
          variant="body2"
          textTransform={"capitalize"}
          color="white"
          sx={{ fontWeight: 450, color: "#aaa" }}
        >
          Type
        </VuiTypography>
      </Grid>
    );
    maybeShowTypeValue = (
      <Grid item xs={8} md={8}>
        <VuiTypography variant="body2" color="white" sx={{ fontWeight: 450 }}>
          {currentObject
            ? ["glb", "gltf", "vrm", "fbx", "obj"].includes(currentObject.fileType)
              ? "3D Model (" + currentObject.fileType + ")"
              : currentObject.fileType
            : ""}
        </VuiTypography>
      </Grid>
    );

    assetIdTitle = (
      <VuiTypography variant="button" textTransform={"capitalize"} color="white">
        &nbsp;&nbsp; Asset ID{" "}
      </VuiTypography>
    );

    assetIdBody = (
      <VuiBox
        onClick={copyToClipboard}
        sx={{
          marginTop: "5px",
          marginBottom: "5px",
          position: "relative",
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <DarkTooltip
          title={
            ["glb", "gltf"].includes(currentObject.fileType)
              ? tooltipText
              : "File type not currently supported by SDK"
          }
        >
          <VuiInput
            style={{
              borderTopLeftRadius: "0.9375rem",
              borderBottomLeftRadius: "0.9375rem",
              userSelect: "text",
              "&::placeholder": {
                color: "white",
              },
            }}
            readOnly={true}
            placeholder={currentObject ? currentObject.modelId : ""}
            // icon={{
            //   style: {
            //     color: "white",
            //   },
            //   component: "copy",
            //   direction: "right",
            // }}
            value={currentObject ? currentObject.modelId : ""}
          />
        </DarkTooltip>
        <ContentCopyIcon
          sx={{
            marginLeft: "5px",
            width: "23px",
            height: "23px",
            marginBottom: "-4px",
            padding: "3px",
            borderRadius: "7px",
            position: "absolute",
            color: "#fff",
            top: "8px",
            right: "8px",
          }}
          onClick={copyToClipboard}
        />
      </VuiBox>
    );
  }

  const versionContent = (
    <div style={{ display: versionTabOpen ? "grid" : "none" }} className="mt_2">
      <JobDetailsModal
        open={jobDetailsModalOpen}
        handleClose={handleJobDetailsModalClose}
        job={inspectedJob}
        handleSendJobFields={handleSendJobFields}
      />
      {currentObject.assetType !== "folder" ? (
        <Grid item xs={12} alignItems="center">
          <VuiBox sx={{ display: "flex", alignItems: "center" }}>
            <VuiTypography
              variant="body1"
              textTransform={"capitalize"}
              color="white"
              marginTop="2px"
              marginLeft="0px"
              sx={{ fontWeight: 450, color: colors.icon.enabled }}
            >
              Versions
              <UploadReplace
                cb={handleReplace}
                data={currentObject}
                handleLoading={handleLoading}
                errorCB={startSnackbar}
              />
            </VuiTypography>
          </VuiBox>
        </Grid>
      ) : null}

      <VuiBox sx={{ width: "100%", height: "auto", minHeight: "200px" }}>
        {!loadingVersions ? (
          <>
            {versionArrayRef.current.map((version, index) => {
              if (version.modelId === currentObject.modelId) {
                return (
                  <VersionItem
                    key={index}
                    version={version}
                    selectedVersion={selectedVersion}
                    setSelectedVersion={setSelectedVersion}
                    setCurrentVersion={setCurrentVersion}
                    currentObject={currentObject}
                    setCurrentObject={setCurrObj}
                    applyRename={applyNameForVersion}
                    deleteVersion={deleteVersion}
                    startSnackbar={startSnackbar}
                    openJobDetails={handleJobDetailsModalOpen}
                    defaultAssets={defaultAssets}
                    handleDownloadModalOpen={handleDownloadModalOpen}
                    subscription={subscription}
                  />
                );
              }
            })}
          </>
        ) : (
          <>
            <Oval
              ariaLabel="loading-indicator"
              height={100}
              width={100}
              strokeWidth={5}
              strokeWidthSecondary={2}
              color={colors.icon.disabled}
              secondaryColor="white"
              // visible={!loadingModel}
              wrapperStyle={{
                position: "absolute",
                // top: "100px",
                left: "50%",
                transform: "translate(-50%, -50%)",
                marginTop: "100px",
              }}
            />
          </>
        )}
      </VuiBox>
    </div>
  );

  const aiJobsContent = (
    <div style={{ display: !versionTabOpen ? "grid" : "none" }}>
      <AIJobModal
        getJobData={getJobData}
        data={selectedVersionObj}
        activeOrg={activeOrg}
        handleClose={handleJobModalClose}
        open={jobModalOpen}
        jobArray={jobArray}
        startSnackbar={startSnackbar}
        addJob={addJob}
      />
      {currentObject.assetType !== "folder" ? (
        <Grid item xs={12} alignItems="center">
          <VuiBox sx={{ display: "flex", alignItems: "center" }}>
            <VuiTypography
              variant="body1"
              textTransform={"capitalize"}
              color="white"
              marginTop="2px"
              marginLeft="0px"
              sx={{ fontWeight: 450, color: colors.icon.enabled }}
            >
              AI Jobs
            </VuiTypography>
          </VuiBox>
        </Grid>
      ) : null}

      <VuiBox sx={{ width: "100%", height: "auto", minHeight: "200px" }}>
        {!loadingJobs ? (
          <>
            {reversedJobArrayRef.current.map((job, index) => {
              // if (job.assetId === currentVersionObj.modelId)
              return <JobItem key={index} job={job} fetchJob={fetchJob} activeOrg={activeOrg} />;
            })}
          </>
        ) : (
          <>
            <Oval
              ariaLabel="loading-indicator"
              height={100}
              width={100}
              strokeWidth={5}
              strokeWidthSecondary={2}
              color={colors.icon.disabled}
              secondaryColor="white"
              // visible={!loadingModel}
              wrapperStyle={{
                position: "absolute",
                // top: "100px",
                left: "50%",
                transform: "translate(-50%, -50%)",
                marginTop: "100px",
              }}
            />
          </>
        )}
      </VuiBox>
      <Grid item xs={12}>
        <VuiBox
          sx={{
            width: "100%",
            height: "auto",
            minHeight: "200px",
            justifyContent: "center",
            // alignItems: "center",
            display: "flex",
            textAlign: "center",
            margin: "0 auto",
          }}
          // ml={2}
        >
          {loading ? <Spinner /> : null}
        </VuiBox>
      </Grid>
    </div>
  );

  return (
    <>
      {/* <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ modalStyle }}
      > */}
      <VuiBox sx={style}>
        <KeyboardDoubleArrowRightIcon
          onClick={props.close}
          style={{
            position: "absolute",
            top: "10px",
            left: "5px",
            cursor: "pointer",
            width: "20px",
            height: "20px",
            color: "#aaa",
          }}
        />

        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <VuiBox
              mb={0}
              width="100%"
              height="250px"
              sx={{
                borderRadius: "15px",
                position: "relative",
              }}
              className="bg_darkest"
            >
              {/* {progress < 100 ? <VuiProgress value={progress} /> : <></>} */}

              <VuiBox
                sx={{
                  width: "100%",
                  height: "100%",
                  display: currentObject.assetType === "folder" ? "none" : "flex",
                }}
                onMouseEnter={(e) => {
                  setShowCameraIcon(true);
                }}
                onMouseLeave={(e) => {
                  setShowCameraIcon(false);
                }}
              >
                <Oval
                  ariaLabel="loading-indicator"
                  height={100}
                  width={100}
                  strokeWidth={5}
                  strokeWidthSecondary={2}
                  color={colors.icon.disabled}
                  secondaryColor="white"
                  visible={!loadingModel}
                  wrapperStyle={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
                <AssetModel
                  file={currentObject}
                  cb={loadingUpdate}
                  startSnackbar={startSnackbar}
                  setRenderer={setRendererContext}
                  setCamera={setCameraContext}
                  setScene={setSceneContext}
                />
                {/* <Button
                  onClick={handleScreenshot}
                  sx={{ position: "absolute", top: "10px", right: "10px" }}
                > */}
                <DarkTooltip title="Capture new thumbnail">
                  <PhotoCameraIcon
                    sx={{
                      position: "absolute",
                      bottom: "10px",
                      right: "10px",
                      width: "30px",
                      height: "30px",
                      color: colors.icon.enabled,
                      visibility: showCameraIcon ? "visible" : "hidden",
                      ":hover": {
                        cursor: "pointer",
                        color: colors.icon.disabled,
                      },
                    }}
                    onClick={handleScreenshot}
                  />
                </DarkTooltip>
                <VuiBox
                  sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    top: "10px",
                    right: "10px",
                  }}
                >
                  <ShareIcon
                    sx={{
                      position: "absolute",
                      top: "40px",
                      right: "0px",
                      width: "30px",
                      height: "30px",
                      color: colors.icon.focusdark,
                      // visibility: showCameraIcon ? "visible" : "hidden",
                      ":hover": {
                        cursor: "pointer",
                        color: colors.icon.disabled,
                      },
                    }}
                    onClick={handleShare}
                  />
                  <FullscreenIcon
                    sx={{
                      position: "absolute",
                      top: "0px",
                      right: "-3px",
                      width: "35px",
                      height: "35px",
                      color: colors.icon.enabled,
                      // visibility: showCameraIcon ? "visible" : "hidden",
                      ":hover": {
                        cursor: "pointer",
                        color: colors.icon.enabled,
                      },
                      "@media (max-width: 600px)": {
                        display: "none",
                      },
                    }}
                    onClick={handleFullscreen}
                  />
                  <VuiBox
                    sx={{
                      display: shareOpen ? "flex" : "none",
                      width: "160px",
                      height: "100px",
                      background: "#fff",
                      position: "absolute",
                      top: "40px",
                      right: "0px",
                      borderRadius: "6px",
                      // box shadow
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                    }}
                    className="share-buttons"
                  >
                    {/* Contains 2 sharing buttons, 1 for twitter, 1 for copy link}*/}
                    <VuiBox
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                      className="share-buttons"
                    >
                      {" "}
                      {shareLoading ? (
                        <Oval
                          ariaLabel="loading-indicator"
                          height={50}
                          width={50}
                          strokeWidth={5}
                          strokeWidthSecondary={2}
                          color={colors.icon.disabled}
                          secondaryColor="white"
                          // visible={!loadingModel}
                          wrapperStyle={{
                            position: "absolute",
                            // top: "100px",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            marginTop: "40px",
                          }}
                        />
                      ) : (
                        <>
                          <VuiBox
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "left",
                              padding: "15px",
                              alignItems: "center",
                              width: "100%",
                              height: "50%",
                              "&:hover": {
                                cursor: "pointer",
                                background: colors.white.main,
                                borderTopRightRadius: "6px",
                                borderTopLeftRadius: "6px",
                              },
                            }}
                            className="share-buttons"
                            onClick={() => {
                              logAuthorizedWombatEvent("clickedShareToTwitter", {
                                shareId: shareLink,
                              });
                              const a = document.createElement("a");
                              a.href = `https://twitter.com/intent/tweet?text=Check+out+my+AI+textured+3D+model+on+Polyhive%21%0D%0A%0D%0Ahttps%3A%2F%2Fpolyhive.ai%2Fs%3Fid%3D${shareLink}%0D%0D&hashtags=stablediffusion,ai,texttotexture,texttogame`;
                              a.target = "_blank";
                              a.click();
                              setShareOpen(false);
                            }}
                          >
                            <Twitter
                              round
                              style={{
                                width: "25px",
                                height: "25px",
                                color: "#fff",
                                background: "#1da1f2",
                                borderRadius: "50%",
                                padding: "5px",
                                marginRight: "10px",
                              }}
                              url={window.location.href}
                              title={currentObject.name}
                              hashtags={["vrm", "vroid", "3d", "3dmodel"]}
                              className="share-buttons"
                            />
                            <VuiTypography
                              sx={{
                                fontSize: "0.9rem",
                                color: colors.background.secondary,
                                textAlign: "center",
                              }}
                              className="share-buttons"
                            >
                              Twitter
                            </VuiTypography>
                          </VuiBox>
                          <VuiBox
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "left",
                              padding: "15px",
                              alignItems: "center",
                              width: "100%",
                              height: "50%",
                              "&:hover": {
                                cursor: "pointer",
                                background: colors.white.main,
                                borderBottomRightRadius: "6px",
                                borderBottomLeftRadius: "6px",
                              },
                            }}
                            className="share-buttons"
                            onClick={() => {
                              logAuthorizedWombatEvent("clickedCopyShareLink", {
                                shareId: shareLink,
                              });

                              navigator.clipboard.writeText(
                                "https://polyhive.ai/s?id=" + shareLink
                              );

                              setShareCopied(true);
                              // setShareOpen(false);
                              startSnackbar({
                                message: "Copied share link to clipboard!",
                                type: "success",
                              });
                            }}
                          >
                            <InsertLinkIcon
                              style={{
                                width: "25px",
                                height: "25px",
                                color: "#fff",
                                background: colors.background.main,
                                borderRadius: "50%",
                                padding: "5px",
                                marginRight: "10px",
                              }}
                              className="share-buttons"
                              url={window.location.href}
                              title={currentObject.name}
                              hashtags={["vrm", "vroid", "3d", "3dmodel"]}
                            />
                            <VuiTypography
                              sx={{
                                fontSize: "0.9rem",
                                color: colors.background.secondary,
                                textAlign: "center",
                              }}
                              className="share-buttons"
                            >
                              {shareCopied ? "Copied!" : "Copy Link"}
                            </VuiTypography>
                          </VuiBox>
                        </>
                      )}
                    </VuiBox>
                  </VuiBox>
                </VuiBox>
                {/* </Button> */}
              </VuiBox>
              {currentObject &&
                (currentObject.assetType === "file" ? (
                  ["vrm", "gltf", "glb", "fbx", "obj"].includes(currentObject.fileType) ? (
                    <> </>
                  ) : (
                    <img
                      src={currentObject.filePath}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        borderRadius: "0px",
                        position: "absolute",
                        top: "0px",
                      }}
                    />
                  )
                ) : (
                  <>
                    <FolderIcon
                      sx={{
                        position: "absolute",
                        top: "0px",
                        pointerEvents: "none",
                        margin: "0 auto",
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                        color: colors.icon.focus,
                      }}
                    />
                  </>
                ))}
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={12}>
            <VuiBox mt={0} mb={0}>
              {/* <VuiTypography variant="button" textTransform={"capitalize"} color="white">
                Contract Address
              </VuiTypography>
              <VuiTypography variant="body2" color="white">
                {props.data.contractAddress}
              </VuiTypography> */}
              <VuiBox display="flex" justifyContent="center" mb={2}>
                <Grid container spacing={1}>
                  {/* FIELD 1 */}
                  <Grid item xs={4} md={4}>
                    <VuiTypography
                      variant="body2"
                      textTransform={"capitalize"}
                      color="white"
                      sx={{ fontWeight: 450, color: "#aaa" }}
                    >
                      {currentObject.assetType === "folder" ? "Folder Name" : "File Name"}
                    </VuiTypography>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <DarkTooltip title={currentObject ? currentObject.fileName : ""}>
                      <VuiTypography
                        variant="body2"
                        color="white"
                        sx={{
                          fontWeight: 450,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {currentObject ? currentObject.fileName : ""}
                      </VuiTypography>
                    </DarkTooltip>
                  </Grid>
                  {/* NEW FIELD */}
                  <Grid item xs={4} md={4}>
                    <VuiTypography
                      variant="body2"
                      textTransform={"capitalize"}
                      color="white"
                      sx={{ fontWeight: 450, color: "#aaa" }}
                    >
                      Date Created{" "}
                    </VuiTypography>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <VuiTypography variant="body2" color="white" sx={{ fontWeight: 450 }}>
                      {currentObject.assetType !== "folder"
                        ? versionData &&
                          versionData.lastUpdatedTimes &&
                          versionData.lastUpdatedTimes.length > 0
                          ? parseDateUTC(
                              versionData.lastUpdatedTimes[versionData.lastUpdatedTimes.length - 1]
                            )
                          : "not available"
                        : parseDateUTC(currentObject.lastUpdated) ?? "not available"}
                    </VuiTypography>
                  </Grid>
                  {/* NEW FIELD */}
                  {/* <Grid item xs={4} md={4}>
                    <VuiTypography
                      variant="body2"
                      textTransform={"capitalize"}
                      color="white"
                      sx={{ fontWeight: 450, color: "#aaa" }}
                    >
                      Modified
                    </VuiTypography>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <VuiTypography variant="body2" color="white" sx={{ fontWeight: 450 }}>
                      {currentObject ? formatBytes(currentObject.fileSize) : ""}
                    </VuiTypography>
                  </Grid> */}
                  {/* NEW FIELD */}

                  {maybeShowType}
                  {maybeShowTypeValue}
                  <Grid item xs={4} md={4}>
                    <VuiTypography
                      variant="body2"
                      textTransform={"capitalize"}
                      color="white"
                      sx={{ fontWeight: 450, color: "#aaa" }}
                    >
                      Size
                    </VuiTypography>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <VuiTypography variant="body2" color="white" sx={{ fontWeight: 450 }}>
                      {currentObject.assetType === "folder"
                        ? formatBytes(parseInt(folderSize))
                        : formatBytes(parseInt(currentObject.fileSize))}
                    </VuiTypography>
                  </Grid>
                </Grid>
              </VuiBox>
              {/* 
              {assetIdTitle}
              {assetIdBody} */}

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {currentObject.assetType === "file" ? (
                    <>
                      <Grid container spacing={1}>
                        <Grid item xs={6} md={6}>
                          <DarkTooltip
                            title={
                              typeof currentVersionObj.fileName !== "undefined" &&
                              !["gltf", "glb", "obj", "fbx"].includes(
                                getFileType(currentVersionObj.fileName)
                              )
                                ? "Only GLTF, GLB, OBJ, and FBX files are supported"
                                : convertBytesToMB(currentVersionObj.fileSize) > 100
                                ? "File size must be less than 100MB for AI retexturing"
                                : ""
                            }
                            style={{
                              opacity:
                                typeof currentVersionObj.fileName !== "undefined" &&
                                (!["gltf", "glb", "obj", "fbx"].includes(
                                  getFileType(currentVersionObj.fileName)
                                ) ||
                                  convertBytesToMB(currentVersionObj.fileSize) > 100)
                                  ? "0.5"
                                  : "1",
                            }}
                          >
                            <Button
                              className="default_btn bg_hivegradient c_white f_small fw_500 w100 mt_1"
                              sx={{
                                opacity:
                                  typeof currentVersionObj.fileName !== "undefined" &&
                                  (!["gltf", "glb", "obj", "fbx"].includes(
                                    getFileType(currentVersionObj.fileName)
                                  ) ||
                                    convertBytesToMB(currentVersionObj.fileSize) > 100)
                                    ? 0.5
                                    : 1,
                              }}
                              onClick={handleRetextureClick}
                              startIcon={
                                <BoltOutlinedIcon
                                  sx={{
                                    width: "18px",
                                    height: "18px",
                                  }}
                                />
                              }
                            >
                              Retexture with AI
                            </Button>
                          </DarkTooltip>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Button
                            className="default_btn bg_icondisabled c_white f_small fw_500 w100 mt_1"
                            onClick={downloadModel}
                            startIcon={
                              <DownloadIcon
                                sx={{
                                  width: "18px",
                                  height: "18px",
                                }}
                              />
                            }
                          >
                            Download Current
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      sx={{
                        color: "#fff",
                        width: "100%",
                        height: "30px",
                        fontSize: "12px",
                        marginTop: "10px",
                        backgroundColor: colors.icon.disabled,
                        "&:hover": {
                          backgroundColor: colors.icon.enabled,
                        },
                        "&:focus": {
                          outline: "none",
                          backgroundColor: colors.icon.disabled,
                        },
                      }}
                      onClick={viewFolder}
                      startIcon={
                        <VisibilityIcon sx={{ width: "25px", height: "25px", marginTop: "4px" }} />
                      }
                    >
                      Open Folder
                    </Button>
                  )}
                </Grid>
                {/* <Grid item xs={6}>
                  <UploadReplace cb={handleReplace} data={currentObject} />
                </Grid> */}

                <Grid item xs={12}>
                  {currentObject.assetType === "file" ? <VuiBox>{versionContent}</VuiBox> : <></>}
                </Grid>
              </Grid>
            </VuiBox>
          </Grid>
        </Grid>
      </VuiBox>
      {/* </Modal> */}
      <VuiSnackbar
        color={snackbarType}
        icon="notifications"
        title="Polyhive"
        content={snackbarMessage}
        dateTime="Just now"
        open={snackbar}
        close={toggleSnackbar}
        onClose={toggleSnackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={3000}
      />
      <DownloadModal open={showDownloadModal} handleClose={handleDownloadModalClose} />;
    </>
  );
});

const modalStyle = {
  width: "300px",
  height: "100%",
  position: "absolute",
  alignItems: "center",
  justifyContent: "center",
};

const AIJobModal = (props) => {
  const [dryRun, setDryRun] = useState(false);
  const [debugJob, setDebug] = useState(false);

  const [devView, setDevView] = useState(false);
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [seed, setSeed] = useState(0);
  const [textureResolution, setTextureResolution] = useState(1024);
  const [guidanceScale, setGuidanceScale] = useState(7.5);
  const [regionThreshold, setRegionThreshold] = useState(0.2);
  const [refineExistingTexture, setRefineExistingTexture] = useState(false);
  const [strictProjectionMasking, setStrictProjectionMasking] = useState(true);
  const [preserveUVs, setPreserveUVs] = useState(true);
  const [materialMaps, setMaterialMaps] = useState(true);

  const [textPrompt, setTextPrompt] = useState("");
  const [fileNameError, setFileNameError] = useState("");
  const [promptError, setPromptError] = useState("");

  useEffect(() => {
    setDryRun(false);
    setDebug(false);

    setAdvancedOpen(false);

    setSeed(Math.floor(Math.random() * 100000));
    setTextureResolution(1024);
    setGuidanceScale(7.5);
    setRegionThreshold(0.2);
    setRefineExistingTexture(false);
    setStrictProjectionMasking(true);
    setPreserveUVs(true);
    setTextPrompt("");
    setFileNameError("");
    setPromptError("");
    if (process.env.NODE_ENV !== "production") {
      setDevView(true);
    }
    console.log("props.open", props.data);
  }, [props.open]);

  const handleChange = async (e) => {
    e.preventDefault();
    console.log("handleChange", e);
    // props.handleChange();
    // if props.jobArray contains any jobs with completedTimes === null, then we need to wait for them to finish before we can start a new job
    let allowNewJob = true;
    // if (devView === false) {
    console.log("checking props.jobArray", props.jobArray);
    for (let i = 0; i < props.jobArray.length; i++) {
      console.log("checking job", props.jobArray[i]);
      if (props.jobArray[i].status === "In progress" || props.jobArray[i].status === "Queued") {
        allowNewJob = false;
        console.log("checkin job is still running", props.jobArray[i]);
        props.startSnackbar({
          type: "error",
          message: "You can only have one job running at a time.",
        });
        break;
      }
    }
    // }
    if (allowNewJob === true) {
      const text = document.getElementById("text-prompt").value;
      const outputFileName = document.getElementById("output-file-name").value;
      const negativePrompt = document.getElementById("negative-prompt").value;

      if (text === "" || outputFileName === "") {
        props.startSnackbar({
          type: "error",
          message: "Please enter a text prompt and output file name.",
        });
        return;
      }

      const params = {};
      if (process.env.NODE_ENV !== "production") {
        params.owner = document.getElementById("owner").value;
        params.dry_run = dryRun;
        params.debug = debugJob;
      }
      params.negative_prompt = negativePrompt;
      params.seed = seed;
      params.texture_resolution = textureResolution;
      params.guidance_scale = guidanceScale;
      params.region_threshold = regionThreshold;
      params.use_existing_texture = refineExistingTexture;
      params.strict_projection_masking = strictProjectionMasking;
      params.preserve_uvs = preserveUVs;
      params.material_maps = materialMaps;
      console.log("params", params);
      logAuthorizedWombatEvent("aiRetextureSubmitButtonClicked", {});

      console.log("input text", text);
      console.log("current object", props.data);

      if (!/^[a-zA-Z0-9_-]+(\.[a-zA-Z0-9]+)?$/.test(outputFileName)) {
        logAuthorizedWombatEvent("aiRetextureSubmitError", {
          error:
            "Invalid output file name. Only alphanumeric characters, dashes, and underscores are allowed.",
        });

        setFileNameError(
          "Invalid output file name. Only alphanumeric characters, dashes, and underscores are allowed."
        );
        return;
      }

      if (text.includes("{") || text.includes("}")) {
        logAuthorizedWombatEvent("aiRetextureSubmitError", {
          error: "Invalid prompt. Curly braces are not allowed.",
        });
        setPromptError("Invalid prompt. Curly braces are not allowed.");
        return;
      } else {
        setPromptError("");
      }

      if (negativePrompt.includes("{") || negativePrompt.includes("}")) {
        logAuthorizedWombatEvent("aiRetextureSubmitError", {
          error: "Invalid negative prompt. Curly braces are not allowed.",
        });
        setPromptError("Invalid negative prompt. Curly braces are not allowed.");
        return;
      } else {
        setPromptError("");
      }

      const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/retexture`);
      const requestBody = {
        version_id: props.data.versionId,
        text_prompt: text,
        job_name: outputFileName,
        job_params: params,
      };
      console.log("requestBody", requestBody);
      const response = fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-ORG-ID": props.activeOrg,
        },
        body: JSON.stringify(requestBody),
      }).catch((error) => {
        console.log("error", error);
        props.startSnackbar({
          type: "error",
          message: "Job failed to start.",
        });
      });
      const response1 = await response;
      console.log("response1", response1);
      if (response1.status === 200) {
        const data = await response1.json();
        logAuthorizedWombatEvent("aiJobStartedFrontend", {
          jobId: data.job_id,
          textPrompt: requestBody.text_prompt,
          versionId: requestBody.version_id,
        });
        props.startSnackbar({
          type: "success",
          message: "Job started successfully. Check the AI Jobs tab for progress.",
        });
        props.addJob({
          completedTime: null,
          errorMsg: null,
          jobId: data.job_id,
          jobName: requestBody.job_name,
          queuedTime: new Date().toISOString(),
          startedTime: null,
          assetId: props.data.modelId,
          // TODO: fill job_params
          job_params: {},
          textPrompt: requestBody.text_prompt,
          versionId: requestBody.version_id,
          status: "Queued",
          eta: null,
        });
        // props.getJobData();
      } else {
        console.log("response failed", response1);
        props.startSnackbar({
          type: "error",
          message: response1.statusText,
        });
      }
    }

    props.handleClose();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ modalStyle }}
    >
      <VuiBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          maxHeight: "80%",
          overflow: "scroll",
          bgcolor: colors.background.main,
          // border: "2px solid #000",
          boxShadow: 24,
          justifyContent: "center",
          p: 3,
          borderRadius: "15px",
          "&:focus": {
            outline: "none",
          },
          "@media (max-width: 600px)": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        {/* WARNING BANNER AT TOP OF MODAL FOR HIGH QUEUE TIMES */}
        {/* <VuiBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: colors.warning.main,
            borderRadius: "5px",
            padding: "10px",
            marginBottom: "10px",
            // width: "100%",
            height: "35px",
            "@media (max-width: 600px)": {
              height: "50px",
            },
          }}
        >
          <VuiTypography variant="body2" sx={{ color: colors.background.secondary }}>
            Due to high demand, AI texturing queue times may be longer than usual.
          </VuiTypography>
        </VuiBox> */}

        <div className="" style={{ textAlign: "left" }}>
          <Grid container spacing={2} mt={0} mb={1} style={{ marginTop: "-15px" }}>
            <Grid item xs={12} sm={6} pt={0}>
              <VuiTypography variant="h6" sx={{ color: "#fff", marginBottom: "10px" }}>
                Retexture {props.data.fileName}
              </VuiTypography>
              <VuiTypography
                variant="body2"
                sx={{ color: colors.icon.enabled, marginBottom: "12px" }}
              >
                Enter a text prompt to retexture this model.
              </VuiTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <VuiBox
                sx={{
                  height: "150px",
                  display: "block",
                }}
              >
                {props.data.fileName && (
                  <VuiBox
                    sx={{
                      height: "100%",
                      width: "100%",
                      backgroundColor: colors.background.secondary,
                      position: "relative",
                      borderRadius: "8px",
                    }}
                  >
                    <ModelViewer url={props.data.filePath} parentDiv="retexture-modal-viewer" />
                  </VuiBox>
                )}
              </VuiBox>
            </Grid>
          </Grid>

          <form noValidate autoComplete="off" onSubmit={handleChange}>
            <VuiBox>
              <VuiTypography
                variant="body2"
                sx={{ color: colors.icon.enabled, marginBottom: "0px", fontSize: "0.9rem" }}
              >
                Text prompt *
              </VuiTypography>
              {promptError !== "" && (
                <VuiTypography
                  variant="body2"
                  sx={{ color: colors.error.main, marginBottom: "0px", fontSize: "0.9rem" }}
                >
                  {promptError}
                </VuiTypography>
              )}
              <input
                type="text"
                placeholder="Enter text prompt"
                id="text-prompt"
                style={{
                  width: "100%",
                  height: "30px",
                  borderRadius: "7px",
                  border: "1px solid " + colors.icon.disabled,
                  padding: "0px 10px",
                  color: "#fff",
                  backgroundColor: colors.background.secondary,
                  marginBottom: "14px",
                  "&:focus": {
                    outline: "none",
                    border: "1px solid " + colors.icon.disabled,
                  },
                }}
                onChange={(e) => {
                  setTextPrompt(e.target.value);
                }}
              />
              <VuiTypography
                variant="body2"
                sx={{ color: colors.icon.enabled, marginBottom: "0px", fontSize: "0.9rem" }}
              >
                Output file name *
              </VuiTypography>
              {fileNameError !== "" && (
                <VuiTypography
                  variant="body2"
                  sx={{ color: colors.error.main, marginBottom: "0px", fontSize: "0.9rem" }}
                >
                  {fileNameError}
                </VuiTypography>
              )}

              <input
                type="text"
                placeholder="Enter output file name"
                id="output-file-name"
                style={{
                  width: "100%",
                  height: "30px",
                  borderRadius: "7px",
                  border: "1px solid " + colors.icon.disabled,
                  padding: "0px 10px",
                  color: "#fff",
                  backgroundColor: colors.background.secondary,
                  marginBottom: "14px",
                  "&:focus": {
                    outline: "none",
                    border: "1px solid " + colors.icon.disabled,
                  },
                }}
                defaultValue={
                  typeof props.data.fileName !== "undefined"
                    ? "retexture-" + getFileName(props.data.fileName)
                    : ""
                }
              />
            </VuiBox>
            {/* ADVANCED SETTINGS */}
            <VuiBox
              sx={{
                marginBottom: "14px",
                backgroundColor: colors.background.secondary,
                borderRadius: "7px",
                padding: "10px",
                display: "block",
                positon: "relative",
              }}
            >
              <VuiBox
                sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                onClick={() => {
                  logAuthorizedWombatEvent("advancedSettingsOpenedRetextureModal", {});
                  setAdvancedOpen(!advancedOpen);
                }}
              >
                <VuiTypography
                  variant="body2"
                  sx={{
                    color: colors.icon.enabled,
                    marginLeft: "4px",
                    fontSize: "0.9rem",
                    fontWeight: 450,
                  }}
                >
                  Advanced settings
                </VuiTypography>
                <ChevronRightIcon
                  style={{
                    color: colors.icon.enabled,
                    transform: advancedOpen ? "rotate(90deg)" : "rotate(0deg)",
                    marginTop: "3px",
                  }}
                />
              </VuiBox>
              <VuiBox
                sx={{
                  marginBottom: "4px",
                }}
              ></VuiBox>
              <VuiBox
                sx={{
                  marginBottom: "4px",
                  marginLeft: "4px",
                  display: advancedOpen ? "block" : "none",
                }}
              >
                {/* NEGATIVE PROMPT */}
                <VuiBox>
                  <VuiTypography
                    variant="body2"
                    sx={{ color: colors.icon.enabled, marginBottom: "0px", fontSize: "0.9rem" }}
                  >
                    Negative prompt
                  </VuiTypography>
                  <input
                    type="text"
                    placeholder="Enter text prompt"
                    id="negative-prompt"
                    style={{
                      width: "100%",
                      height: "30px",
                      borderRadius: "7px",
                      border: "1px solid " + colors.icon.disabled,
                      padding: "0px 10px",
                      color: "#fff",
                      backgroundColor: colors.background.secondary,
                      marginBottom: "14px",
                      "&:focus": {
                        outline: "none",
                        border: "1px solid " + colors.icon.disabled,
                      },
                    }}
                  />
                </VuiBox>
                {/* END NEGATIVE PROMPT */}
                {/* PRESERVE UVS */}
                <VuiBox
                  sx={{
                    marginBottom: "14px",
                    // display: "flex",
                  }}
                >
                  <VuiBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <VuiTypography
                      variant="body2"
                      sx={{ color: colors.icon.enabled, marginBottom: "4px", fontSize: "0.9rem" }}
                      mr={1}
                    >
                      Preserve UV Mapping
                    </VuiTypography>
                    <VuiBox>
                      <HtmlTooltip
                        placement="top"
                        title={
                          <React.Fragment>
                            <VuiTypography color="inherit">UV Preservation</VuiTypography>
                            {
                              "When this option is enabled, the UV's that are included with the mesh will be preserved when generating the new texture. If this is disabled, a new UV map will be created."
                            }
                            <br />
                            <br />
                            {
                              "Please note that issues may rise when this flag is enabled if your mesh has faces that have the same UV mapping. In addition, UV preservation is not currently supported for meshes that have more than one texture "
                            }
                          </React.Fragment>
                        }
                      >
                        <InfoRoundedIcon className="info_icon" />
                      </HtmlTooltip>
                    </VuiBox>
                  </VuiBox>
                  <VuiBox
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      marginBottom: "4px",
                    }}
                  >
                    <Button
                      variant="text"
                      style={{
                        color: "#fff",
                        background: preserveUVs ? colors.icon.focusdark : "none",
                        border: "1px solid " + colors.icon.focusdark,
                        borderRadius: "4px",
                        marginRight: "10px",
                      }}
                      onClick={() => setPreserveUVs(true)}
                    >
                      True
                    </Button>
                    <Button
                      variant="text"
                      style={{
                        color: "#fff",
                        background: !preserveUVs ? colors.icon.focusdark : "none",
                        border: "1px solid " + colors.icon.focusdark,
                        borderRadius: "4px",
                      }}
                      onClick={() => setPreserveUVs(false)}
                    >
                      False
                    </Button>
                  </VuiBox>
                </VuiBox>
                {/* END PRESERVE UVS */}
                {/* MATERIAL MAPPING */}
                <VuiBox
                  sx={{
                    marginBottom: "14px",
                    // display: "flex",
                  }}
                >
                  <VuiBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <VuiTypography
                      variant="body2"
                      sx={{ color: colors.icon.enabled, marginBottom: "4px", fontSize: "0.9rem" }}
                      mr={1}
                    >
                      Generate Material Maps
                    </VuiTypography>
                    <VuiBox>
                      <HtmlTooltip
                        placement="top"
                        title={
                          <React.Fragment>
                            <VuiTypography color="inherit">Material Maps</VuiTypography>
                            {
                              "When this flag is enabled Polyhive will generate a set of material textures (normal, smoothness, and metallic maps) for your asset. These textures allow your asset to react more realistically to light (ex. causing shiny parts to produce specular highlights)."
                            }
                          </React.Fragment>
                        }
                      >
                        <InfoRoundedIcon className="info_icon" />
                      </HtmlTooltip>
                    </VuiBox>
                  </VuiBox>
                  <VuiBox
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      marginBottom: "4px",
                    }}
                  >
                    <Button
                      variant="text"
                      style={{
                        color: "#fff",
                        background: materialMaps ? colors.icon.focusdark : "none",
                        border: "1px solid " + colors.icon.focusdark,
                        borderRadius: "4px",
                        marginRight: "10px",
                      }}
                      onClick={() => setMaterialMaps(true)}
                    >
                      True
                    </Button>
                    <Button
                      variant="text"
                      style={{
                        color: "#fff",
                        background: !materialMaps ? colors.icon.focusdark : "none",
                        border: "1px solid " + colors.icon.focusdark,
                        borderRadius: "4px",
                      }}
                      onClick={() => setMaterialMaps(false)}
                    >
                      False
                    </Button>
                  </VuiBox>
                </VuiBox>
                {/* END MATERIAL MAPPING */}
                {/* SEED SLIDER */}
                <VuiBox>
                  <VuiBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <VuiTypography
                      variant="body2"
                      sx={{ color: colors.icon.enabled, marginBottom: "0px", fontSize: "0.9rem" }}
                    >
                      Seed
                    </VuiTypography>
                    <input
                      type="text"
                      placeholder="0"
                      id="seed"
                      style={{
                        width: "100px",
                        height: "30px",
                        borderRadius: "7px",
                        border: "1px solid " + colors.icon.disabled,
                        padding: "0px 10px",
                        color: "#fff",
                        backgroundColor: colors.background.secondary,
                        "&:focus": {
                          outline: "none",
                          border: "1px solid " + colors.icon.disabled,
                        },
                      }}
                      value={seed}
                      onChange={(e) => {
                        if (e.target.value > 100000) setSeed(100000);
                        else if (e.target.value < 0) setSeed(0);
                        else setSeed(e.target.value);
                      }}
                    />
                  </VuiBox>
                  <input
                    type="range"
                    min="1"
                    max="100000"
                    value={seed}
                    class="slider"
                    id="seed"
                    onChange={(e) => {
                      setSeed(e.target.value);
                    }}
                    style={{
                      width: "100%",
                      marginBottom: "14px",
                      top: "-5px",
                      position: "relative",
                    }}
                  />
                </VuiBox>
                {/* END SEED SLIDER */}
                {/* TEXTURE RES SLIDER */}
                <VuiBox>
                  <VuiBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <VuiTypography
                      variant="body2"
                      sx={{ color: colors.icon.enabled, marginBottom: "0px", fontSize: "0.9rem" }}
                    >
                      Texture resolution
                    </VuiTypography>
                    <input
                      type="text"
                      placeholder="0"
                      id="texture-resolution-text"
                      style={{
                        width: "100px",
                        height: "30px",
                        borderRadius: "7px",
                        border: "1px solid " + colors.icon.disabled,
                        padding: "0px 10px",
                        color: "#fff",
                        backgroundColor: colors.background.secondary,
                        "&:focus": {
                          outline: "none",
                          border: "1px solid " + colors.icon.disabled,
                        },
                      }}
                      value={textureResolution}
                      onChange={(e) => {
                        if (e.target.value > 1024) setTextureResolution(1024);
                        else if (e.target.value < 256) setTextureResolution(256);
                        else setTextureResolution(e.target.value);
                      }}
                    />
                  </VuiBox>
                  <input
                    type="range"
                    min="256"
                    max="1024"
                    value={textureResolution}
                    class="slider"
                    id="texture-resolution-range"
                    onChange={(e) => {
                      setTextureResolution(e.target.value);
                    }}
                    style={{
                      width: "100%",
                      marginBottom: "14px",
                      top: "-5px",
                      position: "relative",
                    }}
                  />
                </VuiBox>
                {/* END TEXTURE RES SLIDER */}
                {/* GUIDANCE SCALE SLIDER */}
                <VuiBox>
                  <VuiBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <VuiTypography
                      variant="body2"
                      sx={{ color: colors.icon.enabled, marginBottom: "0px", fontSize: "0.9rem" }}
                    >
                      Guidance scale
                    </VuiTypography>
                    <input
                      type="number"
                      step="0.1"
                      placeholder="0"
                      id="guidance-scale-text"
                      style={{
                        width: "100px",
                        height: "30px",
                        borderRadius: "7px",
                        border: "1px solid " + colors.icon.disabled,
                        padding: "0px 10px",
                        color: "#fff",
                        backgroundColor: colors.background.secondary,
                        "&:focus": {
                          outline: "none",
                          border: "1px solid " + colors.icon.disabled,
                        },
                      }}
                      value={guidanceScale}
                      onChange={(e) => {
                        if (e.target.value > 50) setGuidanceScale(50);
                        else if (e.target.value < 1) setGuidanceScale(1);
                        else {
                          // set it to the value rounded to 1 place after the decimal
                          setGuidanceScale(Math.round(e.target.value * 10.0) / 10.0);
                        }
                      }}
                    />
                  </VuiBox>
                  <input
                    type="range"
                    min="1"
                    max="50"
                    step={0.1}
                    value={guidanceScale}
                    class="slider"
                    id="guidance-scale-range"
                    onChange={(e) => {
                      // set it to the value rounded to 1 decimal place
                      setGuidanceScale(Math.round(e.target.value * 10) / 10);
                    }}
                    style={{
                      width: "100%",
                      marginBottom: "14px",
                      top: "-5px",
                      position: "relative",
                    }}
                  />
                </VuiBox>
                {/* END GUIDANCE SCALE SLIDER */}
                {/* REFINING THRESHOLD SLIDER */}
                {/* <VuiBox>
                  <VuiBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <VuiTypography
                      variant="body2"
                      sx={{ color: colors.icon.enabled, marginBottom: "0px", fontSize: "0.9rem" }}
                    >
                      Threshold for refining regions
                    </VuiTypography>
                    <input
                      type="number"
                      step="0.01"
                      placeholder="0"
                      id="refining-threshold-text"
                      style={{
                        width: "100px",
                        height: "30px",
                        borderRadius: "7px",
                        border: "1px solid " + colors.icon.disabled,
                        padding: "0px 10px",
                        color: "#fff",
                        backgroundColor: colors.background.secondary,
                        "&:focus": {
                          outline: "none",
                          border: "1px solid " + colors.icon.disabled,
                        },
                      }}
                      value={regionThreshold}
                      onChange={(e) => {
                        if (e.target.value > 1) setRegionThreshold(1);
                        else if (e.target.value < 0) setRegionThreshold(0);
                        else {
                          // set it to the value rounded to 2 decimal places
                          setRegionThreshold(Math.round(e.target.value * 100) / 100);
                        }
                      }}
                    />
                  </VuiBox>
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step={0.01}
                    value={regionThreshold}
                    class="slider"
                    id="region-threshold-range"
                    onChange={(e) => {
                      // set it to the value rounded to 1 decimal place
                      setRegionThreshold(Math.round(e.target.value * 100) / 100);
                    }}
                    style={{
                      width: "100%",
                      marginBottom: "14px",
                      top: "-5px",
                      position: "relative",
                    }}
                  />
                </VuiBox> */}
                {/* END REFINING THRESHOLD SLIDER */}

                {/* STRICT PROJECTION MASKING */}
                {/* <VuiBox
                  sx={{
                    marginBottom: "14px",
                  }}
                >
                  <VuiTypography
                    variant="body2"
                    sx={{ color: colors.icon.enabled, marginBottom: "4px", fontSize: "0.9rem" }}
                  >
                    Stricter masking for projection
                  </VuiTypography>
                  <VuiBox
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      marginBottom: "4px",
                    }}
                  >
                    <Button
                      variant="text"
                      style={{
                        color: "#fff",
                        background: strictProjectionMasking ? colors.icon.focusdark : "none",
                        border: "1px solid " + colors.icon.focusdark,
                        borderRadius: "4px",
                        marginRight: "10px",
                      }}
                      onClick={() => setStrictProjectionMasking(true)}
                    >
                      True
                    </Button>
                    <Button
                      variant="text"
                      style={{
                        color: "#fff",
                        background: !strictProjectionMasking ? colors.icon.focusdark : "none",
                        border: "1px solid " + colors.icon.focusdark,
                        borderRadius: "4px",
                      }}
                      onClick={() => setStrictProjectionMasking(false)}
                    >
                      False
                    </Button>
                  </VuiBox>
                </VuiBox> */}
                {/* END STRICT PROJECTION MASKING */}
                {/* END ADVANCED SETTINGS */}
              </VuiBox>
            </VuiBox>
            {/* DEVELOPMENT PARAMETERS */}
            {/* {process.env.NODE_ENV === "production" ? null : (
              <VuiBox
                sx={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                }}
              >
                <VuiBox
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    marginBottom: "4px",
                  }}
                >
                  <Button
                    variant="text"
                    style={{
                      color: "#fff",
                      background: devView ? colors.icon.focusdark : "none",
                      border: "1px solid " + colors.icon.focusdark,
                      borderRadius: "4px",
                      marginRight: "10px",
                    }}
                    onClick={() => setDevView(true)}
                  >
                    Dev View
                  </Button>
                  <Button
                    variant="text"
                    style={{
                      color: "#fff",
                      background: !devView ? colors.icon.focusdark : "none",
                      border: "1px solid " + colors.icon.focusdark,
                      borderRadius: "4px",
                    }}
                    onClick={() => setDevView(false)}
                  >
                    Prod View
                  </Button>
                </VuiBox>
              </VuiBox>
            )} */}
            {devView === true ? (
              process.env.NODE_ENV === "production" ? null : (
                <>
                  <VuiTypography
                    variant="body2"
                    sx={{ color: colors.icon.enabled, fontWeight: 450, fontSize: "16px" }}
                  >
                    Development Parameters
                  </VuiTypography>
                  <VuiBox>
                    <VuiTypography
                      variant="body2"
                      sx={{ color: colors.icon.enabled, marginBottom: "0px", fontSize: "0.9rem" }}
                    >
                      Owner
                    </VuiTypography>
                    <input
                      type="text"
                      placeholder="Enter owner"
                      id="owner"
                      style={{
                        width: "100%",
                        height: "30px",
                        borderRadius: "7px",
                        border: "1px solid " + colors.icon.disabled,
                        padding: "0px 10px",
                        color: "#fff",
                        backgroundColor: colors.background.secondary,
                        marginBottom: "4px",
                        "&:focus": {
                          outline: "none",
                          border: "1px solid " + colors.icon.disabled,
                        },
                      }}
                    />
                  </VuiBox>
                  {/* Checkbox for dry run */}
                  <VuiBox
                    sx={{
                      marginBottom: "14px",
                    }}
                  >
                    <VuiTypography
                      variant="body2"
                      sx={{ color: colors.icon.enabled, marginBottom: "4px", fontSize: "0.9rem" }}
                    >
                      Dry Run
                    </VuiTypography>
                    <VuiBox
                      sx={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        marginBottom: "4px",
                      }}
                    >
                      <Button
                        variant="text"
                        style={{
                          color: "#fff",
                          background: dryRun ? colors.icon.focusdark : "none",
                          border: "1px solid " + colors.icon.focusdark,
                          borderRadius: "4px",
                          marginRight: "10px",
                        }}
                        onClick={() => setDryRun(true)}
                      >
                        True
                      </Button>
                      <Button
                        variant="text"
                        style={{
                          color: "#fff",
                          background: !dryRun ? colors.icon.focusdark : "none",
                          border: "1px solid " + colors.icon.focusdark,
                          borderRadius: "4px",
                        }}
                        onClick={() => setDryRun(false)}
                      >
                        False
                      </Button>
                    </VuiBox>
                  </VuiBox>
                  {/* Checkbox for debug */}
                  <VuiBox
                    sx={{
                      marginBottom: "14px",
                    }}
                  >
                    <VuiTypography
                      variant="body2"
                      sx={{ color: colors.icon.enabled, marginBottom: "4px", fontSize: "0.9rem" }}
                    >
                      Debug
                    </VuiTypography>
                    <VuiBox
                      sx={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        marginBottom: "4px",
                      }}
                    >
                      <Button
                        variant="text"
                        style={{
                          color: "#fff",
                          background: debugJob ? colors.icon.focusdark : "none",
                          border: "1px solid " + colors.icon.focusdark,
                          borderRadius: "4px",
                          marginRight: "10px",
                        }}
                        onClick={() => setDebug(true)}
                      >
                        True
                      </Button>
                      <Button
                        variant="text"
                        style={{
                          color: "#fff",
                          background: !debugJob ? colors.icon.focusdark : "none",
                          border: "1px solid " + colors.icon.focusdark,
                          borderRadius: "4px",
                        }}
                        onClick={() => setDebug(false)}
                      >
                        False
                      </Button>
                    </VuiBox>
                  </VuiBox>
                </>
              )
            ) : (
              <> </>
            )}
            {/* END DEVELOPMENT PARAMETERS */}
            <Button
              onClick={props.handleClose}
              variant="outlined"
              sx={{
                outline: "none",
                border: "1.5px solid " + colors.icon.disabled,
                color: colors.icon.enabled,
                borderRadius: "7px",
                height: "15px",
                // padding: "0px",
                margin: "0px",
                "&:hover": { border: "1.5px solid " + colors.icon.disabled },
              }}
            >
              Cancel
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              // onClick={props.handleCreateFolder}
              // type="submit"
              variant="contained"
              // onClick={handleChange}
              type="submit"
              style={{
                color: "#000",
                backgroundColor: colors.success.main,
                borderRadius: "7px",
              }}
              // disable if text prompt field is empty
              disabled={textPrompt === ""}
            >
              Generate Texture
            </Button>
          </form>
        </div>
      </VuiBox>
    </Modal>
  );
};

const JobDetailsModal = (props) => {
  const handleSendJobFields = () => {
    props.handleSendJobFields();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ modalStyle }}
    >
      <VuiBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: colors.background.main,
          // border: "2px solid #000",
          boxShadow: 24,
          justifyContent: "center",
          p: 3,
          borderRadius: "15px",
          "&:focus": {
            outline: "none",
          },
        }}
      >
        {typeof props.job !== "undefined" ? (
          <>
            <div className="" style={{ textAlign: "left" }}>
              <VuiTypography variant="h6" sx={{ color: "#fff", marginBottom: "10px" }}>
                {props.job.jobName} Details
              </VuiTypography>

              <VuiBox>
                <VuiTypography
                  variant="body2"
                  sx={{
                    color: colors.icon.enabled,
                    marginBottom: "0px",
                    fontSize: "0.9rem",
                    marginBottom: "8px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}> Text prompt: </span>"{props.job.textPrompt}"
                </VuiTypography>
                {typeof props.job.job_params !== "undefined" ? (
                  <>
                    <VuiTypography
                      variant="body2"
                      sx={{
                        color: colors.icon.enabled,
                        marginBottom: "0px",
                        fontSize: "0.9rem",
                        marginBottom: "8px",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}> Negative prompt: </span>"
                      {props.job.job_params.negative_prompt}"
                    </VuiTypography>

                    <VuiTypography
                      variant="body2"
                      sx={{
                        color: colors.icon.enabled,
                        marginBottom: "0px",
                        fontSize: "0.9rem",
                        marginBottom: "8px",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}> Seed: </span> "
                      {props.job.job_params.seed}"
                    </VuiTypography>

                    <VuiTypography
                      variant="body2"
                      sx={{
                        color: colors.icon.enabled,
                        marginBottom: "0px",
                        fontSize: "0.9rem",
                        marginBottom: "8px",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}> Preserve UV Mapping: </span>
                      {props.job.job_params.preserve_uvs.toString()}
                    </VuiTypography>
                    <VuiTypography
                      variant="body2"
                      sx={{
                        color: colors.icon.enabled,
                        marginBottom: "0px",
                        fontSize: "0.9rem",
                        marginBottom: "8px",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}> Material Maps: </span>
                      {props.job.job_params.material_maps.toString()}
                    </VuiTypography>
                    <VuiTypography
                      variant="body2"
                      sx={{
                        color: colors.icon.enabled,
                        marginBottom: "0px",
                        fontSize: "0.9rem",
                        marginBottom: "8px",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}> Resolution: </span>
                      {props.job.job_params.texture_resolution === 1024
                        ? "1K"
                        : props.job.job_params.texture_resolution === 2048
                        ? "2K"
                        : props.job.job_params.texture_resolution === 4096
                        ? "4K"
                        : "Resolution not found"}
                    </VuiTypography>
                    <VuiTypography
                      variant="body2"
                      sx={{
                        color: colors.icon.enabled,
                        marginBottom: "0px",
                        fontSize: "0.9rem",
                        marginBottom: "8px",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}> Model: </span>
                      {props.job.job_params.sd_output_resolution === 512
                        ? "Standard"
                        : props.job.job_params.sd_output_resolution === 768
                        ? "HD"
                        : "Model not found"}
                    </VuiTypography>
                  </>
                ) : (
                  <></>
                )}
              </VuiBox>
            </div>
          </>
        ) : (
          <></>
        )}
        <Box className="mt_2">
          <Button
            onClick={props.handleClose}
            className="default_btn f_small fw_500 c_white br_1px br_icondisabled mr_2"
          >
            Close
          </Button>
          <Button
            onClick={handleSendJobFields}
            className="default_btn f_small fw_500 c_white bg_hivegradient"
          >
            Send to Generate Form
          </Button>
        </Box>
      </VuiBox>
    </Modal>
  );
};

// props.url
// props.parentDiv
const ModelViewer = (props) => {
  const urlRef = useRef(null);
  urlRef.current = props.url;
  const [viewer1, setViewer1] = useState(null);
  const viewer1Ref = useRef(null);
  viewer1Ref.current = viewer1;
  useEffect(() => {
    let parentDiv = document.getElementById("retexture-model-viewer");
    const onModelLoaded = (e) => {
      const viewer = viewer1Ref.current.GetViewer();
      let boundingSphere = viewer.GetBoundingSphere((meshUserData) => {
        return true;
      });
      viewer.AdjustClippingPlanesToSphere(boundingSphere);
      viewer.FitSphereToWindow(boundingSphere, true);
    };

    const viewer = new OV.EmbeddedViewer(parentDiv, {
      camera: new OV.Camera(
        new OV.Coord3D(-1.5, 2.0, 3.0),
        new OV.Coord3D(0.0, 0.0, 0.0),
        new OV.Coord3D(0.0, 1.0, 0.0),
        45.0
      ),
      backgroundColor: new OV.RGBAColor(255, 255, 255, 0),
      defaultColor: new OV.RGBColor(200, 200, 200),
      edgeSettings: new OV.EdgeSettings(false, new OV.RGBColor(0, 0, 0), 1),
      environmentSettings: new OV.EnvironmentSettings(
        [envMap1, envMap2, envMap3, envMap4, envMap5, envMap6],
        false
      ),
      onModelLoaded: onModelLoaded,
    });
    setViewer1(viewer);
    return () => {
      viewer.Destroy();
    };
  }, []);

  useEffect(() => {
    // initialize the viewer with the parent element and some parameters
    console.log("props.url", props.url);
    console.log("viewer1 was changed", viewer1);

    OV.SetExternalLibLocation("../libs");

    // get the parent element of the viewer
    console.log("creating viewer in id: ", "retexture-model-viewer");
    // console.log("myCanvas in AssetModel Useffect", myCanvas);
    // myCanvas.getContext("webgl", { preserveDrawingBuffer: true });
    if (viewer1Ref.current === null || typeof props.url === "undefined" || props.url === null)
      return;

    try {
      viewer1Ref.current.LoadModelFromUrlList([props.url]);
    } catch (error) {
      console.log("error", error);
    }

    // setViewer(viewer1);
  }, [viewer1]);

  return (
    <>
      <div
        id="retexture-model-viewer"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "0px",
          left: "0px",
        }}
      ></div>
    </>
  );
};

export default Inspector;
