/**

 =========================================================
 * Vision UI PRO React - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com/)

 * Design and Coded by Simmmple & Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

 */

/**
 All of the routes for the Vision UI Dashboard PRO React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Sidenav.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */

// Vision UI Dashboard PRO React layouts

import Content from "layouts/pages/content";
import Security from "layouts/applications/security";

import SignInAQ from "layouts/authentication/sign-in/aqCustom";
import Setup from "layouts/authentication/sign-up/aqCustom";

import Contact from "layouts/applications/home/pages/contact";
import AI from "layouts/applications/home/pages/AI";

import ComingSoon from "layouts/pages/comingsoon";

import Organization from "layouts/applications/organization";
import Profile from "layouts/authentication/sign-up/Profile";
import FailedLogin from "layouts/authentication/sign-in/FailedLogin";
import Viewer from "layouts/applications/viewer/index.js";
import Generate from "layouts/applications/generate/index";

// React icons
import { IoCube, IoPeople } from "react-icons/io5";

import Home from "layouts/applications/home/index.jsx";
import ViewerError from "layouts/applications/error-pages/viewer.js";
import Features from "layouts/applications/home/pages/Features/index";
import Pricing from "layouts/applications/pricing/index";
import Subscription from "layouts/applications/pricing/Subscription/index";
import Blog from "layouts/applications/home/pages/blog/index";

const routes = [
  {
    type: "collapse",
    name: "Manage",
    key: "manage",
    icon: <IoCube size="15px" color="inherit" />,
    collapse: [
      { name: "Content", key: "content", route: "/manage/content", component: Content },
      // {
      //   name: "Data",
      //   key: "data",
      //   route: "/manage/data",
      //   component: ComingSoon,
      // },
      // { name: "Pricing", key: "pricing", route: "/manage/pricing", component: PricingPage },
    ],
  },
  {
    type: "collapse",
    name: "Organization",
    key: "org",
    icon: <IoPeople size="15px" color="inherit" />,
    collapse: [
      {
        name: "Overview",
        key: "overview",
        route: "/org/overview",
        component: Organization,
      },

      { name: "API Keys", key: "apikeys", route: "/org/apikeys", component: Security },
    ],
  },
  // {
  //   type: "collapse",
  //   name: "Analyze",
  //   key: "analyze",
  //   icon: <IoBarChart size="15px" color="inherit" />,
  //   collapse: [
  //     {
  //       name: "Insights",
  //       key: "insights",
  //       icon: <IoBarChart size="15px" color="inherit" />,

  //       route: "/analyze/insights",
  //       component: ComingSoon,
  //     },
  //     {
  //       name: "Users",
  //       key: "users",
  //       route: "/analyze/users",
  //       component: ComingSoon,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Generate",
  //   key: "generate",
  //   icon: <IoDocuments size="15px" color="inherit" />,
  //   collapse: [
  //     {
  //       name: "Collections",
  //       key: "collections",
  //       route: "/generate/Collections",
  //       component: Templates,
  //     },
  //     {
  //       name: "Mint NFTs",
  //       key: "mint-nfts",
  //       route: "/generate/mint-nfts",
  //       component: Minting,
  //     },
  //     {
  //       name: "Manage Traits",
  //       key: "manage-traits",
  //       route: "/generate/manage-traits",
  //       component: ManageTraits,
  //     },
  //   ],
  // },

  {
    type: "title",
    key: "sign-in",
    route: "/sign-in",
    component: SignInAQ,
  },
  {
    type: "title",
    key: "setup",
    route: "/setup",
    component: Setup,
  },
  {
    type: "title",
    key: "products",
    route: "/products",
    component: Home,
  },
  {
    type: "title",
    key: "profile",
    route: "/profile",
    component: Profile,
  },
  {
    type: "title",
    key: "loginfailure",
    route: "/loginfailure",
    component: FailedLogin,
  },
  {
    type: "title",
    key: "viewer",
    route: "/s",
    component: Viewer,
  },
  {
    type: "title",
    key: "viewer-error",
    route: "/s/error",
    component: ViewerError,
  },
  {
    type: "title",
    key: "home",
    route: "/",
    component: Home,
  },
  {
    type: "title",
    key: "generate",
    route: "/generate",
    component: Generate,
  },

  {
    type: "title",
    key: "contact",
    route: "/contact",
    component: Contact,
  },

  // {
  //   type: "title",
  //   key: "features",
  //   route: "/features",
  //   component: Features,
  // },
];

export default routes;
