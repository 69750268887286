/**

 =========================================================
 * Vision UI PRO React - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com/)

 * Design and Coded by Simmmple & Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

 */

import React, { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import Grid from "@mui/material/Grid";
import EastIcon from "@mui/icons-material/East";
// Icons
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";

import VuiSnackbar from "components/VuiSnackbar/index";

// Vision UI Dashboard components
import GradientBorder from "examples/GradientBorder";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import Button from "@mui/material/Button";
import { useStytch } from "@stytch/stytch-react";
import GoogleButton from "assets/images/googlewhite.png";
import colors from "assets/theme/base/colors";

import { useNavigate } from "react-router-dom";

// Images
import bgBasic from "assets/images/background-basic-auth.png";
import Radium, { Style } from "radium";
import { logoutUser } from "layouts/authentication/AuthFunctions";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import amplitude from "amplitude-js";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";
import DashboardNav from "../../../dashboards/default/components/DashboardNav";
import Sidenav from "examples/Sidenav";
import { Box, Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import GradientIcon from "components/Custom/GradientIcon/index";
import { parseDateUTCShort } from "util/UtilFunctions";
import UnsubscribeModal from "../../../applications/pricing/UnsubscribeModal/index";
import { useVisionUIController } from "context";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { HtmlTooltip } from "layouts/dashboards/default/components/CustomTooltip/index";

function Profile() {
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const fileInput = React.useRef(null);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [loggedIn, setLoggedIn] = useState(false);
  const stytchClient = useStytch();

  const [iconSrc, setIconSrc] = useState("");
  const [iconFile, setIconFile] = useState(null);

  const [snackbar, setSnackbar] = useState(false);
  const [controller, dispatch] = useVisionUIController();
  const { activeOrg, credits, subscription } = controller;

  const toggleSnackbar = () => {
    setSnackbar(!snackbar);
  };



  const setupClicked = () => {
    logAuthorizedWombatEvent("profileChangesApplied", {});

    const username =
      document.getElementById("userInput").value === ""
        ? ""
        : document.getElementById("userInput").value;
    const firstname =
      document.getElementById("firstNameInput").value === ""
        ? ""
        : document.getElementById("firstNameInput").value;
    const lastname =
      document.getElementById("lastNameInput").value === ""
        ? ""
        : document.getElementById("lastNameInput").value;
    const job =
      document.getElementById("jobInput").value === ""
        ? ""
        : document.getElementById("jobInput").value;

    //console.log("username", username);
    //console.log("firstname", firstname);
    //console.log("lastname", lastname);
    //console.log("job", job);
    //console.log("iconSrc", iconSrc);
    //console.log("iconFile", iconFile);

    if (username !== "") {
      fetch(process.env.REACT_APP_BACKEND_URL + "/developer/register/", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          firstName: firstname,
          lastName: lastname,
          jobTitle: job,
        }),
      }).then((response) => {
        //console.log("login response", response);
        if (response.status === 200) {
          if (iconFile !== null) {
            const response_thumbnail_upload = fetch(
              `${process.env.REACT_APP_BACKEND_URL}/developer/uploadProfilePicture/`,
              {
                body: iconFile,
                credentials: "include",
                method: "POST",
              }
            ).then((response) => {
              //console.log("thumbnail response", response);
              return response;
            });
          }
          //   fetchDevInfo();
          toggleSnackbar();
        } else {
          logoutUser(navigate);
        }
      });
    } else {
      alert("Username is a required field");
    }
  };

  const formSubmitted = (e) => {
    e.preventDefault();
    //console.log("e", e);
    setupClicked();
  };

  const uploadIcon = () => {
    fileInput.current.click();
  };

  const handleChange = (e) => {
    //console.log("e", e.target.files[0]);
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setIconFile(file);
    setIconSrc(url);
  };

  useEffect(() => {
    fetchDevInfo();
  }, []);

  const fetchDevInfo = () => {
    fetch(process.env.REACT_APP_BACKEND_URL + "/developer/developerInfo/", {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          alert("Error getting developer info:", response.statusText);
        }
      })
      .then((data) => {
        //console.log("data", data);
        document.getElementById("userInput").value = data.username;
        document.getElementById("firstNameInput").value = data.firstName;
        document.getElementById("lastNameInput").value = data.lastName;
        document.getElementById("jobInput").value = data.jobTitle;
        setIconSrc(data.profileUrl);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNav />
      <Sidenav />

      <VuiBox sx={{ marginTop: "100px" }}>
        <Grid container justifyContent="center">
          <form onSubmit={formSubmitted}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <VuiBox maxWidth="100%" minWidth="500px">
                <VuiTypography
                  color="white"
                  fontWeight="bold"
                  textAlign="center"
                  mb="15px"
                  sx={({ typography: { size } }) => ({
                    fontSize: size.lg,
                  })}
                >
                  Edit Profile
                </VuiTypography>
              </VuiBox>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <VuiBox
                maxWidth="100%"
                minWidth="700px"
                margin="0 auto"
                borderRadius="5px"
                p="25px"
                sx={({ palette: { secondary } }) => ({
                  backgroundColor: colors.background.secondary,
                  // border: "1px solid #696969",
                })}
              >
                <VuiBox mb={2}>
                  <VuiBox mb={1} ml={0.5}>
                    <VuiTypography
                      component="label"
                      variant="button"
                      color="white"
                      fontWeight="medium"
                    >
                      Username *
                    </VuiTypography>
                  </VuiBox>
                  <div className="cursor_na">
                    <VuiInput
                      id="userInput"
                      // inputProps={{ style: { backgroundColor: "#fff" } }}
                      style={{
                        backgroundColor: "rgba(10,10,10,1) !important",
                        "&:disabled": {
                          backgroundColor: "#fff !important",
                        },
                        cursor: "not-allowed",
                      }}
                      className="bg_dark"
                      sx={({ typography: { size } }) => ({
                        fontSize: size.sm,
                        border: "1px solid #696969",
                        // background: "#fff",
                      })}
                      disabled={true}
                    />
                  </div>
                  <VuiBox mb={0.5} ml={0.5} mt={1}>
                    <VuiTypography
                      component="label"
                      variant="button"
                      color="white"
                      fontWeight="medium"
                    >
                      Profile photo
                    </VuiTypography>
                  </VuiBox>
                  <Grid container>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      <VuiBox
                        mb={1}
                        ml={0.5}
                        mt={1}
                        mr={0.5}
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          width: "65px",
                          height: "65px",
                          background: colors.background.main,
                        }}
                      >
                        <VuiBox
                          sx={{
                            width: "100%",
                            height: "100%",
                            background: iconSrc === "" ? "rgba(80,80,80,1)" : `url(${iconSrc})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                          }}
                        ></VuiBox>
                      </VuiBox>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <VuiTypography
                        component="label"
                        variant="button"
                        color="white"
                        fontWeight="medium"
                        ml={0.5}
                      >
                        Icon <br />
                      </VuiTypography>
                      <VuiTypography
                        component="label"
                        variant="button"
                        fontWeight="medium"
                        ml={0.5}
                        lineHeight="1.5"
                        sx={{ color: "#999", fontSize: "12px" }}
                        display="block"
                      >
                        We recommend a transparent PNG at least 96px x 96px and less than 1MB
                      </VuiTypography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      <VuiBox mb={1} ml={0.5}>
                        <Button
                          className="default_btn br_1px br_quaternaryfocus c_quaternaryfocus c_white f_small fw_500"
                          sx={{ textAlign: "center", marginTop: "15px" }}
                          onClick={uploadIcon}
                        >
                          Upload Image
                        </Button>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          ref={fileInput}
                          style={{ display: "none" }}
                          onChange={handleChange}
                        />
                      </VuiBox>
                    </Grid>
                  </Grid>
                  <hr
                    style={{
                      marginTop: "15px",
                      marginBottom: "15px",
                      borderTop: "1px solid #696969",
                      borderBottom: "none",
                      borderRight: "none",
                    }}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <VuiBox mb={0} ml={0.5}>
                        <VuiBox mb={1}>
                          <VuiTypography
                            component="label"
                            variant="button"
                            color="white"
                            fontWeight="medium"
                          >
                            First Name
                          </VuiTypography>
                        </VuiBox>

                        <VuiInput
                          id="firstNameInput"
                          // inputProps={{ style: { backgroundColor: "#fff" } }}
                          style={{
                            backgroundColor: "rgba(80,80,80,1)",
                          }}
                          sx={({ typography: { size } }) => ({
                            fontSize: size.sm,
                            border: "1px solid #696969",
                            // background: "#fff",
                          })}
                        />
                      </VuiBox>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <VuiBox mb={0} ml={0.5}>
                        <VuiBox mb={1} ml={0.5}>
                          <VuiTypography
                            component="label"
                            variant="button"
                            color="white"
                            fontWeight="medium"
                          >
                            Last Name
                          </VuiTypography>
                        </VuiBox>

                        <VuiInput
                          id="lastNameInput"
                          // inputProps={{ style: { backgroundColor: "#fff" } }}
                          style={{
                            backgroundColor: "rgba(80,80,80,1)",
                          }}
                          sx={({ typography: { size } }) => ({
                            fontSize: size.sm,
                            border: "1px solid #696969",
                            // background: "#fff",
                          })}
                        />
                      </VuiBox>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <VuiBox mb={1} ml={0.5}>
                        <VuiBox mb={1} ml={0.5}>
                          <VuiTypography
                            component="label"
                            variant="button"
                            color="white"
                            fontWeight="medium"
                          >
                            Job Title
                          </VuiTypography>
                        </VuiBox>

                        <VuiInput
                          id="jobInput"
                          // inputProps={{ style: { backgroundColor: "#fff" } }}
                          style={{
                            backgroundColor: "rgba(80,80,80,1)",
                          }}
                          sx={({ typography: { size } }) => ({
                            fontSize: size.sm,
                            border: "1px solid #696969",
                            // background: "#fff",
                          })}
                        />
                      </VuiBox>
                    </Grid>
                  </Grid>
                </VuiBox>
                <Box className="center_column w100">
                  {" "}
                  <Button
                    type="submit"
                    color="info"
                    className="default_btn bg_quaternaryfocusdark c_white f_small fw_500"
                    sx={{ margin: "auto !important", textAlign: "center" }}
                  >
                    Apply Changes
                  </Button>
                </Box>
              </VuiBox>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <VuiBox mt={2} mb={1} margin="20px auto" width="100%" sx={{ textAlign: "center" }}>
                <p></p>
              </VuiBox>
            </Grid>
          </form>
        </Grid>
      </VuiBox>
      <VuiSnackbar
        color="success"
        title="Polyhive"
        content="Profile updated successfully"
        open={snackbar}
        onClose={() => setSnackbar(false)}
        autoHideDuration={3000}
      />

      {/* </GradientBorder> */}
    </DashboardLayout>
  );
}

export default Profile;
