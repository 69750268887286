import { useVisionUIController } from "context";
export function getFileType(fileName) {
  // to lowercase
  fileName = fileName.toLowerCase();
  return fileName.substring(fileName.lastIndexOf(".") + 1).split(" ")[0];
}

export function getFileTypeFromS3Url(url) {
  const lastDotIndex = url.lastIndexOf(".");
  const lastQuestionMarkIndex = url.lastIndexOf("?");
  const fileType = url.substring(lastDotIndex + 1, lastQuestionMarkIndex);
  return fileType;
}

export function getFileName(fileName) {
  return fileName.substring(0, fileName.lastIndexOf("."));
}

export function convertBytesToGB(bytes) {
  return (bytes / 1000 / 1000 / 1000).toFixed(2);
}

export function convertBytesToMB(bytes) {
  return (bytes / 1000 / 1000).toFixed(2);
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function parseDateUTC(dateString) {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  };
  return date.toLocaleString("en-US", options);
}

export function parseDateUTCShort(dateString) {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return date.toLocaleString("en-US", options);
}

// function to get the difference between two Date objects
// if the difference is less than 1 month, return the difference in days
// if the difference is less than 1 day, return the difference in hours
// if the difference is less than 1 hour, return the difference in minutes and seconds
// if the difference is less than 1 minute, return the difference in seconds
export function getTimeDifference(date1, date2) {
  const time_delta = date2 - date1;
  // console.log("time delta: ", time_delta);

  const one_day_ms = 24 * 60 * 60 * 1000;
  const one_hour_ms = 60 * 60 * 1000;
  const one_minute_ms = 60 * 1000;

  if (time_delta >= 30 * one_day_ms) {
    return `${Math.floor(time_delta / (30 * one_day_ms))} months`;
  } else if (time_delta >= one_day_ms) {
    return `${Math.floor(time_delta / one_day_ms)} days`;
  } else if (time_delta >= one_hour_ms) {
    const hours = Math.floor(time_delta / one_hour_ms);
    const minutes = Math.floor((time_delta % one_hour_ms) / one_minute_ms);
    return `${hours} hour${hours > 1 ? "s" : ""}, ${minutes} minute${minutes > 1 ? "s" : ""}`;
  } else if (time_delta >= one_minute_ms) {
    const minutes = Math.floor(time_delta / one_minute_ms);
    const seconds = Math.floor((time_delta % one_minute_ms) / 1000);
    return `${minutes} minute${minutes > 1 ? "s" : ""}, ${seconds} second${seconds > 1 ? "s" : ""}`;
  } else if (time_delta < 1000) {
    return "0 seconds";
  } else {
    return `${Math.floor(time_delta / 1000)} seconds`;
  }
}

// getTimeDifference except it returns the difference in seconds as an integer
export function getTimeDifferenceInSeconds(date1, date2) {
  const time_delta = date2 - date1;
  console.log("getTimeDifference: ", time_delta, date1, date2);
  return Math.floor(time_delta / 1000);
}

export function generateUUID() {
  // Public Domain/MIT
  let d = new Date().getTime(); //Timestamp
  let d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export async function stripeCheckout(key, activeOrg) {
  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/subscription/checkout`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
      body: JSON.stringify({ lookup_key: key }),
    });
    const data = await res.json();
    console.log("stripe checkout data: ", data);
    window.location.href = data.stripe_url;
  } catch (err) {
    console.log("error redirecting to stripe ", err);
  }
}

export function getCreditsForDownload(job, permissions) {
  if (job.job_params.sd_output_resolution === 512) {
    return permissions["512_model_download"].credit_cost;
  } else if (job.job_params.sd_output_resolution === 768) {
    return permissions["768_model_download"].credit_cost;
  } else {
    return permissions["512_model_download"].credit_cost;
  }
}

export function objectArrayConversion(object) {
  const arrayOfObjects = [];

  // Assuming all arrays are of the same length
  const arrayLength = Object.values(object)[0].length;

  for (let i = 0; i < arrayLength; i++) {
    const obj = {};
    for (const [key, value] of Object.entries(object)) {
      obj[key] = value[i];
    }
    arrayOfObjects.push(obj);
  }

  return arrayOfObjects;
}

export async function hashSHA256(str) {
  const buffer = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(str));
  const hexCodes = [];
  const view = new DataView(buffer);
  for (let i = 0; i < view.byteLength; i += 4) {
    const value = view.getUint32(i);
    const stringValue = value.toString(16);
    const padding = "00000000";
    const paddedValue = (padding + stringValue).slice(-padding.length);
    hexCodes.push(paddedValue);
  }
  return hexCodes.join("");
}

