import { Box, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import Button from "@mui/material/Button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import TerrainOutlinedIcon from "@mui/icons-material/TerrainOutlined";
import { useEffect, useState, useRef } from "react";
import { getTimeDifferenceInSeconds, getTimeDifference } from "util/UtilFunctions";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import { HtmlTooltip } from "layouts/dashboards/default/components/CustomTooltip/index";
import GrassIcon from "@mui/icons-material/Grass";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";

const it_steps = 25;

const Toolbar = (props) => {
  const runningJobsRef = useRef(null);
  runningJobsRef.current = props.runningJobs;
  useEffect(() => {}, [props.runningJobs]);

  const calculateRemainingTimeBasedOnStepAndStartedTime = (job) => {
    if (job) {
      const step = job.step;
      console.log("Job startedTime: ", job);
      const timeStarted = new Date(job.startedTime);
      console.log("Time started: ", timeStarted);
      const timeElapsed = getTimeDifferenceInSeconds(timeStarted, new Date());
      console.log("Time elapsed: ", timeElapsed);
      const timePerStep = timeElapsed / step;
      const remainingTime = timePerStep * (it_steps - step);
      console.log("Remaining time: ", remainingTime);
      // Return time in minutes:seconds for example 2:30
      const minutes = Math.floor(remainingTime / 60);
      // make sure seconds is always 2 digits
      const seconds = Math.floor(remainingTime % 60)
        .toString()
        .padStart(2, "0");
      return `${minutes}:${seconds}`;
    }
  };

  return (
    <>
      <Box
        className={
          props.jobInspectorOpen ? "gen_toolbar gen_toolbar_inspector_open" : "gen_toolbar"
        }
      >
        <Box className="header_flex">
          <ViewInArOutlinedIcon className="toolbar_icon c_white mr_1" />
          <Typography variant="h6" className="c_white f_small fw_500">
            {runningJobsRef.current.length > 0 && runningJobsRef.current.length < 2
              ? props.runningJobs[0].status === "Queued"
                ? "Generating... Queued"
                : props.runningJobs[0].status === "In progress"
                ? props.runningJobs[0].step !== null && props.runningJobs[0].step < it_steps
                  ? `Generating... Step ${
                      props.runningJobs[0].step + 1
                    }/30, (${calculateRemainingTimeBasedOnStepAndStartedTime(
                      runningJobsRef.current[0]
                    )})`
                  : props.runningJobs[0].step === null
                  ? props.runningJobs[0].jobName === "mesh"
                    ? `Running Mesh Job...`
                    : `Initializing texture job`
                  : `Exporting textured mesh...`
                : "Generating..."
              : props.runningJobs.length > 1
              ? props.currentJob.status === "Queued"
                ? "Generating... Queued"
                : props.currentJob.status === "In progress"
                ? `Generating... ${getTimeDifference(
                    props.currentTime,
                    new Date(props.currentJob.eta)
                  )} remaining`
                : "Generating Multiple, select a running job to view progress"
              : ""}
          </Typography>
        </Box>
        <Box className="gen_toolbar_container header_flex">
          <HtmlTooltip title="Toggle Wireframe" placement="top">
            <Box
              className={
                "center_column mr_1 ml_1 toolbar_box" +
                (props.wireframe ? " bg_darkbg stroke_darkbg" : " bg_darkest")
              }
            >
              <TerrainOutlinedIcon
                className={"toolbar_icon" + (props.wireframe ? " c_white" : " c_iconenabled")}
                onClick={() => {
                  logAuthorizedWombatEvent("wireframeClickedFromGeneratePage", {
                    job: props.currentJob,
                  });
                  props.handleWireframe();
                }}
              />
            </Box>
          </HtmlTooltip>
          <HtmlTooltip title="Flip Vertical" placement="top">
            <Box className={"center_column mr_1 ml_1 toolbar_box bg_darkest"}>
              <TerrainOutlinedIcon
                className={"toolbar_icon c_iconenabled"}
                onClick={() => {
                  logAuthorizedWombatEvent("verticalFlipClickedFromGeneratePage", {
                    job: props.currentJob,
                  });
                  props.handleVerticalFlip();
                }}
              />
            </Box>
          </HtmlTooltip>
          <HtmlTooltip title="Change Rotation Axis" placement="top">
            <Box className={"center_column mr_1 ml_1 toolbar_box bg_darkest"}>
              <TerrainOutlinedIcon
                className={"toolbar_icon c_iconenabled"}
                onClick={() => {
                  logAuthorizedWombatEvent("rotationAxisClickedFromGeneratePage", {
                    job: props.currentJob,
                  });
                  props.handleRotationalAxis();
                }}
              />
            </Box>
          </HtmlTooltip>
          <HtmlTooltip title="Toggle Materials" placement="top">
            <Box
              className={
                "center_column mr_2 ml_1 toolbar_box" +
                (props.materialMaps ? " bg_darkbg stroke_lightblack" : " bg_darkest")
              }
            >
              <LightModeOutlinedIcon
                className={"toolbar_icon" + (props.materialMaps ? " c_warning" : " c_iconenabled")}
                onClick={() => {
                  logAuthorizedWombatEvent("lightingClickedFromGeneratePage", {
                    job: props.currentJob,
                  });
                  props.handleMaterialMaps();
                }}
              />
            </Box>
          </HtmlTooltip>
          {/* <HtmlTooltip title="Toggle Job Inspector" placement="top">
            <Box
              className={
                "center_column mr_2 ml_1 toolbar_box" +
                (props.jobInspectorOpen ? " bg_darkbg stroke_darkbg" : " bg_darkest")
              }
            >
              <VisibilityOutlinedIcon
                className={
                  "toolbar_icon" + (props.jobInspectorOpen ? " c_white" : " c_iconenabled")
                }
                onClick={() => {
                  logAuthorizedWombatEvent("jobInspectorClickedFromGeneratePage", {
                    job: props.currentJob,
                  });
                  props.toggleJobInspector();
                }}
              />
            </Box>
          </HtmlTooltip> */}
          <Box className="toolbar_vr"></Box>
          <HtmlTooltip title="Share" placement="top">
            <Box
              className={
                "center_column mr_1 ml_2 toolbar_box" +
                (Object.keys(props.currentJob).length === 0 ||
                !props.currentJob.status ||
                props.currentJob.status === "Queued" ||
                props.currentJob.status === "In progress" ||
                props.currentJob.status === "Failed"
                  ? " bg_lightbg "
                  : " bg_focusbg")
              }
              onClick={() => {
                if (
                  Object.keys(props.currentJob).length === 0 ||
                  !props.currentJob.status ||
                  props.currentJob.status === "Queued" ||
                  props.currentJob.status === "In progress" ||
                  props.currentJob.status === "Failed"
                ) {
                  props.startSnackbar({ type: "error", message: "Select an asset to share" });
                  return;
                }
                logAuthorizedWombatEvent("shareClickedFromGeneratePage", {
                  job: props.currentJob,
                });
                props.handleShare();
              }}
            >
              <ShareOutlinedIcon
                className={
                  "toolbar_icon" +
                  (Object.keys(props.currentJob).length === 0 ||
                  !props.currentJob.status ||
                  props.currentJob.status === "Queued" ||
                  props.currentJob.status === "In progress" ||
                  props.currentJob.status === "Failed"
                    ? " stroke_lightbg c_icondisabled"
                    : " toolbar_action_icon c_white")
                }
              />
            </Box>
          </HtmlTooltip>
          <HtmlTooltip title="Download" placement="top">
            <Box
              className={
                "center_column ml_2 toolbar_box" +
                (Object.keys(props.currentJob).length === 0 ||
                !props.currentJob.status ||
                props.currentJob.status === "Queued" ||
                props.currentJob.status === "In progress" ||
                props.currentJob.status === "Failed"
                  ? " bg_lightbg "
                  : " bg_focusbg")
              }
              onClick={() => {
                if (
                  Object.keys(props.currentJob).length === 0 ||
                  !props.currentJob.status ||
                  props.currentJob.status === "Queued" ||
                  props.currentJob.status === "In progress" ||
                  props.currentJob.status === "Failed"
                ) {
                  props.startSnackbar({ type: "error", message: "Select an asset to download" });
                  return;
                }
                logAuthorizedWombatEvent("downloadClickedFromGeneratePage", {
                  job: props.currentJob,
                });
                props.handleDownload();
              }}
            >
              <FileDownloadOutlinedIcon
                className={
                  "toolbar_icon" +
                  (Object.keys(props.currentJob).length === 0 ||
                  !props.currentJob.status ||
                  props.currentJob.status === "Queued" ||
                  props.currentJob.status === "In progress" ||
                  props.currentJob.status === "Failed"
                    ? " stroke_lightbg c_icondisabled"
                    : " toolbar_action_icon c_white")
                }
              />
            </Box>
          </HtmlTooltip>
        </Box>
      </Box>
    </>
  );
};

export default Toolbar;
