import { useNavigate } from "react-router-dom";

export function logoutUser(navigate) {
  const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/developer/onLogoutClicked`, {
    method: "POST",
    credentials: "include",
  })
    .then((response) => {
      if (response.status === 200) {
      }
    })
    .catch((error) => {
      console.log("ERROR AT /onLogoutClicked", error);
    });
  navigate("/sign-in", { replace: true });
}
