import colors from "assets/theme/base/colors";
import { Box, Typography, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import PolyhiveLogoWhite from "assets/icons/polyhivelogowhite.png";
import { useNavigate } from "react-router-dom";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import GradientIcon from "components/Custom/GradientIcon/index";
import CreditIcon from "assets/icons/crediticon.png";
import { useState, useEffect } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
const InsufficientCreditsModal = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal_container_small">
        <div className="" style={{ textAlign: "left" }}>
          <Box className="align_center_row" mb={2}>
            <ErrorOutlineIcon className="c_error lightbulb_icon" />
            <Typography variant="h6" sx={{ color: "#fff" }}>
              Insufficient Credits
            </Typography>
          </Box>
          <Box className="align_center_row" mb={2}>
            <Typography variant="body2" className="preset_prompt_text" sx={{ color: "#fff" }}>
              You do not have enough credits to texture this model.
            </Typography>
          </Box>

          <Box className="btn_container">
            <Button
              onClick={props.handleClose}
              variant="text"
              className="default_btn c_white f_medium fw_500"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // open a new tab with the /buy page
                window.open("/buy", "_blank");
              }}
              variant="text"
              className="default_btn bg_hivegradient c_white f_mid fw_500"
              startIcon={<FileDownloadOutlinedIcon className="icon_medium c_white" />}
            >
              Buy More Credits
            </Button>
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default InsufficientCreditsModal;
