import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import colors from "assets/theme/base/colors";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

const modalStyle = {
  width: "300px",
  height: "100%",
  position: "absolute",
  alignItems: "center",
  justifyContent: "center",
};
const ChangeVersionModal = (props) => {
  const handleChange = () => {
    props.handleChange();
    props.handleClose();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ modalStyle }}
    >
      <VuiBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: colors.background.main,
          // border: "2px solid #000",
          boxShadow: 24,
          justifyContent: "center",
          p: 3,
          borderRadius: "15px",
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="" style={{ textAlign: "left" }}>
          <VuiTypography variant="h6" sx={{ color: "#fff", marginBottom: "10px" }}>
            Are you sure you want to change the current version?
          </VuiTypography>
          <VuiTypography variant="body2" sx={{ color: colors.warning.main, marginBottom: "12px" }}>
            Warning: Updating the current version of this asset will update the asset in your game
            if you are using one of our SDKs. Proceed with caution and make sure to thoroughly test
            the updated asset before integrating it into your project.
          </VuiTypography>
          <Button
            onClick={props.handleClose}
            variant="outlined"
            sx={{
              outline: "none",
              border: "1.5px solid " + colors.icon.disabled,
              color: colors.icon.enabled,
              borderRadius: "7px",
              height: "15px",
              // padding: "0px",
              margin: "0px",
              "&:hover": { border: "1.5px solid " + colors.icon.disabled },
            }}
          >
            Cancel
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            // onClick={props.handleCreateFolder}
            // type="submit"
            variant="contained"
            onClick={handleChange}
            style={{
              color: "#000",
              backgroundColor: colors.success.main,
              borderRadius: "7px",
            }}
          >
            Confirm
          </Button>
        </div>
      </VuiBox>
    </Modal>
  );
};

export default ChangeVersionModal;
