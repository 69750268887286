/**

 =========================================================
 * AQUEDUCT CONTENT PAGE
 =========================================================
 * Designed and Coded by Rohan Iyer
 =========================================================

 */

import Grid from "@mui/material/Grid";
// Data
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Icon from "@mui/material/Icon";
import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import Modal from "@mui/material/Modal";
import VRMLoader from "../VRMLoader/index";
import Button from "@mui/material/Button";
import VRMScreenshot from "../VRMScreenshot/index";
import useAsyncQueue from "use-async-queue";
import { FileUploader } from "react-drag-drop-files";
import { useState } from "react";
import VuiSnackbar from "components/VuiSnackbar";
import { ProgressBar } from "react-loader-spinner";
import Cookies from "universal-cookie";
import { useVisionUIController, setCurrentlyUploading } from "context";

import { logoutUser } from "layouts/authentication/AuthFunctions";
import { useNavigate, useLocation } from "react-router-dom";
import amplitude from "amplitude-js";
import UploadIcon from "@mui/icons-material/Upload";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Tooltip from "@mui/material/Tooltip";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import * as THREE from "three";
import Draggable from "react-draggable";
import { getFileType } from "util/UtilFunctions";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";

const dropzoneStyle = {
  color: "blue",
  height: "100%",
  padding: "1.5em",
};

const modalStyle = {
  width: "400px",
  height: "100%",
  position: "absolute",
  alignItems: "center",
  justifyContent: "center",
};

const buttonStyle = {
  color: "white",
  backgroundColor: colors.success.main,
  textAlign: "center",
  width: "100px",
  height: "30px",
  borderRadius: "15px",
  margin: "0 auto",
  fontSize: "12px",
  position: "absolute",
  left: "0px",
  right: "0px",
  bottom: "15px",

  marginBottom: "10px",
  "&:disabled": {
    backgroundColor: "#fff",
  },
};

const paginationStyle = {
  justifyContent: "center !important",
};

const iconStyle = {
  margin: "auto",
};

const fileTypes = ["jpeg", "png", "vrm", "glb", "gltf", "jpg", "fbx", "obj"];

const Spinner = () => {
  return (
    <ProgressBar
      height="80"
      width="80"
      ariaLabel="progress-bar-loading"
      wrapperStyle={{}}
      wrapperClass="progress-bar-wrapper"
      borderColor={colors.success.main}
      barColor={colors.success.main}
    />
  );
};

let SCREENSHOT_BATCH_SIZE_PLUS_ONE = 15;
let FILE_SIZE_LIMIT_MB = 100;
let abortController = new AbortController();

const UploadModal = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const { gradients } = colors;
  const { cardContent } = gradients;

  const [open, setOpen] = React.useState(true);
  const openRef = React.useRef();
  openRef.current = open;

  const [loading, setLoading] = React.useState(false); // Loading button state
  const [pageNumber, setPageNumber] = React.useState(false);

  // snackbar states
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");

  const [contractAddress, setContractAddress] = React.useState("");
  const [tokenId, setTokenId] = React.useState("");
  // const [SCREENSHOT_BATCH_SIZE_PLUS_ONE, setSCREENSHOT_BATCH_SIZE_PLUS_ONE] = React.useState(3);

  const [filePath, setFilePath] = React.useState("");

  const [allQueuedUploads, setAllQueuedUploads] = React.useState([]);

  const [uploads, setUploads] = React.useState([]);

  const [totalUploads, setTotalUploads] = React.useState(0);
  const totalUploadsRef = React.useRef();
  totalUploadsRef.current = totalUploads;

  const [uploadsComplete, setUploadsComplete] = React.useState(0);
  const uploadsCompleteRef = React.useRef();
  uploadsCompleteRef.current = uploadsComplete;

  const [uploadsSucceeded, setUploadsSucceeded] = React.useState(0);
  const [uploadsFailed, setUploadsFailed] = React.useState(0);
  const [doneIndices, setDoneIndices] = React.useState([]);
  const [failedUploads, setFailedUploads] = React.useState([]);

  const [uploadsProcessing, setUploadsProcessing] = React.useState(0);
  const uploadsProcessingRef = React.useRef();
  uploadsProcessingRef.current = uploadsProcessing;

  const [currentBatch, setCurrentBatch] = React.useState([]);
  const [currentBatchNumber, setCurrentBatchNumber] = React.useState(0);
  const [totalBatches, setTotalBatches] = React.useState(0);
  const [currentBatchIndex, setCurrentBatchIndex] = React.useState(0);

  const [errorMessage, setErrorMessage] = React.useState("");

  const [controller, dispatch] = useVisionUIController();
  const { activeOrg, currentlyUploading } = controller;

  const [errorTooltipOpen, setErrorTooltipOpen] = React.useState(false);
  const cookies = new Cookies();
  let rgbeLoader = new RGBELoader();
  const [texture, setTexture] = useState(null);

  const [uploadCancelled, setUploadCancelled] = React.useState(false);
  const uploadCancelledRef = React.useRef();
  uploadCancelledRef.current = uploadCancelled;

  const [openTab, setOpenTab] = React.useState(false);

  const [folders, setFolders] = React.useState([]);
  const folderRef = React.useRef();
  folderRef.current = folders;
  const { pathname } = useLocation();

  useImperativeHandle(ref, () => ({
    uploadFiles(files) {
      setOpen(true);
      setOpenTab(true);
      dropzoneUpload(files);
    },
    getOpen() {
      return open;
    },
    setOpen() {
      setOpen(true);
    },
  }));

  useEffect(() => {
    rgbeLoader.setPath("/").load("royal_esplanade_1k.hdr", (tex) => {
      tex.mapping = THREE.EquirectangularReflectionMapping;
      setTexture(tex);
    });
  }, []);

  useEffect(() => {
    console.log("directing to, ", pathname);
    if (pathname !== "/manage/content") {
      handleCancel();
    }
  }, [pathname]);

  useEffect(() => {
    //console.log("UPLOADSCOMPLETE USEEFFECT: ", uploadsComplete);

    if (uploadsComplete % SCREENSHOT_BATCH_SIZE_PLUS_ONE === 0 && uploadsComplete > 0) {
      setCurrentBatchNumber(currentBatchNumber + 1);
      console.log("current batch number: ", currentBatchNumber + 1);
    }

    if (uploadsComplete === totalUploads) {
      console.log("UPLOADSDATA: done uploading");
      setFolders([]);
      setCurrentlyUploading(dispatch, false);
      setCurrentBatchNumber(0);
      setTotalBatches(0);
      setCurrentBatch([]);
      setAllQueuedUploads([]);
      setCurrentBatchIndex(0);
    } else {
      setCurrentlyUploading(dispatch, true);
    }
  }, [uploadsComplete]);

  useEffect(() => {
    if (currentlyUploading === false) {
      setFolders([]);
      // handleCancel();
    }
  }, [currentlyUploading]);

  useEffect(() => {
    console.log("UPLOADSDATA: next batch: ", currentBatchNumber);
    console.log("UPLOADSDATA: total batches: ", totalBatches);
    //console.log("");
    if (currentBatchNumber === totalBatches) {
      //console.log("UPLOADSDATA: done uploading");
      // handleClose();
      return;
    } else {
      console.log("UPLOADSDATA: uploading next batch");
      setCurrentBatch([]);
      const start = currentBatchIndex;

      const end = currentBatchIndex + SCREENSHOT_BATCH_SIZE_PLUS_ONE;
      const newend = end > allQueuedUploads.length ? allQueuedUploads.length : end;

      console.log("UPLOADSDATA: new batch starts at: " + start + " and ends at: " + newend);
      console.log("UPLOADSDATA: all queued uploads for batch: ", allQueuedUploads);

      if (start === newend) {
        const batch = allQueuedUploads.slice(start);
        console.log("UPLOADSDATA: new batch: ", batch);
        setCurrentBatch(allQueuedUploads.slice(start));
      } else {
        setCurrentBatchIndex(newend);
        const batch = allQueuedUploads.slice(start, newend);
        console.log("UPLOADSDATA: uploads new batch: ", batch);
        // replace each element in the currentbatch with the new batch without changing the reference

        for (let i = 0; i < batch.length; i++) {
          currentBatch[i] = batch[i];
        }

        setCurrentBatch([...batch]);
      }
    }
  }, [currentBatchNumber]);

  useEffect(() => {
    const total = uploadsFailed + uploadsSucceeded;
    console.log("UPLOADSDATA: total uploads: ", totalUploads);
    console.log("UPLOADSDATA: uploads succeeded: ", uploadsSucceeded);
    console.log("UPLOADSDATA: uploads failed: ", uploadsFailed);
    console.log("UPLOADSDATA: uploads complete: ", total);
    setUploadsComplete(total);
    if (total > totalUploads) {
      setTotalUploads(total);
    }
  }, [uploadsFailed, uploadsSucceeded]);

  const childRef = React.useRef();

  const handleOpen = () => {
    setOpen(true);
    setLoading(false);
    setPageNumber(false);
    setContractAddress("");
    setTokenId("");
    setFilePath("");
    setAllQueuedUploads([]);
    setUploads([]);
    // setTotalUploads(0);
    setUploadsComplete(0);
    setUploadsSucceeded(0);
    // setUploadsProcessing(0);
    setUploadsFailed(0);
    setCurrentBatch([]);
    setCurrentBatchNumber(0);
    setTotalBatches(0);
    setFailedUploads([]);
    setErrorMessage("");
  };
  const handleClose = () => {
    // setOpen(false);
    setLoading(false);
    setPageNumber(false);
    setContractAddress("");
    setTokenId("");
    setFilePath("");
    setAllQueuedUploads([]);
    setUploads([]);
    setTotalUploads(0);
    setUploadsComplete(0);
    setUploadsSucceeded(0);
    // setUploadsProcessing(0);
    setUploadsFailed(0);
    setCurrentBatch([]);
    setCurrentBatchNumber(0);
    setTotalBatches(0);
    setFailedUploads([]);
    setErrorMessage("");

    // setSCREENSHOT_BATCH_SIZE_PLUS_ONE(0);
  };

  useEffect(() => {
    setLoading(false);
    setPageNumber(false);
    setContractAddress("");
    setTokenId("");
    setFilePath("");
    setAllQueuedUploads([]);
    setUploads([]);
    setTotalUploads(uploadsProcessingRef.current);
    setUploadsComplete(0);
    setUploadsSucceeded(0);
    // setUploadsProcessing(0);
    setUploadsFailed(0);
    setCurrentBatch([]);
    setCurrentBatchNumber(0);
    setTotalBatches(0);
    setFailedUploads([]);
    setErrorMessage("");
  }, [open]);

  const toggleSnackbar = () => {
    setSnackbar(!snackbar);
  };

  //data is an object, formatted as {type: "error", message: "error message"}
  // or {type: "success", message: "success message"}
  const startSnackbar = (data) => {
    setSnackbarMessage(data.message);
    setSnackbarType(data.type);
    if (data.failed != null) {
      // get name of asset within uploads from data.assetId

      const pushObj = { name: data.assetName, error: data.message };
      setFailedUploads((failedUploads) => [...failedUploads, pushObj]);
      setUploadsFailed((uploadsFailed) => uploadsFailed + 1);
      // setUploadsComplete((uploadsComplete) => uploadsComplete + 1);
    }
    setSnackbar(true);
  };

  class FileObject {
    constructor(name, id, parentid, type, file = null, children = []) {
      this.name = name;
      this.id = id;
      this.parentid = parentid;
      this.type = type;
      this.file = file;
      this.children = children;
    }
  }

  function createFolderTree(files, folderNamesInCurrentFolder) {
    let folderTree = new FileObject("root", props.currentFolder, "", "folder", null);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      var relativePath =
        file.webkitRelativePath === "" ? file.relativePath : file.webkitRelativePath;
      var foldersForFile = [];
      if (typeof relativePath !== "undefined") {
        foldersForFile = relativePath.split("/");
      } else {
        handleFileUpload(file, generateUUID(), props.currentFolder);
      }
      let parent = folderTree;
      for (let i = 0; i < foldersForFile.length; i++) {
        let folder = foldersForFile[i];
        let existingChild = parent.children.find((child) => child.name === folder);
        if (existingChild) {
          parent = existingChild;
        } else {
          if (i === foldersForFile.length - 1) {
            let nfile = new FileObject(
              foldersForFile[foldersForFile.length - 1],
              generateUUID(),
              parent.id,
              "file"
            );
            nfile.file = file;

            parent.children.push(nfile);
          } else {
            // NEW NAME CODE WAS CAUSING PROBLEMS
            // var folderName = folder;
            // if (i === 0) {
            //   folderName = recursiveFolderNameCheck(folder, folderNamesInCurrentFolder);
            // }
            let newFolder = new FileObject(folder, generateUUID(), parent.id, "folder");
            parent.children.push(newFolder);
            parent = newFolder;
          }
        }
      }
    }

    return folderTree;
  }

  const iterateFolderTree = async (folderTree) => {
    if (folderTree.type === "file") {
      handleFileUpload(folderTree.file, folderTree.id, folderTree.parentid);
    } else {
      if (folderTree.name !== "root") {
        await handleFolderUpload(folderTree.name, folderTree.id, folderTree.parentid);
      }
      for (let i = 0; i < folderTree.children.length; i++) {
        iterateFolderTree(folderTree.children[i]);
      }
    }
  };

  const dropzoneUpload = (files) => {
    if (files === null) {
      return;
    }
    setUploadCancelled(false);
    setUploadsProcessing(0);
    setUploadsComplete(0);
    setTotalUploads(0);
    setUploadsSucceeded(0);
    setUploadsFailed(0);
    setFailedUploads([]);
    setErrorMessage("");
    setCurrentlyUploading(dispatch, true);

    const folderNamesInCurrentFolder = [];
    const folderIdsInCurrentFolder = [];

    abortController = new AbortController();
    var rootFolder = new FileObject("root", props.currentFolder, null);
    for (let i = 0; i < props.imgData.length; i++) {
      if (props.imgData[i].assetType === "folder") {
        console.log("UPLOAD MODAL: folder name1: ", props.imgData[i].fileName);
        folderNamesInCurrentFolder.push(props.imgData[i].fileName);
        folderIdsInCurrentFolder.push(props.imgData[i].modelId);
      }
    }

    logAuthorizedWombatEvent("assetUploadAttempted", { amount: files.length });

    // construct folder tree from files
    var currentFolder = rootFolder;
    var folderTree = createFolderTree(files, folderNamesInCurrentFolder);

    iterateFolderTree(folderTree);
    // loop through imgData and get a list of all of the folder names

    setLoading(true);

    try {
      setTotalBatches(Math.ceil((files.length + totalUploads) / SCREENSHOT_BATCH_SIZE_PLUS_ONE));

      for (let i = 0; i < files.length; i++) {
        // amplitude.getInstance().logEvent("assetUploadAttempted", {
        //   amount: files.length,
        // });

        if (fileTypes.includes(getFileType(files[i].name))) {
          // console.log("WEBKIT FILE", files[i].webkitGetAsEntry());
          // handleUpload(files[i], folderNamesInCurrentFolder, folderIdsInCurrentFolder);
        } else {
          startSnackbar({
            type: "error",
            message: `File ${files[i].name} is not a valid file type.`,
          });
        }
      }
    } catch (err) {
      window.alert(err.message);
    } finally {
      setLoading(false);
    }
  };

  /* Utility for generating a unique uuid for uploading a file. */
  const generateUUID = () => {
    // Public Domain/MIT
    let d = new Date().getTime(); //Timestamp
    let d2 =
      (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      let r = Math.random() * 16; //random number between 0 and 16
      if (d > 0) {
        //Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        //Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
  };

  const recursiveFolderNameCheck = (folderName, folderNamesInCurrentFolder) => {
    console.log(
      "UPLOAD MODAL: does ",
      folderName,
      " exist within ",
      folderNamesInCurrentFolder,
      "?"
    );
    if (folderNamesInCurrentFolder.includes(folderName)) {
      console.log(
        "UPLOAD MODAL: yes, it does. copy will be appended to the end of the folder name."
      );
      // append a (1) to the end of the folder name and try again
      folderName = folderName + " copy";
      return recursiveFolderNameCheck(folderName, folderNamesInCurrentFolder);
    } else {
      console.log("UPLOAD MODAL: returning folderName: ", folderName);
      return folderName;
    }
  };

  // async function to handle uploading a folder
  const handleFolderUpload = async (name, id, parentid) => {
    const requestBody = {
      folder_name: name,
      folder_id: id,
    };
    if (parentid !== "") {
      requestBody.parent_folder_id = parentid;
    }
    console.log("UPLOAD MODAL: handleFolderUpload: ", requestBody);
    const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/upload/createFolder`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
      body: JSON.stringify(requestBody),
    }).then((res) => {
      if (res.status === 200) {
        console.log(
          "UPLOAD MODAL: FOLDER CREATED: ",
          name,
          "folderId: ",
          id,
          "parentFolderForFile: ",
          parentid
        );
        props.handleCreateFolder({
          folderName: name,
          modelId: id,
          parentFolder: parentid,
        });
      } else {
        //console.log("error", res);
      }
    });
  };

  // async function to handle uploading a file
  const handleFileUpload = async (file, id, parentid) => {
    if (file.size / 1000000 < FILE_SIZE_LIMIT_MB && !uploadCancelled) {
      setTotalUploads((totalUploads) => totalUploads + 1);
      // hard limit of 20MB
      const formData = new FormData();
      // const filename = file.name;
      const filename = file.name;
      const clearType = filename.split(".");
      const metadata = clearType[0].split("|");
      const newContractAddress = metadata[0] == null ? "Not Assigned" : metadata[0];

      const newTokenId = metadata[1] == null ? "Not Assigned" : metadata[1];
      const fileReader = new FileReader();

      fileReader.readAsArrayBuffer(file);

      const headers = {
        "Content-Type": "application/octet-stream",
        "ASSOCIATED-UUID": id,
        "FILE-SIZE": file.size.toString(),
        "CHAIN-NAME": "ETH",
        "CONTRACT-ADDRESS": newContractAddress,
        "TOKEN-ID": newTokenId,
        FILENAME: filename,
        "X-ORG-ID": activeOrg,
        "PARENT-ID": parentid,
      };
      console.log("UPLOAD MODAL: parentFolderIdForFile: ", parentid);
      const fileURL = URL.createObjectURL(file);
      // const fileURL = file;
      if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
        // totalUploadsRef.current = totalUploadsRef.current + 1;
        // handleFinishedScreenshot({ file: file, url: thumbnail, headers: headers });

        if (currentBatch.length < SCREENSHOT_BATCH_SIZE_PLUS_ONE) {
          currentBatch.push({ file: file, headers: headers, url: fileURL });
          setCurrentBatch([...currentBatch]);
        } else {
          allQueuedUploads.push({ file: file, headers: headers, url: fileURL });
          setAllQueuedUploads([...allQueuedUploads]);
        }
      } else {
        if (currentBatch.length < SCREENSHOT_BATCH_SIZE_PLUS_ONE) {
          currentBatch.push({ file: file, headers: headers, url: fileURL });
          setCurrentBatch([...currentBatch]);
        } else {
          allQueuedUploads.push({ file: file, headers: headers, url: fileURL });
          setAllQueuedUploads([...allQueuedUploads]);
        }
      }
    } else {
      startSnackbar({
        type: "error",
        message:
          "File size is too big, " +
          file.name +
          " is " +
          file.size / 1000000 +
          " MB. File size limit is 100MB.",
        failed: true,
        assetName: file.name,
      });

      // alert(
      //   "File size is too big, " +
      //     file.name +
      //     " is " +
      //     file.size / 1000000 +
      //     " MB. File size limit is 100MB."
      // );

      logAuthorizedWombatEvent("assetUploadFailed", {
        error:
          "File size is too big, " +
          file.name +
          " is " +
          file.size / 1000000 +
          " MB. File size limit is 100MB.",
      });
    }
  };

  const renameKey = (object, key, newKey) => {
    const clonedObj = object;

    const targetKey = clonedObj[key];

    delete clonedObj[key];

    clonedObj[newKey] = targetKey;

    return clonedObj;
  };

  // Upload file and thumbnail when VRMScreenshot returns thumbnail in callback
  // data consists of {file, headers, url, thumbnailURL}
  const handleFinishedScreenshot = async (data) => {
    console.log("UPLOAD MODAL: handleFinishedScreenshot: ", data);
    if (
      openRef.current === true &&
      totalUploadsRef.current > 0 &&
      !uploadCancelled &&
      currentlyUploading
    ) {
      setUploadsProcessing((uploadsProcessing) => uploadsProcessing + 1);
      const response_main_upload = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/upload/uploadModelForOrg`,
        {
          body: data.file,
          credentials: "include",
          headers: data.headers,
          method: "POST",
          signal: abortController.signal,
        }
      )
        .then((response) => {
          //console.log("response = ", response, "in first then");
          return response;
        })
        .then((response) => {
          setUploadsProcessing((uploadsProcessing) => uploadsProcessing - 1);

          // find the index of the member of the uploads array that has the same uuid as the one that just finished
          const index = uploads.findIndex(
            (upload) => upload.headers["ASSOCIATED-UUID"] === data.headers["ASSOCIATED-UUID"]
          );

          // // set the complete flag to true
          // uploads[index].complete = true;

          // setUploadsComplete((uploadsComplete) => uploadsComplete + 1);
          if (response.status === 200) {
            //console.log("set uploads succeeded to +1");

            setUploadsSucceeded((uploadsSucceeded) => uploadsSucceeded + 1);

            // if (props.currentFolder === data.headers["PARENT-ID"]) {
            props.cb({
              fileName: data.file.name,
              fileSize: data.file.size,
              filePath: data.url,
              fileObj: data.file,
              thumbnail: data.thumbnailURL,
              modelId: data.headers["ASSOCIATED-UUID"],
              assetType: "file",
              parentFolder: data.headers["PARENT-ID"],
            });
            // }
            logAuthorizedWombatEvent("assetUploadSucceeded", {
              fileType: getFileType(data.file.name),
            });

            const thumbnailImage = new File([data.thumbnail], "thumbnail.png");
            const thumbnailHeaders = renameKey(data.headers, "FILENAME", "ORIGINAL-FILENAME");

            const response_thumbnail_upload = fetch(
              `${process.env.REACT_APP_BACKEND_URL}/upload/uploadThumbnailForOrg`,
              {
                body: thumbnailImage,
                credentials: "include",
                headers: thumbnailHeaders,
                method: "POST",
              }
            )
              .then((response) => {
                return response;
              })
              .then((response) => {
                if (response.status === 200) {
                  // startSnackbar({
                  //   message: "Upload successful",
                  //   type: "success",
                  // });
                } else if (response.status === 403) {
                  logoutUser(navigate);
                } else {
                  startSnackbar({
                    message: response.statusText,
                    type: "error",
                  });
                }
              })
              .catch((err) => {
                console.log("error in uploadThumbnailForOrg catch", err);
              });
            return response;
          } else {
            //console.log("response.status = ", response.status);
            setUploadsFailed((uploadsFailed) => uploadsFailed + 1);
            //console.log("set uploads failed to +1");
            failedUploads.push({ name: data.file.name, error: response.statusText });
            setFailedUploads([...failedUploads]);

            if (response.status === 400) {
              setErrorMessage("Out of storage.");
              // handleCancel();
            }

            logAuthorizedWombatEvent("assetUploadFailed", {
              error: response.statusText,
              fileType: getFileType(data.file.name),
            });

            startSnackbar({
              message: "Upload failed",
              type: "error",
            });
            //console.log("error uploading file, not uploading thumbnail");
            return response;
          }
        })
        .catch((err) => {
          console.log("error in uploadModelForOrg catch", err);
          // setUploadsComplete((uploadsComplete) => uploadsComplete + 1);
          if (err.name === "AbortError") {
            console.log("Upload aborted");
          } else {
            console.log("Upload failed", err);
            setUploadsFailed((uploadsFailed) => uploadsFailed + 1);
            failedUploads.push({ name: data.file.name, error: err });
            setFailedUploads([...failedUploads]);
          }
          console.log();

          setUploadsProcessing((uploadsProcessing) => uploadsProcessing - 1);
        });
    } else {
      //console.log(
      //   "UPLOADMODAL: handleFinishedScreenshot: open = false and not about to make fetch call"
      // );
    }
  };

  const handleTypeError = () => {
    startSnackbar({
      message: "File type not supported",
      type: "error",
    });
  };

  const gridComponent = (
    <VuiBox height="150px" width="150px" margin="auto">
      <VuiBox
        margin="0 auto"
        mb="8px"
        sx={{
          background: "none",
          borderRadius: "20px",
          border: "none",
        }}
        padding="10px"
        display="flex"
      >
        <VuiBox
          sx={{
            background: colors.background.secondary,
            border: "2px solid " + colors.icon.focus,
            borderRadius: "50px",
            textAlign: "center",
            justifyContent: "center",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              filter: "brightness(1.3)",
            },
          }}
          width="80px"
          height="80px"
          margin="auto"
        >
          <UploadIcon
            sx={{
              // margin: "auto",
              marginTop: "17px",
              width: "40px",
              height: "40px",
              color: colors.icon.focus,
            }}
          ></UploadIcon>
        </VuiBox>
      </VuiBox>
      <VuiTypography
        variant="body2"
        color="#aaa"
        // fontWeight="bold"
        mb="15px"
        sx={{
          // whiteSpace: "no-wrap",
          // overflow: "hidden",
          color: colors.icon.enabled,
          fontSize: "0.8rem",
          fontWeight: 500,
          height: "20px",
          lineHeight: "15px",
          textAlign: "center",
        }}
      >
        {/* Wrap text down to 12 characters and ... if its too long */}
        Upload files
      </VuiTypography>
    </VuiBox>
  );

  const listComponent = <></>;

  const handleHoverStart = () => {
    //console.log("hover start");
    setErrorTooltipOpen(true);
  };
  const handleHoverEnd = () => {
    //console.log("hover end");
    setErrorTooltipOpen(false);
  };

  const handleCancel = () => {
    // cancel the upload by setting allQueuedUploads to an empty array
    abortController.abort();
    setCurrentlyUploading(dispatch, false);
    setAllQueuedUploads([]);
    setCurrentBatchIndex(0);
    setCurrentBatch([]);
    setTotalBatches(0);
    setFolders([]);
    setUploadCancelled(true);
    setTotalUploads(uploadsCompleteRef.current);
  };

  const handleClear = () => {
    setAllQueuedUploads([]);
    setCurrentBatchIndex(0);
    setCurrentBatch([]);
    setTotalBatches(0);
    setTotalUploads(0);
    setUploadsComplete(0);
    setUploadsFailed(0);
    setUploadsSucceeded(0);
    setUploadsProcessing(0);
    setFailedUploads([]);
    setErrorMessage("");
    setFolders([]);
  };

  return (
    <>
      <VuiBox
        sx={{
          background: "none",
          overflow: "visible",
          // padding: "10px !important",
          wordWrap: "break-word",
        }}
        onClick={handleOpen}
      >
        {props.component === "grid" ? gridComponent : listComponent}
      </VuiBox>

      {/* <input
            type="file"
            ref={fileInput}
            style={{ display: "none" }}
            onChange={handleChange}
            multiple
            accept=".png, .jpg, .jpeg, .glb, .gltf, .vrm"
          /> */}

      <VuiBox
        sx={{
          position: "fixed",
          bottom: "0px",
          left: "calc(50% - 200px)",

          // right: "0px",
          // transform: "translate(-50%, -50%)",
          bottom: openTab ? "0px" : "-80px",
          transition: "all 1s ease-in-out",

          border: "2px solid " + colors.border.main,
          borderRadius: "10px",
          borderBottomRightRadius: "0px",
          borderBottomLeftRadius: "0px",
          // borderRight: "2px solid " + colors.border.main,
          width: 600,
          height: 80,
          bgcolor: colors.background.secondary,
          // border: "2px solid #000",
          boxShadow: 24,
          justifyContent: "center",
          p: 2,
          pt: 2.5,
          "&:focus": {
            outline: "none",
          },
          zIndex: "9999999",
          "@media (max-width: 600px)": {
            display: "none",
          },
        }}
        className="handle"
      >
        <Grid container spacing={0}>
          <Grid item xs={12} md={12} lg={12}>
            <VuiBox
              sx={
                {
                  // marginTop: currentBatch.length > 0 ? "40px" : "80px",
                }
              }
            >
              <VuiBox>
                <VuiBox
                  sx={{
                    backgroundColor: colors.background.main,
                    borderRadius: "25px",
                    width: "450px",
                    height: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <VuiBox
                    sx={{
                      backgroundColor: colors.success.main,
                      borderRadius: "25px",
                      width: totalUploads > 0 ? `${450 * (uploadsComplete / totalUploads)}px` : 0,
                      // transition: "all 0.1s ease-in",
                      transition: "width 0.5s ease-in-out",
                      height: "10px",
                      // margin: "auto",
                      left: "0",
                      marginBottom: "10px",
                    }}
                  ></VuiBox>
                </VuiBox>

                {/* <VuiTypography
                    variant="h6"
                    sx={{ fontSize: "0.8rem", color: colors.icon.enabled }}
                  >
                    {uploadsProcessing} upload(s) processing
                  </VuiTypography> */}

                {/* Need a box container here that will flex sideways with content */}
                <VuiBox
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    width: "100%",
                    margin: "auto",
                  }}
                >
                  <VuiTypography
                    variant="h6"
                    sx={{ fontSize: "0.8rem", color: colors.icon.enabled, marginRight: "16px" }}
                  >
                    Processing: {uploadsProcessing}
                  </VuiTypography>
                  <VuiTypography
                    variant="h6"
                    sx={{ fontSize: "0.8rem", color: colors.icon.enabled, marginRight: "16px" }}
                  >
                    Succeeded: {uploadsSucceeded}/{totalUploads}
                  </VuiTypography>
                  {uploadsFailed === 0 ? null : (
                    <>
                      <VuiTypography
                        variant="h6"
                        sx={{
                          fontSize: "0.8rem",
                          color: colors.error.main,
                          marginRight: "16px",
                        }}
                      >
                        Failed: {uploadsFailed}
                      </VuiTypography>
                      <VuiTypography
                        variant="h6"
                        sx={{
                          fontSize: "0.8rem",
                          color: colors.error.main,
                        }}
                      >
                        {uploadsComplete === totalUploads ? errorMessage : null}
                      </VuiTypography>
                      <InfoOutlinedIcon
                        sx={{
                          color: colors.error.main,
                          marginLeft: "5px",

                          width: "16px",
                          height: "16px",
                          "&:hover": {
                            color: "#fff",
                          },
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          //console.log("clicked info icon");
                        }}
                        onMouseEnter={handleHoverStart}
                        onMouseLeave={handleHoverEnd}
                      />
                      <VuiBox
                        sx={{
                          display: errorTooltipOpen ? "block" : "none",
                          background: colors.background.secondary,
                          borderRadius: "9px",
                          color: "#fff",
                          // drop shadow
                          overflow: "scroll",
                          width: "auto",
                          height: "auto",
                          maxWidth: "450px",
                          margin: "0 auto",
                          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                          position: "absolute",
                          top: "0px",
                          transform: "translate(310px,  -" + failedUploads.length * 20 + "px)",
                          // right: "0px",
                          // right: "0px",
                          zIndex: "9999999",
                          padding: "10px",
                          textAlign: "left",
                          justifyContent: "center",
                          zIndex: "9999999",
                        }}
                      >
                        <VuiTypography
                          variant="body2"
                          sx={{ color: colors.error.main, fontSize: "0.8rem" }}
                        >
                          {failedUploads.map((upload) => {
                            return (
                              <>
                                {upload.name} - <span className="c_iconenabled">{upload.error}</span>
                                <br />
                              </>
                            );
                          })}
                        </VuiTypography>
                      </VuiBox>
                    </>
                  )}
                </VuiBox>
                <Button
                  variant="text"
                  sx={{
                    height: "24px",
                    width: "80px",
                    color: "#fff",
                    backgroundColor: colors.icon.disabled,
                    padding: "0px",
                    position: "absolute",
                    top: "10px",
                    right: "20px",
                    // disabled style
                    "&:disabled": {
                      backgroundColor: colors.icon.disabled,
                      color: colors.icon.enabled,
                    },
                    "&:hover": {
                      backgroundColor: colors.icon.disabled,
                      color: "#fff",
                    },
                  }}
                  disabled={uploadsComplete === totalUploads}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="text"
                  sx={{
                    height: "24px",
                    width: "80px",
                    color: "#fff",
                    backgroundColor: colors.background.secondary,
                    border: "1px solid " + colors.icon.enabled,
                    padding: "0px",
                    position: "absolute",
                    top: "40px",
                    right: "20px",
                    display: uploadsComplete !== totalUploads ? "none" : "block",
                    // disabled style
                    "&:disabled": {
                      backgroundColor: colors.icon.disabled,
                      color: colors.icon.enabled,
                    },
                    "&:hover": {
                      backgroundColor: colors.background.secondary,
                      color: colors.icon.focus,
                    },
                    "&:active": {
                      backgroundColor: colors.background.secondary,
                      color: "#fff",
                    },
                  }}
                  onClick={handleClear}
                >
                  Clear
                </Button>
              </VuiBox>
            </VuiBox>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <VuiBox mt={4} mb={1} width="100%" position="absolute">
              <Grid container spacing={1}>
                {currentBatch.map((upload, index) =>
                  // if doneIndices contains the index, then don't show VRMScreenshot

                  doneIndices.includes(index) ? null : (
                    <VRMScreenshot
                      key={index}
                      data={upload}
                      callback={handleFinishedScreenshot}
                      errorCB={startSnackbar}
                      texture={texture}
                    />
                  )
                )}
              </Grid>
            </VuiBox>
          </Grid>
        </Grid>
        <VuiBox
          sx={{
            position: "absolute",
            top: "-36px",
            // centered horizontally
            left: "50%",
            transform: "translateX(-50%)",

            // right: "0px",
            zIndex: "9999999",
            width: "40px",
            height: "36px",
            background: colors.background.secondary,
            border: "2px solid " + colors.border.main,
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            borderBottom: "none",
          }}
          onClick={() => {
            setOpenTab(!openTab);
          }}
        >
          {openTab ? (
            <KeyboardArrowDownIcon
              sx={{
                color: colors.icon.enabled,
                margin: "auto",
                left: "4px",
                top: "4px",
                width: "26px",
                height: "26px",
                position: "relative",
              }}
            />
          ) : (
            <KeyboardArrowUpIcon
              sx={{
                color: colors.icon.enabled,
                margin: "auto",
                left: "4px",
                top: "4px",
                width: "26px",
                height: "26px",
                position: "relative",
              }}
            />
          )}
        </VuiBox>
      </VuiBox>
      <VuiSnackbar
        color={snackbarType === "success" ? "success" : "error"}
        icon="notifications"
        title="Polyhive"
        content={snackbarMessage}
        dateTime="Just now"
        open={snackbar}
        close={toggleSnackbar}
        onClose={toggleSnackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={3000}
      />
    </>
  );
});

export default UploadModal;
