import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import colors from "assets/theme/base/colors";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import React from "react";
import { useImperativeHandle, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";

const modalStyle = {
  width: "200px",
  height: "100%",
  position: "absolute",
  alignItems: "center",
  justifyContent: "center",
};
const RenameModal = (props) => {
  const [inputText, setInputText] = React.useState("");
  const [disabledText, setDisabledText] = React.useState("");

  useEffect(() => {
    setInputText(props.currentName);
  }, [props.open]);

  const folderNames = props.allFolders.map((gridItem) =>
    gridItem.fileName ? gridItem.fileName : gridItem.folderName
  );

  const updateInputText = () => {
    setInputText(document.getElementById("newName").value);
    //console.log('inputText is ', inputText.length);
    if (
      props.assetType !== "folder" &&
      props.currentName &&
      inputText &&
      props.currentName.includes(".") &&
      inputText.split(".").pop() !== props.currentName.split(".").pop()
    ) {
      //console.log("RenameModal inputText", inputText);
      setDisabledText("Please choose a file name with the current extension.");
    } else if (
      props.assetType === "folder" &&
      inputText &&
      folderNames.includes(inputText) &&
      inputText != props.currentName
    ) {
      setDisabledText(
        `${inputText} already exists in this directory. Please choose a different name`
      );
    } else if (inputText.length > 300) {
      setDisabledText(`Input text is too long, please choose a name less than 300 characters. `);
    }
  };

  const formSubmitted = (e) => {
    e.preventDefault();
    // props.handleCreateFolder(folderName);

    const newName = document.getElementById("newName").value;

    //console.log("extension", newName.split(".").pop());
    //console.log("extension current", props.currentName.split(".").pop());
    //console.log("current name", props.currentName);
    if (newName !== "") {
      if (
        !(
          props.currentName.includes(".") &&
          newName.split(".").pop() !== props.currentName.split(".").pop()
        )
      ) {
        const inspectedId = props.inspectedId;
        const requestBody = {
          asset_id: inspectedId,
          name: newName,
        };

        //console.log("attempting to rename asset: ", newName, " with id: ", inspectedId);

        logAuthorizedWombatEvent("assetRenameAttempted", {});

        const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/upload/renameAsset/`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-ORG-ID": props.activeOrg,
          },
          body: JSON.stringify(requestBody),
        }).then((res) => {
          if (res.status === 200) {
            props.handleRename({ name: newName, assetId: inspectedId });
            props.handleClose();
          } else {
            //console.log("error", res);
          }
        });
      } else {
        //console.log("error, required to have same file extension");
      }
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ modalStyle }}
    >
      <VuiBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: colors.background.main,
          // border: "2px solid #000",
          boxShadow: 24,
          justifyContent: "center",
          p: 3,
          borderRadius: "15px",
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="" style={{ textAlign: "left" }}>
          <VuiTypography
            variant="h6"
            sx={{
              color: "#fff",
              marginBottom: "10px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              "&:hover": {
                overflow: "visible",
                textOverflow: "initial",
                whiteSpace: "normal",
                wordWrap: "break-word",
                overflowWrap: "break-word",

                // wrap text to fit container
              },
            }}
          >
            Rename {props.currentName}
          </VuiTypography>
          <form onSubmit={formSubmitted}>
            <input
              // id="outlined-basic"
              label="Folder Name"
              variant="outlined"
              id="newName"
              onChange={updateInputText}
              style={{
                width: "100%",
                marginBottom: "10px",
                background: colors.border.main,
                border: "none",
                color: "#fff",
                borderRadius: "4px",
                padding: "0px 10px",
                height: "30px",
              }}
              defaultValue={props.currentName}
            />
          </form>
          <Button
            onClick={props.handleClose}
            className="default_btn fw_500 f_small c_white br_1px br_icondisabled"
          >
            Cancel
          </Button>
          &nbsp;&nbsp;&nbsp;
          {(props.currentName &&
            inputText &&
            props.currentName.includes(".") &&
            inputText.split(".").pop() !== props.currentName.split(".").pop()) ||
          (props.assetType === "folder" &&
            inputText &&
            folderNames.includes(inputText) &&
            inputText != props.currentName) ||
          (inputText && inputText.length > 300) ? (
            <Tooltip title={disabledText}>
              <span>
                <Button
                  // onClick={props.handleCreateFolder}
                  // type="submit"
                  className="default_btn fw_500 f_small c_white bg_quaternaryfocusdark"
                  disabled
                  onClick={formSubmitted}
                >
                  Rename
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button
              // onClick={props.handleCreateFolder}
              // type="submit"
              className="default_btn fw_500 f_small c_white bg_quaternaryfocusdark"
              onClick={formSubmitted}
            >
              Rename
            </Button>
          )}
        </div>
      </VuiBox>
    </Modal>
  );
};

export default RenameModal;
