import { VRMLoaderPlugin } from "@pixiv/three-vrm";
import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import { Canvas, useThree } from "react-three-fiber";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import VuiProgress from "components/VuiProgress";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";

import {
  HemisphereLight,
  LinearToneMapping,
  sRGBEncoding,
  PMREMGenerator,
  WebGLRenderer,
  MeshStandardMaterial,
} from "three";
import CubeMap from "assets/images/cubemap.jpg";
import CubeMap2 from "assets/images/envmap2.jpg";
import { RoomEnvironment } from "three/examples/jsm/environments/RoomEnvironment";
import { Environment } from "@react-three/drei";

import { AmbientLight } from "@react-three/drei";
// camera.position.set(0, 0, 3);
const manager = new THREE.LoadingManager();

const progressbar = 0;

const progressStyle = {
  margin: "auto",
  width: "80%",
  position: "absolute",
};

const useVrm = (props) => {
  const loader = new GLTFLoader();

  const { loading } = useState(true);
  const [vrm, setVrm] = useState(null);
  const scene = new THREE.Scene();
  const group = new THREE.Group();
  const state = {
    background: false,
    playbackSpeed: 1.0,
    actionStates: {},
    wireframe: false,
    skeleton: false,
    grid: false,

    // Lights
    punctualLights: true,
    exposure: 0,
    textureEncoding: "sRGB",
    ambientIntensity: 0.3,
    ambientColor: 0xffffff,
    directIntensity: 0.8 * Math.PI, // TODO(#116)
    directColor: 0xffffff,
    bgColor1: "#ffffff",
    bgColor2: "#353535",
    toneMapping: LinearToneMapping,
  };
  var textureLoader = new THREE.TextureLoader();
  var texture = textureLoader.load(CubeMap2);
  // scene.environment = texture;
  texture.mapping = THREE.EquirectangularReflectionMapping;

  // const vignette = createBackground({

  // gl.setPixelRatio(window.devicePixelRatio);
  loader.register((parser) => {
    return new VRMLoaderPlugin(parser);
  });
  function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

  const takeScreenshot = (myCanvas, assetId) => {
    console.log("takeScreenshot " + assetId + " " + new Date().toISOString());
    myCanvas.toBlob((blob) => {
      saveBlob(blob, `screencapture-${myCanvas.width}x${myCanvas.height}.png`);
    });
  };
  const saveBlob = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    return function saveData(blob, fileName) {
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      const returnObj = {};
      console.log("saveBlob " + props.assetId + " " + new Date().toISOString());
      props.screenshotCB({ thumbnailURL: url, thumbnailFile: blob });
      // a.click();
    };
  })();

  const loadVrm = (renderer, url) => {
    renderer.toneMapping = state.toneMapping;
    renderer.toneMappingExposure = Math.pow(2, state.exposure);
    const canvas = document.getElementById(url).firstChild.firstChild;

    loader.load(
      url,
      async (gltf) => {
        // renderer.physicallyCorrectLights = true;
        // renderer.outputEncoding = sRGBEncoding;
        // renderer.setClearColor(0x5d5c67, 1);
        // renderer.setClearAlpha(0);

        // if type is "vrm", then rotate the model 180 degrees
        if (props.fileType === "vrm") {
          gltf.scene.rotation.y = Math.PI;
        }

        fitCameraToObject(props.camera, props.assetId, gltf.scene, 1.25, null);
        //props.camera.position.set(0, 0, 3);
        //props.camera.updateProjectionMatrix();
        gltf.scene.traverse((c) => {
          if (c instanceof THREE.Mesh) {
            c.material.envMap = props.texture;
            c.material.envMapIntensity = 1;
          }
        });
       
        group.add(gltf.scene);
        addLights(scene);
        scene.add(group);

        //console.log(
        //   "render BEGIN " +
        //     props.assetId +
        //     " " +
        //     JSON.stringify(props.camera.position) +
        //     " " +
        //     new Date().toISOString()
        // );
        try {
          await renderer.render(scene, props.camera);
          takeScreenshot(canvas, props.assetId);
          setVrm(scene);
        } catch (e) {
          console.error("errorer", e);
          props.errorCB({
            type: "error",
            message: "Upload failed, upload window closed during upload. Please try again.",
            failed: true,
            assetName: props.assetName,
          });
        }
        //console.log(
        //   "render END " +
        //     props.assetId +
        //     " " +
        //     JSON.stringify(props.camera.position) +
        //     " " +
        //     new Date().toISOString()
        // );
      },
      (progress) => {},
      (error) => {
        console.error("errorer", error);
        props.errorCB({
          type: "error",
          message:
            "Error loading model file, please make sure that the file does not have any external dependencies.",
          failed: true,
          assetName: props.assetName,
        });
      }
    );
  };
  const loadFBX = (renderer, url) => {
    renderer.toneMapping = state.toneMapping;
    renderer.toneMappingExposure = Math.pow(2, state.exposure);
    let fbxFile;
    const canvas = document.getElementById(url).firstChild.firstChild;

    // Lighting
    addLights(scene);

    const manager = new THREE.LoadingManager();
    manager.onStart = function (url, itemsLoaded, itemsTotal) {
      console.log(
        "Started loading file: " +
          url +
          ".\nLoaded " +
          itemsLoaded +
          " of " +
          itemsTotal +
          " files."
      );
    };

    manager.onLoad = async function () {
      console.log("Loading complete!");
      const meshMat = new THREE.MeshStandardMaterial();

      fbxFile.traverse((c) => {
        if (c instanceof THREE.Mesh) {
          if (c.material) {
            console.log(c.material);
            if (c.material.map && c.material.map.source && c.material.map.source.data === null) {
              console.log("c.material.map.source.data is null", c.material.map.source);
              c.material = meshMat;
            } else if (Array.isArray(c.material)) {
              if (c.material.length > 0) {
                for (let i = 0; i < c.material.length; i++) {
                  if (
                    c.material[i].map &&
                    c.material[i].map.source &&
                    c.material[i].map.source.data === null
                  ) {
                    c.material[i] = meshMat;
                  }
                }
              }
            }
          }
        }
      });
      group.add(fbxFile);

      scene.add(group);
      await renderer.render(scene, props.camera);
      takeScreenshot(canvas, props.assetId);
    };

    manager.onProgress = function (url, itemsLoaded, itemsTotal) {
      console.log(
        "Loading file: " + url + ".\nLoaded " + itemsLoaded + " of " + itemsTotal + " files."
      );
    };

    manager.onError = function (url) {
      console.log("There was an error loading " + url);
    };
    const fbxLoader = new FBXLoader(manager);

    fbxLoader.load(
      url,
      async (fbx) => {
        fbxFile = fbx;
        // renderer.setClearColor(0x5d5c67, 1);

        if (props.fileType === "vrm") {
          fbx.rotation.y = Math.PI;
        }
        console.log("GLTF: ", fbx);

        fitCameraToObject(props.camera, props.assetId, fbx, 1.25, null);

        try {
          setVrm(scene);
        } catch (e) {
          console.error("errorer", e);
          props.errorCB({
            type: "error",
            message: "Upload failed, upload window closed during upload. Please try again.",
            failed: true,
            assetName: props.assetName,
          });
        }
      },
      (progress) => {},
      (error) => {
        console.error("errorer", error);
        props.errorCB({
          type: "error",
          message:
            "Error loading model file, please make sure that the file does not have any external dependencies.",
          failed: true,
          assetName: props.assetName,
        });
      }
    );
  };

  const loadOBJ = (renderer, url) => {
    renderer.toneMapping = state.toneMapping;
    renderer.toneMappingExposure = Math.pow(2, state.exposure);
    let objFile;
    const canvas = document.getElementById(url).firstChild.firstChild;

    // Lighting
    addLights(scene);

    const manager = new THREE.LoadingManager();
    manager.onStart = function (url, itemsLoaded, itemsTotal) {
      console.log(
        "Started loading file: " +
          url +
          ".\nLoaded " +
          itemsLoaded +
          " of " +
          itemsTotal +
          " files."
      );
    };

    manager.onLoad = async function () {
      console.log("Loading complete!");
      // objFile.traverse((c) => {
      //   if (c instanceof THREE.Mesh) {
      //     if (c.material) {
      //       console.log(c.material);
      //       if (c.material.map && c.material.map.source && c.material.map.source.data === null) {
      //         console.log("c.material.map.source.data is null", c.material.map.source);
      //         const meshMat = new THREE.MeshStandardMaterial();
      //         c.material = meshMat;
      //       } else {
      //       }
      //     }
      //   }
      // });
      group.add(objFile);

      scene.add(group);
      await renderer.render(scene, props.camera);
      takeScreenshot(canvas, props.assetId);
    };

    manager.onProgress = function (url, itemsLoaded, itemsTotal) {
      console.log(
        "Loading file: " + url + ".\nLoaded " + itemsLoaded + " of " + itemsTotal + " files."
      );
    };

    manager.onError = function (url) {
      console.log("There was an error loading " + url);
    };
    const objLoader = new OBJLoader(manager);

    objLoader.load(
      url,
      async (obj) => {
        objFile = obj;
        // renderer.setClearColor(0x5d5c67, 1);

        console.log("OBJ: ", obj);

        fitCameraToObject(props.camera, props.assetId, obj, 1.25, null);

        try {
          setVrm(scene);
        } catch (e) {
          console.error("errorer", e);
          props.errorCB({
            type: "error",
            message: "Upload failed, upload window closed during upload. Please try again.",
            failed: true,
            assetName: props.assetName,
          });
        }
      },
      (progress) => {},
      (error) => {
        console.error("errorer", error);
        props.errorCB({
          type: "error",
          message:
            "Error loading model file, please make sure that the file does not have any external dependencies.",
          failed: true,
          assetName: props.assetName,
        });
      }
    );
  };
  const loadModel = (renderer, url, fileType) => {
    if (["vrm", "glb", "gltf"].includes(fileType)) {
      loadVrm(renderer, url);
    } else if (["fbx"].includes(fileType)) {
      loadFBX(renderer, url);
    } else if (["obj"].includes(fileType)) {
      loadOBJ(renderer, url);
    }
  };

  return { vrm, loadModel };
};
const addLights = (scene) => {
   const directionalLight = new THREE.DirectionalLight(0x555555, 1);
   directionalLight.layers.enable(0);
   directionalLight.position.set(-3.287, 4.383, 6.574);
   directionalLight.rotation.set(0, 0, 0);
   directionalLight.castShadow = true;

   const shadowCamera = new THREE.OrthographicCamera(-5, 5, 5, -5, 0.5, 500);
   shadowCamera.layers.enable(0);
   directionalLight.shadow.camera = shadowCamera;
   scene.add(directionalLight);
   const ambientLight = new THREE.AmbientLight(0xffffff, 1);
   ambientLight.layers.enable(0);
   ambientLight.position.set(0, 0, 0);
   ambientLight.rotation.set(0, 0, 0);
   scene.add(ambientLight);
};
const fitCameraToObject = async function (camera, assetId, object, offset, controls) {
  //console.log("fitCameraToObject " + assetId + " BEGIN " + new Date().toISOString());
  offset = offset || 1.25;

  const oldBoundingBox = new THREE.Box3();
  // get bounding box of object - this will be used to setup controls and camera
  oldBoundingBox.setFromObject(object);

  // Set size to Vector3 of bounding box sizes
  const size = new THREE.Vector3(
    oldBoundingBox.max.x - oldBoundingBox.min.x,
    oldBoundingBox.max.y - oldBoundingBox.min.y,
    oldBoundingBox.max.z - oldBoundingBox.min.z
  );

  // Get center point of bounding box
  const oldcenter = new THREE.Vector3(
    (oldBoundingBox.max.x + oldBoundingBox.min.x) / 2,
    (oldBoundingBox.max.y + oldBoundingBox.min.y) / 2,
    (oldBoundingBox.max.z + oldBoundingBox.min.z) / 2
  );

  // Move the anchor position of the object to the center of the object
  object.position.x = object.position.x - oldcenter.x;
  object.position.y = object.position.y - oldcenter.y;
  object.position.z = object.position.z - oldcenter.z;

  const boundingBox = new THREE.Box3();
  boundingBox.setFromObject(object);
  const center = new THREE.Vector3(
    (boundingBox.max.x + boundingBox.min.x) / 2,
    (boundingBox.max.y + boundingBox.min.y) / 2,
    (boundingBox.max.z + boundingBox.min.z) / 2
  );

  // get the max side of the bounding box (fits to width OR height as needed )
  const maxDim = Math.max(size.x, size.y, size.z);
  const fov = camera.fov * (Math.PI / 180);
  let cameraZ = maxDim / 2 / Math.tan(fov / 2);

  cameraZ *= offset; // zoom out a little so that objects don't fill the screen

  camera.position.z = center.z + cameraZ;

  const minZ = boundingBox.min.z;
  const cameraToFarEdge = minZ < 0 ? -minZ + cameraZ : cameraZ - minZ;

  camera.far = cameraToFarEdge * 3;
  camera.lookAt(center);
  camera.updateProjectionMatrix();
  //console.log("camera position is", camera.position);
  //console.log("fitCameraToObject " + assetId + " END " + new Date().toISOString());
};

const VRMContainer = forwardRef((props, ref) => {
  // const {
  //   gl, // WebGL renderer
  //   scene, // Default scene
  // } = useThree();

  const { vrm, loadModel } = useVrm({
    camera: props.camera,
    assetId: props.assetId,
    errorCB: props.errorCB,
    fileType: props.fileType,
    screenshotCB: props.screenshotCB,
    assetName: props.assetName,
    texture: props.texture,
  });
  const [loading, setLoading] = useState(true);

  /*
  useImperativeHandle(ref, () => ({
    loadModel(url) {
      loadVrm(url);
    },
    loadingComplete() {
      setLoading(false);
    },
    reset() {
      setLoading(true);
    },
  }));
  */

  useEffect(() => {
    //console.log(props.url);
    try {
      const canvas = document.getElementById(props.url).firstChild.firstChild;
      const renderer = new WebGLRenderer({ canvas, alpha: true, antialias: true });
      loadModel(renderer, props.url, props.fileType);
    } catch (e) {
      //console.log("UPLOADMODAL: Aborted Upload");
    }
  }, [props.url]);

  useEffect(() => {
    if (vrm) {
      props.callback(props.assetId);
    }
  }, [vrm]);

  return (
    <>
      {/* <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
      <spotLight position={[-10, -10, -10]} angle={0.15} penumbra={1} />
      <directionalLight position={[0, 0, 10]} />
      <ambientLight intensity={1} /> */}
      {vrm && <primitive object={vrm} />}
    </>
  );
});

const VRMLoader = forwardRef((props, ref) => {
  const camera = new THREE.PerspectiveCamera(45, 1, 0.01, 5000);

  const childRef = useRef(null);
  var startTime = 0;
  useImperativeHandle(ref, () => ({
    loadVrm: (url) => {
      childRef.current.loadModel(url);
    },
    loadingComplete: () => {},
    reset: () => {
      // childRef.current.reset();
    },
  }));
  const [loading, setLoading] = React.useState("0");
  const [visible, setVisible] = useState(true);

  manager.onStart = function (url, itemsLoaded, itemsTotal) {
    //console.log(
    //   "Started loading file: " + url + ".\nLoaded " + itemsLoaded + " of " + itemsTotal + " files."
    // );
  };

  manager.onProgress = function (url, itemsLoaded, itemsTotal) {
    setLoading(Math.floor((itemsLoaded / itemsTotal) * 100) + "");
    if (itemsLoaded == itemsTotal) {
    }
  };

  const onLoadedCallback = (assetId) => {
    setLoading("0");
  };

  return (
    <>
      <Canvas
        frameloop="off"
        gl={{ preserveDrawingBuffer: true, depth: true, stencil: false }}
        id={props.url}
        camera={camera}
      >
        {/* <Environment background={false} path="/" files="royal_esplanade_1k.hdr" blur={0.4} /> */}
        <ambientLight intensity={1} />
        <VRMContainer
          url={props.url}
          ref={childRef}
          callback={onLoadedCallback}
          camera={camera}
          errorCB={props.errorCB}
          fileType={props.fileType}
          assetId={props.assetId}
          assetName={props.assetName}
          screenshotCB={props.callback}
          texture={props.texture}
        />
      </Canvas>
    </>
  );
});

export default VRMLoader;
