import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import colors from "assets/theme/base/colors";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import { useState, setState, forwardRef, useImperativeHandle } from "react";
import React from "react";
import { ProgressBar, Oval } from "react-loader-spinner";

const modalStyle = {
  width: "200px",
  height: "100%",
  position: "absolute",
  alignItems: "center",
  justifyContent: "center",
};
const Spinner = () => {
  return (
    <Oval
      ariaLabel="loading-indicator"
      height={50}
      width={50}
      strokeWidth={5}
      strokeWidthSecondary={2}
      color={colors.icon.disabled}
      secondaryColor="white"
      wrapperStyle={{
        position: "absolute",
        top: "67%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    />
  );
};
const DownloadFolderModal = forwardRef((props, ref) => {
  //console.log("CreateFolderModal folderNames", folderNames);
  const [open, setOpen] = React.useState(false);

  const [currentFolderName, setCurrentFolderName] = useState("");
  const [downloadingFolder, setDownloadingFolder] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    props.abortDownloadController.abort();
    handleClose();
  };
  const updateCurrentFolderName = () => {
    setCurrentFolderName(document.getElementById("folderName").value);
  };

  useImperativeHandle(ref, () => ({
    setDownloadingFolder(folderName) {
      setCurrentFolderName(folderName);
      setDownloadingFolder(true);
      handleOpen();
    },

    setProgress(newprogress) {
      if (newprogress !== progress) {
        setProgress(newprogress);
      }
    },
    downloadComplete() {
      setDownloadingFolder(false);
      setProgress(0);
      handleClose();
    },
  }));

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ modalStyle }}
    >
      <VuiBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          height: 190,
          bgcolor: colors.background.main,
          // border: "2px solid #000",
          boxShadow: 24,
          justifyContent: "center",
          p: 3,
          borderRadius: "15px",
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="" style={{ textAlign: "left" }}>
          <VuiTypography variant="h6" sx={{ color: "#fff", marginBottom: "10px" }}>
            Downloading folder: {currentFolderName}
          </VuiTypography>
          <VuiBox
            sx={{
              width: "100%",
              height: "80px",
              marginBottom: "10px",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <VuiTypography
              variant="h6"
              sx={{ color: colors.icon.enabled, marginBottom: "10px", fontWeight: 450 }}
            >
              {progress === 0 ? <>Fetching assets</> : <>Zipping {progress}% complete</>}
            </VuiTypography>
            {downloadingFolder ? <Spinner /> : <></>}
          </VuiBox>
          <Button
            onClick={handleCancel}
            variant="outlined"
            sx={{
              outline: "none",
              border: "1.5px solid " + colors.icon.disabled,
              color: colors.icon.enabled,
              borderRadius: "7px",
              height: "15px",
              position: "absolute",
              bottom: "10px",
              margin: "0 auto",
              // padding: "0px",
              "&:hover": { border: "1.5px solid " + colors.icon.disabled },
            }}
          >
            Cancel
          </Button>
        </div>
      </VuiBox>
    </Modal>
  );
});

export default DownloadFolderModal;
