/** 

=========================================================
* AQUEDUCT CONTENT PAGE
=========================================================
* Designed and Coded by Rohan Iyer
=========================================================

*/

// Vision UI Dashboard PRO React base styles
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function ComingSoon() {
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <VuiBox py={40}>
        <VuiTypography variant="h1" align="center" sx={{ color: "#fff" }}>
          This feature is on the way. Stay tuned for updates on{" "}
          <a
            href="https://twitter.com/AqueductGG"
            target="_blank"
            style={{
              color: "#00b0ff",
              textDecoration: "underline",
              textDecorationColor: "#00b0ff",
            }}
          >
            Twitter
          </a>
          !
        </VuiTypography>
      </VuiBox>
    </DashboardLayout>
  );
}

export default ComingSoon;
