/**

 =========================================================
 * Vision UI PRO React - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com/)

 * Design and Coded by Simmmple & Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

 */

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

// Icons
import { FaApple, FaFacebook } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";

import { Box, Typography } from "@mui/material";

// Vision UI Dashboard components
import GradientBorder from "examples/GradientBorder";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import Button from "@mui/material/Button";
import { useStytch } from "@stytch/stytch-react";
import GoogleButton from "assets/images/googlewhite.png";
import colors from "assets/theme/base/colors";

import { useNavigate } from "react-router-dom";

// Images
import bgBasic from "assets/images/background-basic-auth.png";
import Radium, { Style } from "radium";
import { Navigate } from "../../../../../node_modules/react-router-dom/dist/index";
import { sendUTMToWombat, logAuthorizedWombatEvent, getDeviceId } from "util/UTMFunctions";
import PolyhiveLogo from "assets/images/logos/polyhivelogo.png";
import KeyIcon from "@mui/icons-material/Key";
import LockIcon from "@mui/icons-material/Lock";
import PageLayout from "examples/LayoutContainers/PageLayout/index";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import pageRoutes from "page.routes";

import { hashSHA256 } from "util/UtilFunctions";

function SignInAQ() {
  const [showError, setShowError] = useState(false);
  const [errorString, setErrorString] = useState("");

  const [login, setLogin] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {}, []);

  const loginClicked = async () => {
    const email = document.getElementById("emailInput").value;
    const password = document.getElementById("passwordInput").value;
    // hash password sha256
    const hashed = await hashSHA256(password);

    //console.log("email inputted is", emailInputted);
    const resp = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/developer/loginWithEmailAndPassword/",
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "DEVICE-ID": getDeviceId(),
        },
        body: JSON.stringify({ email: email, password: hashed }),
      }
    );

    const response = await resp.json();
    
    console.log("loginresponse is", response);
    if (resp.status === 200) {
      // localStorage.setItem("token", response.data.accessToken);
      // redirect to home
      console.log("redirecting to home");
      navigate("/generate");
    } else if (resp.status === 403) {

      setShowError(true);
      setErrorString(response.error);
    }
  };

  const registerClicked = async () => {
    const email = document.getElementById("emailInput").value;
    const password = document.getElementById("passwordInput").value;
    const confirmPassword = document.getElementById("confirmPasswordInput").value;

    if (password !== confirmPassword) {
      setShowError(true);
      setErrorString("Passwords do not match");
      return;
    }

    if (email.length < 3) {
      setShowError(true);
      setErrorString("Email must be at least 3 characters");
      return;
    }

    if (password.length < 8) {
      setShowError(true);
      setErrorString("Password must be at least 8 characters");
      return;
    }

    // hash password sha256
    const hashed = await hashSHA256(password);

    const resp = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/developer/registerWithEmailAndPassword/",
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "DEVICE-ID": getDeviceId(),
        },
        body: JSON.stringify({ email: email, password: hashed }),
      }
    );

    const response = await resp.json();
    //console.log("loginresponse is", response);

    if (resp.status === 200) {
      // localStorage.setItem("token", response.data.accessToken);
      navigate("/generate");
    } else if (resp.status === 403) {
      setShowError(true);
      setErrorString(response.error);
    }
  };

  const loginFormSubmitted = (e) => {
    e.preventDefault();
    logAuthorizedWombatEvent("clickedLoginButton", {});
    loginClicked();
  };

  const registerFormSubmitted = (e) => {
    e.preventDefault();
    logAuthorizedWombatEvent("clickedRegisterButton", {});
    registerClicked();
  };

  const loginForm = ( 
    <form onSubmit={loginFormSubmitted}>
      <LockIcon
        style={{
          color: colors.black.main,
          width: "40px",
          height: "40px",
          marginBottom: "0px",
          marginTop: "16px",
        }}
      />

      <Typography
        variant="body2"
        className="c_black"
        fontWeight="bold"
        mb="8px"
        sx={({ typography: { size } }) => ({
          fontSize: "1.2rem",
        })}
      >
        Sign in
      </Typography>
      {showError && <Typography className="c_error f_small fw_500">{errorString}</Typography>}
      <input
        id="emailInput"
        type="email"
        placeholder="Email"
        // inputProps={{ style: { backgroundColor: "#fff" } }}
        className="br_icondisabled br_1px"
        style={{
          fontSize: "0.9rem",
          width: "100%",
          height: "40px",
          borderRadius: "4px",
          padding: "0 16px",
          color: colors.icon.disabled,
          backgroundColor: colors.white.off,
          marginBottom: "16px",
        }}
      />
      <input
        id="passwordInput"
        type="password"
        placeholder="Password"
        // inputProps={{ style: { backgroundColor: "#fff" } }}
        className="br_icondisabled br_1px"
        style={{
          fontSize: "0.9rem",
          width: "100%",
          height: "40px",
          borderRadius: "4px",
          padding: "0 16px",
          color: colors.icon.disabled,
          backgroundColor: colors.white.off,
        }}
      />
      <Box mt={2} mb={1}>
        <Button
          type="submit"
          sx={{
            width: "100%",
            height: "40px",
            color: "#fff",
            textTransform: "none",
            fontSize: "0.9rem",
            fontWeight: 450,
            borderRadius: "4px",
            backgroundColor: colors.black.main,
            // add an inner shadow
            border: "1.5px solid #000",
            ":hover": {
              color: colors.white.bright,
              backgroundColor: colors.black.main,
            },
          }}
        >
          Sign in
        </Button>
        <Typography className="c_icondisabled f_small fw_500 mt_2">
          Need an account?{" "}
          <a
            className="c_quaternaryfocus f_small fw_500 c_pointer"
            onClick={() => {
              setShowError(false);
              setErrorString("");
              setLogin(false);
            }}
          >
            Register.
          </a>
        </Typography>
      </Box>
    </form>
  );

  const registerForm = (
    <form onSubmit={registerFormSubmitted}>
      <LockIcon
        style={{
          color: colors.black.main,
          width: "40px",
          height: "40px",
          marginBottom: "0px",
          marginTop: "16px",
        }}
      />

      <Typography
        variant="body2"
        className="c_black"
        fontWeight="bold"
        mb="8px"
        sx={({ typography: { size } }) => ({
          fontSize: "1.2rem",
        })}
      >
        Register
      </Typography>
      {showError && (
        <Typography
          variant="body2"
          className="c_error"
          fontWeight="bold"
          mb="8px"
          sx={({ typography: { size } }) => ({
            fontSize: "1.2rem",
          })}
        >
          {errorString}
        </Typography>
      )}
      <input
        id="emailInput"
        type="email"
        placeholder="Email"
        // inputProps={{ style: { backgroundColor: "#fff" } }}
        className="br_icondisabled br_1px"
        style={{
          fontSize: "0.9rem",
          width: "100%",
          height: "40px",
          borderRadius: "4px",
          padding: "0 16px",
          color: colors.icon.disabled,
          backgroundColor: colors.white.off,
          marginBottom: "16px",
        }}
      />
      <input
        id="passwordInput"
        type="password"
        placeholder="Password"
        // inputProps={{ style: { backgroundColor: "#fff" } }}
        className="br_icondisabled br_1px"
        style={{
          fontSize: "0.9rem",
          width: "100%",
          height: "40px",
          borderRadius: "4px",
          padding: "0 16px",
          color: colors.icon.disabled,
          backgroundColor: colors.white.off,
          marginBottom: "16px",
        }}
      />
      <input
        id="confirmPasswordInput"
        type="password"
        placeholder="Confirm Password"
        // inputProps={{ style: { backgroundColor: "#fff" } }}
        className="br_icondisabled br_1px"
        style={{
          fontSize: "0.9rem",
          width: "100%",
          height: "40px",
          borderRadius: "4px",
          padding: "0 16px",
          color: colors.icon.disabled,
          backgroundColor: colors.white.off,
        }}
      />
      <Box mt={2} mb={1}>
        <Button
          type="submit"
          sx={{
            width: "100%",
            height: "40px",
            color: "#fff",
            textTransform: "none",
            fontSize: "0.9rem",
            fontWeight: 450,
            borderRadius: "4px",
            backgroundColor: colors.black.main,
            // add an inner shadow
            border: "1.5px solid #000",
            ":hover": {
              color: colors.white.bright,
              backgroundColor: colors.black.main,
            },
          }}
        >
          Register
        </Button>
        <Typography className="c_icondisabled f_small fw_500 mt_2">
          Already have an account?{" "}
          <a
            className="c_quaternaryfocus f_small fw_500 c_pointer"
            onClick={() => {
              setShowError(false);
              setErrorString("");
              setLogin(true);
            }}
          >
            Login.
          </a>
        </Typography>
      </Box>
    </form>
  );
  return (
    <PageLayout background={colors.white.bright}>
      <DefaultNavbar
        routes={pageRoutes}
        action={{
          type: "external",
          route: "/",
        }}
        transparent
      />
      {/* <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%"> */}
      <Box
        maxWidth="400px"
        minWidth="400px"
        className="mauto"
        borderRadius={borders.borderRadius.form}
        p="45px"
        pt="150px"
        sx={({ palette: { secondary } }) => ({
          // backgroundColor: colors.background.secondary,
          // border: "1px solid #696969",
        })}
      >
        {login ? loginForm : registerForm}
      </Box>

      {/* </GradientBorder> */}
    </PageLayout>
  );
}

export default SignInAQ;
