import VuiBox from "components/VuiBox/index";
import VuiTypography from "components/VuiTypography/index";
import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import colors from "assets/theme/base/colors";
import Modal from "@mui/material/Modal";
import { Twitter } from "../../../../../../node_modules/@mui/icons-material/index";
import CloseIcon from "@mui/icons-material/Close";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";
import { Oval } from "react-loader-spinner";
import Backdrop from "@mui/material/Backdrop";
const ShareModal = (props) => {
  const [copied, setCopied] = React.useState(false);

  return (
    <>
      <Modal open={props.open} onClose={props.onClose}>
        <VuiBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            height: 230,
            bgcolor: colors.background.main,
            //   border: "2px solid #000",
            borderRadius: "10px",
            outline: "none",
            boxShadow: 24,
            p: 4,
            //make width on mobile 300
            "@media (max-width: 768px)": {
              width: 360,
            },
          }}
        >
          {props.loading === true ? (
            <Spinner h={70} w={70} />
          ) : (
            <>
              <CloseIcon
                onClick={props.onClose}
                sx={{
                  position: "absolute",
                  cursor: "pointer",
                  top: "20px",
                  right: "20px",
                  color: colors.icon.enabled,
                }}
              />
              <VuiBox display="flex" justifyContent="left" alignItems="center">
                <VuiTypography variant="h6" sx={{ fontWeight: "bold", color: colors.white.main }}>
                  Share
                </VuiTypography>
              </VuiBox>
              <VuiBox
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  // padding: "15px",
                  alignItems: "center",
                  width: "100%",
                  height: "50%",
                  "&:hover": {
                    cursor: "pointer",
                    //   background: colors.white.main,
                    borderTopRightRadius: "6px",
                    borderTopLeftRadius: "6px",
                  },
                }}
                className="share-buttons"
                onClick={() => {
                  // create an a tag
                  // set its href to twitter.com/share
                  // call a.click()
                  logAuthorizedWombatEvent("sharedToTwitterFromGeneratePage", {
                    shareId: props.link,
                  });
                  const a = document.createElement("a");
                  a.href = `https://twitter.com/intent/tweet?text=Check+out+my+AI+textured+3D+model+on+Polyhive%21%0D%0A%0D%0Ahttps%3A%2F%2Fpolyhive.ai%2Fs%3Fid%3D${props.link}%0D%0D&hashtags=stablediffusion,ai,texttotexture,texttogame`;
                  a.target = "_blank";
                  a.click();
                }}
              >
                <Twitter
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#fff",
                    background: "#1da1f2",
                    borderRadius: "50%",
                    padding: "10px",
                    marginRight: "10px",
                  }}
                  hashtags={["vrm", "vroid", "3d", "3dmodel"]}
                  className="share-buttons"
                />
                <VuiTypography
                  sx={{
                    fontSize: "0.9rem",
                    color: colors.white.main,
                    textAlign: "center",
                  }}
                  className="share-buttons"
                >
                  Twitter
                </VuiTypography>
              </VuiBox>
              <VuiBox
                display="flex"
                alignItems="center"
                sx={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "25px",
                  backgroundColor: colors.background.secondary,
                  // space between
                  justifyContent: "space-between",
                  padding: "20px",
                  paddingRight: "7px",
                }}
              >
                <VuiTypography
                  sx={{
                    fontSize: "0.9rem",
                    color: colors.white.main,
                    textAlign: "center",
                  }}
                >
                  {`${process.env.REACT_APP_FRONTEND_URL}/s?id=${props.link}`}
                </VuiTypography>
                <Button
                  sx={{
                    color: colors.white.main,
                    backgroundColor: colors.icon.focusdark,
                    borderRadius: "20px",
                    padding: "7px 12px",
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: colors.icon.focusdark,
                      color: colors.white.main,
                    },
                  }}
                  onClick={() => {
                    logAuthorizedWombatEvent("copiedLinkFromGeneratePage", {
                      shareId: props.link,
                    });
                    navigator.clipboard.writeText(
                      `${process.env.REACT_APP_FRONTEND_URL}/s?id=${props.link}`
                    );
                    setCopied(true);
                  }}
                >
                  {copied ? "Copied" : "Copy"}
                </Button>
              </VuiBox>
            </>
          )}
        </VuiBox>
      </Modal>
      <Backdrop
        open={props.open}
        sx={{ backdropFilter: "blur(2px)", backgroundColor: "rgba(0, 0, 0, 0.1)" }}
      />
    </>
  );
};

const Spinner = (props) => {
  return (
    <Oval
      ariaLabel="loading-indicator"
      height={props.h}
      width={props.w}
      strokeWidth={5}
      strokeWidthSecondary={2}
      color={colors.icon.disabled}
      secondaryColor="white"
      // visible={!loadingModel}
      wrapperStyle={{
        position: "absolute",
        // top: "100px",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    />
  );
};

export default ShareModal;
