/**

 =========================================================
 * Vision UI PRO React - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com/)

 * Design and Coded by Simmmple & Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Modal from "@mui/material/Modal";
import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import VuiButton from "../../../../../components/VuiButton";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import VuiInput from "../../../../../components/VuiInput";
import Tooltip from "@mui/material/Tooltip";
import { logoutUser } from "layouts/authentication/AuthFunctions";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useVisionUIController } from "context";
import amplitude from "amplitude-js";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";

const buttonStyle = {
  textAlign: "center",

  right: "20px",
  bottom: "20px",
  position: "absolute",
};
const modalStyle = {
  height: "100%",
  position: "absolute",
  alignItems: "left",
  justifyContent: "left",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "rgba(24,27,88,1)",
  border: "2px solid #000",
  boxShadow: 24,
  justifyContent: "center",
  p: 3,
  borderRadius: "15px",
};

const copyFieldStyle = {
  borderTopLeftRadius: "0.9375rem",
  borderBottomLeftRadius: "0.9375rem",
};

const ActionCell = forwardRef((props, ref) => {
  const cookies = new Cookies();
  const [controller, dispatch] = useVisionUIController();
  const { activeOrg, isAdmin } = controller;

  const navigate = useNavigate();
  const [keyModalOpen, setKeyModalOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const [tooltipText, setTooltipText] = React.useState("Click to copy to clipboard");

  useImperativeHandle(ref, () => ({
    openKeyModal() {
      setKeyModalOpen(true);
    },
    closeKeyModal() {
      setKeyModalOpen(false);
    },
  }));

  const handleActions = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleKeyModalOpen = () => {
    logAuthorizedWombatEvent("showKeyClicked", {});

    setKeyModalOpen(true);
  };
  const handleKeyModalClose = () => {
    setKeyModalOpen(false);
  };

  // Open Modal to edit API key
  // const handleEditKey = () => {
  //   handleMenuClose();
  // };

  // Open Modal to delete API key
  const handleDeleteKey = () => {
    logAuthorizedWombatEvent("deleteKeyClicked", {});

    fetch(`${process.env.REACT_APP_BACKEND_URL}/developer/apiKeyForOrg`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
      body: JSON.stringify({ apiKey: props.dataFields.key.substring(0, 5) }),
    }).then((res) => {
      if (res.status === 200) {
        props.onDelete(props.dataFields.key);
      } else if (res.status === 403) {
        logoutUser(navigate);
      }
    });
    handleMenuClose();
  };

  useEffect(() => {
    //console.log("schmactiveorg", activeOrg);
  }, [activeOrg]);

  const copyToClipboard = () => {
    if (props.dataFields.key.length > 5) {
      setTooltipText("Copied key");
      navigator.clipboard.writeText(props.dataFields.key);
    }
  };

  const inputWithTooltip = (
    <Tooltip title={tooltipText}>
      <VuiInput
        style={{
          borderTopLeftRadius: "0.9375rem",
          borderBottomLeftRadius: "0.9375rem",
          userSelect: "text",
        }}
        readOnly={true}
        placeholder="Type here..."
        icon={{
          style: {
            color: "white",
          },
          component: "copy",
          direction: "right",
        }}
        defaultValue={props.dataFields.key}
      />
    </Tooltip>
  );

  const inputWithoutTooltip = (
    <VuiInput
      style={{
        borderTopLeftRadius: "0.9375rem",
        borderBottomLeftRadius: "0.9375rem",
        userSelect: "text",
      }}
      readOnly={true}
      placeholder="Type here..."
      defaultValue={props.dataFields.key + "***************"}
    />
  );

  return (
    <>
      <VuiBox display="flex" alignItems="center">
        <VuiBox>
          <VuiButton
            variant="text"
            size="large"
            textTransform="capitalize"
            fontWeight="medium"
            color="info"
            onClick={handleKeyModalOpen}
            sx={{ padding: "0" }}
          >
            SHOW KEY
          </VuiButton>
        </VuiBox>
        {isAdmin && (
          <IconButton size="large" color="info" onClick={handleActions}>
            <MoreVertIcon fontSize="inherit"></MoreVertIcon>
          </IconButton>
        )}

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {/* <MenuItem onClick={handleEditKey}>Edit API Key</MenuItem> */}
          <MenuItem onClick={handleDeleteKey}>Delete API Key</MenuItem>
        </Menu>
      </VuiBox>

      <Modal
        open={keyModalOpen}
        onClose={handleKeyModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ modalStyle }}
      >
        <VuiBox sx={style}>
          <div className="Upload" style={{ textAlign: "center" }}>
            <h4 style={{ textAlign: "center", color: "white" }}>{props.dataFields.name}</h4>
            <VuiTypography color="white" variant="caption"></VuiTypography>
            <VuiBox onClick={copyToClipboard} mt={2}>
              {props.dataFields.key.length > 5 ? inputWithTooltip : inputWithoutTooltip}
            </VuiBox>
          </div>
          <VuiBox mt={4} mb={1}>
            <VuiButton
              variant="text"
              size="large"
              textTransform="capitalize"
              fontWeight="medium"
              color="info"
              onClick={handleKeyModalClose}
              style={buttonStyle}
            >
              CLOSE
            </VuiButton>
          </VuiBox>
        </VuiBox>
      </Modal>
    </>
  );
});

export default ActionCell;
