import { Box, Typography, Grid, Button } from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import CloseIcon from "@mui/icons-material/Close";
import { modelToImage } from "util/modelToImage";

import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import colors from "assets/theme/base/colors";
import { ColorRing } from "react-loader-spinner";
import Thumbnailer from "../../../../dashboards/default/components/Inspector/Thumbnailer/index";
import { logoutUser } from "layouts/authentication/AuthFunctions";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import { HtmlTooltip } from "layouts/dashboards/default/components/CustomTooltip/index";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";
import { getFileTypeFromS3Url } from "util/UtilFunctions";

const it_steps = 45;

const Dock = forwardRef((props, ref) => {
  const [jobsShown, setJobsShown] = useState([]);
  const jobsShownRef = useRef(null);
  jobsShownRef.current = jobsShown;

  const [jobsShownIndex, setJobsShownIndex] = useState(0);
  const [thumbnailerArray, setThumbnailerArray] = useState([]);

  const [loadingThumbnails, setLoadingThumbnails] = useState(false);

  useImperativeHandle(ref, () => ({
    refreshDock() {
      console.log("refreshDock called");
      setJobsShown([...jobsShown]);
    },
  }));

  useEffect(() => {
    // test();
    if (props.jobs.length > 0) {
      // get the first 8 jobs with the status "completed, in progress, or queued" from the list and set them to jobsShown
      const jobs = props.jobs.filter(
        (job) =>
          job.status === "Completed" ||
          job.status === "In progress" ||
          job.status === "Queued" ||
          job.status === "Failed"
      );

      const jobsShownNoThumb = jobs.slice(0, 8);
      for (let i = 0; i < jobsShownNoThumb.length; i++) {
        jobsShownNoThumb[i].thumbnail = "";
      }
      setJobsShown(jobsShownNoThumb);
      //   setJobsShown(jobs.slice(0, 8));
    }
  }, [props.jobs]);

  const previousJobsShownRef = useRef([]);

  useEffect(() => {
    if (jobsShown.length > 0 && !arraysEqual(jobsShown, previousJobsShownRef.current)) {
      previousJobsShownRef.current = jobsShown;
      //   getJobThumbnails();
    }
    console.log("jobsShown changed", jobsShown);
    // for each job in jobsShown, if the thumbnail is empty, get the thumbnail
    const copyArray = [];

    for (let i = 0; i < jobsShown.length; i++) {
      if (
        (jobsShown[i].res_thumbnail === null || jobsShown[i].res_thumbnail === "") &&
        jobsShown[i].res_asset_path &&
        jobsShown[i].res_asset_path !== null
      ) {
        copyArray.push({
          url: jobsShown[i].res_asset_path,
          file: {
            name: jobsShown[i].jobName + "." + getFileTypeFromS3Url(jobsShown[i].res_asset_path),
          },
          headers: {
            "ASSOCIATED-UUID": jobsShown[i].assetId,
            "VERSION-ID": jobsShown[i].resVersionId,
          },
        });
        setThumbnailerArray(copyArray);
      }
    }
  }, [jobsShown]);

  function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  useEffect(() => {
    console.log("jobsShownIndex", jobsShownIndex);
    // need to set jobsShown to the next 8 jobs that are completed, in progress, or queued
    const jobs = props.jobs.filter(
      (job) =>
        job.status === "Completed" ||
        job.status === "In progress" ||
        job.status === "Queued" ||
        job.status === "Failed"
    );
    const jobsShownNoThumb = jobs.slice(jobsShownIndex, jobsShownIndex + 8);
    setJobsShown([...jobsShownNoThumb]);
  }, [jobsShownIndex]);

  const handleNext = () => {
    const jobs = props.jobs.filter(
      (job) =>
        job.status === "Completed" ||
        job.status === "In progress" ||
        job.status === "Queued" ||
        job.status === "Failed"
    );
    if (jobsShownIndex + 8 < jobs.length) {
      setJobsShownIndex(jobsShownIndex + 8);
    }
  };

  const handlePrev = () => {
    if (jobsShownIndex - 8 >= 0) {
      setJobsShownIndex(jobsShownIndex - 8);
    }
  };
  const handleFinishedScreenshot = (screenshot) => {
    console.log("handleFinishedScreenshot called in Dock", screenshot);
    handleBlob({
      thumbnailURL: screenshot.thumbnailURL,
      thumbnailFile: screenshot.thumbnail,
      id: screenshot.headers["ASSOCIATED-UUID"],
      versionId: screenshot.headers["VERSION-ID"],
    });
    // set the thumbnail for the job in jobsShown that matches the versionId
    const jobsShownCopy = [...jobsShown];
    for (let i = 0; i < jobsShownCopy.length; i++) {
      if (jobsShownCopy[i].resVersionId === screenshot.headers["VERSION-ID"]) {
        jobsShownCopy[i].res_thumbnail = screenshot.thumbnailURL;
      }
    }
    setJobsShown(jobsShownCopy);
  };

  const handleBlob = (obj) => {
    const headers = {
      "Content-Type": "application/octet-stream",
      "ASSOCIATED-UUID": obj.id,
      "FILE-SIZE": obj.thumbnailFile.size,
      "X-ORG-ID": props.activeOrg,
      "VERSION-ID": obj.versionId,
    };
    const response_thumbnail_upload = fetch(
      `${process.env.REACT_APP_BACKEND_URL}/upload/uploadThumbnailForVersion`,
      {
        body: obj.thumbnailFile,
        credentials: "include",
        headers: headers,
        method: "POST",
      }
    )
      .then((response) => {
        //console.log("thumbnail response", response);
        return response;
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("thumbnail upload success");
        } else if (response.status === 403) {
          logoutUser(navigate);
        } else {
          //console.log("thumbnail upload failed, cancelling grid item addition");
        }
      });

    // this is a folder
    return;
  };
  return (
    <Box className="retexture_container">
      <Box className="retexture_form_body">
        <Grid container spacing={1}>
          {jobsShown.length > 0 ? (
            jobsShownRef.current.map((job, index) => {
              return (
                <Grid item sm={12}>
                  <Box
                    className={
                      props.currentJob.jobId === job.jobId
                        ? "align_center_row w100 bg_light position_relative"
                        : "align_center_row w100 position_relative"
                    }
                    onClick={() => {
                      logAuthorizedWombatEvent("jobViewedFromDock", {
                        job: job,
                      });
                      props.handleView(job);
                      // props.handleSelectedMesh(-2);
                    }}
                  >
                    {job.status === "In progress" ? (
                      <Box
                        sx={{
                          width:
                            job.status === "Queued" || job.status === "In progress"
                              ? `${
                                  (job.step / it_steps) * 100 > 100
                                    ? 100
                                    : (job.step / it_steps) * 100
                                }%`
                              : "",

                          height: "100%",
                          backgroundColor: "rgba(100, 100, 100, 0.3)",
                          position: "absolute",
                          zIndex: 0,
                          left: 0,
                          top: 0,
                          transition: "width 0.5s",
                        }}
                      ></Box>
                    ) : (
                      <></>
                    )}

                    <Box className={"dock_flex"}>
                      {job.status === "Queued" ? (
                        <>{job.jobName === "image" ? <></> : <Spinner h={50} w={50} />}</>
                      ) : job.status === "Failed" ? (
                        <Box className="center_column bg_error failure_icon p_1">
                          <ReportProblemRoundedIcon className="c_white bg_error" />
                          <Typography className="c_white f_small fw_500"> Failed </Typography>
                        </Box>
                      ) : (
                        <>
                          {job.res_thumbnail === null || job.res_thumbnail === "" ? (
                            <Spinner h={50} w={50} />
                          ) : (
                            <img src={job.res_thumbnail} className="dock_thumb" />
                            // <></>
                          )}
                        </>
                      )}
                    </Box>
                    <Box className="dock_prompt_container">
                      <Typography className="c_white f_small fw_500 wrap_ellipses">
                        {job.textPrompt}
                      </Typography>
                      <Typography className="c_iconenabled f_small fw_500 wrap_ellipses">
                        <b>Seed: </b>
                        {job.job_params.seed}
                      </Typography>
                    </Box>
                    <Box className="dock_status_container">
                      {job.status === "Queued" ? (
                        <Button
                          className="default_btn bg_error c_white f_small fw_500"
                          startIcon={<CloseIcon className="dock_cancel c_white bg_error" />}
                          onClick={() => {
                            logAuthorizedWombatEvent("jobCancelledFromDock", {
                              job: job,
                            });
                            props.handleCancel(job);
                          }}
                        >
                          Cancel
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Box>
                </Grid>
              );
            })
          ) : (
            <Typography className="c_white f_small fw_500 w_100 ta_center mt_3">
              {" "}
              You haven't textured any assets yet, use the Texture tab at the top to view your
              results here!{" "}
            </Typography>
          )}
        </Grid>
      </Box>
      <Box className="retexture_form_footer">
        <hr className="rt_form_hr" />
        <Box className="align_center_row w100 d_justify_center">
          <Box className="dock_flex" onClick={handlePrev}>
            <ArrowBackRoundedIcon
              className={
                jobsShownIndex - 8 >= 0
                  ? "dock_arrow c_white bg_light"
                  : "dock_arrow c_icondisabled bg_dark"
              }
            />
          </Box>
          <Box className="dock_flex" onClick={handleNext}>
            <ArrowForwardRoundedIcon
              className={
                props.jobs.filter(
                  (job) =>
                    job.status === "Completed" ||
                    job.status === "In progress" ||
                    job.status === "Queued" ||
                    job.status === "Failed"
                ).length >
                jobsShownIndex + 8
                  ? "dock_arrow c_white bg_light"
                  : "dock_arrow c_icondisabled bg_dark"
              }
            />
          </Box>
        </Box>
      </Box>
      <Thumbnailer
        data={thumbnailerArray}
        handleFinishedScreenshot={handleFinishedScreenshot}
        startSnackbar={props.startSnackbar}
      />
    </Box>
  );
});

const Spinner = (props) => {
  return (
    <ColorRing
      visible={true}
      height={props.h}
      width={props.w}
      ariaLabel="blocks-loading"
      wrapperStyle={{}}
      wrapperClass="blocks-wrapper"
      colors={[
        colors.icon.focus,
        colors.icon.secondaryfocus,
        colors.icon.focus,
        colors.icon.secondaryfocus,
        colors.icon.focus,
      ]}
    />
  );
};
export default Dock;
