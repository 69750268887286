import React from "react";
import "./style.css";
//
import Hero from "./Hero";
import Management from "./Management";
import FileStructure from "./FileStructure";
import Footer from "./layout/footer/Footer.jsx";
import Header from "./layout/header/Header.jsx";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import pageRoutes from "page.routes";
import { Helmet } from "react-helmet";
import Content1 from "./Content1";
import Content2 from "./Content2";
import Content3 from "./Content3";
import Content4 from "./Content4";
import Content5 from "./Content5";

const Home = () => {
  return (
    <div className="home_wrap w_100">
      <Helmet>
        <link rel="canonical" href="https://polyhive.ai/" />
      </Helmet>
      <DefaultNavbar
        routes={pageRoutes}
        action={{
          type: "external",
        }}
        transparent
      />
      <Hero />
      {/* <Content1 /> */}
      {/* <Content2 /> */}
      {/* <Content3 /> */}
      {/* <Content4 /> */}
      {/* <Content5 /> */}
      {/* <Management />
      <FileStructure /> */}
      {/* <Footer /> */}
    </div>
  );
};

export default Home;
