import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import colors from "assets/theme/base/colors";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import { useState, setState } from "react";

const modalStyle = {
  width: "200px",
  height: "100%",
  position: "absolute",
  alignItems: "center",
  justifyContent: "center",
};
const CreateFolderModal = (props) => {
  const folderNames = props.allFolders.map((gridItem) =>
    gridItem.fileName ? gridItem.fileName : gridItem.folderName
  );
  //console.log("CreateFolderModal folderNames", folderNames);

  const [currentFolderName, setCurrentFolderName] = useState("");

  const disabled = folderNames.includes(currentFolderName);

  const updateCurrentFolderName = () => {
    setCurrentFolderName(document.getElementById("folderName").value);
  };

  const formSubmitted = (e) => {
    e.preventDefault();

    if (disabled) {
      return;
    }
    // props.handleCreateFolder(folderName);
    //console.log("form submitted");
    const folderName = document.getElementById("folderName").value;
    const folderId = generateUUID();
    const requestBody = {
      folder_name: folderName,
      folder_id: folderId,
    };

    if (props.currentFolder !== "") {
      requestBody.parent_folder_id = props.currentFolder;
    }
    console.log("requestBody", requestBody);

    const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/upload/createFolder`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": props.activeOrg,
      },
      body: JSON.stringify(requestBody),
    }).then((res) => {
      if (res.status === 200) {
        console.log("folder creation success");
        props.handleCreateFolder({
          folderName: folderName,
          modelId: folderId,
          parentFolder: props.currentFolder,
        });
      } else {
        //console.log("error", res);
      }
    });
  };

  const disabledText = `${currentFolderName} already exists in this directory. Please choose a different name`;
  const submitButton = !disabled ? (
    <Button
      // onClick={props.handleCreateFolder}
      // type="submit"
      onClick={formSubmitted}
      className="default_btn fw_500 f_small c_white bg_quaternaryfocusdark"
    >
      Create Folder
    </Button>
  ) : (
    <Tooltip title={disabledText}>
      <span>
        <Button
          // onClick={props.handleCreateFolder}
          // type="submit"
          disabled
          onClick={formSubmitted}
          className="default_btn fw_500 f_small c_white bg_quaternaryfocusdark"
        >
          Create Folder
        </Button>
      </span>
    </Tooltip>
  );

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ modalStyle }}
    >
      <VuiBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: colors.background.main,
          // border: "2px solid #000",
          boxShadow: 24,
          justifyContent: "center",
          p: 3,
          borderRadius: "15px",
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="" style={{ textAlign: "left" }}>
          <VuiTypography variant="h6" sx={{ color: "#fff", marginBottom: "10px" }}>
            Create a new folder
          </VuiTypography>
          <form onSubmit={formSubmitted}>
            <input
              // id="outlined-basic"
              label="Folder Name"
              variant="outlined"
              id="folderName"
              onChange={updateCurrentFolderName}
              style={{
                width: "100%",
                marginBottom: "10px",
                background: colors.border.main,
                border: "none",
                color: "#fff",
                borderRadius: "4px",
                padding: "0px 10px",
                height: "30px",
              }}
            />
          </form>
          <Button
            onClick={props.handleClose}
            className="default_btn fw_500 f_small c_white br_icondisabled br_1px"
          >
            Cancel
          </Button>
          &nbsp;&nbsp;&nbsp;
          {submitButton}
        </div>
      </VuiBox>
    </Modal>
  );
};

/* Utility for generating a unique uuid for uploading a file. */
const generateUUID = () => {
  // Public Domain/MIT
  let d = new Date().getTime(); //Timestamp
  let d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};
export default CreateFolderModal;
