/*

Polyhive Dashboard

*/

import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

import PropTypes from "prop-types";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import SidenavRoot from "examples/Sidenav/SidenavRoot";

import GenerateTooltip from "assets/icons/sidenavgeneratetooltip.png";
import GenerateTooltip2 from "assets/icons/sidenavgeneratetooltip2.png";
import ContentTooltip from "assets/icons/sidenavcontenttooltip.png";

import { useVisionUIController, setMiniSidenav, setCurrentlyUploading } from "context";

import colors from "assets/theme/base/colors";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import BurstModeOutlinedIcon from "@mui/icons-material/BurstModeOutlined";

import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";
import React from "react";
import { HtmlTooltip } from "layouts/dashboards/default/components/CustomTooltip/index";
import "examples/Sidenav/style.css";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import AutoAwesomeMosaicOutlinedIcon from "@mui/icons-material/AutoAwesomeMosaicOutlined";
import GradientIcon from "components/Custom/GradientIcon/index";
import FormatPaintOutlinedIcon from "@mui/icons-material/FormatPaintOutlined";
import BrushOutlinedIcon from "@mui/icons-material/BrushOutlined";
import TextureIcon from "@mui/icons-material/Texture";
import PagesOutlinedIcon from "@mui/icons-material/PagesOutlined";
import FoundationOutlinedIcon from "@mui/icons-material/FoundationOutlined";

function Sidenav({
  permissions,
  showRetextureForm,
  handleRetextureForm,
  showPromptingForm,
  handlePromptingForm,
  showInpaintingForm,
  handleInpaintingForm,
  showTiledForm,
  handleTiledForm,
  showCanvasForm,
  handleCanvasForm,
  showMeshGenerateForm,
  handleMeshGenerateForm,
  showMeshDemoModal,
  handleMeshDemoModal,
  brand,

  ...rest
}) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useVisionUIController();
  const { transparentSidenav, isAdmin, currentlyUploading } = controller;
  const { miniSidenav } = true;
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const itemName = pathname.split("/").slice(1)[1];

  const [currentlyUploadingModalOpen, setCurrentlyUploadingModalOpen] = useState(false);
  const [link, setLink] = useState("");
  const closeSidenav = () => setMiniSidenav(dispatch, true);

  const handleCurrentlyUploadingModalOpen = () => setCurrentlyUploadingModalOpen(true);
  const handleCurrentlyUploadingModalClose = () => setCurrentlyUploadingModalOpen(false);
  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      // setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    // window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    // handleMiniSidenav();

    // Remove event listener on cleanup
    // return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // useEffect(() => {
  //   if (currentlyUploading === true) {
  //     console.log("HELLO?");
  //     handleCurrentlyUploadingModalOpen();
  //   } else {
  //     navigate(link);
  //   }
  // }, [link]);

  const handlePageChange = () => {
    navigate(link);
  };

  return (
    <>
      {/* <OrgNav /> */}
      <SidenavRoot {...rest} variant="permanent" ownerState={{ miniSidenav, transparentSidenav }}>
        {/* <Scrollbars
          autoHide
          autoHideTimeout={1000}
          renderThumbVertical={renderThumb}
          renderTrackVertical={window.location.href.includes("rtl") ? renderTrackRTL : renderTrack}
          renderView={window.location.href.includes("rtl") ? renderViewRTL : renderView}
        > */}
        <VuiBox
          display="flex"
          sx={{
            flexDirection: "column",
            height: "100%",
            // dont display on mobile
          }}
          mb={3}
          className="sidenav"
        >
          <VuiBox
            mt={3}
            sx={{
              display: "flex",
              color: pathname === "/manage/content" ? "#fff" : colors.icon.enabled,
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                color: "#fff",
                cursor: "pointer",
              },
              borderRadius: "7px",
              // backgroundColor: pathname === "/manage/content" ? "#31343E" : "none",
            }}
            onClick={() => {
              logAuthorizedWombatEvent("navigatedToManageContentPage", {});
              navigate("/manage/content");
            }}
          >
            <HtmlTooltip
              placement="right"
              title={
                <React.Fragment>
                  <img src={ContentTooltip} className="sidenav_tooltip_image" />
                  <VuiTypography className="sidenav_tooltip_title">Asset Library</VuiTypography>
                  <p className="sidenav_tooltip_body">Manage your 3D assets and upload new ones.</p>
                </React.Fragment>
              }
              className="sidenav_tooltip"
            >
              <AutoAwesomeMosaicOutlinedIcon
                className="sidenav_icon"
                sx={{
                  color: pathname === "/manage/content" ? colors.icon.quaternaryfocus : "inherit",
                }}
              />
            </HtmlTooltip>
          </VuiBox>
          <hr className="sidenav_hr" />
          <VuiBox
            mt={3}
            sx={{
              display: "flex",
              color: colors.icon.enabled,
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                color: "#fff",
                cursor: "pointer",
                pointer: "cursor",
              },
              borderRadius: "7px",
            }}
            onClick={() => {
              if (pathname === "/generate") {
                logAuthorizedWombatEvent("toggledRetextureForm", {});
                handleInpaintingForm(false);
                handlePromptingForm(false);
                handleTiledForm(false);
                handleCanvasForm(false);

                handleRetextureForm(true);
              } else {
                logAuthorizedWombatEvent("navigatedToGeneratePage", {});
                navigate("/generate");
              }
            }}
          >
            <HtmlTooltip
              placement="right"
              title={
                <React.Fragment>
                  <img src={GenerateTooltip} className="sidenav_tooltip_image" />
                  <VuiTypography className="sidenav_tooltip_title">Classic Generate</VuiTypography>
                  <p className="sidenav_tooltip_body">
                    Fully texture a 3D mesh with a text prompt in one shot.
                  </p>
                </React.Fragment>
              }
              className="sidenav_tooltip"
            >
              {pathname === "/generate" ? (
                showRetextureForm === true ? (
                  <GradientIcon>
                    <ViewInArOutlinedIcon className="sidenav_icon" />
                  </GradientIcon>
                ) : (
                  <ViewInArOutlinedIcon className="sidenav_icon" />
                )
              ) : (
                <ViewInArOutlinedIcon className="sidenav_icon" />
              )}
            </HtmlTooltip>
          </VuiBox>

          <VuiBox
            mt={3}
            sx={{
              display: "flex",
              color: colors.icon.enabled,
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                color: "#fff",
                cursor: "pointer",
                pointer: "cursor",
              },
              borderRadius: "7px",
            }}
            onClick={() => {
              if (pathname === "/generate") {
                logAuthorizedWombatEvent("toggledRetextureForm", {});
                handleRetextureForm(false);
                handleInpaintingForm(false);
                handleTiledForm(false);
                handleCanvasForm(false);
                handleMeshGenerateForm(false);
                handlePromptingForm(true);
              } else {
                logAuthorizedWombatEvent("navigatedToGeneratePage", {});
                navigate("/generate");
              }
            }}
          >
            <HtmlTooltip
              placement="right"
              title={
                <React.Fragment>
                  <img src={GenerateTooltip2} className="sidenav_tooltip_image" />
                  <VuiTypography className="sidenav_tooltip_title">Generate</VuiTypography>
                  <p className="sidenav_tooltip_body">
                    Select between previews to find the best texture for your 3D mesh.
                  </p>
                </React.Fragment>
              }
              className="sidenav_tooltip"
            >
              {pathname === "/generate" ? (
                showPromptingForm === true ? (
                  <GradientIcon>
                    <BurstModeOutlinedIcon className="sidenav_icon" />
                  </GradientIcon>
                ) : (
                  <BurstModeOutlinedIcon className="sidenav_icon" />
                )
              ) : (
                <BurstModeOutlinedIcon className="sidenav_icon" />
              )}
            </HtmlTooltip>
          </VuiBox>
          {/* <VuiBox
            mt={3}
            sx={{
              display: "flex",
              color: colors.icon.enabled,
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                color: "#fff",
                cursor: "pointer",
                pointer: "cursor",
              },
              borderRadius: "7px",
            }}
            onClick={() => {
              if (pathname === "/generate") {
                logAuthorizedWombatEvent("toggledInpaintingForm", {});
                handleRetextureForm(false);
                handlePromptingForm(false);
                handleTiledForm(false);
                handleCanvasForm(false);
                handleMeshGenerateForm(false);

                handleInpaintingForm(true);
              } else {
                logAuthorizedWombatEvent("navigatedToGeneratePage", {});
                navigate("/generate");
              }
            }}
          >
            <HtmlTooltip
              placement="right"
              title={
                <React.Fragment>
                  <img src={GenerateTooltip2} className="sidenav_tooltip_image" />
                  <VuiTypography className="sidenav_tooltip_title">AI Paintbrush</VuiTypography>
                  <p className="sidenav_tooltip_body">
                    Paint a region directly onto your mesh and let the AI do the rest.
                  </p>
                </React.Fragment>
              }
              className="sidenav_tooltip"
            >
              {pathname === "/generate" ? (
                showInpaintingForm === true ? (
                  <GradientIcon>
                    <FormatPaintOutlinedIcon className="sidenav_icon" />
                  </GradientIcon>
                ) : (
                  <FormatPaintOutlinedIcon className="sidenav_icon" />
                )
              ) : (
                <FormatPaintOutlinedIcon className="sidenav_icon" />
              )}
            </HtmlTooltip>
          </VuiBox> */}
          {/* {permissions && permissions.special_model && permissions.special_model === "Civitas" ? ( */}
          <>
            <VuiBox
              mt={3}
              sx={{
                display: "flex",
                color: colors.icon.enabled,
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  color: "#fff",
                  cursor: "pointer",
                  pointer: "cursor",
                },
                borderRadius: "7px",
              }}
              onClick={() => {
                if (pathname === "/generate") {
                  logAuthorizedWombatEvent("toggledTiledForm", {});
                  handleRetextureForm(false);
                  handlePromptingForm(false);
                  handleInpaintingForm(false);
                  handleCanvasForm(false);
                  handleMeshGenerateForm(false);

                  handleTiledForm(true);
                } else {
                  logAuthorizedWombatEvent("navigatedToGeneratePage", {});
                  navigate("/generate");
                }
              }}
            >
              <HtmlTooltip
                placement="right"
                title={
                  <React.Fragment>
                    <img src={GenerateTooltip2} className="sidenav_tooltip_image" />
                    <VuiTypography className="sidenav_tooltip_title">PBR Generate</VuiTypography>
                    <p className="sidenav_tooltip_body">
                      Generate tileable PBR textures for your 3D assets.
                    </p>
                  </React.Fragment>
                }
                className="sidenav_tooltip"
              >
                {pathname === "/generate" ? (
                  showTiledForm === true ? (
                    <GradientIcon>
                      <TextureIcon className="sidenav_icon" />
                    </GradientIcon>
                  ) : (
                    <TextureIcon className="sidenav_icon" />
                  )
                ) : (
                  <TextureIcon className="sidenav_icon" />
                )}
              </HtmlTooltip>
            </VuiBox>
          </>
          {/* ) : null} */}
          <VuiBox
            mt={3}
            sx={{
              display: "flex",
              color: colors.icon.enabled,
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                color: "#fff",
                cursor: "pointer",
                pointer: "cursor",
              },
              borderRadius: "7px",
            }}
            onClick={() => {
              if (pathname === "/generate") {
                logAuthorizedWombatEvent("toggledCanvasForm", {});
                handleRetextureForm(false);
                handlePromptingForm(false);
                handleInpaintingForm(false);
                handleTiledForm(false);
                handleMeshGenerateForm(false);

                handleCanvasForm(true);
              } else {
                logAuthorizedWombatEvent("navigatedToGeneratePage", {});
                navigate("/generate");
              }
            }}
          >
            <HtmlTooltip
              placement="right"
              title={
                <React.Fragment>
                  <img src={GenerateTooltip2} className="sidenav_tooltip_image" />
                  <VuiTypography className="sidenav_tooltip_title">2D Canvas</VuiTypography>
                  <p className="sidenav_tooltip_body">
                    Edit preview images to gain full control over your textures.
                  </p>
                </React.Fragment>
              }
              className="sidenav_tooltip"
            >
              {pathname === "/generate" ? (
                showCanvasForm === true ? (
                  <GradientIcon>
                    <PagesOutlinedIcon className="sidenav_icon" />
                  </GradientIcon>
                ) : (
                  <PagesOutlinedIcon className="sidenav_icon" />
                )
              ) : (
                <PagesOutlinedIcon className="sidenav_icon" />
              )}
            </HtmlTooltip>
          </VuiBox>
          {/*  */}
          {/* <VuiBox
            mt={3}
            sx={{
              display: "flex",
              color: colors.icon.enabled,
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                color: "#fff",
                cursor: "pointer",
                pointer: "cursor",
              },
              borderRadius: "7px",
            }}
            onClick={() => {
              if (pathname === "/generate") {
                logAuthorizedWombatEvent("toggledMeshForm", {});
                handleRetextureForm(false);
                handlePromptingForm(false);
                handleInpaintingForm(false);
                handleTiledForm(false);
                handleCanvasForm(false);
                handleMeshDemoModal(true);
              } else {
                logAuthorizedWombatEvent("navigatedToGeneratePage", {});
                navigate("/generate");
              }
            }}
          >
            <HtmlTooltip
              placement="right"
              title={
                <React.Fragment>
                  <img src={GenerateTooltip2} className="sidenav_tooltip_image" />
                  <VuiTypography className="sidenav_tooltip_title">Mesh Generate</VuiTypography>
                  <p className="sidenav_tooltip_body">Generate 3D meshes from a text prompt.</p>
                </React.Fragment>
              }
              className="sidenav_tooltip"
            >
              {pathname === "/generate" ? (
                showMeshGenerateForm === true ? (
                  <GradientIcon>
                    <FoundationOutlinedIcon className="sidenav_icon" />
                  </GradientIcon>
                ) : (
                  <FoundationOutlinedIcon className="sidenav_icon" />
                )
              ) : (
                <FoundationOutlinedIcon className="sidenav_icon" />
              )}
            </HtmlTooltip>
          </VuiBox> */}
        </VuiBox>
        {/* </Scrollbars> */}
      </SidenavRoot>
      <CurrentlyUploadingModal
        open={currentlyUploadingModalOpen}
        handleClose={handleCurrentlyUploadingModalClose}
        handleChange={handlePageChange}
        link={link}
        navigate={navigate}
      />
    </>
  );
}

const modalStyle = {
  width: "300px",
  height: "100%",
  position: "absolute",
  alignItems: "center",
  justifyContent: "center",
};
const CurrentlyUploadingModal = (props) => {
  const [controller, dispatch] = useVisionUIController();

  const handleChange = () => {
    props.handleChange();
    setCurrentlyUploading(dispatch, false);
    props.handleClose();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ modalStyle }}
    >
      <VuiBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: colors.background.main,
          // border: "2px solid #000",
          boxShadow: 24,
          justifyContent: "center",
          p: 3,
          borderRadius: "15px",
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="" style={{ textAlign: "left" }}>
          <VuiTypography variant="h6" sx={{ color: "#fff", marginBottom: "10px" }}>
            Are you sure you want to leave this page?
          </VuiTypography>
          <VuiTypography variant="body2" sx={{ color: colors.warning.main, marginBottom: "12px" }}>
            You have an upload in progress. Leaving this page will cancel the upload and may result
            in some thumbnails being generated incorrectly.
          </VuiTypography>
          <Button
            onClick={props.handleClose}
            variant="outlined"
            sx={{
              outline: "none",
              border: "1.5px solid " + colors.background.secondary,
              color: colors.icon.enabled,
              borderRadius: "7px",
              height: "15px",
              // padding: "0px",
              margin: "0px",
              "&:hover": { border: "1.5px solid " + colors.icon.disabled },
            }}
          >
            Stay
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            // onClick={props.handleCreateFolder}
            // type="submit"
            variant="contained"
            onClick={handleChange}
            style={{
              color: "#000",
              backgroundColor: colors.success.main,
              borderRadius: "7px",
            }}
          >
            Confirm
          </Button>
        </div>
      </VuiBox>
    </Modal>
  );
};
// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
};

export default Sidenav;
