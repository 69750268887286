/*

Polyhive Dashboard

*/

// @mui material components
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";

export default styled(Drawer)(({ theme, ownerState }) => {
  const { palette, boxShadows, transitions, breakpoints, functions } = theme;
  const { transparentSidenav, miniSidenav } = ownerState;

  const sidebarWidth = 64;
  const { white, transparent, gradients } = palette;
  const { xxl } = boxShadows;
  const { pxToRem } = functions;

  // styles for the sidenav when miniSidenav={false}
  const drawerOpenStyles = () => ({
    transform: "translateX(0)",
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    boxShadow: transparentSidenav ? "none" : xxl,
    marginBottom: 0,
    width: sidebarWidth,
    transform: "translateX(0)",
    transition: transitions.create(["width", "background-color"], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
    "@media (max-width: 600px)": {
      display: "none",
    },
  });

  // styles for the sidenav when miniSidenav={true}
  const drawerCloseStyles = () => ({
    transform: `translateX(${pxToRem(-320)})`,
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    // [breakpoints.up("xl")]: {
    //   boxShadow: transparentSidenav ? "none" : xxl,
    //   marginBottom: 0,
    //   width: pxToRem(96),
    //   overflowX: "hidden",
    //   transform: "translateX(0)",
    //   transition: transitions.create(["width", "background-color"], {
    //     easing: transitions.easing.sharp,
    //     duration: transitions.duration.shorter,
    //   }),
    // },
  });

  return {
    "& .MuiDrawer-paper": {
      boxShadow: xxl,
      border: "none",
      background: colors.background.main,
      margin: "0",
      position: "fixed",
      top: "calc(50% - 157px)",
      // transform: "translateY(50%)",
      boxSizing: "border-box",
      // marginLeft: pxToRem(0),
      height: "auto",
      borderRadius: "15px",
      zIndex: "1200",
      borderRight: "1px solid " + colors.border.main,
      borderTop: "1px solid " + colors.border.main,
      borderBottom: "1px solid " + colors.border.main,

      borderBottomLeftRadius: "0",
      borderTopLeftRadius: "0",

      backdropFilter: transparentSidenav ? "unset" : "blur(120px)",

      ...drawerOpenStyles(),
    },
  };
});
