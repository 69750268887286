/*

Polyhive Dashboard

*/

export default {
  defaultProps: {
    disableRipple: true,
  },
};
