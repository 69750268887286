import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import colors from "assets/theme/base/colors";
import { styled } from "@mui/material/styles";
import { withStyles, makeStyles } from "@material-ui/core/styles";

const DarkTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.background.secondary,
    color: colors.icon.enabled,
    boxShadow: theme.shadows[1],
    fontSize: "0.8rem",
    fontWeight: "bold",
    width: "auto",
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      backgroundColor: colors.background.secondary,
    },
  },
}));
const DarkLongTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.background.secondary,
    color: colors.icon.enabled,
    boxShadow: theme.shadows[1],
    fontSize: "0.9rem",
    fontWeight: 400,
    width: "400px !important",
    maxWidth: "400px !important",
    textAlign: "left",
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      backgroundColor: colors.background.secondary,
    },
  },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.background.secondary,
    color: colors.icon.enabled,
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.7)", // add this line for drop shadow
    fontSize: "0.9rem",
    fontWeight: 400,
    // width: "400px !important",
    maxWidth: "400px !important",
    textAlign: "left",
    // border: "1px solid " + colors.icon.enabled,
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      background: "none",
    },
  },
}));

export { DarkTooltip, DarkLongTooltip, HtmlTooltip };

export default DarkTooltip;
