/** 

=========================================================
* AQUEDUCT CONTENT PAGE
=========================================================
* Designed and Coded by Rohan Iyer
=========================================================

*/

// @mui material components
import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";
// Vision UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ModelGrid2 from "layouts/dashboards/default/components/ModelGrid/index2";

function Content() {
  const { values } = breakpoints;

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <VuiBox py={3}>
        <ModelGrid2></ModelGrid2>
      </VuiBox>
    </DashboardLayout>
  );
}

export default Content;
