// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiBadge from "components/VuiBadge";
import VuiButton from "components/VuiButton";
import colors from "assets/theme/base/colors";

// React-icons
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { stripeCheckout } from "util/UtilFunctions";
import { useVisionUIController } from "context";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";

function DefaultPricingCard({
  badge,
  price,
  specifications,
  action,
  className,
  buttonClassName,
  activeOrg,
  flavorText,
}) {
  const renderSpecifications = specifications.map(({ label, includes }) => (
    <VuiBox key={label} display="flex" alignItems="center" p={1}>
      <VuiBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.5rem"
        height="1.5rem"
        borderRadius="50%"
        shadow="md"
        mr={2}
      >
        <VuiTypography variant="button" color="white" sx={{ lineHeight: 0 }}>
          {includes === true ? (
            <CheckRoundedIcon className="c_quaternaryfocus icon_medium" />
          ) : includes === false ? (
            <AiFillCloseCircle color="#e31a1a" size="22px" />
          ) : (
            <></>
          )}
        </VuiTypography>
      </VuiBox>
      <VuiTypography variant="body2" color="white" className="f_small">
        {label}
      </VuiTypography>
    </VuiBox>
  ));

  return (
    <VuiBox className={className}>
      <VuiBox>
        <VuiBox textAlign="center">
          <VuiTypography className="c_white fw_400 f_xlarge">{badge.label}</VuiTypography>
          <VuiBox my={1}>
            <VuiTypography className="c_white fw_600 f_xxlarge">
              <VuiTypography display="inline" className="c_white fw_600 f_xxlarge">
                {price.currency}
              </VuiTypography>
              {price.value}
            </VuiTypography>
          </VuiBox>
        </VuiBox>

        <VuiTypography className="f_small c_white fw_500 ml_1 mt_4 mb_1">
          {flavorText}
        </VuiTypography>
        {/* <hr className="clean_hr" /> */}

        {renderSpecifications}
      </VuiBox>
      <VuiBox>
        {action.type === "internal" ? (
          <VuiBox mt={3}>
            <VuiButton component={Link} to={action.route} fullWidth className={buttonClassName}>
              {action.label}&nbsp;
            </VuiButton>
          </VuiBox>
        ) : action.type === "stripe" ? (
          <VuiBox mt={3}>
            <VuiButton
              type="submit"
              fullWidth
              className={buttonClassName}
              onClick={() => {
                if (badge.label === "Plus") {
                  // stripe for plus
                  logAuthorizedWombatEvent("clickedUpgradeToPlusButton", {});
                  stripeCheckout("polyhive_plus_plan", activeOrg);
                }
              }}
            >
              {action.label}&nbsp;
            </VuiButton>
          </VuiBox>
        ) : action.type === "cancel_plus" ? (
          <VuiBox mt={3}>
            <VuiButton fullWidth className={buttonClassName}>
              {action.label}&nbsp;
            </VuiButton>
          </VuiBox>
        ) : (
          <VuiBox mt={3}>
            <VuiButton
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              fullWidth
              className={buttonClassName}
            >
              {action.label}&nbsp;
            </VuiButton>
          </VuiBox>
        )}
      </VuiBox>
    </VuiBox>
  );
}

// Typechecking props for the DefaultPricingCard
DefaultPricingCard.propTypes = {
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "text",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired,
};

export default DefaultPricingCard;
