import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import Button from "@mui/material/Button";
import * as OV from "painting-3d-viewer";
import envMap1 from "assets/envmaps/fishermans_bastion/posx.jpg";
import envMap2 from "assets/envmaps/fishermans_bastion/negx.jpg";
import envMap3 from "assets/envmaps/fishermans_bastion/posy.jpg";
import envMap4 from "assets/envmaps/fishermans_bastion/negy.jpg";
import envMap5 from "assets/envmaps/fishermans_bastion/posz.jpg";
import envMap6 from "assets/envmaps/fishermans_bastion/negz.jpg";
import colors from "assets/theme/base/colors";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { Raycaster, Vector2 } from "three";

import Albedo from "assets/test_materials/albedo.jpg";
import Normal from "assets/test_materials/normal.jpg";
import Roughness from "assets/test_materials/roughness.jpg";
import Metalness from "assets/test_materials/metallic.png";
import Height from "assets/test_materials/height.jpg";
import Smoothness from "assets/test_materials/smoothness.png";

import UVPainter from "util/UVPainter";

import * as THREE from "three";

// const FACE_LIMIT = 25000;
const FACE_LIMIT_ON = false;

const MESH_LIMIT_ON = false;
const ModelViewer = forwardRef((props, ref) => {
  let paintCanvas = null;
  let paintContext = null;

  let eraseCanvas = null;
  let eraseContext = null;

  let uvPainter = null;
  const mouse = new Vector2();

  const paintbrushSizeRef = useRef(null);
  paintbrushSizeRef.current = props.paintbrushSize;

  const paintbrushColorRef = useRef(null);
  paintbrushColorRef.current = props.paintbrushColor;

  const paintbrushTypeRef = useRef(null);
  paintbrushTypeRef.current = props.paintbrushType;

  const [mouseDragging, setMouseDragging] = useState(false);
  const draggingRef = useRef(null);
  draggingRef.current = mouseDragging;

  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const cursorPositionRef = useRef(null);
  cursorPositionRef.current = cursorPosition;

  const [cursorOverTarget, setCursorOverTarget] = useState(false);
  const cursorOverTargetRef = useRef(null);
  cursorOverTargetRef.current = cursorOverTarget;

  const urlRef = useRef(null);
  urlRef.current = props.url;

  const [viewer1, setViewer1] = useState(null);
  const viewer1Ref = useRef(null);
  viewer1Ref.current = viewer1;

  const raycaster = new Raycaster();

  const [mouseCoords, setMouseCoords] = useState({ x: 0, y: 0 });
  const mouseCoordsRef = useRef(null);
  mouseCoordsRef.current = mouseCoords;

  const permsRef = useRef(null);
  permsRef.current = props.permissions;

  const inpainting = useRef(null);
  inpainting.current = props.showInpaintingForm;

  const tiled = useRef(null);
  tiled.current = props.showTiledForm;

  const canvasFormRef = useRef(null);
  canvasFormRef.current = props.showCanvasForm;

  const [raycastTarget, setRaycastTarget] = useState(null);
  const raycastTargetRef = useRef(null);
  raycastTargetRef.current = raycastTarget;

  const [boundingSphere, setBoundingSphere] = useState(null);
  const boundingSphereRef = useRef(null);
  boundingSphereRef.current = boundingSphere;

  useImperativeHandle(ref, () => ({
    handleProgressUpdate: async (data, callback) => {
      console.log("handleProgressUpdate", data);
      if (viewer1Ref.current !== null) {
        await viewer1Ref.current.LoadModelFromUrlList([data.obj_path]);
        callback(data.texture_map_path, true);
      }
    },
  }));

  const onModelLoaded = (e) => {
    const viewer = viewer1Ref.current.GetViewer();
    let boundingSphereTemp = viewer.GetBoundingSphere((meshUserData) => {
      return true;
    });

    console.log("viewer.scene", viewer.scene);

    setBoundingSphere(boundingSphereTemp);

    console.log("should we load tiledObject? ", tiled.current);
    if (tiled.current === true) {
      // remove this object from scene and add a cube where the texture will be applied
      console.log("viewer.scene.children", viewer.scene.children);

      for (let i = 0; i < viewer.scene.children.length; i++) {
        if (viewer.scene.children[i].type === "Object3D") {
          console.log("removing object", viewer.scene.children[i]);
          viewer.scene.remove(viewer.scene.children[i]);
        }
      }

      const loader = new THREE.TextureLoader();

      const albedo = loader.load(Albedo);
      albedo.wrapS = albedo.wrapT = THREE.RepeatWrapping;
      albedo.repeat.set(2, 1);

      const normal = loader.load(Normal);
      normal.wrapS = normal.wrapT = THREE.RepeatWrapping;
      normal.repeat.set(2, 1);

      const roughness = loader.load(Roughness);
      roughness.wrapS = roughness.wrapT = THREE.RepeatWrapping;
      roughness.repeat.set(2, 1);

      const metalness = loader.load(Metalness);
      metalness.wrapS = metalness.wrapT = THREE.RepeatWrapping;
      metalness.repeat.set(2, 1);

      const height = loader.load(Height);
      height.wrapS = height.wrapT = THREE.RepeatWrapping;
      height.repeat.set(2, 1);
      const planeMaterial = new THREE.MeshStandardMaterial({
        side: THREE.DoubleSide, // Apply the material to both sides of the plane
        metalness: 1,
      });

      const envMap = new THREE.CubeTextureLoader().load([
        envMap1,
        envMap2,
        envMap3,
        envMap4,
        envMap5,
        envMap6,
      ]);
      envMap.mapping = THREE.CubeReflectionMapping;
      planeMaterial.envMap = envMap;
      planeMaterial.envMapIntensity = 1;
      planeMaterial.needsUpdate = true;

      // Create a sphere and apply the material we defined earlier
      const sphereGeometry = new THREE.SphereGeometry(5, 256, 256);
      const sphere = new THREE.Mesh(sphereGeometry, planeMaterial);
      boundingSphereTemp = new THREE.Sphere(new THREE.Vector3(0, 0, 0), 10);
      sphere.position.set(0, 0, 0);
      // name the sphere tiledSphere so we can find it later
      sphere.name = "tiledObject";
      console.log("adding tiledObject");
      viewer.scene.add(sphere);
    }

    if (canvasFormRef.current === true) {
      for (let i = 0; i < viewer.scene.children.length; i++) {
        if (viewer.scene.children[i].type === "Object3D") {
          console.log("removing object", viewer.scene.children[i]);
          viewer.scene.remove(viewer.scene.children[i]);
        }
      }
      viewer.renderer.render(viewer.scene, viewer.camera);

      return;
    }

    console.log("boundingSphere", boundingSphere);

    const anisotropy = viewer.renderer.capabilities.getMaxAnisotropy();

    // MESH LIMIT CHECK
    if (viewer.scene.children.length > 0) {
      var meshCount = 0;
      var faces = 0;
      var vertexCount = 0;
      let objMesh = null;
      viewer.scene.traverse(function (object) {
        console.log("object", object);
        if (object.isMesh && object.name !== "planeYZ") {
          var geometry = object.geometry;
          geometry = BufferGeometryUtils.mergeVertices(geometry);

          vertexCount += geometry.attributes.position.count;
          objMesh = object;

          meshCount++;
          if (geometry.index) {
            faces += geometry.index.count / 3;
          } else {
            faces += geometry.attributes.position.count / 3;
          }
          const materials = Array.isArray(object.material) ? object.material : [object.material];
          materials.forEach((material) => {
            console.log("setting material", material);
            console.log("setting prev metalness to " + material.metalness);
            props.handlePrevMetalness(material.metalness);
            props.handlePrevTexture(material);
          });

          console.log("mesh", object);
        }
      });
      if (viewer.scene.getObjectByName("noEnvLight")) {
        viewer.scene.remove(viewer.scene.getObjectByName("noEnvLight"));
      }

      logAuthorizedWombatEvent("modelLoaded", {
        meshCount: meshCount,
        vertexCount: vertexCount,
        faceCount: faces,
        textures: viewer.renderer.info.memory.textures,
      });

      console.log("viewer info, Total vertices:", vertexCount);
      console.log("viewer info, Total faces:", faces);
      console.log("viewer info, textures:", viewer.renderer.info.memory.textures);
      let FACE_LIMIT;
      if (permsRef.current.face_count_level) {
        FACE_LIMIT = parseInt(permsRef.current.face_count_level.permission_value);
      } else {
        FACE_LIMIT = 25000;
      }
      console.log("Checking facelimit", FACE_LIMIT, " vs ", faces, " FACE_LIMIT_ON", FACE_LIMIT_ON);

      if (faces > FACE_LIMIT && FACE_LIMIT_ON === true && tiled.current === false) {
        console.log("faces exceed limit");
        logAuthorizedWombatEvent("faceExceedsLimitError", {
          error:
            "The model you uploaded has " +
            faces +
            " faces. The face limit for your subscription tier is " +
            FACE_LIMIT +
            " faces.",
          faceCount: faces,
        });
        props.errorCB(
          "The model you uploaded has " +
            faces +
            " faces. The face limit for your subscription tier is " +
            FACE_LIMIT +
            " faces."
        );
        return;
      }

      if (meshCount > 1 && MESH_LIMIT_ON === true && tiled.current === false) {
        logAuthorizedWombatEvent("submeshExceedsLimitError", {
          error:
            "The model you uploaded has more than one mesh. To use Polyhive, please upload a model with only one mesh.",
          meshCount: meshCount,
        });
        props.errorCB(
          "The model you uploaded has more than one mesh. To use Polyhive, please upload a model with only one mesh."
        );
        return;
      }

      // i want to log the data type of objMesh.material
      console.log(
        "objMesh",
        objMesh,
        "type",
        typeof objMesh.material,
        "isArray",
        objMesh.material.isArray
      );

      if (
        (objMesh.name !== "tiledObject" &&
          objMesh.material[0].map &&
          objMesh.material[0].map.source) ||
        (objMesh.material.map && objMesh.material.map.source)
      ) {
        const map = objMesh.material[0].map;
        const spherical = new THREE.Spherical();
        spherical.setFromCartesianCoords(
          viewer.camera.position.x,
          viewer.camera.position.y,
          viewer.camera.position.z
        );
        const distance = spherical.radius;
        console.log("distance from object", distance);

        console.log("should be setting old tex map to ", map);
        props.handleOldTextureMap(map.source);

        let loadedTexture;
        loadedTexture = map;
        console.log("progressupdate: texture being applied", loadedTexture);
        loadedTexture.wrapS = THREE.RepeatWrapping;
        loadedTexture.wrapT = THREE.RepeatWrapping;
        loadedTexture.magFilter = THREE.LinearFilter;
        loadedTexture.minFilter = THREE.LinearMipMapLinearFilter;

        // create a new meshbasicmaterial with the texture
        const material = new THREE.MeshBasicMaterial({
          map: loadedTexture,
          side: THREE.DoubleSide,
          transparent: false,
          opacity: 1,
        });
        // Assign the loaded texture to the target material's map property
        console.log("new texture target", objMesh);
        objMesh.material[0] = material;
        // Mark the material's map as needing an update
        objMesh.material[0].map.needsUpdate = true;

        // If you want to render the scene and see the changes, you can call the renderer.render function
        viewer.renderer.render(viewer.scene, viewer.camera);
      } else {
        console.error("no texture map found");
      }
    }

    // get texture map from mesh

    viewer.AdjustClippingPlanesToSphere(boundingSphereTemp);
    viewer.FitSphereToWindow(boundingSphereTemp, true);
    props.handleNewScene(viewer.scene, viewer.renderer, viewer.camera);

    // PAINTING CODE
    viewer.scene.traverse(function (object) {
      if (object.type === "Mesh" && object.name !== "planezy" && object.name !== "planexy") {
        setRaycastTarget(object);
      }
    });

    console.log("anisotropy", anisotropy);
  };

  let originalTextureImage;

  const paintUV = (uv, materialIndex, mesh) => {
    const texture = mesh.material[materialIndex].map;

    // get texture dimensions
    const textureDim = texture.image.width;

    // Ensure that the texture image data is loaded
    if (!texture || !texture.image) {
      console.error("Texture image data is not available.");
      return;
    }

    // Create a temporary canvas and context
    if (!paintCanvas) {
      originalTextureImage = texture.image;
      paintCanvas = document.createElement("canvas");
      paintContext = paintCanvas.getContext("2d");
    }

    if (paintCanvas.width !== textureDim || paintCanvas.height !== textureDim) {
      originalTextureImage = texture.image;
      paintCanvas.width = textureDim;
      paintCanvas.height = textureDim;
    }

    if (!eraseCanvas) {
      eraseCanvas = document.createElement("canvas");
      eraseContext = eraseCanvas.getContext("2d");
    }

    if (eraseCanvas.width !== textureDim || eraseCanvas.height !== textureDim) {
      eraseCanvas.width = textureDim;
      eraseCanvas.height = textureDim;
    }

    // Set the canvas size to match the texture dimensions

    const radius =
      paintbrushTypeRef.current === "fill" ? 4000 : paintbrushSizeRef.current * 0.001 * textureDim;

    if (paintbrushTypeRef.current === "brush" || paintbrushTypeRef.current === "fill") {
      // Draw the current texture image onto the canvas
      paintContext.drawImage(texture.image, 0, 0);

      // Perform the painting operation
      paintContext.beginPath();
      paintContext.arc(uv.x * textureDim, uv.y * -textureDim, radius, 0, 2 * Math.PI, false);
      // KEEP FOR INPAINTING
      // context.fillStyle = "#FF00FE";
      paintContext.fillStyle = paintbrushColorRef.current;
      paintContext.fill();

      // Update the texture image with the new canvas
      texture.image = paintCanvas;
      texture.needsUpdate = true;
    } else if (paintbrushTypeRef.current === "eraser") {
      // Clear the eraseCanvas
      eraseContext.clearRect(0, 0, eraseCanvas.width, eraseCanvas.height);

      const x = uv.x * textureDim;
      const y = uv.y * -textureDim;

      eraseContext.save();

      // Draw a circle on the eraseCanvas
      eraseContext.beginPath();
      eraseContext.arc(x, y, radius, 0, Math.PI * 2);
      eraseContext.closePath();
      eraseContext.clip();

      // Draw the original texture within the circle
      eraseContext.drawImage(originalTextureImage, 0, 0);

      eraseContext.restore();

      // Draw the eraseCanvas onto the main paintCanvas
      paintContext.drawImage(texture.image, 0, 0); // Draw the current texture image onto the paintCanvas
      paintContext.drawImage(eraseCanvas, 0, 0); // Draw the circular section

      // Update the texture image with the new canvas
      texture.image = paintCanvas;
      texture.needsUpdate = true;
    }

    // viewer1Ref.current.renderer.render(viewer1Ref.current.scene, viewer1Ref.current.camera);
  };

  const handleMouseMove = (event) => {
    // console.log("mouse move, viewer", viewer);
    // console.log("MOUSE MOVE EVENT, event", event);

    if (inpainting.current !== true) {
      return;
    }
    const viewer = viewer1Ref.current.GetViewer();

    // const canvasBounds = event.target.getBoundingClientRect();
    // since canvasBounds.left is always 0 and canvasBounds.top is always 140
    // we can just set them manually

    // console.log("canvasBounds", canvasBounds);
    // setCursorPosition({ x: event.clientX, y: event.clientY });

    const mouseX = event.clientX - 470;
    const mouseY = event.clientY - 60;

    mouse.x = (mouseX / viewer.renderer.domElement.clientWidth) * 2 - 1;
    mouse.y = -(mouseY / viewer.renderer.domElement.clientHeight) * 2 + 1;

    // console.log("viewer.camera", viewer.camera);
    raycaster.setFromCamera(mouse, viewer.camera);

    // console.log("INTIAL UV MAPPING: ", raycastTarget.geometry.attributes.uv);

    try {
      let intersects;
      if (draggingRef.current) {
        // Perform raycasting against the actual object when dragging
        intersects = raycaster.intersectObject(raycastTargetRef.current, true);
      } else {
        // Perform raycasting against the bounding sphere when not dragging

        intersects = raycaster.ray.intersectsSphere(boundingSphereRef.current) ? [1] : [];
      }

      // console.log("intersects", intersects);
      if (intersects.length > 0) {
        // console.log("mouse move, intersects", intersects);
        const clickedObject = intersects[0].object;
        const intersectedFace = intersects[0].face;
        const uv = intersects[0].uv;
        setCursorOverTarget(true);

        if (draggingRef.current !== true) {
          return;
        }

        paintUV(uv, intersectedFace.materialIndex, clickedObject);
      } else {
        setCursorOverTarget(false);
      }
    } catch (e) {
      // throw error
      console.log("HANDLEMOUSEMOVE_ERROR", e);
    }
  };

  useEffect(() => {
    let parentDiv = document.getElementById("retexture-model-viewer");

    const viewer = new OV.EmbeddedViewer(parentDiv, {
      camera: new OV.Camera(
        new OV.Coord3D(-1.5, 2.0, 3.0),
        new OV.Coord3D(0.0, 0.0, 0.0),
        new OV.Coord3D(0.0, 1.0, 0.0),
        45.0
      ),
      backgroundColor: new OV.RGBAColor(255, 255, 255, 0),
      defaultColor: new OV.RGBColor(200, 200, 200),
      edgeSettings: new OV.EdgeSettings(false, new OV.RGBColor(0, 0, 0), 1),
      environmentSettings: new OV.EnvironmentSettings(
        [envMap1, envMap2, envMap3, envMap4, envMap5, envMap6],
        false
      ),
      onModelLoaded: onModelLoaded,
    });
    props.setRendererContext(viewer.viewer.scene, viewer.viewer.camera, viewer.viewer.renderer);
    setViewer1(viewer);
    window.addEventListener("resize", handleResize);

    return () => {
      viewer.Destroy();
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, [props.fullscreen]);

  const handleResize = () => {
    if (viewer1Ref.current !== null) {
      viewer1Ref.current.Resize();
    }
  };

  useEffect(() => {
    // initialize the viewer with the parent element and some parameters

    OV.SetExternalLibLocation("../libs");
    console.log("tiledObject: attempting to load model");

    if (viewer1Ref.current === null || typeof props.url === "undefined" || props.url === null)
      return;

    try {
      if (typeof props.url === "string") {
        viewer1Ref.current.LoadModelFromUrlList([props.url]);
      } else if (Array.isArray(props.url)) {
        viewer1Ref.current.LoadModelFromUrlList(props.url);
      } else {
        viewer1Ref.current.LoadModelFromFileList([props.url]);
      }
    } catch (error) {
      console.log("error", error);
    }

    // setViewer(viewer1);
  }, [props.url, viewer1]);

  useEffect(() => {
    const handleMouseClick = (event) => {
      // setMouseDragging(true);

      // set mouse draggin to true only if its left click
      if (event.button === 0) {
        console.log("mouse down, setting dragging to true");
        setMouseDragging(true);
      }
    };

    const handleMouseUp = (event) => {
      if (event.button === 0) {
        console.log("mouse up, setting dragging to false");
        setMouseDragging(false);
      }
    };
    window.addEventListener("mousedown", handleMouseClick);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("mousemove", handleMouseMove);

    // Add event listener for wheel events

    return () => {
      window.removeEventListener("mousedown", handleMouseClick);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const getCursorSvg = (radius) => {
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${radius * 2}" height="${
      radius * 2
    }" viewport="0 0 100 100" style="font-size:24px;"><circle cx="${radius}" cy="${radius}" r="${radius}" fill="#FF00FE" fill-opacity="0.5"/></svg>`;
    return `url('data:image/svg+xml;utf8,${encodeURIComponent(svg)}') ${radius} ${radius}, auto`;
  };

  const squareNum = (num) => {
    return num * num;
  };
  return (
    <>
      <div
        id="retexture-model-viewer"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "0px",
          left: "0px",
          cursor: cursorOverTargetRef.current ? "crosshair" : "default", // Use a crosshair cursor when over target, default otherwise
        }}
      ></div>
      {cursorOverTarget && (
        <div
          style={{
            position: "fixed",
            left: `${cursorPosition.x - props.paintbrushSize}px`,
            top: `${cursorPosition.y - props.paintbrushSize}px`,
            width: `${props.paintbrushSize * 2}px`,
            height: `${props.paintbrushSize * 2}px`,
            borderRadius: "50%",
            backgroundColor: "rgba(255, 255, 254, 0)", // semi-transparent purple
            border: "1px solid rgba(255, 255, 254, 1)", // semi-transparent purple
            pointerEvents: "none", // to avoid interfering with mouse events
          }}
        ></div>
      )}
    </>
  );
});

export default ModelViewer;
