import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Grid from "@mui/material/Grid";
import colors from "assets/theme/base/colors";
import Button from "@mui/material/Button";
import React, { useEffect, useRef, useState } from "react";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import amplitude from "amplitude-js";
import Tooltip from "@mui/material/Tooltip";
import DeleteVersionModal from "../DeleteVersionModal";
import DarkTooltip from "../../CustomTooltip/index";
import ChangeVersionModal from "../ChangeVersionModal/index";
import { getFileType, parseDateUTC, getTimeDifference } from "util/UtilFunctions";

// assetPath -> filePath,
// thumbnailPath -> thumbnail
// lastUpdatedTime -> lastUpdated
const JobItem = (props) => {
  const [polling, setPolling] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const jobRef = useRef(null);
  jobRef.current = props.job;
  useEffect(() => {
    if (props.job.completedTime === null && props.job.errorMsg === null) {
      console.log(
        "job item polling: ",
        polling,
        props.job.completedTime,
        props.job.startedTime,
        "props.job",
        jobRef.current
      );
    } else if (props.job.textPrompt === "test prompt") {
      console.log("test job ", props.job);
    }
    if (props.job.status === "In progress" || props.job.status === "Queued") {
      setPolling(true);
      const interval = setInterval(() => {
        console.log("starting polling");
        if (
          props.job.status === "Failed" ||
          props.job.status === "Completed" ||
          props.job.status === "Cancelled"
        ) {
          console.log("stopping polling");
          setPolling(false);
          clearInterval(interval);
        } else {
          props.fetchJob(props.job.jobId);
        }
      }, 5000);
      // set interval to set current time every second
      const timeInterval = setInterval(() => {
        if (
          props.job.status === "Failed" ||
          props.job.status === "Completed" ||
          props.job.status === "Cancelled"
        ) {
          clearInterval(timeInterval);
        } else {
          setCurrentTime(new Date());
        }
      }, 1000);

      return () => {
        clearInterval(interval);
        clearInterval(timeInterval);
      };
    } else if (props.job.completedTime !== null && props.job.startedTime !== null) {
      setPolling(false);
    }
  }, [props.job]);

  const cancelJob = () => {
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/killRetextureJob`);
    const response = fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": props.activeOrg,
      },
      body: JSON.stringify({ job_id: props.job.jobId }),
    }).then((res) => {
      console.log("cancel job response", res);
      props.fetchJob(props.job.jobId);
    });
  };

  return (
    <>
      <Grid item xs={12}>
        <VuiBox
          mt={2}
          mb={0}
          width="100%"
          height="120px"
          sx={{
            borderRadius: "4px",
            border: "1px solid " + colors.icon.focusdark,

            "&:hover": {
              border: "1px solid " + colors.highlight.light,
            },
          }}
          padding="4px"
          position="relative"
        >
          <VuiBox
            sx={{
              width: "100%",
              height: "100%",
              //   alignItems: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <VuiBox
              sx={{
                height: "100%",
                marginLeft: "0px",
                marginTop: "7px",
                // alignItems: "top",
                // position: "relative",
                // top: "0px",
              }}
            >
              {props.job.status === "Queued" || props.job.status === "In progress" ? (
                <Button
                  variant="text"
                  textTransform="none"
                  sx={{
                    // border: "1.5px solid " + colors.icon.focusdark,
                    color: colors.white.main,
                    background: colors.error.main,
                    fontSize: "0.7rem",
                    width: "70px",
                    height: "25px",
                    marginRight: "12px",
                    marginTop: "12px",
                    fontWeight: "400",
                    right: "0",
                    top: "0",
                    position: "absolute",
                    lineHeight: "1",
                    textTransform: "none",
                    borderRadius: "5px",

                    "&:hover": {
                      color: "#fff",
                      background: colors.error.focus,
                    },
                    "&:focus": {
                      color: "#fff",
                      background: colors.error.main,
                    },
                  }}
                  onClick={cancelJob}
                >
                  Cancel
                </Button>
              ) : (
                <> </>
              )}

              <DarkTooltip title={props.job.jobName}>
                <VuiTypography
                  variant="body2"
                  sx={{
                    fontWeight: 450,
                    fontSize: "0.9rem",
                    color: "#fff",
                    width: "240px",
                    display: "flex",
                    textAlign: "left",
                    marginLeft: "10px",
                    marginBottom: "2px",
                    marginTop: "0px",
                  }}
                >
                  <span
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {props.job.jobName}
                  </span>
                </VuiTypography>
              </DarkTooltip>
              <VuiTypography
                variant="body2"
                sx={{
                  fontWeight: 450,
                  fontSize: "0.9rem",
                  color:
                    props.job.status === "Completed" ? colors.success.main : colors.warning.main,
                  width: "205px",
                  //   make first letter of status uppercase
                  textTransform: "capitalize",
                  textAlign: "left",
                  marginLeft: "10px",
                  marginBottom: "7px",
                  marginTop: "0px",
                }}
              >
                <span
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {props.job.errorMsg === null &&
                  props.job.status !== "Failed" &&
                  props.job.status !== "Cancelled" ? (
                    <>
                      <>{props.job.status}</>
                      <span style={{ textTransform: "none" }}>
                        {props.job.status === "Completed"
                          ? " at " + parseDateUTC(props.job.completedTime)
                          : props.job.eta !== null
                          ? " ETA: " + getTimeDifference(currentTime, new Date(props.job.eta))
                          : " ETA: Calculating"}
                      </span>
                    </>
                  ) : (
                    <span style={{ color: colors.error.main }}>
                      {props.job.status === "" ? "Failed" : props.job.status}
                    </span>
                  )}
                </span>
              </VuiTypography>
              <DarkTooltip title={'"' + props.job.textPrompt + '"'}>
                <VuiTypography
                  variant="body2"
                  sx={{
                    fontWeight: 450,
                    fontSize: "0.8rem",
                    color: colors.icon.enabled,
                    textAlign: "left",
                    marginLeft: "10px",
                    height: "45px",
                    width: "330px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    "-webkit-line-clamp": "2",
                    "-webkit-box-orient": "vertical",
                    display: "-webkit-box",
                  }}
                >
                  Prompt: "{props.job.textPrompt}"
                </VuiTypography>
              </DarkTooltip>
              <VuiTypography
                variant="body2"
                sx={{
                  fontWeight: 450,
                  fontSize: "0.8rem",
                  color: colors.icon.focusdark,
                  textAlign: "left",
                  marginLeft: "10px",
                }}
              >
                {/* {props.job.completedTime !== null ? (
                  ""
                ) : (
                  <span style={{ color: colors.icon.enabled }}>
                    {"Started at " + parseDateUTC(props.job.startedTime)}
                  </span>
                )} */}
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        </VuiBox>
      </Grid>
    </>
  );
};

export default JobItem;
