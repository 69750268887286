import * as OV from "painting-3d-viewer";
import Sidebar from "./components/Sidebar";
import ViewerWindow from "./components/ViewerWindow";
import "./style.css";

import { useEffect, useState } from "react";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import pageRoutes from "page.routes";
import Management from "../home/Management";
import Footer from "../home/layout/footer/Footer.jsx";
import ManagementViewer from "./components/ManagementViewer.jsx";

const Viewer = () => {
  const [assetId, setAssetId] = useState("");
  useEffect(() => {
    // get parameters from URL
    // document.title = "Polyhive - Shared AI Textured Asset";
    const urlParams = new URLSearchParams(window.location.search);
    const modelId = urlParams.get("id");
    console.log("assetId", modelId);
    setAssetId(modelId);
  }, []);

  return (
    <>
      <DefaultNavbar
        routes={pageRoutes}
        action={{
          type: "external",
          route: "https://creative-tim.com/product/vision-ui-dashboard-pro-react",
          label: "buy now",
        }}
        transparent
      />
      <ViewerWindow assetId={assetId} activeOrg="user-live-55e5441e-9599-4b6f-84f1-bb757a3bd9ca" />
      {/* <ManagementViewer /> */}
      <Footer headerText={true} />
    </>
  );
};

export default Viewer;
