import * as OV from "painting-3d-viewer";
import { useVisionUIController } from "context";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import envMap1 from "assets/envmaps/fishermans_bastion/posx.jpg";
import envMap2 from "assets/envmaps/fishermans_bastion/negx.jpg";
import envMap3 from "assets/envmaps/fishermans_bastion/posy.jpg";
import envMap4 from "assets/envmaps/fishermans_bastion/negy.jpg";
import envMap5 from "assets/envmaps/fishermans_bastion/posz.jpg";
import envMap6 from "assets/envmaps/fishermans_bastion/negz.jpg";
import VuiTypography from "components/VuiTypography/index";
import colors from "assets/theme/base/colors";
import VuiBox from "components/VuiBox/index";
import Grid from "@mui/material/Grid";
import { formatBytes, parseDateUTC } from "util/UtilFunctions";
import DarkTooltip from "../../../dashboards/default/components/CustomTooltip/index";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Twitter } from "../../../../../node_modules/@mui/icons-material/index";
import CloseIcon from "@mui/icons-material/Close";
import { Helmet } from "react-helmet";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { logWombatEvent, getDeviceId } from "util/UTMFunctions";

const ViewerWindow = (props) => {
  const navigate = useNavigate();
  const [controller, dispatch] = useVisionUIController();
  const { activeOrg } = controller;
  const [info, setInfo] = React.useState({
    ai_job_info: {},
    asset_info: { fileName: "", lastUpdatedTime: "" },
    user_info: {
      profilePicURL: "",
      username: "",
    },
  });
  const [isMobile, setIsMobile] = useState(false);

  const [metaDataTitle, setMetaDataTitle] = useState("");
  const [metaDataImageURL, setMetaDataImageURL] = useState("");

  const [shareModalOpen, setShareModalOpen] = useState(false);

  const [fullscreen, setFullscreen] = useState(false);

  const handleShareModalOpen = async () => {
    logWombatEvent("clickedShareButtonFromShareViewer", {
      shareId: props.assetId,
    });
    if (isMobile) {
      try {
        await navigator.share({
          title: "Polyhive Generated Texture",
          text: "Share this texture",
          url: `${process.env.REACT_APP_FRONTEND_URL}/s?id=${shareLink}`,
        });
      } catch (err) {
        console.log("Share error", err);
        setShareModalOpen(true);
      }
    } else {
      setShareModalOpen(true);
    }
  };

  const handleShareModalClose = () => {
    setShareModalOpen(false);
  };

  useEffect(() => {
    console.log("useEffect", props.assetId);

    if (props.assetId !== "") {
      // set the location of the external libraries
      OV.SetExternalLibLocation("../libs");

      // get the parent element of the viewer
      let parentDiv = document.getElementById("viewer");

      // initialize the viewer with the parent element and some parameters
      let viewer;

      const onModelLoaded = () => {
        console.log("onModelLoaded", viewer.GetModel());
        const model = viewer.GetModel();
        const viewer1 = viewer.GetViewer();
        let boundingSphere = viewer1.GetBoundingSphere((meshUserData) => {
          return true;
        });
        viewer1.AdjustClippingPlanesToSphere(boundingSphere);
        viewer1.FitSphereToWindow(boundingSphere, true);
      };
      viewer = new OV.EmbeddedViewer(parentDiv, {
        camera: new OV.Camera(
          new OV.Coord3D(-1.5, 2.0, 3.0),
          new OV.Coord3D(0.0, 0.0, 0.0),
          new OV.Coord3D(0.0, 1.0, 0.0),
          45.0
        ),
        backgroundColor: new OV.RGBAColor(255, 255, 255, 255),
        defaultColor: new OV.RGBColor(200, 200, 200),
        edgeSettings: new OV.EdgeSettings(false, new OV.RGBColor(0, 0, 0), 1),
        environmentSettings: new OV.EnvironmentSettings(
          [envMap1, envMap2, envMap3, envMap4, envMap5, envMap6],
          false
        ),
        onModelLoaded: onModelLoaded,
      });

      const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/share/info`);
      const params = new URLSearchParams();
      url.search = params.toString();
      fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "SHARE-ID": props.assetId,
          "DEVICE-ID": getDeviceId(),
        },
      })
        .then((response) => {
          console.log("response", response);
          if (response.status === 200) {
            logWombatEvent("viewedSharedModel", {
              shareId: props.assetId,
            });
            return response.json();
          } else {
            // log error in wombat

            logWombatEvent("failedToLoadSharedModel", {
              shareId: props.assetId,
            });
            // navigate to 404 page
            navigate("/s/error", { replace: true });
          }
        })
        .then((data) => {
          console.log("data", data);
          setInfo(data.info);
          //   setMetaDataTitle("Polyhive - Shared Model: " + data.info.asset_info.fileName);
          //   setMetaDataImageURL(data.info.asset_info.thumbnailURL);
          viewer.LoadModelFromUrlList([data.info.asset_info.assetURL]);
        })
        .catch((err) => console.log(err));
    }
  }, [props.assetId]);

  const handleFullScreen = () => {
    if (fullscreen === true) {
      // exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      setFullscreen(false);
    } else {
      const elem = document.getElementById("viewer");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
      setFullscreen(true);
    }
  };

  // useeffect with event listener to detect fullscreen change
  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    const handleFullScreenChange = () => {
      if (document.fullscreenElement) {
        setFullscreen(true);
      } else {
        setFullscreen(false);
      }
    };
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  return (
    <div className="hero_section w_100 d_block bg_white pt_4">
      {/* <Helmet>
        <title>{metaDataTitle}</title>
        <meta property="og:title" content={metaDataTitle} />
        <meta property="og:image" content={metaDataImageURL} />
      </Helmet> */}
      <div className="container d_flex d_justify_left d_align_center pt_10">
        <VuiBox
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",

            "@media (max-width: 768px)": {
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            },
          }}
        >
          <VuiTypography
            variant="h1"
            sx={{
              color: colors.icon.enabled,
              fontWeight: "bold",
              fontSize: "2rem",
              // if its on mobile, center text
              "@media (max-width: 768px)": {
                textAlign: "center",
                fontSize: "1.3rem",
              },
            }}
          >
            {typeof info.ai_job_info.textPrompt !== "undefined" ? (
              <>
                Retextured using <span className="c_hivegradient">AI</span> with
              </>
            ) : (
              "Hosted by"
            )}
            <span className="c_hivegradient"> Polyhive</span>
          </VuiTypography>

          <Button
            variant="text"
            className="default_btn ml_2 bg_quaternaryfocusdark c_white f_small fw_500"
            onClick={handleShareModalOpen}
          >
            Share
          </Button>
        </VuiBox>
      </div>
      <div className="container d_flex d_justify_between d_align_center">
        <div className="hl d_flex d_flex_column">
          <div id="viewer" className="share_viewer">
            <Button
              onClick={handleFullScreen}
              sx={{
                position: "absolute",
                top: "0px",
                right: "-5px",
                "@media (max-width: 768px)": {
                  display: "none",
                },
                // background: colors.icon.background,
              }}
            >
              {fullscreen ? (
                <FullscreenExitIcon
                  sx={{ width: "40px", height: "40px", color: colors.icon.disabled }}
                />
              ) : (
                <FullscreenIcon
                  sx={{ width: "40px", height: "40px", color: colors.icon.disabled }}
                />
              )}
            </Button>
          </div>
          {/* Fixed position full screen button */}
        </div>
        <div className="d_flex d_flex_column">
          <VuiBox className="info_viewer">
            <VuiBox>
              <VuiTypography className="c_icondisabled fw_600 f_medium mb_3">
                3D Model Information
              </VuiTypography>
              <VuiBox display="flex" justifyContent="center" ml={0} mb={5}>
                <Grid container spacing={1}>
                  {/* FIELD 1 */}
                  <Grid item xs={4} md={4}>
                    <VuiTypography
                      variant="body2"
                      textTransform={"capitalize"}
                      className="c_iconenabled fw_500"
                    >
                      File Name
                    </VuiTypography>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <VuiTypography
                      variant="body2"
                      className="c_icondisabled fw_500"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {info.asset_info.fileName}
                    </VuiTypography>
                  </Grid>
                  {/* NEW FIELD */}
                  <Grid item xs={4} md={4}>
                    <VuiTypography
                      variant="body2"
                      textTransform={"capitalize"}
                      className="c_iconenabled fw_500"
                    >
                      Date Created
                    </VuiTypography>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <VuiTypography variant="body2" className="c_icondisabled fw_500">
                      {parseDateUTC(info.asset_info.lastUpdatedTime)}
                    </VuiTypography>
                  </Grid>
                </Grid>
              </VuiBox>
              {typeof info.ai_job_info.textPrompt !== "undefined" && (
                <>
                  <VuiTypography className="c_icondisabled fw_600 f_medium mb_3">
                    AI Generation Information
                  </VuiTypography>
                  <VuiBox display="flex" justifyContent="center" ml={0} mb={2}>
                    <Grid container spacing={1}>
                      {/* FIELD 1 */}
                      <Grid item xs={4} md={4}>
                        <VuiTypography
                          variant="body2"
                          textTransform={"capitalize"}
                          className="c_iconenabled fw_500"
                        >
                          Text Prompt
                        </VuiTypography>
                      </Grid>
                      <Grid item xs={8} md={8}>
                        <VuiTypography variant="body2" className="c_icondisabled fw_500">
                          {info.ai_job_info.textPrompt}
                        </VuiTypography>
                      </Grid>
                      {/* NEW FIELD */}
                      <Grid item xs={4} md={4}>
                        <VuiTypography
                          variant="body2"
                          textTransform={"capitalize"}
                          className="c_iconenabled fw_500"
                        >
                          Negative Prompt
                        </VuiTypography>
                      </Grid>
                      <Grid item xs={8} md={8}>
                        <VuiTypography variant="body2" className="c_icondisabled fw_500">
                          {info.ai_job_info.jobParams.negative_prompt}
                        </VuiTypography>
                      </Grid>
                      {/* NEW FIELD */}
                      <Grid item xs={4} md={4}>
                        <VuiTypography
                          variant="body2"
                          textTransform={"capitalize"}
                          className="c_iconenabled fw_500"
                        >
                          Seed
                        </VuiTypography>
                      </Grid>
                      <Grid item xs={8} md={8}>
                        <VuiTypography variant="body2" className="c_icondisabled fw_500">
                          {info.ai_job_info.jobParams.seed}
                        </VuiTypography>
                      </Grid>
                    </Grid>
                  </VuiBox>
                </>
              )}
            </VuiBox>
          </VuiBox>
        </div>
      </div>
      <ShareModal open={shareModalOpen} onClose={handleShareModalClose} link={props.assetId} />
    </div>
  );
};

const ShareModal = (props) => {
  const [copied, setCopied] = React.useState(false);

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <VuiBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "#374151",
          //   border: "2px solid #000",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
          //make width on mobile 300
          "@media (max-width: 768px)": {
            width: 360,
          },
        }}
      >
        <CloseIcon
          onClick={props.onClose}
          sx={{
            position: "absolute",
            cursor: "pointer",
            top: "20px",
            right: "20px",
            color: colors.icon.enabled,
          }}
        />
        <VuiBox display="flex" justifyContent="left" alignItems="center" mb={3}>
          <VuiTypography variant="h6" sx={{ fontWeight: "bold", color: colors.white.main }}>
            Share
          </VuiTypography>
        </VuiBox>
        <VuiBox
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            // padding: "15px",
            alignItems: "center",
            width: "100%",
            height: "50%",
            "&:hover": {
              cursor: "pointer",
              //   background: colors.white.main,
              borderTopRightRadius: "6px",
              borderTopLeftRadius: "6px",
            },
          }}
          className="share-buttons"
          onClick={() => {
            // create an a tag
            // set its href to twitter.com/share
            // call a.click()
            logWombatEvent("sharedToTwitterFromShareViewer", {
              shareId: props.link,
            });
            const a = document.createElement("a");
            a.href = `https://twitter.com/intent/tweet?text=Check+out+my+AI+textured+3D+model+on+Polyhive%21%0D%0A%0D%0Ahttps%3A%2F%2Fpolyhive.ai%2Fs%3Fid%3D${props.link}%0D%0D&hashtags=stablediffusion,ai,texttotexture,texttogame`;
            a.target = "_blank";
            a.click();
            setShareOpen(false);
          }}
        >
          <Twitter
            round
            style={{
              width: "50px",
              height: "50px",
              color: "#fff",
              background: "#1da1f2",
              borderRadius: "50%",
              padding: "10px",
              marginRight: "10px",
            }}
            hashtags={["vrm", "vroid", "3d", "3dmodel"]}
            className="share-buttons"
          />
          <VuiTypography
            sx={{
              fontSize: "0.9rem",
              color: colors.white.main,
              textAlign: "center",
            }}
            className="share-buttons"
          >
            Twitter
          </VuiTypography>
        </VuiBox>
        <VuiBox
          display="flex"
          alignItems="center"
          mt={2}
          sx={{
            width: "100%",
            height: "50px",
            borderRadius: "25px",
            backgroundColor: colors.icon.disabled,
            // space between
            justifyContent: "space-between",
            padding: "20px",
            paddingRight: "7px",
          }}
        >
          <VuiTypography
            sx={{
              fontSize: "0.9rem",
              color: colors.white.main,
              textAlign: "center",
            }}
          >
            {`${process.env.REACT_APP_FRONTEND_URL}/s?id=${props.link}`}
          </VuiTypography>
          <Button
            sx={{
              color: colors.white.main,
              backgroundColor: colors.icon.focusdark,
              borderRadius: "20px",
              padding: "7px 12px",
              fontSize: "0.8rem",
              fontWeight: "bold",
              textTransform: "none",
              "&:hover": {
                backgroundColor: colors.icon.focusdark,
                color: colors.white.main,
              },
            }}
            onClick={() => {
              logWombatEvent("copiedLinkFromShareViewer", {
                shareId: props.link,
              });
              navigator.clipboard.writeText(
                `${process.env.REACT_APP_FRONTEND_URL}/s?id=${props.link}`
              );
              setCopied(true);
            }}
          >
            {copied ? "Copied" : "Copy"}
          </Button>
        </VuiBox>
      </VuiBox>
    </Modal>
  );
};

export default ViewerWindow;
