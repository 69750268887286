import colors from "assets/theme/base/colors";
import Button from "@mui/material/Button";
import UploadIcon from "@mui/icons-material/CloudUpload";
import { useEffect, useRef, useState } from "react";
import VRMScreenshot from "../VRMScreenshot/index";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useVisionUIController } from "context";
import { logoutUser } from "layouts/authentication/AuthFunctions";
import VuiSnackbar from "components/VuiSnackbar";
import amplitude from "amplitude-js";

import VuiBox from "components/VuiBox/";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import * as THREE from "three";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";

let FILE_SIZE_LIMIT_MB = 100;
const UploadReplace = (props) => {
  const [uploads, setUploads] = React.useState([]);
  const [loading, setLoading] = React.useState(false); // Loading button state

  const [uploadsComplete, setUploadsComplete] = React.useState(0);
  const [uploadsSucceeded, setUploadsSucceeded] = React.useState(0);
  const [uploadsFailed, setUploadsFailed] = React.useState(0);

  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const navigate = useNavigate();
  const [controller, dispatch] = useVisionUIController();
  // const [loading, setLoading] = useState(false);
  const { activeOrg } = controller;
  // file picker
  const fileInput = useRef(null);

  let rgbeLoader = new RGBELoader();
  const [texture, setTexture] = useState(null);

  useEffect(() => {
    rgbeLoader.setPath("/").load("royal_esplanade_1k.hdr", (tex) => {
      tex.mapping = THREE.EquirectangularReflectionMapping;
      setTexture(tex);
    });
  }, []);

  useEffect(() => {
    // //console.log("upload replace props", props);
    if (uploads.length > 0 && loading === false) {
      props.handleLoading(true);
    } else {
      props.handleLoading(false);
    }
  }, [uploads]);

  const handleClick = () => {
    // Call the click event of the input element

    logAuthorizedWombatEvent("assetReplaceClicked", {});

    //console.log("handleClick uploadReplace ", fileInput, fileInput.current);
    fileInput.current.click();
  };

  const toggleSnackbar = () => {
    setSnackbar(!snackbar);
  };

  const handleChange = async (event) => {
    //console.log("handleChange event.target", event.target);
    //console.log("handleChange uploadReplace files", event.target.files);
    const files = event.target.files;
    if (files === null) {
      return;
    }

    try {
      const file = files[0];
      //console.log("handleChange uploadReplace files", files);
      // iterate over event.target.files and call handleUpload for each one
      if (files.length === 1) {
        for (let i = 0; i < files.length; i++) {
          //console.log("files amount", files.length);
          await handleUpload(files[i]);
        }
      } else {
        startSnackbar({
          type: "error",
          message: "You can only upload one file at a time.",
        });
      }
    } catch (err) {
      window.alert(err.message);
    } finally {
    }
  };

  //data is an object, formatted as {type: "error", message: "error message"}
  // or {type: "success", message: "success message"}
  const startSnackbar = (data) => {
    //console.log("snackbar data", data);
    setSnackbarMessage(data.message);
    setSnackbarType(data.type);
    if (data.failed != null) {
      setUploadsFailed((uploadsFailed) => uploadsFailed + 1);
      setUploadsComplete((uploadsComplete) => uploadsComplete + 1);
    }
    setSnackbar(true);
  };

  const handleUpload = (file) => {
    if (file.size / 1000000 < FILE_SIZE_LIMIT_MB) {
      //console.log(file);
      // const filename = file.name;
      const filename = file.name;

      const fileReader = new FileReader();

      fileReader.readAsArrayBuffer(file);

      //console.log("After read as buffer");

      const uid = generateUUID();
      const headers = {
        "Content-Type": "application/octet-stream",
        "ASSOCIATED-UUID": props.data.modelId,
        "FILE-SIZE": file.size.toString(),
        FILENAME: filename,
        "X-ORG-ID": activeOrg,
        "VERSION-ID": uid,
      };
      const fileURL = URL.createObjectURL(file);

      if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
        const thumbnail = URL.createObjectURL(file);
        handleFinishedScreenshot({ file: file, url: thumbnail, headers: headers });
      } else {
        uploads.push({ file: file, headers: headers, url: fileURL });
        setUploads([...uploads]);
      }
    } else {
      alert("File size is too big");
    }
  };
  const renameKey = (object, key, newKey) => {
    const clonedObj = object;

    const targetKey = clonedObj[key];

    delete clonedObj[key];

    clonedObj[newKey] = targetKey;

    return clonedObj;
  };
  const handleFinishedScreenshot = async (data) => {
    //console.log("upload request", {
    //   body: data.file,
    //   credentials: "include",
    //   headers: data.headers,
    //   method: "POST",
    // });
    // upload file, then if reponse is ok, upload thumbnail
    const response_main_upload = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/upload/uploadNewAssetVersionForOrg`,
      {
        body: data.file,
        credentials: "include",
        headers: data.headers,
        method: "POST",
      }
    )
      .then((response) => {
        //console.log("main response", response);
        return response;
      })
      .then((response) => {
        setUploadsComplete((uploadsComplete) => uploadsComplete + 1);
        if (response.status === 200) {
          setUploadsSucceeded((uploadsSucceeded) => uploadsSucceeded + 1);
          const thumbnailImage = new File([data.thumbnail], "thumbnail.png");
          // remove filename key from headers to create thumbnail headers
          const thumbnailHeaders = {
            "Content-Type": "application/octet-stream",
            "ASSOCIATED-UUID": data.headers["ASSOCIATED-UUID"],
            "FILE-SIZE": data.headers["FILE-SIZE"],
            "X-ORG-ID": data.headers["X-ORG-ID"],
            "VERSION-ID": data.headers["VERSION-ID"],
          };

          const response_thumbnail_upload = fetch(
            `${process.env.REACT_APP_BACKEND_URL}/upload/uploadThumbnailForVersion`,
            {
              body: thumbnailImage,
              credentials: "include",
              headers: thumbnailHeaders,
              method: "POST",
            }
          )
            .then((response) => {
              //console.log("thumbnail response", response);
              return response;
            })
            .then((response) => {
              props.handleLoading(false);

              if (response.status === 200) {
                props.cb({
                  fileName: data.file.name,
                  fileSize: data.file.size,
                  fileObj: data.file,
                  filePath: data.url,
                  thumbnail: data.thumbnailURL,
                  modelId: data.headers["ASSOCIATED-UUID"],
                  versionId: data.headers["VERSION-ID"],
                });
              } else if (response.status === 403) {
                logoutUser(navigate);
              } else {
                startSnackbar({
                  message: response.statusText,
                  type: "error",
                });
                //console.log("thumbnail upload failed, cancelling grid item addition");
              }
            });

          return response;
        } else if (response.status === 401) {
          setUploadsFailed((uploadsFailed) => uploadsFailed + 1);
          startSnackbar({
            message: "Not authorized to upload versions. Please contact your administrator.",
            type: "error",
          });
          props.handleLoading(false);
          //console.log("error uploading file, not uploading thumbnail");
          return response;
        } else {
          setUploadsFailed((uploadsFailed) => uploadsFailed + 1);
          startSnackbar({
            message: "Upload failed",
            type: "error",
          });
          props.handleLoading(false);
          //console.log("error uploading file, not uploading thumbnail");
          return response;
        }
      });
  };
  const onFileInputClick = (event) => {
    event.target.value = "";
  };

  const handleTypeError = () => {
    startSnackbar({
      message: "File type not supported",
      type: "error",
    });
  };
  return (
    <>
      <Button
        variant="text"
        textTransform="none"
        sx={{
          border: "1.5px solid " + colors.icon.disabled,
          color: "#fff",
          fontSize: "0.7rem",
          width: "120px",
          height: "30px",
          // margin: "0px !important",
          padding: "0px !important",
          position: "absolute",
          right: "0px",
          fontWeight: "400",
          lineHeight: "1",
          textTransform: "none",
          borderRadius: "5px",
          marginRight: "14px",
        }}
        onClick={handleClick}
      >
        Upload Version
      </Button>
      <VuiBox sx={{ zIndex: -1000, position: "absolute", left: "0px", top: "0px" }}>
        {uploads.map((upload, index) => (
          <VRMScreenshot
            data={upload}
            callback={handleFinishedScreenshot}
            errorCB={props.errorCB}
            texture={texture}
          />
        ))}
      </VuiBox>
      <input
        type="file"
        ref={fileInput}
        onClick={onFileInputClick}
        style={{ display: "none" }}
        onChange={handleChange}
        accept=".png, .jpg, .jpeg, .glb, .gltf, .vrm, .fbx, .obj"
      />
      <VuiSnackbar
        color={snackbarType === "success" ? "success" : "error"}
        icon="notifications"
        title="Polyhive"
        content={snackbarMessage}
        dateTime="Just now"
        open={snackbar}
        close={toggleSnackbar}
        onClose={toggleSnackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={3000}
      />
    </>
  );
};
/* Utility for generating a unique uuid for uploading a file. */
const generateUUID = () => {
  // Public Domain/MIT
  let d = new Date().getTime(); //Timestamp
  let d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export default UploadReplace;
