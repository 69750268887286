/**

 =========================================================
 * Vision UI PRO React - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com/)

 * Design and Coded by Simmmple & Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

 */

import React, { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import Grid from "@mui/material/Grid";
import EastIcon from "@mui/icons-material/East";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
// Icons
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import { Box, Typography } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";

// Vision UI Dashboard components
import GradientBorder from "examples/GradientBorder";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import Button from "@mui/material/Button";
import { useStytch } from "@stytch/stytch-react";
import GoogleButton from "assets/images/googlewhite.png";
import colors from "assets/theme/base/colors";

import { useNavigate } from "react-router-dom";

// Images
import bgBasic from "assets/images/background-basic-auth.png";
import Radium, { Style } from "radium";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";
import VuiSnackbar from "components/VuiSnackbar/";

function Setup() {
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const fileInput = React.useRef(null);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [loggedIn, setLoggedIn] = useState(false);
  const stytchClient = useStytch();

  const [iconSrc, setIconSrc] = useState("");
  const [iconFile, setIconFile] = useState(null);
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");
  const [validUsername, setValidUsername] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    logAuthorizedWombatEvent("setupPageOpened", {});
  }, []);

  const startSnackbar = (data) => {
    //console.log("snackbar data", data);
    setSnackbarMessage(data.message);
    setSnackbarType(data.type);

    setSnackbar(true);
  };

  const openSnackbar = () => {
    setSnackbar(true);
  };
  const closeSnackbar = () => {
    setSnackbar(false);
  };

  const checkUsername = async (username) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/developer/checkUsername/`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            username: username,
          },
        }
      );
      const data = await response.json();
      setValidUsername(data.valid);
      return data.valid;
    } catch (error) {
      console.log("error", error);
    }
    // return data;
  };

  const setupClicked = async () => {
    const username =
      document.getElementById("userInput").value === ""
        ? ""
        : document.getElementById("userInput").value;
    const firstname =
      document.getElementById("firstNameInput").value === ""
        ? ""
        : document.getElementById("firstNameInput").value;
    const lastname =
      document.getElementById("lastNameInput").value === ""
        ? ""
        : document.getElementById("lastNameInput").value;
    const job =
      document.getElementById("jobInput").value === ""
        ? ""
        : document.getElementById("jobInput").value;

    // if username is longer than 20 characters do something
    if (username.length > 20) {
      startSnackbar({
        message: "Username must be less than 20 characters",
        type: "error",
      });
      return;
    }

    const unValid = await checkUsername(username);
    if (unValid === false) {
      startSnackbar({
        message: "Username is already taken",
        type: "error",
      });
      return;
    }

    //console.log("username", username);
    //console.log("firstname", firstname);
    //console.log("lastname", lastname);
    //console.log("job", job);
    //console.log("iconSrc", iconSrc);
    //console.log("iconFile", iconFile);

    if (username !== "") {
      logAuthorizedWombatEvent("setupButtonClicked", {});

      fetch(process.env.REACT_APP_BACKEND_URL + "/developer/register/", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          firstName: firstname,
          lastName: lastname,
          jobTitle: job,
          // icon: iconFile,
        }),
      }).then((response) => {
        //console.log("login response", response);
        if (response.status === 200) {
          logAuthorizedWombatEvent("setupButtonSuccess", {});
          if (iconFile !== null) {
            logAuthorizedWombatEvent("uploadingProfilePicture", {});

            const response_thumbnail_upload = fetch(
              `${process.env.REACT_APP_BACKEND_URL}/developer/uploadProfilePicture/`,
              {
                body: iconFile,
                credentials: "include",
                method: "POST",
              }
            ).then((response) => {
              navigate("/generate", {
                state: {
                  onboarding: true,
                },
              });
              return response;
            });
          } else {
            navigate("/generate", {
              state: {
                onboarding: true,
              },
            });
          }
        } else {
          console.log("response", response.statusText);
          logAuthorizedWombatEvent("setupButtonFailure", { response: response });
        }
      });
    } else {
      alert("Username is a required field");
    }
  };

  const formSubmitted = (e) => {
    e.preventDefault();
    //console.log("e", e);
    setupClicked();
  };

  const uploadIcon = () => {
    fileInput.current.click();
  };

  const handleChange = (e) => {
    //console.log("e", e.target.files[0]);
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setIconFile(file);
    setIconSrc(url);
  };

  return (
    <BasicLayout>
      <Grid container>
        <form onSubmit={formSubmitted}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <VuiBox maxWidth="100%" minWidth="300px">
              <VuiTypography
                color="white"
                fontWeight="bold"
                textAlign="center"
                mb="5px"
                sx={({ typography: { size } }) => ({
                  fontSize: size.lg,
                })}
              >
                Let's setup your profile
              </VuiTypography>
              <VuiTypography
                color="white"
                textAlign="center"
                mb="24px"
                sx={({ typography: { size } }) => ({
                  fontSize: size.md,
                })}
              >
                Don't worry, you can change this later
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <VuiBox
              maxWidth="100%"
              // minWidth="00px"
              margin="0 auto"
              borderRadius="5px"
              p="25px"
              sx={({ palette: { secondary } }) => ({
                backgroundColor: colors.background.secondary,
                minWidth: "500px",

                // if its a mobile device, make the width 100%
                "@media (max-width: 600px)": {
                  minWidth: "300px",
                },

                // border: "1px solid #696969",
              })}
            >
              <VuiBox mb={2}>
                <VuiBox mb={1} ml={0.5}>
                  <VuiTypography
                    component="label"
                    variant="button"
                    color="white"
                    fontWeight="medium"
                  >
                    Username * (max 20 characters)
                  </VuiTypography>
                </VuiBox>
                <Box>
                  <VuiInput
                    id="userInput"
                    // inputProps={{ style: { backgroundColor: "#fff" } }}
                    style={{
                      backgroundColor: "rgba(80,80,80,1)",
                    }}
                    sx={({ typography: { size } }) => ({
                      fontSize: size.sm,
                      border: "1px solid #696969",
                      ".MuiInputBase-input": {
                        color: "white",
                        backgroundColor: "rgba(80,80,80,0)",
                      },
                      // background: "#fff",
                    })}
                    onChange={(e) => {
                      setUsername(e.target.value);
                      checkUsername(e.target.value);
                    }}
                    autoComplete="off"
                  />
                  {username.length > 0 ? (
                    validUsername ? (
                      <Box className="align_center_row">
                        <CheckIcon className="c_success" />

                        <Typography
                          ml={0.5}
                          sx={{
                            color: colors.success.main,
                          }}
                          className="f_small c_success"
                        >
                          Username available
                        </Typography>
                      </Box>
                    ) : (
                      <Box className="align_center_row">
                        <CloseIcon className="c_error" />

                        <Typography ml={0.5} className="f_mid c_error">
                          Username taken
                        </Typography>
                      </Box>
                    )
                  ) : null}
                </Box>

                <VuiBox mb={0.5} ml={0.5} mt={1}>
                  <VuiTypography
                    component="label"
                    variant="button"
                    color="white"
                    fontWeight="medium"
                  >
                    Profile photo
                  </VuiTypography>
                </VuiBox>
                <Grid container>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <VuiBox
                      mb={1}
                      ml={0.5}
                      mt={1}
                      mr={0.5}
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        width: "65px",
                        height: "65px",
                        background: colors.background.main,
                      }}
                    >
                      <VuiBox
                        sx={{
                          width: "100%",
                          height: "100%",
                          background: iconSrc === "" ? "rgba(80,80,80,1)" : `url(${iconSrc})`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                        }}
                      ></VuiBox>
                    </VuiBox>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{
                      // dont show this box on mobile
                      "@media (max-width: 600px)": {
                        display: "none",
                      },
                    }}
                  >
                    <VuiBox>
                      <VuiTypography
                        component="label"
                        variant="button"
                        color="white"
                        fontWeight="medium"
                        ml={0.5}
                      >
                        Icon <br />
                      </VuiTypography>
                      <VuiTypography
                        component="label"
                        variant="button"
                        fontWeight="medium"
                        ml={0.5}
                        lineHeight="1.5"
                        sx={{ color: "#999", fontSize: "12px" }}
                        display="block"
                      >
                        We recommend a transparent PNG at least 96px x 96px and less than 1MB
                      </VuiTypography>
                    </VuiBox>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <VuiBox
                      mb={1}
                      ml={0.5}
                      sx={{
                        // marginLeft on mobile is bigger
                        "@media (max-width: 600px)": {
                          marginLeft: "30px",
                        },
                      }}
                    >
                      <VuiButton
                        color="info"
                        variant="outlined"
                        sx={{ textAlign: "center", marginTop: "15px" }}
                        onClick={uploadIcon}
                      >
                        Upload Image
                      </VuiButton>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        ref={fileInput}
                        style={{ display: "none" }}
                        onChange={handleChange}
                      />
                    </VuiBox>
                  </Grid>
                </Grid>
                <hr
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    borderTop: "1px solid #696969",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                />
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <VuiBox mb={0} ml={0.5}>
                      <VuiBox mb={1}>
                        <VuiTypography
                          component="label"
                          variant="button"
                          color="white"
                          fontWeight="medium"
                        >
                          First Name
                        </VuiTypography>
                      </VuiBox>

                      <VuiInput
                        id="firstNameInput"
                        // inputProps={{ style: { backgroundColor: "#fff" } }}
                        style={{
                          backgroundColor: "rgba(80,80,80,1)",
                        }}
                        sx={({ typography: { size } }) => ({
                          fontSize: size.sm,
                          border: "1px solid #696969",
                          // background: "#fff",
                        })}
                      />
                    </VuiBox>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <VuiBox mb={0} ml={0.5}>
                      <VuiBox mb={1} ml={0.5}>
                        <VuiTypography
                          component="label"
                          variant="button"
                          color="white"
                          fontWeight="medium"
                        >
                          Last Name
                        </VuiTypography>
                      </VuiBox>

                      <VuiInput
                        id="lastNameInput"
                        // inputProps={{ style: { backgroundColor: "#fff" } }}
                        style={{
                          backgroundColor: "rgba(80,80,80,1)",
                        }}
                        sx={({ typography: { size } }) => ({
                          fontSize: size.sm,
                          border: "1px solid #696969",
                          // background: "#fff",
                        })}
                      />
                    </VuiBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <VuiBox mb={1} ml={0.5}>
                      <VuiBox mb={1} ml={0.5}>
                        <VuiTypography
                          component="label"
                          variant="button"
                          color="white"
                          fontWeight="medium"
                        >
                          Job Title
                        </VuiTypography>
                      </VuiBox>

                      <VuiInput
                        id="jobInput"
                        // inputProps={{ style: { backgroundColor: "#fff" } }}
                        style={{
                          backgroundColor: "rgba(80,80,80,1)",
                        }}
                        sx={({ typography: { size } }) => ({
                          fontSize: size.sm,
                          border: "1px solid #696969",
                          // background: "#fff",
                        })}
                      />
                    </VuiBox>
                  </Grid>
                </Grid>
              </VuiBox>
            </VuiBox>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <VuiBox mt={2} mb={1} margin="20px auto" width="100%" sx={{ textAlign: "center" }}>
              <p>
                <VuiButton
                  type="submit"
                  color="info"
                  sx={{ margin: "auto", textAlign: "center" }}
                  endIcon={<EastIcon />}
                >
                  START USING POLYHIVE
                </VuiButton>
              </p>
            </VuiBox>
          </Grid>
        </form>
      </Grid>
      <VuiSnackbar
        color={snackbarType}
        icon="notifications"
        title="Polyhive"
        content={snackbarMessage}
        dateTime="Just now"
        open={snackbar}
        close={closeSnackbar}
        onClose={openSnackbar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={2000}
      />
      {/* </GradientBorder> */}
    </BasicLayout>
  );
}

export default Setup;
