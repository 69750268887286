import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import colors from "assets/theme/base/colors";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    border: "1px solid " + colors.icon.quaternaryfocus,
    padding: "1px 10px",
    color: colors.icon.quaternaryfocus,
    textTransform: "capitalize",
    fontWeight: 400,
    "&.Mui-selected": {
      backgroundColor: colors.icon.quaternaryfocusdark,
      color: colors.white.main,
      "&:hover": {
        backgroundColor: colors.icon.quaternaryfocus,
        color: colors.white.main,
      },
    },
    "&.Mui-disabled": {
      //   border: 0,
    },
    "&:not(:first-of-type)": {},
    "&:first-of-type": {},
  },
}));

const Toggler = (props) => {
  return (
    <StyledToggleButtonGroup
      color="primary"
      value={props.select}
      exclusive
      onChange={props.handleChange}
      aria-label="Platform"
    >
      {props.options.map((option) => (
        <ToggleButton key={option} value={option} aria-label={option} className="f_small">
          {option}
        </ToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

export default Toggler;
