import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import colors from "assets/theme/base/colors";

import SearchIcon from "@mui/icons-material/Search";
import "layouts/applications/generate/style.css";
import PolyhiveLogo from "assets/icons/polyhivelogo-transparent2.png";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { usePopupState, bindTrigger, bindMenu } from "material-ui-popup-state/hooks";
import ContentCopy from "@mui/icons-material/ContentCopy";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
// import sparkles-outline icon from react ionicons
import { Wine } from "react-ionicons";
import CreditIcon from "assets/icons/crediticon.png";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Box, Typography } from "@mui/material";

import { useEffect, useState } from "react";
import {
  useVisionUIController,
  setOrgs,
  setActiveOrg,
  setIsAdmin,
  setDeveloperInfo,
  setCredits,
  setSubscription,
  setPermissions,
} from "context";
import PeopleIcon from "@mui/icons-material/People";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import OrgTab from "../../../../../examples/Sidenav/OrgTab";
import { logoutUser } from "layouts/authentication/AuthFunctions";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "universal-cookie";

const DashboardNav = (props) => {
  // selected tab can be "files" or "tags"
  const [selectedTab, setSelectedTab] = useState("files");
  const [controller] = useVisionUIController();
  const { isAdmin, activeOrg, orgs, credits, subscription } = controller;
  const [orgName, setOrgName] = useState("");
  const navigate = useNavigate();

  const getOrgFromId = (id) => {
    const org = orgs.find((org) => org.id === id);
    return org.name;
  };

  useEffect(() => {
    if (activeOrg !== "") {
      setOrgName(getOrgFromId(activeOrg));
    }
    console.log("props.show: ", props.show);
  }, [activeOrg]);

  return props.show === false ? (
    <>
      <MenuPopupState show={false} />
    </>
  ) : (
    <VuiBox
      sx={{
        display: "flex",
        justifyContent: "left",
        height: "64px",
        margin: "0",
        padding: "0 10px",
        position: "fixed",
        top: "0px",
        left: "0px",
        // padding: "0 100px",
        // borderBottom: "2px solid " + colors.border.main,
        backgroundColor: colors.background.main,
        width: "100%",
        transition: "all 0.5s ease",
        zIndex: "100",
        // vertically center children
        alignItems: "center",
        "@media (max-width: 600px)": {
          display: "none",
        },
      }}
    >
      <VuiBox className="nav_left">
        <VuiBox className="nav_left" component={Link} to="/">
          <img src={PolyhiveLogo} alt="polyhive logo" className="nav_logo ml_1" />
        </VuiBox>
        {subscription.name === "polyhive_plus_plan" ? (
          <>
            <VuiTypography className="c_hivegradient f_midlarge fw_400 mb_0 ml_025neg">
              Plus
            </VuiTypography>
          </>
        ) : (
          <></>
        )}
        {/* <VuiTypography className="logo_text">Polyhive</VuiTypography> */}
        <MenuPopupState show={false} />
      </VuiBox>

      <VuiBox className="nav_right">
        {/* <Button
          className="f_small bg_discord c_white fw_500 default_btn"
          component="a"
          href="https://discord.gg/GrqWkANrce"
          target="_blank"
        >
          Join the Discord!
        </Button> */}

        {/* <Box className="credits_container ml_3">
          <Box className="align_center_row ml_1">
            <VuiTypography className="c_white f_mid mr_05 fw_500">{credits}</VuiTypography>
            <img src={CreditIcon} alt="credits" className="credit_icon" />
            <AddRoundedIcon
              className="c_white add_credits_icon ml_1"
              onClick={() => {
                navigate("/buy");
              }}
            />
          </Box>
          <Box className="credits_vr"></Box>
          <Button
            className="f_small c_white fw_500 default_btn bg_hivegradient"
            onClick={() => {
              navigate("/buy");
            }}
          >
            Upgrade Plan
          </Button>
        </Box> */}

        {/* <Wine color={colors.white.main} height="30px" width="30px" /> */}
        <ProfileMenu />
      </VuiBox>
    </VuiBox>
  );
};

const MenuPopupState = (props) => {
  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });

  // const [orgs, setOrgs] = useState([]);
  const cookies = new Cookies();

  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, orgs, isAdmin, activeOrg } = controller;
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch orgs from new endpoint
    fetchOrgs();
    // Get dev info
    fetchDevInfo();
  }, []);

  useEffect(() => {
    if (activeOrg !== "") {
      // fetchPermissions();
      // fetchSubscriptionInfo();
    }
  }, [activeOrg]);

  const fetchSubscriptionInfo = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/subscription/info`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      // data.credits is a string, need to convert to int and set in context
      setCredits(dispatch, parseInt(data.credits));
      setSubscription(dispatch, data.subscription);
      console.log("subscription info: ", data);
    } else {
      console.log("error fetching subscription info", response);
    }
  };

  const fetchPermissions = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/subscription/permissions`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      setPermissions(dispatch, data.permissions);
      console.log("permission info: ", data);
    } else {
      console.log("error fetching permission info", response);
    }
  };

  const fetchOrgs = () => {
    const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/organization/membership`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 403) {
          console.log("fetchOrgs 403, LOGGING OUT USER");
          logoutUser(navigate);
          return response.json();
        } else {
          return response.json();
        }
      })
      .then((response) => {
        const morgs = [];
        for (let i = 0; i < response.orgs.length; i++) {
          if (response.member_privileges[i] === "admin") {
            morgs.unshift({
              membership: response.member_privileges[i],
              name: response.org_names[i],
              id: response.orgs[i],
            });
          } else {
            morgs.push({
              membership: response.member_privileges[i],
              name: response.org_names[i],
              id: response.orgs[i],
            });
          }
        }
        setOrgs(dispatch, morgs);
        const activeOrg = cookies.get("activeOrg");
        if (activeOrg && morgs.some((org) => org.id === activeOrg)) {
          const tempOrgs = morgs.map((org) => {
            if (org.id === activeOrg) {
              if (org.membership === "admin") {
                setIsAdmin(dispatch, true);
              }
              handleActiveOrg(org.id);
            }
          });
        } else {
          const tempOrgs = morgs.map((org, index) => {
            if (index === 0) {
              if (org.membership === "admin") {
                setIsAdmin(dispatch, true);
              }
              handleActiveOrg(org.id);
            }
          });
        }
      })
      .catch((error) => {
        console.log("ERROR AT /MEMBERSHIP", error);
        logoutUser(navigate);
      });
  };

  const fetchDevInfo = () => {
    const response2 = fetch(`${process.env.REACT_APP_BACKEND_URL}/developer/developerInfo`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        console.log("developerinfo", response);
        if (response.status === 403) {
          logoutUser(navigate);
          return response.json();
        } else {
          return response.json();
        }
      })
      .then((response) => {
        setDeveloperInfo(dispatch, response);
        if (response.username === null) {
          // navigate("/setup");
        }
      })
      .catch((error) => {
        console.log("ERROR AT /developerInfo", error);
      });
  };

  // const setActiveOrg = (id) => {
  //   //console.log("setting new active org", id);
  //   setOrgs(dispatch, (prevOrgs) => {
  //     return prevOrgs.map((org) => {
  //       if (org.id === id) {
  //         cookies.set("activeOrg", org.id, { path: "/" });
  //         return { ...org, active: true };
  //       } else {
  //         return { ...org, active: false };
  //       }
  //     });
  //   });
  // };
  const handleActiveOrg = (id) => {
    const org = orgs.find((org) => org.id === id);
    if (org === undefined) {
    } else {
      if (org.membership === "admin") {
        setIsAdmin(dispatch, true);
      } else {
        setIsAdmin(dispatch, false);
      }
    }

    cookies.set("activeOrg", id, { path: "/" });

    setActiveOrg(dispatch, id);
  };
  return props.show === false ? (
    <></>
  ) : (
    <div>
      <Button
        variant="contained"
        {...bindTrigger(popupState)}
        className="org_toggle"
        startIcon={<PeopleIcon className="" />}
      >
        {/* get org name from orgs where org.id === activeOrg and set that as the text */}
        {orgs.find((org) => org.id === activeOrg) === undefined
          ? "Select an organization"
          : orgs.find((org) => org.id === activeOrg).name}
      </Button>
      <Menu {...bindMenu(popupState)} className="org_menu">
        {orgs.map((org) => (
          <MenuItem>
            {/* Get First letter of org.name and center it in this box */}
            <OrgTab name={org.name} id={org.id} setActive={handleActiveOrg} />
          </MenuItem>
        ))}
        {/* <MenuItem onClick={popupState.close}>
          {" "}
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy</ListItemText>
        </MenuItem>
        <MenuItem onClick={popupState.close}>Death</MenuItem> */}
      </Menu>
    </div>
  );
};

const ProfileMenu = () => {
  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, orgs, isAdmin, developerInfo } = controller;
  const navigate = useNavigate();

  const handleLogOut = () => {
    logoutUser(navigate);
  };

  const handleProfile = () => {
    navigate("/profile");
  };

  const handleOrganizations = () => {
    navigate("/org/overview");
  };

  const handleKeys = () => {
    navigate("/org/apiKeys");
  };

  const handleSubscription = () => {
    navigate("/subscription");
  };

  return (
    <>
      <PersonOutlineOutlinedIcon className="profile_toggle" {...bindTrigger(popupState)} />
      <Menu {...bindMenu(popupState)} className="profile_menu">
        <MenuItem
          // onClick={handleCloseMenu}
          sx={{
            bgcolor: colors.background.secondary,
            "&:hover": {
              backgroundColor: colors.background.secondary + "!important",
              color: "#fff",
              cursor: "default",
            },
            borderBottomRightRadius: "0px",
            borderBottomLeftRadius: "0px",
            fontWeight: "bold",
          }}
        >
          {" "}
          {developerInfo.email}
        </MenuItem>
        {/* <MenuItem
          onClick={handleProfile}
          sx={{
            bgcolor: colors.background.secondary,
            "&:hover": {
              backgroundColor: colors.background.main + "!important",
              color: "#fff",
            },
            borderBottomRightRadius: "0px",
            borderBottomLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderTopLeftRadius: "0px",
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={handleOrganizations}
          sx={{
            bgcolor: colors.background.secondary,
            "&:hover": {
              backgroundColor: colors.background.main + "!important",
              color: "#fff",
            },
            borderBottomRightRadius: "0px",
            borderBottomLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderTopLeftRadius: "0px",
          }}
        >
          Organization
        </MenuItem> */}
        <MenuItem
          onClick={handleLogOut}
          sx={{
            bgcolor: colors.background.secondary,
            "&:hover": {
              backgroundColor: colors.background.main + "!important",
              color: "#fff",
            },
            borderTopRightRadius: "0px",
            borderTopLeftRadius: "0px",
          }}
        >
          Log out
        </MenuItem>
      </Menu>
    </>
  );
};

export default DashboardNav;
