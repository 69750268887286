import VRMScreenshot from "../../VRMScreenshot/index";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { useState, useEffect } from "react";
import * as THREE from "three";

const Thumbnailer = (props) => {
  const handleFinishedScreenshot = (data) => {
    console.log("THUMBNAILER: handleFinishedScreenshot called", data);
    props.handleFinishedScreenshot(data);
  };
  let rgbeLoader = new RGBELoader();
  const [texture, setTexture] = useState(null);

  useEffect(() => {
    rgbeLoader.setPath("/").load("royal_esplanade_1k.hdr", (tex) => {
      tex.mapping = THREE.EquirectangularReflectionMapping;
      setTexture(tex);
    });
  }, []);
  return (
    <>
      {props.data.map((item, index) => {
        console.log("THUMBNAILER: item", item);
        return (
          <VRMScreenshot
            key={index}
            data={item}
            callback={handleFinishedScreenshot}
            errorCB={props.startSnackbar}
            texture={texture}
          />
        );
      })}
    </>
  );
};

export default Thumbnailer;
