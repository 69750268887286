import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import VuiBox from "components/VuiBox";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import colors from "assets/theme/base/colors";
import VuiTypography from "components/VuiTypography";
import React, { useState, useEffect, forwardRef, useRecoilState } from "react";
import { Modal, Tooltip } from "../../../../node_modules/@mui/material/index";
import VuiInput from "components/VuiInput/index";
import VuiSnackbar from "components/VuiSnackbar/index";
import Cookies from "universal-cookie";
import { logoutUser } from "layouts/authentication/AuthFunctions";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import VuiSelect from "components/VuiSelect/index";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import MenuItem from "@mui/material/MenuItem";
import { useVisionUIController, setMiniSidenav, setOrgs, setActiveOrg } from "context";
import TimerIcon from "@mui/icons-material/Timer";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CheckIcon from "@mui/icons-material/Check";
import amplitude from "amplitude-js";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";
import Usage from "../profile/usage/index";
import DashboardNav from "../../dashboards/default/components/DashboardNav";
import Sidenav from "examples/Sidenav";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

const labelToValue = {
  "Member (Read/Upload/Delete Assets)": "member",
  "Member (Read/Upload Assets)": "ru_member",
  "Member (Read Assets)": "r_member",
};

const modalStyle = {
  width: "400px",
  height: "100%",
  position: "absolute",
  alignItems: "center",
  justifyContent: "center",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: colors.background.secondary,
  boxShadow: 24,
  justifyContent: "center",
  p: 3,
  borderRadius: "15px",
  outline: "none",
};
const colourStyles = {
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#ffffff",
    };
  },
};

const cookies = new Cookies();

// Organization page contains the following parts:
// - OrganizationHeader
// - Member Grid
// - Subscription info
const Organization = () => {
  return (
    <DashboardLayout>
      <DashboardNav />
      <Sidenav />

      <OrganizationHeader />
      <MemberGrid />
      <Subscription />
      <Usage />
    </DashboardLayout>
  );
};

const OrganizationHeader = () => {
  const [orgName, setOrgName] = useState("");
  const [changeOpen, setChangeOpen] = useState(false);
  const [leaveOpen, setLeaveOpen] = useState(false);

  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, orgs, activeOrg, isAdmin } = controller;
  useEffect(() => {
    //console.log("activeOrg", activeOrg);
    if (activeOrg === "") {
    } else {
      // if activeOrg is not empty, find the id of the org in orgs and set the orgName to the name of the org
      const org = orgs.find((org) => org.id === activeOrg);
      setOrgName(org.name);
    }
    //console.log("is he admin?", isAdmin);
  }, [activeOrg]);

  useEffect(() => {
    //console.log("admin", isAdmin);
  }, [isAdmin]);

  const handleNameChange = (name) => {
    setOrgName(name);
    // set the name of the org in the orgs array based on activeorg id
    const newOrgs = orgs.map((org) => {
      if (org.id === activeOrg) {
        org.name = name;
      }
      return org;
    });
    setOrgs(dispatch, newOrgs);
  };

  return (
    <>
      <VuiBox mt={7} sx={{ borderRadius: "20px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <VuiBox className="d_flex d_justify_between">
              <VuiBox
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <VuiTypography className="f_xlarge c_white fw_500">{orgName}</VuiTypography>
                <Button
                  className="default_btn bg_dark c_white ml_2"
                  // sx={{display: isAdmin ? "inline" : "none" }}
                  startIcon={<EditIcon />}
                  onClick={
                    isAdmin
                      ? () => {
                          logAuthorizedWombatEvent("orgNameChangeClicked", {});

                          setChangeOpen(true);
                        }
                      : null
                  }
                >
                  Edit Name
                </Button>
              </VuiBox>
              <Tooltip
                title={isAdmin ? "Admins cannot leave their organization." : ""}
                placement="top"
              >
                <Button
                  onClick={() => {
                    logAuthorizedWombatEvent("orgLeaveClicked", {});

                    setLeaveOpen(true);
                  }}
                  className="default_btn"
                  sx={{
                    marginTop: "auto",
                    color: "#fff",
                    backgroundColor: colors.error.main,
                    "&:hover": {
                      backgroundColor: colors.error.main,
                    },
                    "&:disabled": {
                      backgroundColor: "#696969",
                      color: "#fff",
                    },
                  }}
                  disabled={isAdmin}
                  color="error"
                >
                  Leave Organization
                </Button>
              </Tooltip>
            </VuiBox>
          </Grid>
        </Grid>
      </VuiBox>
      <ChangeNameModal
        open={changeOpen}
        onClose={() => setChangeOpen(false)}
        callback={handleNameChange}
      />
      <ConfirmLeaveModal open={leaveOpen} onClose={() => setLeaveOpen(false)} />
    </>
  );
};

const RemoveMemberModal = (props) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [controller, dispatch] = useVisionUIController();
  const { activeOrg } = controller;
  const removeMember = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/organization/removeMember`, {
      method: "DELETE",
      credentials: "include",
      body: JSON.stringify({ email: props.email }),
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
    })
      .then((data) => {
        if (data.status === 500) {
          setSnackbarMessage("Something went wrong. Please try again later.");
          setSnackbarOpen(true);
          return null;
        } else if (data.status !== 200) {
          setSnackbarMessage("Something went wrong. Please try again later.");
          setSnackbarOpen(true);
          return null;
        } else {
          return data.json();
        }
      })
      .then((data) => {
        if (data) {
          //console.log(data);
          props.onClose();
          window.location.reload();
        }
      });
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ modalStyle }}
      >
        <VuiBox sx={style}>
          <div style={{ textAlign: "center", marginBottom: "24px" }}>
            <h5 style={{ textAlign: "center", color: "white" }}>
              Are you positive you want to remove {props.email} from this organization? They will
              lose access to all managed content and API access.
            </h5>
          </div>

          <Button
            onClick={removeMember}
            variant="contained"
            color="error"
            sx={{
              color: "#fff",
              fontSize: "10pt",
              width: "100%",
              marginTop: "15px",
              backgroundColor: colors.error.main,
              "&:hover": {
                backgroundColor: colors.error.main,
              },
            }}
          >
            Remove {props.email}
          </Button>
        </VuiBox>
      </Modal>
      <VuiSnackbar
        color="error"
        title="Polyhive"
        content={snackbarMessage}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={3000}
      />
    </>
  );
};

const ConfirmLeaveModal = (props) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, orgs, activeOrg } = controller;
  const leaveOrganization = () => {
    const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/organization/departOrg`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
    })
      .then((data) => {
        if (data.status === 500) {
          setSnackbarMessage("Admin users cannot leave their organization.");
          setSnackbarOpen(true);
          return null;
        } else if (data.status !== 200) {
          setSnackbarMessage("Something went wrong. Please try again later.");
          setSnackbarOpen(true);
          return null;
        } else {
          return data.json();
        }
      })
      .then((data) => {
        if (data) {
          //console.log(data);
          cookies.remove("activeOrg");
          props.onClose();
          window.location.reload();
        }
      });
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ modalStyle }}
      >
        <VuiBox sx={style}>
          <div style={{ textAlign: "center", marginBottom: "24px" }}>
            <h5 style={{ textAlign: "center", color: "white" }}>
              Are you positive you want to leave this organization? You will lose access to all
              managed content and API access.
            </h5>
          </div>

          <Button
            onClick={leaveOrganization}
            variant="contained"
            color="error"
            sx={{
              color: "#fff",
              fontSize: "10pt",
              width: "100%",
              marginTop: "15px",
              backgroundColor: colors.error.main,
              "&:hover": {
                backgroundColor: colors.error.main,
              },
            }}
          >
            Leave Organization
          </Button>
        </VuiBox>
      </Modal>
      <VuiSnackbar
        color="error"
        title="Polyhive"
        content={snackbarMessage}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={3000}
      />
    </>
  );
};

const MemberGrid = () => {
  const [inviteOpen, setInviteOpen] = useState(false);
  const [members, setMembers] = React.useState([]);
  const navigate = useNavigate();
  const [controller, dispatch] = useVisionUIController();
  const [removeOpen, setRemoveOpen] = useState(false);
  const [removeEmail, setRemoveEmail] = useState("");

  const { activeOrg, isAdmin } = controller;

  const columns = [
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "pending",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (value) => {
        return <PendingCell value={value.formattedValue} />;
      },
      sortable: false,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (value) => {
        return (
          <RemoveCell
            value={value}
            disable={(value && value.row && value.row.role === "admin") || !isAdmin}
            onTrashClick={() => {
              setRemoveEmail(value.row.email);
              setRemoveOpen(true);
            }}
          />
        );
      },
      sortable: false,
      clickable: true,
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (value) => {
    //     return <ActionCell value={value} />;
    //   },
    // },
  ];
  const [rows, setRows] = React.useState([]);
  useEffect(() => {
    //console.log("setting members ");
    // set members to be the list of members
    // fetch members from REACT_BACKEND_URL/organization/members
    if (activeOrg !== "") {
      //console.log("active org: ", activeOrg);
      const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/organization/members`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-ORG-ID": activeOrg,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 403) {
            logoutUser(navigate);
          }
        })
        .then((data) => {
          //console.log("membvers: ", data);
          const mrows = [];
          for (let i = 0; i < data.emails.length; i++) {
            //console.log("email: ", data.emails[i], " role: ", data.member_privileges[i]);
            var email = data.emails[i];
            if (
              activeOrg === "user-live-4de80b7b-38a1-475a-91f8-3933679b64cf" &&
              isAdmin === false
            ) {
              email = "Email Hidden";
            }

            if (data.member_privileges[i] !== "admin") {
              mrows.push({
                id: i,
                email: email,
                role: data.member_privileges[i],
                pending: data.pending[i],
              });
            } else {
              mrows.unshift({
                id: i,
                email: email,
                role: data.member_privileges[i],
                pending: data.pending[i],
              });
            }
          }
          setRows(mrows);
        });
    }
  }, [activeOrg]);

  const addPendingInvite = (email) => {
    // const newRows = rows;
    // newRows.push({
    //   id: rows.length,
    //   email: email,
    //   role: "member",
    //   pending: true,
    // });
    // setRows(newRows);
    // above code is not working: error says TypeError: Cannot add property 4, object is not extensible
    // so using the below code
    const newRows = [...rows];
    newRows.push({
      id: rows.length,
      email: email,
      role: "member",
      pending: true,
    });
    setRows(newRows);
  };
  return (
    <>
      <VuiBox className="mt_2">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <VuiBox
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // marginTop: "50px",
              }}
            >
              <VuiTypography className="c_white fw_600 f_large">Members</VuiTypography>
              <Button
                className="default_btn bg_dark c_white ml_2"
                disabled={!isAdmin}
                onClick={() => {
                  logAuthorizedWombatEvent("orgInviteClicked", {});

                  setInviteOpen(true);
                }}
              >
                + Invite
              </Button>
            </VuiBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <div style={{ height: 600, width: "100%", color: "#fff" }}>
              <DataGrid
                sx={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  border: "none",
                  backgroundColor: colors.background.main,
                  color: "#fff",
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.background.main,
                    color: "#fff",
                  },
                  "& .MuiTablePagination-root": {
                    borderRadius: "30px",

                    backgroundColor: colors.background.main,
                    color: "#fff",
                  },
                  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
                    borderBottom: "1px solid #3b3b3b",
                  },
                  // set disabled state color differe
                  "& .MuiTablePagination-actions button:disabled": {
                    color: "#a0a0a0",
                  },
                  "& .MuiSvgIcon-root": {
                    height: 0,
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderColor: "#3b3b3b",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    borderColor: "#3b3b3b",
                  },
                }}
                disableSelectionOnClick
                rows={rows}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[5]}
                disableColumnFilter
                disableColumnMenu
              />
            </div>
          </Grid>
        </Grid>
      </VuiBox>
      <InviteModal
        open={inviteOpen}
        callback={addPendingInvite}
        onClose={() => setInviteOpen(false)}
      />
      <RemoveMemberModal
        open={removeOpen}
        onClose={() => setRemoveOpen(false)}
        email={removeEmail}
      />
    </>
  );
};

const ActionCell = (props) => {
  // Render the custom cell component using the cell's value
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [role, setRole] = useState(props.row.role);
  // const [pending, setPending] = useState(props.row.pending);
  // const [email, setEmail] = useState(props.row.email);
  // const [id, setId] = useState(props.row.id);
  // const [activeOrg, setActiveOrg] = useRecoilState(activeOrgState);
  // const [isAdmin, setIsAdmin] = useRecoilState(isAdminState);
  // const [rows, setRows] = useRecoilState(rowsState);
  // const [members, setMembers] = useRecoilState(membersState);

  useEffect(() => {
    //console.log("action cell: ", props.value.row);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Button
      variant="contained"
      sx={{ marginLeft: "20px", color: "#fff", fontSize: "8pt" }}
      // disabled={!isAdmin}
      backgroundColor="error"
      onClick={() => {
        setInviteOpen(true);
      }}
    >
      Remove
    </Button>
  );
};

const RemoveCell = (props) => {
  const handleRemove = () => {
    // Call the appropriate function to remove the member
    // You may need to pass in additional information to the function, such as the member's ID

    // //console.log("remove cell: ", props);
    props.onTrashClick();
    // props.onRemove(props.memberId);
  };

  return props.disable ? undefined : (
    <>
      <Tooltip title="Remove member" arrow>
        <HighlightOffOutlinedIcon
          style={{ width: "30px", height: "30px", color: colors.error.main }}
          onClick={handleRemove}
        />
      </Tooltip>
    </>
  );
};

const PendingCell = (props) => {
  // Render the custom cell component using the cell's value

  useEffect(() => {
    //console.log("pending cell: ", props);
  }, []);
  return (
    <>
      {props.value ? (
        <Tooltip title="Invite pending" arrow>
          <HourglassEmptyIcon style={{ width: "30px", height: "30px", color: "#fff" }} />
        </Tooltip>
      ) : (
        <CheckIcon style={{ width: "30px", height: "30px", color: "#00e676" }} />
      )}
    </>
  );
};
const ChangeNameModal = forwardRef((props, ref) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [controller, dispatch] = useVisionUIController();
  const { activeOrg } = controller;
  const navigate = useNavigate();

  const changeName = (e) => {
    e.preventDefault();
    const name = e.target[0].value;
    const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/organization/name`, {
      method: "POST",
      credentials: "include",
      headers: {
        "X-ORG-ID": activeOrg,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
      }),
    }).then((response) => {
      if (response.ok) {
        props.callback(name);
        props.onClose();
        setSnackbarMessage("Name changed successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        return response.json();

        // return name in callback
      } else if (response.status === 403) {
        logoutUser(navigate);
      } else if (response.status === 401) {
        //console.log(response.statusText);
        setSnackbarMessage("You are not authorized to change the name");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        props.onClose();
      }
    });

    // send invite to email

    // if successful, then show snackbar and clear input
    e.target[0].value = "";
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ modalStyle }}
      >
        <VuiBox sx={style}>
          <div style={{ textAlign: "center", marginBottom: "24px" }}>
            <h4 style={{ textAlign: "center", color: "white" }}>Set Organization Name</h4>
          </div>

          <form onSubmit={changeName}>
            <VuiInput placeholder="Name" sx={{ height: "100%" }} type="name" />
            <Button
              type="submit"
              variant="contained"
              sx={{
                color: "#fff",
                fontSize: "10pt",
                width: "100%",
                marginTop: "15px",
              }}
            >
              Apply
            </Button>
          </form>
        </VuiBox>
      </Modal>
      <VuiSnackbar
        color={snackbarSeverity}
        title="Polyhive"
        content={snackbarMessage}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={3000}
      />
    </>
  );
});

const InviteModal = forwardRef((props, ref) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [controller, dispatch] = useVisionUIController();
  const { activeOrg } = controller;

  const sendInvite = (e) => {
    e.preventDefault();
    const role = labelToValue[document.getElementById("roleSelector").textContent];
    const email = document.getElementById("emailInput").value;
    // const role = e.target.querySelector('Role').value;
    // const eMail = e.target.querySelector('emailInput').value;

    //console.log("role", role);
    //console.log("email", email);
    // const email = e.target[0].value;
    const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/organization/invite`, {
      method: "POST",
      credentials: "include",
      headers: {
        "X-ORG-ID": activeOrg,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        privileges: role,
      }),
    }).then((response) => {
      if (response.status === 200) {
        //console.log("invite sent");
        // Add to table with pending status
        props.callback(email);
      } else {
        //console.log("active org", activeOrg);
        //console.log("invite not sent", response);
      }
    });

    // send invite to email

    // if successful, then show snackbar and clear input
    e.target[0].value = "";
    setSnackbarOpen(true);
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ modalStyle }}
      >
        <VuiBox sx={style}>
          <div style={{ textAlign: "center", marginBottom: "24px" }}>
            <h4 style={{ textAlign: "center", color: "white" }}>Send Invite</h4>
          </div>
          <VuiTypography
            variant="body1"
            color="white"
            fontWeight="bold"
            fontSize="12pt"
            sx={{ marginBottom: "5px", marginLeft: "5px" }}
          >
            Role
          </VuiTypography>
          <form onSubmit={sendInvite}>
            <VuiSelect
              id="roleSelector"
              sx={{
                marginBottom: "15px",
                color: "#fff",

                "& .Input": {
                  color: "#fff",
                },
              }}
              placeholder="Member (Read/Upload/Delete Assets)"
              options={[
                { value: "member", label: "Member (Read/Upload/Delete Assets)" },
                // { value: "ru_member", label: "Member (Read/Upload Assets)" },
                // { value: "r_member", label: "Member (Read Assets)" },
              ]}
              styles={colourStyles}
              // isDisabled
              // defaultInputValue="Member"
              // isDisabled
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral50: "#fff", // Placeholder color
                },
              })}
            ></VuiSelect>
            <VuiTypography
              variant="body1"
              color="white"
              fontWeight="bold"
              fontSize="12pt"
              sx={{ marginTop: "15px", marginBottom: "5px", marginLeft: "5px" }}
            >
              Email
            </VuiTypography>
            <VuiInput
              id="emailInput"
              label="emailInput"
              placeholder="Email"
              sx={{ height: "100%" }}
              type="email"
              autocomplete="on"
            />
            <Button
              type="submit"
              variant="contained"
              sx={{
                color: "#fff",
                fontSize: "10pt",
                width: "100%",
                marginTop: "15px",
              }}
            >
              Send
            </Button>
          </form>
        </VuiBox>
      </Modal>
      <VuiSnackbar
        color="success"
        title="Polyhive"
        content="Invite sent! Be sure to check your spam folder."
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={7000}
      />
    </>
  );
});

const Subscription = () => {
  return <></>;
};

export default Organization;
