/*

Polyhive Dashboard

*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import colors from "assets/theme/base/colors";
import { PropaneSharp } from "../../../../node_modules/@mui/icons-material/index";

function DefaultNavbarHref({ name, light, link, alink, beta, ...rest }) {
  return (
    <>
      <VuiBox
        {...rest}
        mx={1}
        p={1}
        display="flex"
        alignItems="center"
        textAlign="left"
        // color="#9896A9"
        sx={{
          width: "100%",
          marginTop: "3px",
          "&:hover": {
            color: colors.icon.focusdark,
          },
        }}

        // minWidth="80px"
      >
        <VuiTypography
          variant="button"
          fontWeight={400}
          textTransform="capitalize"
          // textAlign="center"
          // color="#9896A9"
          sx={{
            width: "auto",
            whiteSpace: "nowrap",
            color: colors.icon.disabled,
            fontWeight: 400,
            "&:hover": {
              // color: colors.icon.focusdark,
            },
          }}
        >
          {alink === "" ? (
            <Link
              style={{
                textAlign: "center",
                color: colors.icon.disabled,
                "&:hover": {
                  color: colors.icon.focusdark,
                },
              }}
              to={link}
            >
              {name}
            </Link>
          ) : (
            <a
              style={{
                // textAlign: "left",
                color: colors.icon.disabled,
                "&:hover": {
                  color: colors.icon.focusdark,
                },
              }}
              href={alink}
              target={"_blank"}
            >
              {name}
            </a>
          )}
        </VuiTypography>
        {beta ? (
          <Link to={link}>
            <VuiBox
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                height: "16px",
                // margin: "0px 5px",
                width: "40px",
                marginLeft: "5px",
                marginTop: "2px",
                // marginBottom: "0px",
                borderRadius: "10px",
                backgroundColor: colors.highlight.submain,
                color: colors.highlight.main,
              }}
              className="bg_hivegradient"
            >
              <VuiTypography
                variant="button"
                // fontWeight="bold"
                textAlign="center"
                textTransform="uppercase"
                sx={{
                  //   color: colors.icon.focus,
                  fontSize: "0.55rem",
                  //   fontWeight: 450,
                }}
                className="fw_500 c_white"
              >
                Beta
              </VuiTypography>
            </VuiBox>
          </Link>
        ) : null}
      </VuiBox>
    </>
  );
}

// Setting default values for the props of DefaultNavbarLink
DefaultNavbarHref.defaultProps = {
  light: false,
};

// Typechecking props for the DefaultNavbarLink
DefaultNavbarHref.propTypes = {
  name: PropTypes.string.isRequired,
  light: PropTypes.bool,
};

export default DefaultNavbarHref;
