/**

 =========================================================
 * AQUEDUCT CONTENT PAGE
 =========================================================
 * Designed and Coded by Rohan Iyer
 =========================================================

 */

import { Grid, Button, Modal, Box, Typography } from "@mui/material";

import UploadModal from "../UploadModal";
import React, { useEffect, useState, useRef, lazy, Suspense, useReducer } from "react";

import "layouts/dashboards/default/components/UploadModal/style/style.css";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import ModelGridItem from "../ModelGridItem/index";
import ModelListItem from "../ModelListItem/index";
// const LazyModelGridItem = lazy(() => import("../ModelGridItem"));
import { ProgressBar, Circles, Triangle } from "react-loader-spinner";
import colors from "assets/theme/base/colors";

import { useFetch, useInfiniteScroll, useLazyLoading } from "./hooks/customHooks";
import { useVisionUIController, setOnboardingIndex } from "context";
import { logoutUser } from "layouts/authentication/AuthFunctions";

import amplitude from "amplitude-js";
import Inspector from "../Inspector";
import UploadIcon from "@mui/icons-material/Upload";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridViewIcon from "@mui/icons-material/GridView";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SettingsIcon from "@mui/icons-material/Settings";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DarkTooltip from "../CustomTooltip/";
import DashboardNav from "../DashboardNav/";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import TextField from "@mui/material/TextField";
import Draggable, { DraggableCore } from "react-draggable";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FolderIcon from "@mui/icons-material/Folder";
import VuiSnackbar from "components/VuiSnackbar";
import Breadcrumbs from "../Breadcrumbs";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RenameModal from "../RenameModal";
import CreateFolderModal from "../CreateFolderModal";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import DownloadFolderModal from "../DownloadFolderModal/index";
import { getFileType } from "util/UtilFunctions";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";
import SearchIcon from "@mui/icons-material/Search";
import Sidenav from "examples/Sidenav";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import "layouts/dashboards/default/components/Inspector/style.css";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";

const modalStyle = {
  width: "200px",
  height: "100%",
  position: "absolute",
  alignItems: "center",
  justifyContent: "center",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: colors.background.main,
  border: "2px solid #000",
  boxShadow: 24,
  justifyContent: "center",
  p: 3,
  borderRadius: "15px",
};

const Spinner = () => {
  return (
    <Triangle
      height="80"
      width="80"
      ariaLabel="progress-bar-loading"
      wrapperStyle={{}}
      wrapperClass="progress-bar-wrapper"
      // borderColor="#fff"
      color={colors.icon.quaternaryfocus}
    />
  );
};

const DOWNLOAD_SIZE_MAX_MB = 1000;
const THRESHOLD = 100; // Distance from top/bottom of screen before scrolling starts
const fileTypes = ["jpeg", "png", "vrm", "glb", "gltf", "jpg", "fbx", "obj"];
let abortDownloadController = new AbortController();

function ModelGrid2() {
  const [dropEnabled, setDropEnabled] = useState(false);
  const uploadRef = useRef(null);
  const fileInput = useRef(null);
  const folderInput = useRef(null);
  const breadcrumbRef = useRef(null);

  const [controller, dispatch] = useVisionUIController();
  const { activeOrg, orgs, currentlyUploading, onboardingIndex, miniSidenav } = controller;
  const currentlyUploadingRef = useRef();
  currentlyUploadingRef.current = currentlyUploading;
  const [rcMenu, setRcMenu] = React.useState(false);
  const rcMenuRef = useRef();
  rcMenuRef.current = rcMenu;

  const [mousePosition, setMousePosition] = React.useState({ x: 0, y: 0 });
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [renameModalOpen, setRenameModalOpen] = React.useState(false);

  const [createFolderModalOpen, setCreateFolderModalOpen] = React.useState(false);
  const downloadFolderModalRef = useRef();
  const [inspectorOpen, setInspectorOpen] = React.useState(false);
  const [gridView, setGridView] = React.useState(true);
  const gridViewRef = useRef;
  gridViewRef.current = gridView;

  const [currentFolder, setCurrentFolder] = React.useState("");
  const currentFolderRef = useRef();
  currentFolderRef.current = currentFolder;

  const [currentPath, setCurrentPath] = useState([]);
  const [selectedIds, setSelectedIds] = useState("");
  const [inspectedId, setInspectedId] = useState("");
  const inspectedIdRef = useRef();
  inspectedIdRef.current = inspectedId;
  const [draggingId, setDraggingId] = useState("");

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");
  const [snackbar, setSnackbar] = useState(false);
  const [draggingEnabled, setDraggingEnabled] = useState(true);
  const [barDragged, setBarDragged] = useState(false);

  const [uploadMenu, setUploadMenu] = useState(false);

  const [rightClickingFolder, setRightClickingFolder] = useState(false);

  const [showPopup, setShowPopup] = useState(false);

  const [firstLoad, setFirstLoad] = useState(true);

  const [allowDragAndDrop, setAllowDragAndDrop] = useState(true);

  useEffect(() => {
    const cookieExists = document.cookie.split(";").some((cookie) => {
      return cookie.trim().startsWith("visited2=true");
    });

    if (!cookieExists) {
      setShowPopup(true);
    }

    // need to add an event listener for window resize and if the width is less than 600px then set allowDragAndDrop to false
    window.addEventListener("resize", handleResize);

    if (window.innerWidth <= 600) {
      setAllowDragAndDrop(false);
    } else {
      setAllowDragAndDrop(true);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    if (window.innerWidth <= 600) {
      setAllowDragAndDrop(false);
    } else {
      setAllowDragAndDrop(true);
    }
  };

  useEffect(() => {
    if (onboardingIndex === 1) {
      setShowPopup(false);
    }
  }, [onboardingIndex]);

  const handleNextPopup = () => {
    setOnboardingIndex(dispatch, onboardingIndex + 1);
  };

  const rcShowStyle = {
    zIndex: "1000",
    position: "fixed",

    backgroundColor: colors.background.secondary,
    padding: "2px",
    display: "block",
    marginBottom: "100px",
    top: mousePosition.y + "px",
    left: mousePosition.x + "px",
  };
  const rcHideStyle = {
    display: "none",
  };

  // IMAGE AND PAGE REDUCER FOR STATE MANAGEMENT OF ASSETS
  const imgReducer = (state, action) => {
    switch (action.type) {
      case "STACK_IMAGES":
        // add images to array of images in state
        // dont add duplicates with same modelId
        if (action.imagesArray.length !== 0) {
          const modelIds = state.images.map((image) => image.modelId);
          const filteredImages = action.imagesArray.filter(
            (image) => !modelIds.includes(image.modelId)
          );
          return { ...state, images: state.images.concat(filteredImages) };
        } else {
          return state;
        }

      case "SEARCH_TERM":
        return { ...state, searchTerm: action.searchTerm };
      case "FOLDER":
        return { ...state, parentFolder: action.folder };
      case "UPLOAD_IMAGE":
        if (action.image.parentFolder === currentFolderRef.current) {
          return { ...state, images: [action.image, ...state.images] };
        } else {
          return state;
        }

      // case for adding single image to end of array
      case "ADD_IMAGE":
        return { ...state, images: [...state.images, action.image] };
      case "FETCHING_IMAGES":
        return { ...state, fetching: action.fetching };
      // delete image from array of images in state by filePath of image
      case "SET_THUMBNAIL_FOR_MODELID":
        return {
          ...state,
          images: state.images.map((image) => {
            if (image.modelId === action.modelId) {
              return { ...image, thumbnail: action.thumbnail };
            } else {
              return image;
            }
          }),
        };

      case "ADD_IMAGES":
        return {
          ...state,
          images: [...state.images, ...action.images],
        };

      case "DELETE_IMAGE":
        return {
          ...state,
          images: state.images.filter((image) => image.modelId !== action.modelId),
        };

      case "DELETE_IMAGES":
        return {
          ...state,
          images: state.images.filter((image) => !action.modelIds.includes(image.modelId)),
        };

      case "REPLACE_IMAGE":
        return {
          ...state,
          images: state.images.map((image) => {
            if (image.modelId === action.modelId) {
              return action.image;
            } else {
              return image;
            }
          }),
        };

      case "RENAME_IMAGE":
        //console.log("rename image", action.index, action.name);
        return {
          ...state,
          images: state.images.map((image, i) => {
            if (i === action.index) {
              //console.log("asset type is", image.assetType);
              if (image.assetType === "folder") {
                return { ...image, folderName: action.name, fileName: action.name };
              } else {
                return { ...image, fileName: action.name };
              }
            } else {
              return image;
            }
          }),
        };

      case "CLEAR_IMAGES":
        return { ...state, images: [] };

      default:
        return state;
    }
  };
  const pageReducer = (state, action) => {
    switch (action.type) {
      case "ADVANCE_PAGE":
        if (activeOrg !== "") return { ...state, page: state.page + 1 };
        else return { ...state, page: 1 };
      case "SET_SEARCH_TERM":
        //console.log("pageReducer set search term", action.searchTerm);
        return { ...state, searchTerm: action.searchTerm, page: 1 };
      case "SET_FOLDER":
        //console.log("pageReducer set folder", action.parentFolder);
        return { ...state, parentFolder: action.parentFolder, page: action.page };
      case "RESET_PAGE":
        return { ...state, page: 1 };
      case "REFETCH_SPECIFIC_PAGE":
        return { ...state, page: action.page };

      default:
        return state;
    }
  };
  const [pager, pagerDispatch] = useReducer(pageReducer, { page: 1, searchTerm: "" });
  const [imgData, imgDispatch] = useReducer(imgReducer, { images: [], fetching: true });

  let bottomBoundaryRef = useRef(null);

  const arrRef = useRef();
  arrRef.current = imgData.images;

  const currentPathRef = useRef();
  currentPathRef.current = currentPath;

  useFetch(pager, imgDispatch);
  useLazyLoading(".grid-img-top", imgData.images);
  useInfiniteScroll(bottomBoundaryRef, pagerDispatch);

  // EVENT HANDLERS
  useEffect(() => {
    window.addEventListener("dragover", handleDragOver);
    window.addEventListener("dragend", handleDragEnd);
    // window.addEventListener("drop", handleDrop);
    window.addEventListener("dragenter", handleDragEnter);
    window.addEventListener("mouseout", handleMouseOut);

    const topBar = document.getElementById("topBar");
    topBar.addEventListener("dragover", handleDragBarOver);
    topBar.addEventListener("dragleave", handleDragBarLeave);

    if (window.addEventListener) {
      window.addEventListener("contextmenu", handleRightClick, false);
    }

    // if the page is clicked, hide the context menu
    // document.addEventListener("click", handleClick);
    // double click to open folder
    document.addEventListener("dblclick", handleDoubleClick);

    // document.addEventListener("drag", handleMouseMove);
    // mousedown event to get dragging asset id
    // document.addEventListener("mousedown", handleMouseDown);

    // DROPZONE EVENT LISTENERS
    var dropArea = document.getElementById("dropArea");
    var fileDrop = document.getElementById("file");
    // fileDrop.removeEventListener("click", () => console.log("click"));
    // dropArea.addEventListener("dragover", dragHandler);
    // dropArea.addEventListener("change", handleFileUpload);
    dropArea.addEventListener("drop", handleFileUpload);

    return () => {
      window.removeEventListener("dragover", handleDragOver);
      window.removeEventListener("dragend", handleDragEnd);
      // window.removeEventListener("drop", handleDrop);
      window.removeEventListener("dragenter", handleDragEnter);
      window.removeEventListener("mouseout", handleMouseOut);

      topBar.removeEventListener("dragover", handleDragBarOver);
      topBar.removeEventListener("dragleave", handleDragBarLeave);

      window.removeEventListener("contextmenu", handleRightClick, false);
      // document.removeEventListener("click", handleClick);
      document.removeEventListener("dblclick", handleDoubleClick);
      // document.removeEventListener("drag", handleMouseMove);
      // document.removeEventListener("mousedown", handleMouseDown);

      dropArea.removeEventListener("change", handleFileUpload);
      dropArea.removeEventListener("drop", handleFileUpload);
    };
  }, []);

  useEffect(() => {
    console.log("imgData.images changed", arrRef.current);
    if (arrRef.current.length > 0) {
      if (firstLoad === true) {
        setFirstLoad(false);

        // set inspected id to the ID of the first element of the array where the assetType is "file"
        const index = arrRef.current.findIndex((image) => image.assetType === "file");
        if (index !== -1) {
          setInspectedId(arrRef.current[index].modelId);
          setInspectorOpen(true);
        }
      }
    }
  }, [arrRef.current]);

  useEffect(() => {
    // find index of currentFolder in imgData.images
    const index = arrRef.current.findIndex((image) => image.modelId === currentFolder);
    setDraggingEnabled(true);

    if (currentFolder === "") {
      viewFolder("");
      // pagerDispatch({ type: "SET_SEARCH_TERM", searchTerm: "" });
      // document.getElementById("search").value = "";
      setCurrentPath([]);
    } else if (index !== -1 && imgData.images[index].assetType === "folder") {
      if (imgData.images[index].assetType === "folder") {
        viewFolder(currentFolder);
        pagerDispatch({ type: "SET_SEARCH_TERM", searchTerm: "" });
        document.getElementById("search").value = "";
        const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/upload/folderPath`);
        const params = new URLSearchParams();
        params.set("asset_id", currentFolder);
        url.search = params.toString();

        fetch(url, {
          method: "GET",
          credentials: "include",

          headers: {
            "Content-Type": "application/json",
            "X-ORG-ID": activeOrg,
          },
        })
          .then((response) => {
            if (response.status === 403) {
              logoutUser(navigate);
            } else if (response.status !== 200) {
              alert("Error fetching data. Please try again later.");
            }
            return response.json();
          })
          .then((data) => {
            //console.log("folder path", data);
            const pathIds = data.assetIdPath.split("/");
            const pathNames = data.folderPath.split("/");
            const newPathArr = [];
            for (let i = 0; i < pathIds.length; i++) {
              const pathObj = {
                modelId: pathIds[i],
                name: pathNames[i],
              };
              newPathArr.push(pathObj);
            }
            setCurrentPath(newPathArr);
          });

        // BELOW WILL BE DELETED WHEN ABOVE IS WORKING
        // const pathObj = {
        //   modelId: currentFolder,
        //   name: arrRef.current[index].fileName,
        // };
        // setCurrentPath([...currentPath, pathObj]);
      }
    } else if (currentPath.some((path) => path.modelId === currentFolder)) {
      const index = currentPath.findIndex((path) => path.modelId === currentFolder);
      const newPath = currentPath.slice(0, index + 1);
      setCurrentPath(newPath);
      viewFolder(currentFolder);
    }
  }, [currentFolder]);

  useEffect(() => {
    setCurrentFolder("");
    setInspectorOpen(false);
  }, [activeOrg]);

  useEffect(() => {
    //console.log("current path changed", currentPath);
  }, [currentPath]);

  useEffect(() => {
    //console.log("selectedIds changed", selectedIds);
  }, [selectedIds]);

  const handleMouseMove = (e) => {
    // setTimeout for this

    //console.log("pointer move", e);
    //console.log("dragging enabled", draggingEnabled);
    //console.log("dragging id", draggingId);
    if (draggingEnabled) {
      //console.log("made it in");
      setMousePosition({ x: e.clientX, y: e.clientY });
      const { clientY } = e;
      const { innerHeight } = window;
      //console.log("clientY", clientY);
      //console.log("THRESHOLD", THRESHOLD);
      //console.log("innerHeight", innerHeight);
      if (clientY < THRESHOLD) {
        //console.log("SCROLLING UP");
        window.scrollBy(0, -100);
      } else if (clientY > innerHeight - THRESHOLD) {
        //console.log("SCROLLING DOWN");
        window.scrollBy(0, 100);
      }
    }
  };

  const handleRightClick = (e) => {
    const path = e.composedPath();
    //console.log("right click", e);
    //console.log("path", path);

    if (path) {
      let assetId = "";
      const useX = e.x;
      const useY = e.y;

      for (let i = 1; i < 6; i++) {
        //console.log("path[i] before error", path[i]);

        try {
          if (path[i].id !== "" && path[i].id.length === 36) {
            e.preventDefault();
            setRcMenu(true);
            // setSelectedId(path[i].id);
            setInspectedId(path[i].id);
            assetId = path[i].id;
            setMousePosition({ x: useX, y: useY });
            //console.log("INSPECTEDedd ID");
            // get index of asset in arrRef

            const index = arrRef.current.findIndex((image) => image.modelId === path[i].id);

            if (index !== -1) {
              if (
                arrRef.current.find((image) => image.modelId === path[i].id).assetType === "folder"
              ) {
                setRightClickingFolder(true);
              } else {
                setRightClickingFolder(false);
              }
            } else {
              assetId = "";
            }

            break;
          }
        } catch (err) {
          //console.log(err);
        }
      }
      if (assetId === "") {
        setRcMenu(false);
      }

      if (assetId !== "") {
        logAuthorizedWombatEvent("userRightClickedAsset", {
          assetId: assetId,
          activeOrg: activeOrg,
        });
      }
    }
  };
  const moveToFolder = (folderId) => {
    logAuthorizedWombatEvent("moveToFolder", {});

    const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/upload/moveAssetToFolder`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ asset_ids: [selectedIds[0]], parent_id: folderId }),
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
    }).then(async (response) => {
      if (response.status === 200) {
        //console.log("moved from grid", selectedIds[0]);
        // setDraggingId("");
        deleteCb(selectedIds[0]);
        // setItemDeleted(true);
      } else if (response.status === 400) {
        const detailedResponse = await response.json();
        //console.log("move ", detailedResponse.assetIdsToNotMove);
        props.deleteCb(
          [selectedIds[0]].filter((x) => !detailedResponse.assetIdsToNotMove.includes(x)),
          "move"
        );
        startSnackbar({
          message: "Moving one of these folders causes a name conflict in the target directory.",
          type: "error",
        });
      }
    });
  };

  const handleInspectedId = (id) => {
    setInspectedId(id);
  };

  const setNewDraggingId = (id) => {
    setDraggingId(id);
  };

  const handleInspectorOpen = (open) => {
    setInspectorOpen(open);
  };

  const handleClick = (e) => {
    const path = e.composedPath();

    if (path) {
      if (e.shiftKey) {
        e.preventDefault();

        //console.log("shift clicked");

        setRcMenu(false);
        let assetId = "";
        // if one of the imgData.images contains e.path[2].id as its image.modelId, then set inspector open to true)
        for (let i = 0; i < 6; i++) {
          if (path[i] && path[i].id) {
            if (path[i].id !== "" && path[i].id.length === 36) {
              // if path string follows format of xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx, then continue
              if (
                path[i].id.match(
                  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/
                )
              ) {
                if (inspectedIdRef.current !== path[i].id) {
                  setSelectedIdsToRange(inspectedIdRef.current, path[i].id);
                }

                break;
              }
            }
          }
        }

        if (assetId !== "") {
          logAuthorizedWombatEvent("userShiftClickedAsset", {
            assetId: assetId,
            activeOrg: activeOrg,
          });
        }
      } else {
        setRcMenu(false);

        let assetId = "";
        // if one of the imgData.images contains e.path[2].id as its image.modelId, then set inspector open to true)
        for (let i = 0; i < 6; i++) {
          //console.log("typeof path[i]", typeof path[i]);

          if (typeof path[i] === "object" && path[i].id) {
            if (path[i].id !== "" && path[i].id.length === 36) {
              // if path string follows format of xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx, then continue
              if (
                path[i].id.match(
                  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/
                )
              ) {
                setSelectedIds([]);

                setInspectedId(path[i].id);
                assetId = path[i].id;
                setInspectorOpen(true);
                break;
              }
            }
          }
        }

        if (assetId !== "") {
          logAuthorizedWombatEvent(
            gridViewRef.current ? "userClickedAssetInGridView" : "userClickedAssetInListView",
            { assetId: assetId, activeOrg: activeOrg }
          );
        } else {
          if (rcMenuRef.current === false) {
            //console.log("CLICK EVENT: wiping selection", e, "path", path);
            for (let i = 0; i < 6; i++) {
              if (typeof path[i] === "object" && path[i].id) {
                if (path[i].id === "ModelGrid") {
                  //console.log("CLICK EVENT: clicked on ModelGrid");
                  setSelectedIds([]);
                  setInspectedId("");
                  setInspectorOpen(false);
                  break;
                }
              }
            }
          }
        }
      }
    }
  };

  // start is the id of the first asset in the range
  // end is the id of the last asset in the range
  // get a sub array of the elements between start and end in the imgData.images array
  // set the selectedIds state to the array of ids
  //
  const setSelectedIdsToRange = (start, end) => {
    //console.log(arrRef.current);
    const startIdx = arrRef.current.findIndex((img) => img.modelId === start);
    const endIdx = arrRef.current.findIndex((img) => img.modelId === end);

    const realStart = startIdx < endIdx ? startIdx : endIdx;
    const realEnd = startIdx < endIdx ? endIdx : startIdx;

    const range = arrRef.current.slice(realStart, realEnd + 1);
    //console.log("range", range);

    const ids = range.map((img) => img.modelId);

    setSelectedIds(ids);
  };

  const handleDoubleClick = (e) => {
    console.log("DOUBLE CLICK EVENT", e);
    const path = e.composedPath();

    if (path) {
      e.preventDefault();
      setRcMenu(false);
      let assetId = "";
      // if one of the imgData.images contains e.path[2].id as its image.modelId, then set inspector open to true)
      if (rcMenu === false) {
        for (let i = 0; i < 6; i++) {
          if (typeof path[i] === "object" && path[i].id) {
            if (path[i].id !== "" && path[i].id.length === 36) {
              // if path string follows format of xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx, then continue
              if (
                path[i].id.match(
                  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/
                )
              ) {
                //console.log("double clicked on asset", path[i].id);
                //console.log(
                //   "current path",
                //   currentPathRef.current.find((el) => el.id === path[i].id)
                // );
                //console.log(getImage(path[i].id));
                //  only do this if the asset is a folder, check arrRef.current
                // and if the id isnt a property of any of the elements in currentPathRef.current
                if (currentPathRef.current.findIndex((el) => el.modelId === path[i].id) === -1) {
                  if (getImage(path[i].id).assetType === "folder") {
                    //console.log("path id", path[i].id);
                    setCurrentFolder(path[i].id);
                    pagerDispatch({ type: "SET_SEARCH_TERM", searchTerm: "" });
                    assetId = path[i].id;

                    logAuthorizedWombatEvent("folderDoubleClicked", {});

                    break;
                  }
                }

                // }
              }
            }
          }
        }

        if (assetId !== "") {
          logAuthorizedWombatEvent("userDoubleClicked", { assetId: assetId, activeOrg: activeOrg });
        }
      }
    }
  };

  const handleDragBarOver = (e) => {
    if (e.dataTransfer.types.length === 1 && e.dataTransfer.types[0] === "text/plain") {
      if (currentPathRef.current.length > 0) {
        // setBarDragged(true);
      }
    }
    e.preventDefault();
  };

  const handleDragBarLeave = (e) => {
    e.preventDefault();
    setBarDragged(false);
  };

  const viewFolder = (id) => {
    setInspectorOpen(false);
    // imgDispatch({ type: "CLEAR_IMAGES" });
    pagerDispatch({ type: "SET_SEARCH_TERM", searchTerm: "" });
    pagerDispatch({ type: "SET_FOLDER", parentFolder: id, page: 1 });
  };

  const getImage = (id) => {
    //console.log("imgData", imgData);
    const index = arrRef.current.findIndex((image) => image.modelId === id);
    return arrRef.current[index];
  };

  const updateWithLoaded = (data) => {
    // if (data.parentFolder !== pagerData.parentFolder) return;
    addItemFirst(data);
  };

  const addItemFirst = (data) => {
    const pushObj = {
      key: imgData.images.length,
      fileName: data.fileName,
      fileSize: data.fileSize,
      thumbnail: data.thumbnail,
      modelId: data.modelId,
      filePath: data.filePath,
      fileType: getFileType(data.fileName),
      lastUpdated: new Date().toISOString(),
      assetType: data.assetType,
      parentFolder: data.parentFolder,
      show: true,
    };
    if (typeof data.fileObj !== "undefined") {
      pushObj.fileObj = data.fileObj;
    }

    imgDispatch({ type: "UPLOAD_IMAGE", image: pushObj });
  };

  const renameItem = (data) => {
    const index = arrRef.current.findIndex((image) => image.modelId === data.assetId);
    //console.log("Renaming ", data);
    //console.log("Index ", index);
    // const newImages = [...imgData.images];
    // newImages[index].name = data.name;
    imgDispatch({ type: "RENAME_IMAGE", name: data.name, index: index });
  };

  const addFolder = (data) => {
    const pushObj = {
      key: imgData.images.length,
      fileName: data.folderName,
      lastUpdated: new Date().toISOString(),
      modelId: data.modelId,
      show: true,
      assetType: "folder",
      parentFolder: data.parentFolder,
    };
    console.log("ADDING FOLDER", data);
    setCreateFolderModalOpen(false);
    imgDispatch({ type: "UPLOAD_IMAGE", image: pushObj });
  };

  const handleDragOver = (e) => {
    // if (uploadRef.current.getOpen()) {
    //   return;
    // }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnd = (e) => {
    // if (uploadRef.current.getOpen()) {
    //   return;
    // }
    setDropEnabled(false);
  };

  const handleDrop = (event) => {
    // if (uploadRef.current.getOpen()) {
    //   return;
    // }
    event.preventDefault();
    event.stopPropagation();

    var dt = event.dataTransfer;
    if (
      dt.types &&
      (dt.types.indexOf ? dt.types.indexOf("Files") != -1 : dt.types.contains("Files"))
    ) {
      const files = event.dataTransfer.files;
      setDropEnabled(false);
      uploadRef.current.uploadFiles(files);
    }
  };

  const handleChange = async (event) => {
    const files = event.target.files;
    console.log("files", files);

    uploadRef.current.uploadFiles(files);
  };

  const handleDragEnter = (e) => {
    // if (uploadRef.current.getOpen()) {
    //   return;
    // }
    e.preventDefault();
    e.stopPropagation();
    var dt = e.dataTransfer;
    if (
      dt.types &&
      (dt.types.indexOf ? dt.types.indexOf("Files") != -1 : dt.types.contains("Files"))
    ) {
      setDropEnabled(true);
    }
  };

  const handleReplace = (data) => {
    // find the item in the array that matches the modelId and replace it
    //console.log("handle replace from index2", data);
    const index = imgData.images.findIndex((image) => image.modelId === data.modelId);
    // for now just print the image at that index
    //console.log("index", imgData.images[index]);

    const newElement = imgData.images[index];
    // set the fileName, fileSize, thumbnail, filePath, and fileType of imgData.images[index] to the values in data
    newElement.fileName = data.fileName;
    newElement.fileSize = data.fileSize;
    newElement.thumbnail = data.thumbnail;
    newElement.filePath = data.filePath;
    newElement.fileType = getFileType(data.fileName);
    // get a new date in the format of "2023-01-13T05:25:15.585Z"
    // and set it to the lastUpdated property of imgData.images[index]
    newElement.lastUpdated = new Date().toISOString();
    // set the state to the new array
    imgDispatch({ type: "REPLACE_IMAGE", image: newElement, modelId: data.modelId });
  };

  const handleMouseOut = (e) => {
    if (e.relatedTarget === null) {
      // The user has moved the mouse outside of the window and has released the mouse button, so trigger the dragend event
      setDropEnabled(false);
    }
  };
  const handleTrash = () => {
    logAuthorizedWombatEvent("assetDeleteClicked", {
      assetId: inspectedId,
      numDeleted: selectedIds.length > 0 ? selectedIds.length : 1,
    });

    if (selectedIds.length > 0) {
      deleteObjects(selectedIds);
    } else if (inspectedId !== "") {
      deleteObjects([inspectedId]);
    }
  };

  const deleteObjects = (model_ids) => {
    // get file path of item in imgData.images that matches model_id
    let folderIds = [];
    let fileIds = [];
    model_ids.forEach((model_id) => {
      const index = arrRef.current.findIndex((image) => image.modelId === model_id);
      if (arrRef.current[index].assetType === "folder") {
        folderIds.push(model_id);
      } else {
        fileIds.push(model_id);
      }
    });
    if (folderIds.length > 0) {
      // deleteFolders(folderIds);
      //console.log("folderIds", folderIds);
      for (let i = 0; i < folderIds.length; i++) {
        //console.log("deleting folder", folderIds[i]);
        const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/upload/deleteFolder`, {
          method: "DELETE",
          credentials: "include",
          headers: {
            "X-ORG-ID": activeOrg,
            "PARENT-ID": folderIds[i],
          },
        }).then((response) => {
          if (response.status === 200) {
            //console.log("deleted", folderIds[i]);
            if (folderIds.length > 0 && fileIds.length > 0) {
              startSnackbar({
                message:
                  "Deleted " + fileIds.length + " asset(s) and " + folderIds.length + " folder(s)",
                type: "success",
              });
            } else {
              startSnackbar({
                message: "Deleted " + folderIds.length + " folder(s)",
                type: "success",
              });
            }
            deleteCb([folderIds[i]]);

            // setItemDeleted(true);
            // call parent function to update state of GridItems

            handleDeleteModalClose();
          } else if (response.status === 403) {
            logoutUser(navigate);
          } else {
            //console.log("deleted failed? ", response);
            //console.log("deleted failed? ", response);
            startSnackbar({
              type: "error",
              message: "You are not authorized to delete items in this org.",
            });
            handleDeleteModalClose();
          }
        });
      }
    }
    if (fileIds.length > 0) {
      //console.log("deleting files", fileIds);
      //console.log("as opposed to ", model_ids);
      const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/upload/deleteModelsForOrg`, {
        method: "DELETE",
        credentials: "include",
        body: JSON.stringify({ model_ids: fileIds }),
        headers: {
          "Content-Type": "application/json",
          "X-ORG-ID": activeOrg,
        },
      }).then((response) => {
        if (response.status === 200) {
          if (folderIds.length === 0) {
            startSnackbar({
              message: "Deleted " + fileIds.length + " asset(s)",
              type: "success",
            });
          }
          //console.log("deleted", fileIds);
          deleteCb(fileIds);

          // setItemDeleted(true);
          // call parent function to update state of GridItems

          handleDeleteModalClose();
        } else if (response.status === 403) {
          logoutUser(navigate);
        } else {
          //console.log("deleted failed? ", response);
          startSnackbar({
            type: "error",
            message: "You are not authorized to delete items in this org.",
          });
          handleDeleteModalClose();
        }
      });
    }
  };

  //data.thumbnail, data.modelId
  const handleNewThumbnail = (data) => {
    //console.log("thumbnail data", data);
    imgDispatch({
      type: "SET_THUMBNAIL_FOR_MODELID",
      thumbnail: data.thumbnail,
      modelId: data.modelId,
    });
  };

  const handleOpenUpload = () => {
    logAuthorizedWombatEvent("openUploadButtonClicked", {});
    uploadRef.current.setOpen();
  };

  const deleteCb = (modelIds, type) => {
    // figure out what page the item got deleted from before deleting element
    // by finding the index of the item in the array and getting the page number
    setSelectedIds([]);
    //console.log("aadelete cb", modelIds);
    //console.log("aainspected id", inspectedIdRef.current);
    if (modelIds.includes(inspectedIdRef.current)) {
      setInspectedId("");
      setInspectorOpen(false);
    } else if (selectedIds.includes(inspectedIdRef.current)) {
      setInspectedId("");
      setInspectorOpen(false);
    }

    if (type) {
      if (type === "move") {
        startSnackbar({
          message: "Moved asset(s) to folder",
          type: "success",
        });
      }
    } else {
      if (modelIds.includes(draggingId)) {
        startSnackbar({
          message: "Moved asset(s) to folder",
          type: "success",
        });
        setDraggingId("");
      } else {
      }
    }

    const index = arrRef.current.findIndex((image) => image.modelId === modelIds[0]);
    //console.log("index is", index);
    //console.log(modelIds);
    //console.log("imagesarra", arrRef.current);
    const arr = arrRef.current;
    if (index === -1) return;

    const page = arr[index].page;

    //if
    // refetch the page that the item was deleted from

    imgDispatch({ type: "DELETE_IMAGES", modelIds: modelIds });
    // refetch the page that the item was deleted from
    let url = undefined;
    const params = new URLSearchParams();

    params.set("page", page);
    const searchTerm = pager.searchTerm;

    if (searchTerm === "") {
      url = new URL(`${process.env.REACT_APP_BACKEND_URL}/upload/metadataForOrg`);
    } else {
      params.append("startsWith", searchTerm);
      url = new URL(`${process.env.REACT_APP_BACKEND_URL}/upload/search/`);
    }
    params.set("pageSize", 128);
    if (currentFolderRef.current !== "") {
      params.set("parent_id", currentFolderRef.current);
    }

    url.search = params.toString();

    //console.log("Fetching with url after delete" + url);

    // if modelIds.length > 64 then we need to clear the array and refetch page 1

    //console.log("REFETCHING AFTER DELETE", page);
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
    })
      .then((data) => {
        if (data.status === 403) {
          logoutUser(navigate);
        } else if (data.status !== 200) {
          alert("Error fetching data. Please try again later.");
        }
        return data.json();
      })
      .then((images) => {
        //console.log("images", images);
        // convert images object, which contains multiple arrays of same lengths with metadata for each image, into a single array of objects with metadata for each image
        const imagesArray = [];
        for (let i = 0; i < images.fileNames.length; i++) {
          // if image with this model_id already exists in imagesArray, don't add it
          if (arrRef.current.find((image) => image.modelId === images.model_ids[i])) {
            //console.log("dont add");
          } else {
            //console.log("adding", images.fileNames[i], "to imagesArray");

            // const pushObj = {
            //   assetType: images.assetTypes[i],
            //   key: imgData.images.length,
            //   fileName: images.fileNames[i],
            //   fileSize: images.fileSizes[i],
            //   thumbnail: images.thumbnailPaths[i],
            //   modelId: images.model_ids[i],
            //   filePath: images.filePaths[i],
            //   fileType: getFileType(images.fileNames[i]),
            //   lastUpdated: images.lastUpdatedTimes[i],
            //   show: true,
            //   page: page,
            // };
            if (images.assetTypes[i] === "folder") {
              const pushObj = {
                key: arrRef.current[arrRef.current.length - 1].key + 1 + i,
                fileName: images.folderNames[i],
                lastUpdated: images.lastUpdatedTimes[i],
                modelId: images.model_ids[i],
                page: page,
                show: true,
                assetType: "folder",
              };
              //console.log("pushing", pushObj);
              //console.log("adding", pushObj, "to imagesArray");
              imagesArray.push(pushObj);

              // imgDispatch({ type: "UPLOAD_IMAGE", pushObj });
            } else {
              const pushObj = {
                key: arrRef.current[arrRef.current.length - 1].key + 1 + i,
                fileName: images.fileNames[i],
                fileSize: images.fileSizes[i],
                thumbnail: images.thumbnailPaths[i],
                modelId: images.model_ids[i],
                filePath: images.filePaths[i],
                fileType: getFileType(images.fileNames[i]),
                lastUpdated: images.lastUpdatedTimes[i],
                show: true,
                page: page,
                assetType: "file",
              };
              //console.log("pushing", pushObj);
              //console.log("adding", pushObj, "to imagesArray");
              imagesArray.push(pushObj);

              // imgDispatch({ type: "UPLOAD_IMAGE", pushObj });
            }

            // imagesArray.push(pushObj);
          }
        }
        imgDispatch({ type: "STACK_IMAGES", imagesArray });
        imgDispatch({ type: "FETCHING_IMAGES", fetching: false });

        // imgDispatch({ type: "STACK_IMAGES", imagesArray });
        // imgDispatch({ type: "FETCHING_IMAGES", fetching: false });
      })
      .catch((e) => {
        //console.log("reached catch", e);
        imgDispatch({ type: "FETCHING_IMAGES", fetching: false });
        return e;
      });

    // //console.log("image array after delete", imgData.images);
  };
  const handleDeleteModalOpen = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleRenameModalOpen = () => {
    setRenameModalOpen(true);
  };
  const handleRenameModalClose = () => {
    //console.log("rename modal close");
    setRenameModalOpen(false);
  };

  const handleCreateFolderModalOpen = () => {
    setCreateFolderModalOpen(true);
  };
  const handleCreateFolderModalClose = () => {
    setCreateFolderModalOpen(false);
  };

  const onFileInputClick = (event) => {
    event.target.value = "";
  };

  // download button clicked, check folder size and make sure it's not too big
  const downloadFolder = async () => {
    console.log("download folder clicked");
    abortDownloadController = new AbortController();
    // need to check folder size and make sure it's not too big
    const folderSizeUrl = new URL(`${process.env.REACT_APP_BACKEND_URL}/upload/folderSize`);
    const folderQueryParams = new URLSearchParams();
    folderQueryParams.set("parent_id", inspectedId);
    folderSizeUrl.search = folderQueryParams.toString();

    const resp = fetch(folderSizeUrl, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log("sizeData ", data);
        console.log(data.folderSize / 1000000);
        if (data.folderSize / 1000000 < DOWNLOAD_SIZE_MAX_MB) {
          console.log("folder less than 100mb, downloading");
          // get name of folder
          const inspectedName = arrRef.current.find(
            (image) => image.modelId === inspectedId
          ).fileName;
          downloadFolderModalRef.current.setDownloadingFolder(inspectedName);
          const str = createStructure(
            inspectedId,
            new FileStructure(inspectedName, "", []),
            abortDownloadController.signal
          );
          return str;
          console.log("str", str);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    await resp;
    const resp2 = await resp;
    console.log("resp", resp2);
    try {
      await zipFileStructure(resp2, abortDownloadController.signal);
    } catch (e) {
      console.log("error", e);
    }
  };

  class FileStructure {
    constructor(name, url, children = []) {
      this.name = name;
      this.url = url;
      this.children = children;
    }
  }

  // recursively get all s3 urls for files in folder and generate file structure
  const createStructure = async (folderId, structure, signal) => {
    let url = undefined;
    const params = new URLSearchParams();

    params.set("page", 1);
    url = new URL(`${process.env.REACT_APP_BACKEND_URL}/upload/metadataForOrg`);
    if (folderId && folderId !== "") {
      params.set("parent_id", folderId);
    }
    params.set("pageSize", 128);
    url.search = params.toString();

    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
      signal: signal,
    }).catch((e) => {
      if (e.name === "AbortError") {
        console.log("aborted download");
      }
    });

    if (response.status === 403) {
      logoutUser(navigate);
      return;
    } else if (response.status !== 200) {
      alert("Error fetching data. Please try again later.");
      return;
    }

    const images = await response.json();

    for (let i = 0; i < images.fileNames.length; i++) {
      if (images.assetTypes[i] === "folder") {
        var newStructure = new FileStructure(images.folderNames[i], "", []);
        structure.children.push(newStructure);
        await createStructure(images.model_ids[i], newStructure, signal);
      } else {
        structure.children.push(new FileStructure(images.fileNames[i], images.filePaths[i]));
      }
    }

    return structure;
  };

  const JSZip = require("jszip");
  const saveAs = require("file-saver");

  // use file structure to create zip file
  async function zipFileStructure(fileStructure, signal) {
    console.log("fileStructure", fileStructure);
    const zip = new JSZip();

    async function addToZip(currentNode, currentPath) {
      if (currentNode.url) {
        // This is a file
        const fileName = currentPath + currentNode.name;
        console.log("adding file", fileName, "to zip");
        const response = await fetch(currentNode.url, {
          method: "GET",
          signal: signal,
        });
        const blob = await response.blob();
        zip.file(fileName, blob);
      } else {
        // This is a folder
        const folderName = currentPath + currentNode.name + "/";
        console.log("adding folder", folderName, "to zip");
        const folder = zip.folder(folderName);
        console.log("currentNode.children", currentNode);
        for (const child of currentNode.children) {
          console.log("child", child);
          await addToZip(child, folderName);
        }
      }
    }

    await Promise.all(fileStructure.children.map((child) => addToZip(child, "")));

    const blob = await zip.generateAsync({ type: "blob" }, function updateCallback(metadata) {
      var msg = "progression : " + metadata.percent.toFixed(2) + " %";
      if (metadata.currentFile) {
        msg += ", current file = " + metadata.currentFile;
      }
      downloadFolderModalRef.current.setProgress(parseInt(metadata.percent));
      console.log(msg);
    });
    saveAs(blob, fileStructure.name + ".zip");
    downloadFolderModalRef.current.downloadComplete();
  }

  const downloadModel = () => {
    const filePath = imgData.images.find((image) => image.modelId === inspectedId).filePath;
    const fileName = imgData.images.find((image) => image.modelId === inspectedId).fileName;

    logAuthorizedWombatEvent("assetDownloadClicked", { assetId: inspectedId });
    try {
      fetch(filePath)
        .then((response) => {
          //console.log("response", response);
          if (response.status === 200) {
            logAuthorizedWombatEvent("assetDownloadSuccess", { assetId: inspectedId });

            response.blob().then((blob) => {
              // Creating new object of PDF file
              const fileURL = window.URL.createObjectURL(blob);
              // Setting various property values
              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download = fileName;
              alink.click();
            });
          } else {
            logAuthorizedWombatEvent("assetDownloadFailed", { assetId: inspectedId });
          }
        })
        .catch((e) => {
          logAuthorizedWombatEvent("assetDownloadFailed", { assetId: inspectedId });
          startSnackbar({
            type: "error",
            message: "Error downloading asset.",
          });
        });
    } catch (e) {
      startSnackbar({
        type: "error",
        message: "Error downloading asset.",
      });
    }
  };

  const handleFileClick = () => {
    // Call the click event of the input element

    logAuthorizedWombatEvent("assetUploadClicked", {});
    setUploadMenu(false);
    fileInput.current.click();
  };
  const handleFolderClick = () => {
    logAuthorizedWombatEvent("folderUploadClicked", {});

    setUploadMenu(false);
    folderInput.current.click();
  };
  const closeInspector = () => {
    //console.log("should close inspector");
    setInspectorOpen(false);
  };

  const searchItems = (searchTerm) => {
    setCurrentFolder("");
    pagerDispatch({ type: "SET_SEARCH_TERM", searchTerm: searchTerm });
    if (searchTerm.length > 0) {
      setDraggingEnabled(false);
    } else {
      setDraggingEnabled(true);
    }
  };

  const resetSearch = () => {
    // pagerDispatch({ type: "RESET_PAGE" });
    // pagerDispatch({ type: "SET_SEARCH_TERM", searchTerm: "" });
    setCurrentFolder("");
    viewFolder("");
    document.getElementById("search").value = "";
    setDraggingEnabled(true);
  };

  //data is an object, formatted as {type: "error", message: "error message"}
  // or {type: "success", message: "success message"}
  const startSnackbar = (data) => {
    setSnackbarMessage(data.message);
    setSnackbarType(data.type);
    setSnackbar(true);
  };

  const toggleSnackbar = () => {
    setSnackbar(!snackbar);
  };

  const rightClickMenu =
    selectedIds.length < 2 ? (
      <VuiBox sx={rcMenu ? rcShowStyle : rcHideStyle}>
        <ul>
          {/* <Button variant="text" sx={{ color: "#fff", fontSize: "12px" }}>
            Preview
          </Button>
          <br /> */}

          {/* {imgData.images &&
          imgData.images.find((image) => image.modelId === inspectedId) === "file" ? ( */}
          {rightClickingFolder ? (
            <>
              <Button
                variant="text"
                onClick={downloadFolder}
                sx={{ color: "#fff", fontSize: "12px" }}
              >
                Download
              </Button>
              <br />
            </>
          ) : (
            <>
              <Button
                variant="text"
                onClick={downloadModel}
                sx={{ color: "#fff", fontSize: "12px" }}
              >
                Download
              </Button>
              <br />
            </>
          )}

          <Button
            variant="text"
            onClick={handleRenameModalOpen}
            sx={{ color: "#fff", fontSize: "12px" }}
          >
            Rename
          </Button>

          <br />

          <Button
            variant="text"
            onClick={handleDeleteModalOpen}
            sx={{
              color: colors.error.main,
              fontSize: "12px",
              "&:hover": { color: colors.error.main },
            }}
          >
            Delete
          </Button>
        </ul>
      </VuiBox>
    ) : (
      <VuiBox sx={rcMenu ? rcShowStyle : rcHideStyle}>
        <ul>
          {/* <Button variant="text" sx={{ color: "#fff", fontSize: "12px" }}>
            Preview
          </Button>
          <br /> */}
          {/* <Button variant="text" onClick={downloadModel} sx={{ color: "#fff", fontSize: "12px" }}>
            Download
          </Button>
          <br /> */}

          <Button
            variant="text"
            onClick={handleDeleteModalOpen}
            sx={{
              color: colors.error.main,
              fontSize: "12px",
              "&:hover": { color: colors.error.main },
            }}
          >
            Delete
          </Button>
        </ul>
      </VuiBox>
    );

  const handleFileUpload = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDropEnabled(false);
    console.log("IS UPLOADING", currentlyUploadingRef.current);
    if (!currentlyUploadingRef.current) {
      const files = e.target.files;
      console.log("files", e);
      var processedFiles = [];
      function handleEntry(entry) {
        // See https://developer.mozilla.org/en-US/docs/Web/API/DataTransferItem/webkitGetAsEntry
        let file =
          "getAsEntry" in entry
            ? entry.getAsEntry()
            : "webkitGetAsEntry" in entry
            ? entry.webkitGetAsEntry()
            : entry;
        return Promise.resolve(file);
      }
      function handleFile(file) {
        return new Promise(function (resolve, reject) {
          if (file) {
            if (file.isDirectory) {
              console.log("is directory");
              var dirReader = file.createReader();
              dirReader.readEntries(function (entries) {
                console.log("entries", entries);
                Promise.all(
                  [].map.call(entries, function (entry) {
                    return handleFile(entry);
                  })
                )
                  .then(function (entries) {
                    resolve([].concat.apply([], entries));
                  })
                  .catch(reject);
              }, reject);
            } else if (file.isFile) {
              console.log("is file");

              file.file(function (file2) {
                file2.relativePath = file.fullPath.substring(1);
                if (fileTypes.includes(getFileType(file2.name))) {
                  processedFiles.push(file2);
                }
                resolve(file2);
              }, reject);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        });
      }

      function processFiles(files) {
        Promise.all(
          [].map.call(files, function (file, index) {
            return handleEntry(file, index).then(handleFile);
          })
        )
          .then(function () {
            console.log("complete", processedFiles);
            uploadRef.current.uploadFiles(processedFiles);
          })
          .catch(function (err) {
            alert(err.message);
          });
      }
      var files1;
      if (e.type === "drop" && e.target.webkitdirectory) {
        files1 = e.dataTransfer.items || e.dataTransfer.files;
      } else if (e.type === "change") {
        files1 = e.target.files;
      }

      if (files1) {
        processFiles(files1);
      }
    } else {
      startSnackbar({ type: "error", message: "Please wait for the current upload to finish." });
    }
  };
  return (
    <div onDragEnd={handleDragEnd}>
      {allowDragAndDrop === false ? (
        <VuiBox
          sx={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: inspectorOpen ? "calc(100% - 300px" : "100%",
            height: "71px",
            zIndex: 1,
            backgroundColor: colors.icon.disabled,
            alignItems: "center",
          }}
        >
          <VuiTypography variant="body2" sx={{ color: "#fff", padding: "10px" }}>
            Polyhive's mobile site has limited features. For the full experience, switch to your
            desktop.
          </VuiTypography>
        </VuiBox>
      ) : (
        <></>
      )}
      {showPopup && inspectorOpen && onboardingIndex === 0 && (
        <VuiBox
          className="bg_green rounded box_shadow"
          sx={{
            position: "fixed",
            width: "300px",
            // height: "290px",
            right: "385px",
            top: "485px",
            zIndex: 100000,
            padding: "30px 20px",
            "@media (max-width: 600px)": {
              padding: "0px 20px",
            },
          }}
        >
          <div className="tooltip_arrow"></div>

          {/* Lightbulb icon with Bolt icon inside it */}
          <LightbulbOutlinedIcon
            className="c_white"
            sx={{
              width: "80px",
              height: "90px",
              stroke: colors.background.tertiary,
              strokeWidth: "1px",
              fontWeight: 200,
              position: "absolute",
              top: "10px",
              left: "0px",
            }}
          />
          <BoltOutlinedIcon
            sx={{
              width: "39px",
              height: "39px",
              color: colors.warning.main,
              position: "absolute",
              top: "25px",
              left: "20px",
            }}
          />
          <VuiBox sx={{ marginTop: "75px" }}>
            <VuiTypography variant="body2" className="fw_450 f_large" sx={{ color: "#fff" }}>
              Retexture 3D Models
            </VuiTypography>
            <VuiTypography
              variant="body2"
              className="fw_400 c_white f_medium"
              sx={{ marginTop: "5px" }}
            >
              Create new textures for your assets in minutes! Click the "Retexture with AI" button
              to start the process.
            </VuiTypography>
            <VuiTypography variant="body2" className="fw_450 c_lightbg f_medium">
              <a
                href="https://docs.polyhive.ai/ai-quickstart-guide"
                target="_blank"
                rel="noreferrer"
              >
                Learn more.
              </a>
            </VuiTypography>

            <Button
              className="focus-button c_white f_small rounded"
              sx={{ marginTop: "10px" }}
              onClick={handleNextPopup}
            >
              Close
            </Button>
          </VuiBox>
        </VuiBox>
      )}
      {allowDragAndDrop ? (
        <label
          htmlFor="file-upload"
          className="custom-file-upload"
          id="dropArea"
          // class="area"
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",

            zIndex: !dropEnabled ? "0" : "1000",
            // display none on mobile
            "@media (max-width: 600px)": {
              left: "-2000px",
            },
            // backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <input
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              // display: "none",
              // need to remove click event but keep drag event
              pointerEvents: !dropEnabled ? "none" : "auto",

              // display none on mobile
              "@media (max-width: 600px)": {
                display: "none",
              },
              // zIndex: "1000",
            }}
            id="file"
            type="file"
            webkitdirectory=""
            directory=""
            multiple
            // onChange={handleFileUpload}
          />
        </label>
      ) : (
        <VuiBox id="dropArea"></VuiBox>
      )}

      {rightClickMenu}
      <Grid
        container
        spacing={0}
        sx={{
          width: inspectorOpen ? "calc(100% - 400px)" : "100%",

          transition: "all 0.5s ease",
          margin: "0",
          marginTop: "40px",
          "@media (max-width: 600px)": {
            width: inspectorOpen ? "calc(100% - 300px)" : "100%",
            marginTop: "0",
          },
        }}
      >
        <Grid item sm={12}>
          <DashboardNav />
          <Sidenav />
        </Grid>
        <Grid item sm={12}>
          <VuiBox
            sx={{
              width: inspectorOpen ? "calc(100% - 400px)" : "100%",
              "@media (max-width: 600px)": {
                width: inspectorOpen ? "calc(100% - 300px)" : "100%",
                display: "none",
                top: "0",
              },
            }}
            className="dashboard_toolbar"
            id="topBar"
          >
            {/* BREADIIES */}
            <Breadcrumbs
              setCurrentFolder={setCurrentFolder}
              currentFolder={currentFolder}
              currentPath={currentPath}
              barDragged={barDragged}
              draggingId={draggingId}
              setDraggingId={setDraggingId}
              setBarDragged={setBarDragged}
              selectedIds={selectedIds}
              deleteCb={deleteCb}
              moveToFolder={moveToFolder}
              ref={breadcrumbRef}
              resetSearch={resetSearch}
              startSnackbar={startSnackbar}
            />
            <VuiBox className="flex_end_container">
              <VuiBox className="align_center_row">
                <input
                  className="retexture_form_input bg_lightblack mr_1 ml_1 height_4 width_25"
                  id="search"
                  placeholder="Search for an asset"
                  onChange={(e) => {
                    searchItems(e.target.value);
                  }}
                ></input>
              </VuiBox>

              {/* <FormatListBulletedIcon
                sx={{
                  color: gridView ? colors.icon.enabled : colors.icon.focus,
                  fontSize: "20px",
                  width: "20px",
                  height: "20px",
                  margin: "5px",
                  display: "flex",

                  "&:hover": {
                    color: gridView ? "#fff" : colors.icon.focus,
                  },
                }}
                onClick={() => {
                  setGridView(false);

                  logAuthorizedWombatEvent("listViewClicked", {});
                }}
              /> */}
              {/* <GridViewIcon
                sx={{
                  color: gridView ? colors.icon.focus : colors.icon.enabled,
                  fontSize: "30px",
                  width: "20px",
                  height: "20px",
                  margin: "5px",
                  display: "flex",
                  "&:hover": {
                    color: gridView ? colors.icon.focus : "#fff",
                  },
                }}
                onClick={() => {
                  setGridView(true);

                  logAuthorizedWombatEvent("gridViewClicked", {});
                }}
              /> */}
              <VuiBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CreateNewFolderOutlinedIcon
                  className="dashboard_toolbar_icon bg_darkest ml_1 mr_1 c_white br_1px br_icondisabled"
                  onClick={() => {
                    handleCreateFolderModalOpen();

                    logAuthorizedWombatEvent("createFolderClicked", {});
                  }}
                />
              </VuiBox>
              <VuiBox className="align_center_row">
                <DeleteForeverOutlinedIcon
                  className="dashboard_toolbar_icon bg_darkest ml_1 mr_1 c_white br_1px br_icondisabled"
                  onClick={() => {
                    logAuthorizedWombatEvent("deleteForeverIconClicked", {});

                    handleDeleteModalOpen();
                    // amplitude.getInstance().logEvent("createFolderClicked");
                  }}
                />
              </VuiBox>

              <input
                type="file"
                ref={fileInput}
                onClick={onFileInputClick}
                style={{ display: "none" }}
                multiple={true}
                // webkitdirectory=""
                onChange={handleChange}
                accept=".png, .jpg, .jpeg, .glb, .gltf, .vrm, .fbx, .obj"
              />
              <input
                type="file"
                ref={folderInput}
                onClick={onFileInputClick}
                style={{ display: "none" }}
                multiple={true}
                webkitdirectory=""
                onChange={handleChange}
                accept=".png, .jpg, .jpeg, .glb, .gltf, .vrm, .fbx, .obj"
              />
              {currentlyUploading ? (
                <DarkTooltip title="Cancel or wait for the current upload to complete before uploading more files.">
                  <Button
                    className="f_small c_iconenabled bg_icondisabled fw_500 default_btn mr_1"
                    startIcon={<FileUploadOutlinedIcon className="default_btn_icon" />}
                    onClick={() => {}}
                  >
                    Upload
                  </Button>
                </DarkTooltip>
              ) : (
                <Button
                  className="py-5 f_small c_quaternaryfocus bg_darkest fw_500 default_btn mr_1 br_1px br_icondisabled"
                  startIcon={<FileUploadOutlinedIcon className="default_btn_icon" />}
                  onClick={() => {
                    setUploadMenu(!uploadMenu);
                  }}
                >
                  Upload
                </Button>
              )}
            </VuiBox>

            {uploadMenu && (
              <>
                <Button
                  variant="text"
                  textTransform="none"
                  sx={{
                    border: "1.5px solid " + colors.icon.disabled,
                    background: colors.background.secondary,
                    color: "#fff",
                    fontSize: "0.7rem",
                    width: "101px",
                    height: "30px",
                    margin: "0px !important",
                    padding: "0px !important",
                    fontWeight: "400",
                    right: "324px",
                    top: "-48px",
                    position: "absolute",
                    lineHeight: "1",
                    textTransform: "none",
                    borderRadius: "0px",
                    zIndex: "9999999",
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",

                    "&:hover": {
                      color: "#fff",
                      border: "1.5px solid " + colors.icon.quaternaryfocus,
                      background: colors.background.secondary,
                    },
                    "&:focus": {
                      color: "#fff",
                      border: "1.5px solid " + colors.icon.disabled,
                      background: colors.background.secondary,
                    },
                  }}
                  onClick={handleFolderClick}
                >
                  <DriveFolderUploadOutlinedIcon
                    sx={{
                      // padding: "0px !important",
                      margin: "5px !important",
                      width: "14px",
                      height: "14px",
                    }}
                  />
                  Folder
                </Button>
                <Button
                  variant="text"
                  textTransform="none"
                  sx={{
                    border: "1.5px solid " + colors.icon.disabled,
                    background: colors.background.secondary,
                    color: "#fff",
                    fontSize: "0.7rem",
                    width: "101px",
                    height: "30px",
                    margin: "0px !important",
                    padding: "0px !important",
                    fontWeight: "400",
                    right: "324px",
                    top: "-20px",
                    position: "absolute",
                    lineHeight: "1",
                    textTransform: "none",
                    borderRadius: "0px",
                    zIndex: 999999999,

                    "&:hover": {
                      color: "#fff",
                      border: "1.5px solid " + colors.icon.quaternaryfocus,
                      background: colors.background.secondary,
                    },
                    "&:focus": {
                      color: "#fff",
                      border: "1.5px solid " + colors.icon.disabled,
                      background: colors.background.secondary,
                    },
                  }}
                  onClick={handleFileClick}
                >
                  <AttachFileOutlinedIcon
                    sx={{
                      // padding: "0px !important",
                      margin: "5px !important",
                      marginLeft: "-5px !important",
                      width: "14px",
                      height: "14px",
                    }}
                  />
                  Files
                </Button>
              </>
            )}
          </VuiBox>
        </Grid>
        <Grid item sm={12} mt={5}>
          <VuiBox
            sx={{
              // display: "block",
              position: "fixed",
              zIndex: "100",
              // width: "100%",
              marginTop: "0px",
              height: "calc(100% - 100px)",
              // marginRight: "100px",
              width: inspectorOpen ? "calc(100% - 490px)" : "calc(100% - 90px)",
              "@media (max-width: 600px)": {
                width: inspectorOpen ? "calc(100% - 365px)" : "calc(100% - 65px)",
              },

              overflow: "scroll",
            }}
            id="ModelGrid"
          >
            <VuiBox sx={{ display: "flex", justifyContent: "space-between" }}>
              <Grid container spacing={gridView ? 4 : 0}>
                {/* {gridView ? () : (<> </>))} */}
                {/* <Grid item sx={{ transition: "all 0.3s ease" }}> */}
                <UploadModal
                  cb={updateWithLoaded}
                  ref={uploadRef}
                  component={"list"}
                  currentFolder={currentFolder}
                  imgData={arrRef.current}
                  handleCreateFolder={addFolder}
                />

                {/* </Grid> */}
                {/* {gridView ? ( */}

                {imgData.images.map((gridItem) => {
                  // //console.log("gridItem", gridItem);
                  if (gridItem && gridItem.assetType === "folder") {
                    return (
                      <Suspense
                        fallback={
                          <VuiBox display="flex" justifyContent="center" position="relative">
                            <Spinner
                              style={{
                                position: "absolute",
                                "z-index": "9999",
                                top: "0px",
                                left: "0px",
                              }}
                            />
                          </VuiBox>
                        }
                      >
                        <ModelGridItem
                          key={gridItem.id}
                          thumbnail={gridItem.thumbnail}
                          fileName={gridItem.fileName}
                          fileSize={gridItem.fileSize}
                          modelId={gridItem.modelId}
                          url={gridItem.filePath}
                          fileType={gridItem.fileType}
                          show={gridItem.show}
                          deleteCb={deleteCb}
                          handleReplace={handleReplace}
                          inspected={inspectedId === gridItem.modelId}
                          selected={selectedIds.includes(gridItem.modelId)}
                          selectedIds={selectedIds}
                          lastUpdated={gridItem.lastUpdated}
                          startSnackbar={startSnackbar}
                          assetType={gridItem.assetType}
                          draggingId={draggingId}
                          setDraggingId={setNewDraggingId}
                          draggingEnabled={draggingEnabled}
                          handleInspectedId={handleInspectedId}
                          handleInspectorOpen={handleInspectorOpen}
                        />
                      </Suspense>
                    );
                  }
                })}
                {imgData.images.map((gridItem) => {
                  // //console.log("gridItem", gridItem);
                  if (gridItem && gridItem.assetType === "file") {
                    return (
                      <Suspense
                        fallback={
                          <VuiBox display="flex" justifyContent="center" position="relative">
                            <Spinner
                              style={{
                                position: "absolute",
                                "z-index": "9999",
                                top: "0px",
                                left: "0px",
                              }}
                            />
                          </VuiBox>
                        }
                      >
                        <ModelGridItem
                          key={gridItem.id}
                          thumbnail={gridItem.thumbnail}
                          fileName={gridItem.fileName}
                          fileSize={gridItem.fileSize}
                          modelId={gridItem.modelId}
                          url={gridItem.filePath}
                          fileType={gridItem.fileType}
                          show={gridItem.show}
                          deleteCb={deleteCb}
                          handleReplace={handleReplace}
                          inspected={inspectedId === gridItem.modelId}
                          selected={selectedIds.includes(gridItem.modelId)}
                          selectedIds={selectedIds}
                          lastUpdated={gridItem.lastUpdated}
                          assetType={gridItem.assetType}
                          draggingId={draggingId}
                          setDraggingId={setNewDraggingId}
                          draggingEnabled={draggingEnabled}
                          handleInspectedId={handleInspectedId}
                          handleInspectorOpen={handleInspectorOpen}
                        />
                      </Suspense>
                    );
                  }
                })}

                <Grid item xs={12} md={12} lg={12}>
                  <VuiBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100px"
                    // bottom="10px"
                    width="100%"
                    ref={bottomBoundaryRef}
                  >
                    {" "}
                    {imgData.fetching ? <Spinner /> : null}
                  </VuiBox>
                </Grid>
              </Grid>
            </VuiBox>
          </VuiBox>
        </Grid>
        <Grid item sm={2}>
          <Inspector
            open={inspectorOpen}
            imgArray={imgData.images}
            selectedId={inspectedId}
            close={closeInspector}
            viewFolder={setCurrentFolder}
            handleRename={renameItem}
            handleCurrentVersion={handleReplace}
            handleNewThumbnail={handleNewThumbnail}
          />
        </Grid>
      </Grid>
      <div
        onDragEnd={handleDragEnd}
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          top: "0px",
          left: "0px",
          zIndex: dropEnabled ? "1000" : "-1",
          backgroundColor: dropEnabled ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0)",
          pointerEvents: "none",
        }}
      >
        {dropEnabled ? (
          <VuiTypography
            variant="h1"
            color="white"
            align="center"
            sx={{ top: "50%", left: "0", right: "0", position: "fixed" }}
          >
            Drop Files Here
          </VuiTypography>
        ) : null}
      </div>
      <DeleteModal
        open={deleteModalOpen}
        handleClose={handleDeleteModalClose}
        handleTrash={handleTrash}
      />
      <CreateFolderModal
        open={createFolderModalOpen}
        handleClose={handleCreateFolderModalClose}
        handleCreateFolder={addFolder}
        allFolders={imgData.images.filter((gridItem) => gridItem.assetType === "folder")}
        currentFolder={currentFolder}
        activeOrg={activeOrg}
      />
      <DownloadFolderModal
        ref={downloadFolderModalRef}
        abortDownloadController={abortDownloadController}
      />
      <RenameModal
        open={renameModalOpen}
        inspectedId={inspectedId}
        selectedIds={selectedIds}
        currentName={
          imgData.images
            .filter((item) => item.modelId === inspectedId)
            .map((item) => item.fileName)[0] ||
          imgData.images
            .filter((item) => item.modelId === inspectedId)
            .map((item) => item.folderName)[0]
        }
        allFolders={imgData.images.filter((gridItem) => gridItem.assetType === "folder")}
        assetType={
          imgData.images
            .filter((item) => item.modelId === inspectedId)
            .map((item) => item.assetType)[0]
        }
        handleClose={handleRenameModalClose}
        handleRename={renameItem}
        activeOrg={activeOrg}
      />
      <VuiSnackbar
        color={snackbarType === "success" ? "success" : "error"}
        icon="notifications"
        title="Polyhive"
        content={snackbarMessage}
        dateTime="Just now"
        open={snackbar}
        close={toggleSnackbar}
        onClose={toggleSnackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={3000}
      />
    </div>
  );
}

const DeleteModal = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ modalStyle }}
    >
      <VuiBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 200,
          bgcolor: colors.background.main,
          // border: "2px solid #000",
          boxShadow: 24,
          justifyContent: "center",
          alignItems: "center",
          p: 3,
          borderRadius: "15px",
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="center_column" style={{ textAlign: "center" }}>
          <VuiTypography variant="h6" sx={{ color: "#fff", marginBottom: "10px" }}>
            Delete this entry?
          </VuiTypography>
          <Box className="align_center_row">
            <Button
              onClick={props.handleClose}
              className="default_btn fw_500 f_small c_white br_icondisabled br_1px"
            >
              Cancel
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              onClick={props.handleTrash}
              className="default_btn fw_500 f_small c_white bg_error"
            >
              Delete
            </Button>
          </Box>
        </div>
      </VuiBox>
    </Modal>
  );
};

export default ModelGrid2;
