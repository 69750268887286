/*

Polyhive Dashboard

*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useReducer } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// The Vision UI Dashboard PRO Material main context
const VisionUI = createContext();

// Setting custom name for the context which is visible on react dev tools
VisionUI.displayName = "VisionUIContext";

// Vision UI Dashboard PRO React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "ORGS": {
      return { ...state, orgs: action.value };
    }
    case "ACTIVE_ORG": {
      return { ...state, activeOrg: action.value };
    }
    case "IS_ADMIN": {
      return { ...state, isAdmin: action.value };
    }
    case "DEVELOPER_INFO": {
      return { ...state, developerInfo: action.value };
    }
    case "CURRENTLY_UPLOADING": {
      return { ...state, currentlyUploading: action.value };
    }
    case "ONBOARDING_INDEX": {
      return { ...state, onboardingIndex: action.value };
    }
    case "CREDITS": {
      return { ...state, credits: action.value };
    }
    case "SUBSCRIPTION": {
      return { ...state, subscription: action.value };
    }
    case "PERMISSIONS": {
      return { ...state, permissions: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Vision UI Dashboard PRO React context provider
function VisionUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    orgs: [],
    activeOrg: "",
    isAdmin: false,
    developerInfo: {},
    currentlyUploading: false,
    onboardingIndex: 0,
    credits: 0,
    subscription: {},
    permissions: {},
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  return <VisionUI.Provider value={[controller, dispatch]}>{children}</VisionUI.Provider>;
}

// Vision UI Dashboard PRO React custom hook for using context
function useVisionUIController() {
  const context = useContext(VisionUI);

  if (!context) {
    throw new Error("useVisionUIController should be used inside the VisionUIControllerProvider.");
  }

  return context;
}

// Typechecking props for the VisionUIControllerProvider
VisionUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setOrgs = (dispatch, value) => dispatch({ type: "ORGS", value });
const setActiveOrg = (dispatch, value) => dispatch({ type: "ACTIVE_ORG", value });
const setIsAdmin = (dispatch, value) => dispatch({ type: "IS_ADMIN", value });
const setDeveloperInfo = (dispatch, value) => dispatch({ type: "DEVELOPER_INFO", value });
const setCurrentlyUploading = (dispatch, value) => dispatch({ type: "CURRENTLY_UPLOADING", value });
const setOnboardingIndex = (dispatch, value) => dispatch({ type: "ONBOARDING_INDEX", value });
const setCredits = (dispatch, value) => dispatch({ type: "CREDITS", value });
const setSubscription = (dispatch, value) => dispatch({ type: "SUBSCRIPTION", value });
const setPermissions = (dispatch, value) => dispatch({ type: "PERMISSIONS", value });

export {
  VisionUIControllerProvider,
  useVisionUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setOrgs,
  setActiveOrg,
  setIsAdmin,
  setDeveloperInfo,
  setCurrentlyUploading,
  setOnboardingIndex,
  setCredits,
  setSubscription,
  setPermissions,
};
