import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";

import { Box, Typography } from "@mui/material";

import colors from "assets/theme/base/colors";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Grid from "@mui/material/Grid";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";
import ShirtThumb from "assets/images/default-thumbnails/shirtthumb.png";
import ShedThumb from "assets/images/default-thumbnails/shedthumb.png";
import ChestThumb from "assets/images/default-thumbnails/chestthumb.png";
import TruckThumb from "assets/images/default-thumbnails/truckthumb.png";
import HammerThumb from "assets/images/default-thumbnails/hammerthumb.png";
import HelmetThumb from "assets/images/default-thumbnails/helmetthumb.png";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import Button from "@mui/material/Button";
import {
  convertBytesToMB,
  getFileType,
  generateUUID,
  getTimeDifferenceInSeconds,
} from "util/UtilFunctions";
import { useNavigate } from "react-router-dom";
import { useVisionUIController } from "context/index";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { HtmlTooltip } from "layouts/dashboards/default/components/CustomTooltip/index";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import CreditIcon from "assets/icons/crediticon.png";
import GradientIcon from "components/Custom/GradientIcon/index";
import Toggler from "../Toggler/index";
import PagesOutlinedIcon from "@mui/icons-material/PagesOutlined";
import InputSlider from "../InputSlider/index";

import DemoResult from "assets/demo_assets/inpaint_result.png";

const ESTIMATED_INPAINT_TIME = 18; // 7 seconds

const PROCESSING_TIMOUT_MS = 25000; // 20 seconds
const TIMEOUT_MS = 50000; // 100 seconds

const CanvasForm = forwardRef((props, ref) => {
  const fileInput = React.useRef(null);
  const navigate = useNavigate();

  const [controller, dispatch] = useVisionUIController();
  const { activeOrg, developerInfo } = controller;
  const [loadingJobStart, setLoadingJobStart] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [targetDate, setTargetDate] = useState(new Date());
  const [abortController, setAbortController] = useState(new AbortController());

  const [prompt, setPrompt] = useState("");
  const [negativePrompt, setNegativePrompt] = useState("");
  const [seed, setSeed] = useState("");

  const [lastRequest, setLastRequest] = useState(null);

  useImperativeHandle(ref, () => ({
    handleRetry: async () => {
      console.log("canvas_handleRetry_imperative");
      await handleRetry();
    },
    handleFields: async (fields) => {
      console.log("canvas_handleFields_imperative", fields);
      setPrompt(fields.text_prompt);
      setNegativePrompt(fields.job_params.negative_prompt);
      setSeed(fields.job_params.seed);
    },
  }));

  useEffect(() => {
    console.log("canvas_targetDate changed", targetDate);
    // new step initiated, need to have a timer updating current date every second
    const interval = setInterval(() => {
      console.log("canvas_interval");
      setCurrentDate(new Date());
      // if current date is past target date then clear interval
      if (new Date() > targetDate) {
        console.log("canvas_interval_clear");
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [targetDate]);

  const resetState = () => {
    console.log("canvas_resetState");

    setAbortController(new AbortController());
    setLoadingJobStart(false);
    setCurrentDate(new Date());
    setTargetDate(new Date());
  };

  const startJob = async (requestBody, refresh = false) => {
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/submitNewGenerate`);
    logAuthorizedWombatEvent("canvasAIStartAttemptFrontend", {
      requestBody: requestBody,
    });

    if (loadingJobStart === true) {
      props.startSnackbar({
        message: "Please wait for the current job to finish.",
        type: "error",
      });
      logAuthorizedWombatEvent("canvasAIStartFailureJobInProgress", {
        requestBody: requestBody,
      });
      return;
    }

    const newRequestBody = requestBody;

    if (lastRequest && lastRequest.job_params.seed === requestBody.job_params.seed) {
      newRequestBody.job_params.seed = Math.floor(Math.random() * 10000000);
    }

    console.log("canvas_requestBody", requestBody);
    setLoadingJobStart(true);
    const preFlightTarget = new Date();
    setCurrentDate(new Date());
    preFlightTarget.setSeconds(preFlightTarget.getSeconds() + ESTIMATED_INPAINT_TIME);
    setTargetDate(preFlightTarget);

    try {
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error("Timeout"));
        }, PROCESSING_TIMOUT_MS);
      });
      const response = await Promise.race([
        fetch(url, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-ORG-ID": activeOrg,
          },
          body: JSON.stringify(newRequestBody),
        }),
        timeoutPromise,
      ]);
      console.log("canvas_response", response);
      if (response.status === 200) {
        logAuthorizedWombatEvent("canvasAIStartSuccessFrontend", {
          requestBody: newRequestBody,
        });
      } else if (response.status === 402) {
        setLoadingJobStart(false);
        logAuthorizedWombatEvent("canvasAIStartFailureInsufficientFunds", {
          message: response.statusText,
        });
        return { status: 402, message: "Insufficient credits" };
      } else if (response.status === 420) {
        setLoadingJobStart(false);
        // servers are overloaded
        logAuthorizedWombatEvent("canvasAIStartFailureOverloaded", {
          message: response.statusText,
        });
        return { status: 420, message: "Servers are overloaded" };
      } else if (response.status === 400) {
        logAuthorizedWombatEvent("canvasAIStartFailureInternalError", {
          message: response.statusText,
        });
        return { status: 400, message: "Internal server error" };
      } else {
        console.log("canvas_response_error", response);
        logAuthorizedWombatEvent("canvasAIStartErrorFrontend", {
          requestBody: newRequestBody,
          error: response.status,
        });
        return;
      }
      const data = await response.json();
      return data.jobIds[0];
      console.log("canvas_data", data);
    } catch (error) {
      console.log("canvas_error", error);
    }
  };

  const handleChange = (event) => {
    props.handleSelectedMesh(-1);
    const fileUploaded = event.target.files[0];

    if (convertBytesToMB(fileUploaded.size) > 100) {
      props.startSnackbar({
        message: "File size too large. Please upload a file less than 10MB",
        type: "error",
      });
      logAuthorizedWombatEvent("generatePageUploadError", {
        message: "File size too large. Please upload a file less than 10MB",
      });
      return;
    }
    logAuthorizedWombatEvent("fileUploadedToGeneratePage", {
      name: fileUploaded.name,
      type: getFileType(fileUploaded.name),
    });
    props.handleUploadedFile(fileUploaded);
    // reset event target value so that the same file can be uploaded again
    event.target.value = null;
  };

  const handleFileButtonClick = () => {
    fileInput.current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (props.runningJobs.length > 0 && props.subscription.name !== "polyhive_plus_plan") {
      // pop a modal, then return
      props.handleJobStartModalOpen();
      logAuthorizedWombatEvent("freeUserAttemptedToQueueMultipleJobs", {});

      return;
    }

    const submitbutton = e.target.elements.submitbutton;
    // disable submit button for 2 seconds, then enable
    submitbutton.disabled = true;
    console.log("submit button disabled");
    setTimeout(() => {
      submitbutton.disabled = false;
      console.log("submit button enabled");
    }, 2000);

    console.log("submitbutton", submitbutton);

    logAuthorizedWombatEvent("inpaintAISubmitButtonClicked", {});

    console.log("form submitted", e);
    const prompt = e.target.elements.prompt.value;
    // if (prompt === "") {
    //   logAuthorizedWombatEvent("inpaintAISubmitError", {
    //     error: "Please enter a prompt.",
    //   });
    //   props.startSnackbar({
    //     message: "Please enter a prompt.",
    //     type: "error",
    //   });
    //   return;
    // }

    const negative_prompt = e.target.elements.negativePrompt.value;
    const seed = e.target.elements.seed.value;
    const step_number = 4;
    // const preserve_uvs = e.target.elements.preserveuvs.checked;
    // const material_maps = e.target.elements.materialmaps.checked;
    // const zy_symmetry = e.target.elements.bilateralsymmetry.checked;
    const job_params = {
      negative_prompt: negative_prompt,
      seed: seed,
      //   preserve_uvs: preserve_uvs,
      //   material_maps: material_maps,
      //   zy_symmetry: zy_symmetry,
    };

    // if (process.env.NODE_ENV !== "production") {
    //   job_params.owner = e.target.elements.owner.value;
    //   job_params.dry_run = e.target.elements.dryrun.checked;
    //   job_params.debug = e.target.elements.debug.checked;
    //   job_params.force_fail = e.target.elements.forcefail.checked;
    //   job_params.disable_depth = e.target.elements.disable_depth.checked;
    //   job_params.disable_blending = e.target.elements.disable_blending.checked;
    //   job_params.disable_smooth_normals = e.target.elements.disable_smooth_normals.checked;
    // }
    if (job_params.seed === "") {
      job_params.seed = Math.floor(Math.random() * 10000000) + "";
    } else if (parseInt(job_params.seed) < 1 || parseInt(job_params.seed) > 10000000) {
      job_params.seed = Math.floor(Math.random() * 10000000) + "";
    }

    // job_params.texture_resolution = 1024;
    // job_params.guidance_scale = 7.5;
    job_params.controlnet_conditioning = e.target.elements.cn_depth.value;
    // job_params.controlnet_conditioning_normal = e.target.elements.cn_normal.value;
    job_params.guidance_scale = e.target.elements.guidance_scale.value;
    // job_params.region_threshold = 0.2;
    // job_params.use_existing_texture = false;
    // job_params.strict_projection_masking = true;
    job_params.sd_output_resolution = 1024;

    // Form validation
    // if (!/^[a-zA-Z0-9, ]+$/.test(prompt)) {
    if (prompt.includes("{") || prompt.includes("}")) {
      logAuthorizedWombatEvent("generateAISubmitError", {
        error: "Invalid prompt. Curly braces are not allowed.",
      });
      props.startSnackbar({
        message: "Invalid prompt. Curly braces are not allowed.",
        type: "error",
      });
      return;
    }

    if (negative_prompt.includes("{") || negative_prompt.includes("}")) {
      logAuthorizedWombatEvent("generateAISubmitError", {
        error: "Invalid negative prompt. Curly braces are not allowed.",
      });
      props.startSnackbar({
        message: "Invalid negative prompt. Curly braces are not allowed.",
        type: "error",
      });
      return;
    }

    if (!props.jobStartParams || !props.jobStartParams.version_id) {
      logAuthorizedWombatEvent("generateAISubmitError", {
        error: "Version not selected.",
      });
      props.startSnackbar({
        message: "Version not selected.",
        type: "error",
      });
      return;
    }

    let imageUUID = "";
    let imageUUIDBack = "";
    const mask_images = await props.handleCanvasImage();

    if (mask_images) {
      imageUUID = generateUUID();
      imageUUIDBack = generateUUID();
      await props.uploadImage(mask_images[0], imageUUID);
      await props.uploadImage(mask_images[1], imageUUIDBack);
    } else {
      logAuthorizedWombatEvent("generateAICanvasSubmitError", {
        error: "No mask drawn.",
      });
      props.startSnackbar({
        message: "No mask drawn.",
        type: "error",
      });
      return;
    }

    console.log("job_params", job_params);
    const requestBody = {
      submission_id: props.selectedPreviewId,
      text_prompt: prompt,
      job_params: job_params,
      mask_version_id: imageUUID,
      mask_version_id_back: imageUUIDBack,
      step_number: step_number,
      version_id: props.jobStartParams.version_id,
      parameter_submission_id: props.parameterImageId,
    };

    const poll_id = await startJob(requestBody);

    const historyJson = requestBody;
    historyJson.submission_id = poll_id;
    props.saveToHistory(historyJson, "pre-generate");

    setLastRequest(requestBody);

    props.handleSelectedPreviewId(poll_id);

    try {
      const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/imageJob`);
      const promise = fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "JOB-ID": poll_id,
          "X-ORG-ID": activeOrg,
        },
        signal: abortController.signal,
      });

      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error("Timeout"));
        }, TIMEOUT_MS);
      });

      console.log("attempting to get imageJob with poll_id", poll_id);

      const response = await Promise.race([promise, timeoutPromise]);
      console.log("canvas_response", response);
      const jsonData = await response.json();
      console.log("canvas_jsonData", jsonData);
      if (jsonData.output_map_paths) {
        setLoadingJobStart(false);
        props.sendPreviewToCanvas(jsonData.output_s3_path, jsonData.second_output_s3_path, poll_id);
        // props.sendPreviewToCanvas(DemoResult, poll_id);
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("canvas_fetch aborted");
        setLoadingJobStart(false);
      } else if (error.message === "Timeout") {
        console.log("canvas_fetch timeout");
        abortController.abort();
        logAuthorizedWombatEvent("promptingAIPreviewTimeoutError", {});
        props.startSnackbar({ type: "error", message: "Request timed out. Please try again." });
        resetState();
      } else {
        console.log(error);
        setLoadingJobStart(false);
      }
    }
  };

  const handleRetry = async () => {
    if (!lastRequest) {
      props.startSnackbar({
        message: "No previous request found.",
        type: "error",
      });
      return;
    }

    console.log("canvas_handleRetry");
    const poll_id = await startJob(lastRequest);

    console.log("attempting to get imageJob with poll_id", poll_id);

    if (!poll_id) {
      return;
    }

    props.handleSelectedPreviewId(poll_id);

    try {
      const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/imageJob`);
      const promise = fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "JOB-ID": poll_id,
          "X-ORG-ID": activeOrg,
        },
        signal: abortController.signal,
      });

      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error("Timeout"));
        }, TIMEOUT_MS);
      });

      const response = await Promise.race([promise, timeoutPromise]);
      console.log("canvas_response", response);
      const jsonData = await response.json();
      console.log("canvas_jsonData", jsonData);
      if (jsonData.output_map_paths) {
        setLoadingJobStart(false);
        props.sendPreviewToCanvas(jsonData.output_s3_path, jsonData.second_output_s3_path, poll_id);
        // props.sendPreviewToCanvas(DemoResult, poll_id);
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("canvas_fetch aborted");
        setLoadingJobStart(false);
      } else if (error.message === "Timeout") {
        console.log("canvas_fetch timeout");
        abortController.abort();
        logAuthorizedWombatEvent("promptingAIPreviewTimeoutError", {});
        props.startSnackbar({ type: "error", message: "Request timed out. Please try again." });
        resetState();
      } else {
        console.log(error);
        setLoadingJobStart(false);
      }
    }
  };

  const handleSymmetry = (e) => {
    const symettry = e.target.checked;
    console.log("symettry", symettry);
    props.handleSymmetry(symettry);
  };

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleNegativePromptChange = (e) => {
    setNegativePrompt(e.target.value);
  };

  const handleSeedChange = (e) => {
    setSeed(e.target.value);
  };
  // ... Add more handlers for other form fields as needed.

  const renderForm = () => {
    return (
      <>
        <Box className="retexture_form_body" mt={1}>
          {/* Text Prompt Area */}
          <Box className="retexture_form_ta" mb={1} mt={1}>
            <Box className="title_tooltip_container">
              <Typography className="f_small c_white fw_500">Prompt</Typography>
              <HtmlTooltip
                title={
                  <Box width={200}>
                    <Typography className="f_small c_white fw_500">Prompt</Typography>
                    <Typography className="f_small c_iconenabled fw_400">
                      Describe your texture using text. It is recommended to be as descriptive as
                      possible.
                    </Typography>
                  </Box>
                }
                placement="top"
              >
                <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
              </HtmlTooltip>
            </Box>
            <Box className="retexture_form_textarea_container">
              <textarea
                className="retexture_form_textarea"
                placeholder="Describe your texture in a few words..."
                id="prompt"
                name="prompt"
                value={prompt}
                onChange={handlePromptChange}
              />
            </Box>
          </Box>
          {/* <hr className="rt_form_hr" /> */}
          <Box className="retexture_form_ta" mb={1} mt={1}>
            <Box className="title_tooltip_container">
              <Typography className="f_small c_white fw_500">Negative Prompt</Typography>
              <HtmlTooltip
                title={
                  <Box width={200}>
                    <Typography className="f_small c_white fw_500">Negative Prompt</Typography>
                    <Typography className="f_small c_iconenabled fw_400">
                      Describe what you don't want your texture to be. It is recommended to be as
                      descriptive as possible.
                    </Typography>
                  </Box>
                }
                placement="top"
              >
                <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
              </HtmlTooltip>
            </Box>
            <Box className="retexture_form_textarea_container">
              <textarea
                className="retexture_form_textarea"
                placeholder="Describe what you don't want your texture to be..."
                id="negative-prompt"
                name="negativePrompt"
                value={negativePrompt}
                onChange={handleNegativePromptChange}
              />
            </Box>
          </Box>
          {/* <hr className="rt_form_hr" /> */}

          <Box mb={2} className="retexture_form_ta" mt={1}>
            <Box className="title_tooltip_container">
              <Typography className="f_small c_white fw_500">Seed</Typography>
              <HtmlTooltip
                title={
                  <Box width={200}>
                    <Typography className="f_small c_white fw_500">Seed</Typography>
                    <Typography className="f_small c_iconenabled fw_400">
                      A seed is a number used to introduce randomness into the AI generation. This
                      is useful for generating different variations with the same prompt.
                    </Typography>
                  </Box>
                }
                placement="top"
              >
                <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
              </HtmlTooltip>
            </Box>
            <Box className="retexture_form_input_container">
              <input
                type="number"
                className="retexture_form_input"
                placeholder="A number between 1 and 100000"
                id="seed"
                name="seed"
                value={seed}
                onChange={handleSeedChange}
                autoComplete="off"
              />
            </Box>
          </Box>

          <InputSlider
            title="Depth Scale"
            name="cn_depth"
            tooltipString="Adjust the influence of the 3D model's depth on the image. At 0, depth is ignored; at 1, depth is heavily weighted."
            min={0}
            max={1}
            step={0.01}
            defaultValue={0.3}
          />

          <InputSlider
            title="Prompt Scale"
            name="guidance_scale"
            tooltipString="Balance between text prompt influence and generation quality. At 7.5, prioritize higher quality with less prompt emphasis; at 30, maximize prompt influence at the potential cost of quality."
            min={7.5}
            max={30}
            step={0.5}
            defaultValue={17}
          />
        </Box>
        <Box className="retexture_form_footer">
          <hr className="rt_form_hr" />
          <Button
            className={
              props.runningJobs.length === 0
                ? "rt_form_btn"
                : props.subscription && props.subscription.name === "polyhive_plus_plan"
                ? "rt_form_btn"
                : "rt_form_btn"
            }
            type="submit"
            size="large"
            name="submitbutton"
          >
            Paint Masked Region
          </Button>
          <Box className="align_center_row mauto mt_1 d_justify_center">
            <Typography className="f_small c_iconenabled fw_400 mr_05 ta_center">
              Preview Edits are free and do not consume credits.
            </Typography>
            <img src={CreditIcon} alt="credits" className="icon_small" />
          </Box>
        </Box>
      </>
    );
  };

  const renderLoading = () => {
    return (
      <>
        <Box className="max_container">
          <Box
            className="small_progress mb_1"
            sx={{
              width: `${
                100 -
                  (getTimeDifferenceInSeconds(currentDate, targetDate) / ESTIMATED_INPAINT_TIME) *
                    100 >
                100
                  ? 100
                  : 100 -
                    (getTimeDifferenceInSeconds(currentDate, targetDate) / ESTIMATED_INPAINT_TIME) *
                      100
              }%`,
            }}
          ></Box>
          <Typography className="f_medium c_iconenabled fw_400">
            Inpainting masked region...
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="file"
        ref={fileInput}
        style={{ display: "none" }}
        multiple={true}
        onChange={handleChange}
        accept=".glb, .gltf, .fbx, .obj"
      />
      <Box
        className="retexture_form inpainting_form"
        sx={{
          display: props.open ? "flex" : "none",
        }}
      >
        <Box className="retexture_form_head">
          <Box className="retexture_form_header">
            <Box className="header_flex">
              <GradientIcon>
                <PagesOutlinedIcon
                  className="retexture_form_header_icon"
                  sx={
                    {
                      // backgroundColor: colors.icon.focusdark,
                    }
                  }
                />
              </GradientIcon>

              <Typography className="retexture_form_header_text c_white">Preview Editor</Typography>
            </Box>
            <Box className="header_flex">
              <CloseRoundedIcon
                className="retexture_form_header_icon_btn c_white"
                onClick={props.handleClose}
              />
            </Box>
          </Box>
          <Typography className="retexture_form_subheader">
            Influence your texture generation by editing the preview.
          </Typography>
          <hr className="rt_form_hr" />
        </Box>
        {loadingJobStart ? renderLoading() : renderForm()}
      </Box>
    </form>
  );
});

export default CanvasForm;
