/**

 =========================================================
 * AQUEDUCT CONTENT PAGE
 =========================================================
 * Designed and Coded by Rohan Iyer
 =========================================================

 */

import { Card, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
// Data
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
// import VRMLoader from "../VRMLoader"
import VRMLoader from "../VRMLoader";
import { useThree, Canvas } from "react-three-fiber";
import VuiProgress from "components/VuiProgress";
import React, { useEffect, useState, useRef } from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import EditModal from "../EditModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import { logoutUser } from "layouts/authentication/AuthFunctions";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useVisionUIController } from "context";
import amplitude from "amplitude-js";
import FolderIcon from "@mui/icons-material/Folder";
import Draggable from "react-draggable";
import MultipleFiles from "layouts/dashboards/default/components/ModelGridItem/multiple-files.png";
import Tooltip from "@mui/material/Tooltip";
import DarkTooltip from "../CustomTooltip/index";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";

const ModelGridItem = (props) => {
  const cookies = new Cookies();

  const { gradients } = colors;
  const { cardContent } = gradients;
  const [itemDeleted, setItemDeleted] = useState(false);
  const childRef = useRef(null);
  const navigate = useNavigate();
  const [controller, dispatch] = useVisionUIController();
  const { activeOrg } = controller;
  const [itemHovered, setItemHovered] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [draggedOver, setDraggedOver] = useState(false);
  const selectedRef = useRef();
  selectedRef.current = props.selected;
  const myComponentStyle = {
    height: "80%",
    width: "80%",
    display: "block",
    margin: "auto",
  };

  const handleTrash = () => {
    deleteObjects([props.modelId]);
  };
  const handleEdit = () => {
    logAuthorizedWombatEvent("viewDetailsClicked", {});
    props.handleInspectedId(props.modelId);
    props.handleInspectorOpen(true);

    // // Load VRM
    // // CALL CHILDREF FUNCTION
    // childRef.current.handleOpen();
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // pass in array of ids
  const deleteObjects = (model_ids) => {
    const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/upload/deleteModelsForOrg`, {
      method: "DELETE",
      credentials: "include",
      body: JSON.stringify({ model_ids }),
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
    }).then((response) => {
      if (response.status === 200) {
        //console.log("deleted", props.modelId);
        props.deleteCb(props.url);

        // setItemDeleted(true);
        // call parent function to update state of GridItems

        handleClose();
      } else if (response.status === 403) {
        logoutUser(navigate);
      } else {
        //console.log("deleted failed? ", response);
      }
    });
  };

  const handleReplace = (data) => {
    props.handleReplace(data);
  };

  useEffect(() => {
    // Get file object from URL and convert to local object URL to pass into VRMLoader
    //create event listener for hover on grid item

    // get html element for this grid item

    const gridItem = document.getElementById(props.modelId);
    gridItem.addEventListener("mouseover", () => {
      setItemHovered(true);
    });

    gridItem.addEventListener("mouseout", () => {
      setItemHovered(false);
    });

    gridItem.addEventListener("dragstart", (e) => {
      //console.log("drag started on grid item", e);
      // if (selectedRef.current === false) {
      //console.log(selectedRef.current);
      setDragging(true);
      // }
    });
    gridItem.addEventListener("drop", (e) => {
      //console.log("drop on grid item ", e);
      setDragging(false);
      setDraggedOver(false);
    });
    gridItem.addEventListener("dragend", (e) => {
      //console.log("drag end on grid item ", e);
      setDragging(false);
      setDraggedOver(false);
    });

    gridItem.addEventListener("dragover", (e) => {
      //console.log("drag over on grid item ", e);
      if (props.assetType === "folder") {
        if (e.dataTransfer.effectAllowed === "move") {
          setDraggedOver(true);
        }
      }
    });
    gridItem.addEventListener("dragleave", (e) => {
      //console.log("drag leave on grid item ", e);
      const THRESHOLD = 200;
      //console.log("made it in");
      const { clientY } = e;
      const { innerHeight } = window;
      //console.log("clientY", clientY);
      //console.log("THRESHOLD", THRESHOLD);
      //console.log("innerHeight", innerHeight);
      if (clientY !== 0) {
        if (clientY < THRESHOLD) {
          //console.log("SCROLLING UP");
          window.scrollBy(0, -200);
        } else if (clientY > innerHeight - THRESHOLD) {
          //console.log("SCROLLING DOWN");
          window.scrollBy(0, 200);
        }
      }
      setDraggedOver(false);
    });
    // setDragging(true);

    return () => {
      gridItem.removeEventListener("mouseover", () => {
        setItemHovered(true);
      });

      gridItem.removeEventListener("mouseout", () => {
        setItemHovered(false);
      });

      gridItem.removeEventListener("dragstart", (e) => {
        //console.log("drag started on grid item", e);
        setDragging(true);
      });
      gridItem.removeEventListener("drop", (e) => {
        //console.log("drop on grid item ", e);
        setDragging(false);
      });
      gridItem.removeEventListener("dragend", (e) => {
        //console.log("drag end on grid item ", e);
        setDragging(false);
      });
      gridItem.removeEventListener("dragover", (e) => {
        //console.log("drag over on grid item ", e);
        setDraggedOver(true);
      });
      gridItem.removeEventListener("dragleave", (e) => {
        //console.log("drag leave on grid item ", e);
        setDraggedOver(false);
      });
    };
  }, []);

  useEffect(() => {
    //console.log("dragging state changed", dragging);
  }, [dragging]);

  const handleDraggableStart = (e, data) => {
    //console.log("draggable start e", e.target.firstChild.id);
    props.setDraggingId(e.target.firstChild.id);

    e.dataTransfer.effectAllowed = "move";
    if (selectedRef.current === false) {
      setDragging(true);
    } else {
      setDragging(true);

      let img = new Image();
      // img.src = ["png", "jpg", "jpeg"].includes(props.fileType) ? props.url : props.thumbnail;
      //console.log(
      //   "MultipleFiles",
      //   ["png", "jpg", "jpeg"].includes(props.fileType) ? props.url : props.thumbnail
      // );
      img.src = "";
      const element = document.createElement("div");
      element.style.width = "100px";
      element.style.height = "100px";
      element.style.background = "red";
      const imgElement = document.createElement("img");
      imgElement.src = MultipleFiles;
      // element.appendChild(img);
      // element.appendChild(<img src={MultipleFiles} />);
      // e.dataTransfer.setDragImage(element, 0, 0);
      // e.dataTransfer.setDragElement(element, 0, 0);
    }

    //console.log("draggable start e.target", e.target);
    e.dataTransfer.setData("text", e.target.firstChild.id);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    //console.log("drop e", e);
    setDragging(false);

    //console.log("drop e.parentElement", e.parentElement);
    //console.log("drop e.target.parentElement.classList", e.target.parentElement.classList);
    // e.target.classList must contain "folder"
    if (e.target.parentElement.classList.contains("folder")) {
      //console.log("landed on a folder with id", e.target.parentElement.parentElement.id);
      moveToFolder(e.target.parentElement.parentElement.id);
    }
  };
  const handleDragEnd = (e) => {
    //console.log("drag end e", e);
    setDragging(false);
  };

  const moveToFolder = (folderId) => {
    // needs to make request to the backend
    if (props.selectedIds.length > 0) {
      //console.log("attempting to move assets", props.selectedIds, "to folder", folderId);
      const failedIds = [];

      // remove folderId from props.selectedIds if it is in there
      const pushIds = [];
      for (let i = 0; i < props.selectedIds.length; i++) {
        if (props.selectedIds[i] !== folderId) {
          pushIds.push(props.selectedIds[i]);
        }
      }

      logAuthorizedWombatEvent("moveToFolder", {});

      const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/upload/moveAssetToFolder`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ asset_ids: pushIds, parent_id: folderId }),
        headers: {
          "Content-Type": "application/json",
          "X-ORG-ID": activeOrg,
        },
      }).then(async (response) => {
        if (response.status === 200) {
          //console.log("moved from griditem", pushIds);
          props.deleteCb(pushIds, "move");
        } else if (response.status === 400) {
          const detailedResponse = await response.json();
          //console.log("move ", detailedResponse.assetIdsToNotMove);
          props.deleteCb(
            pushIds.filter((x) => !detailedResponse.assetIdsToNotMove.includes(x)),
            "move"
          );
          props.startSnackbar({
            message: "Moving one of these folders causes a name conflict in the target directory.",
            type: "error",
          });
        }
      });

      // call delete cb on the items of props.selectedIds that are not in failedIds
    } else {
      //console.log("attempting to move asset", props.draggingId, "to folder", folderId);
      if (props.draggingId !== folderId) {
        logAuthorizedWombatEvent("moveToFolder", {});

        const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/upload/moveAssetToFolder`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({ asset_ids: [props.draggingId], parent_id: folderId }),
          headers: {
            "Content-Type": "application/json",
            "X-ORG-ID": activeOrg,
          },
        }).then(async (response) => {
          if (response.status === 200) {
            //console.log("moved from griditem2", props.draggingId);
            props.deleteCb([props.draggingId], "move");
            // setItemDeleted(true);
          } else if (response.status === 400) {
            const detailedResponse = await response.json();
            //console.log("move ", detailedResponse.assetIdsToNotMove);
            props.deleteCb(
              [props.draggingId].filter((x) => !detailedResponse.assetIdsToNotMove.includes(x)),
              "move"
            );
            props.startSnackbar({
              message:
                "Moving one of these folders causes a name conflict in the target directory.",
              type: "error",
            });
          }
        });
      }
    }
  };
  return (
    <>
      {itemDeleted ? null : (
        <>
          <Grid
            item
            style={{
              display: props.show ? "initial" : "none",
            }}
          >
            <div
              draggable={props.draggingEnabled ? "true" : "false"}
              onDragStart={handleDraggableStart}
              onDrop={handleDrop}
              onDragEnd={handleDragEnd}
            >
              <VuiBox
                sx={{
                  background: "none",
                  overflow: "visible",
                  // padding: "10px !important",
                  wordWrap: "break-word",
                  cursor: "pointer",
                }}
                onClick={handleEdit}
                id={props.modelId}
              >
                <VuiBox
                  className={props.assetType === "folder" ? "folder" : "file"}
                  sx={{
                    // background: "#fff",
                    width: "150px",
                    margin: "auto",
                    height: "150px",
                    padding: "10px",
                    borderRadius: "20px",
                    // background: itemHovered ? "rgba(90, 90, 90, 0.1)" : "none",
                    background: colors.background.darkest,
                    // background: props.inspected ? "rgba(250, 250, 250, 0.1)" : "none",
                    filter: props.inspected || props.selected ? "brightness(1.9)" : "none",
                    border: draggedOver ? " 1px solid " + colors.icon.focus : "none",
                    //  set transform scale to 0.5 when dragging
                    // itemHovered ? "brightness(1.1)" : "none",
                    transform: dragging ? "scale(0.5)" : "none",

                    // border: props.inspected ? " 1px solid purple" : "none",
                    // border: itemHovered ? " 1px solid purple" : "none",
                    transition: "background 0.3s ease",
                    // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <VuiBox
                    mb="18px"
                    height={itemHovered ? "85px" : "80px"}
                    sx={{
                      background: "none",
                      borderRadius: "20px",
                      border: "none",
                      transition: "all 0.3s ease",
                    }}
                    padding="0px"
                    display="flex"
                  >
                    {/* THIS IS WHERE VRMLOADER GOES */}
                    {/* <VRMLoader url={props.url} ref={childRef}></VRMLoader> */}

                    {props.assetType === "folder" ? (
                      <>
                        <FolderIcon
                          sx={{
                            pointerEvents: "none",
                            margin: "0 auto",
                            objectFit: "contain",
                            width: "100%",
                            height: "100%",
                            color: colors.icon.quaternaryfocus,
                          }}
                        />
                      </>
                    ) : (
                      <img
                        className="grid-img-top"
                        src={
                          ["png", "jpg", "jpeg"].includes(props.fileType)
                            ? props.url
                            : props.thumbnail
                        }
                        style={{
                          pointerEvents: "none",
                          margin: "0 auto",
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    )}
                  </VuiBox>

                  <DarkTooltip title={props.fileName} placement="top" id={props.modelId}>
                    <VuiTypography
                      variant="body2"
                      color="white"
                      // fontWeight="bold"
                      fontSize={itemHovered ? "15px" : "14px"}
                      mb="15px"
                      sx={{
                        fontWeight: 500,
                        fontSize: "0.9rem",
                        height: "47px",
                        lineHeight: "15px",
                        textAlign: "center",
                        transition: "all 0.3s ease",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        // whiteSpace: "pre",
                        "-webkit-line-clamp": "3",
                        "-webkit-box-orient": "vertical",
                        display: "-webkit-box",
                        // whiteSpace: "nowrap",
                      }}
                    >
                      {props.fileName}
                    </VuiTypography>
                  </DarkTooltip>
                </VuiBox>
              </VuiBox>
            </div>
          </Grid>
        </>
      )}
    </>
  );
};

export default ModelGridItem;
