import ViewerWindow from "../viewer/components/ViewerWindow";
import { useEffect, useState } from "react";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import pageRoutes from "page.routes";
import Footer from "../home/layout/footer/Footer.jsx";
import colors from "assets/theme/base/colors";
const ViewerError = () => {
  return (
    <>
      <DefaultNavbar
        routes={pageRoutes}
        action={{
          type: "external",
          route: "https://creative-tim.com/product/vision-ui-dashboard-pro-react",
          label: "buy now",
        }}
        transparent
      />
      {/* Some header text about how the owner has deleted the file */}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="text-center">
              <h1 className="display-1 font-weight-bold">404</h1>
              <h1
                className="h2"
                style={{
                  marginTop: "100px",
                  textAlign: "center",
                  color: colors.icon.secondaryfocus,
                }}
              >
                File Not Found
              </h1>
              <p
                className="h4 font-weight-normal mt-3 mb-4"
                style={{
                  textAlign: "center",
                  color: colors.icon.enabled,
                }}
              >
                The file you are trying to access doesn't exist or has been deleted by the owner.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer headerText={true} />
    </>
  );
};

export default ViewerError;
