// customHooks.js

import { useEffect, useCallback, useRef, useState } from "react";
import { logoutUser } from "layouts/authentication/AuthFunctions";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useVisionUIController } from "context";
import { getFileType } from "util/UtilFunctions";

import { logAuthorizedWombatEvent } from "util/UTMFunctions";

export const useFetch = (data, dispatch) => {
  const [firstcall, setFirstcall] = useState(true);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [controller] = useVisionUIController();
  const { activeOrg } = controller;

  useEffect(() => {
    //console.log("useFetch search term", data.searchTerm);

    dispatch({ type: "FETCHING_IMAGES", fetching: true });
    let url = undefined;
    const params = new URLSearchParams();
    //console.log("setting page to", data.page, "in useFetch");
    params.set("page", data.page);

    if (data.searchTerm && data.searchTerm !== "") {
      params.append("startsWith", data.searchTerm);
      url = new URL(`${process.env.REACT_APP_BACKEND_URL}/upload/search/`);
    } else {
      url = new URL(`${process.env.REACT_APP_BACKEND_URL}/upload/metadataForOrg`);
      if (data.parentFolder && data.parentFolder !== "") {
        params.set("parent_id", data.parentFolder);
      }
    }

    if (firstcall && activeOrg !== "") {
      // params.set("page", 1);
      // dispatch({ type: "RESET_PAGE" });
      params.set("pageSize", 64);
      setFirstcall(false);
    } else {
      params.set("pageSize", 64);
    }
    url.search = params.toString();
    //console.log("useFetch url", url.search);

    // if (firstcall) {
    if (activeOrg !== "") {
      fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-ORG-ID": activeOrg,
        },
      })
        .then((data) => {
          if (data.status === 403) {
            logoutUser(navigate);
          } else if (data.status !== 200) {
            alert("Error fetching data. Please try again later.");
          }
          return data.json();
        })
        .then((images) => {
          //console.log("initial fetch", images);
          // convert images object, which contains multiple arrays of same lengths with metadata for each image, into a single array of objects with metadata for each image
          const imagesArray = [];
          for (let i = 0; i < images.fileNames.length; i++) {
            // if image with this model_id already exists in imagesArray, don't add it
            if (imagesArray.find((image) => image.modelId === images.model_ids[i])) {
              //console.log("dont add");
            } else {
              // if images.assetTypes[i] === "folder", then add it to the imagesArray with assetType: "folder", else add it with assetType: "file"
              if (images.assetTypes[i] === "folder") {
                imagesArray.push({
                  key: imagesArray.length,
                  fileName: images.folderNames[i],
                  lastUpdated: images.lastUpdatedTimes[i],
                  modelId: images.model_ids[i],
                  page: data.page,
                  show: true,
                  assetType: "folder",
                });
              } else {
                imagesArray.push({
                  key: imagesArray.length,
                  fileName: images.fileNames[i],
                  fileSize: images.fileSizes[i],
                  thumbnail: images.thumbnailPaths[i],
                  modelId: images.model_ids[i],
                  filePath: images.filePaths[i],
                  fileType: getFileType(images.fileNames[i]),
                  lastUpdated: images.lastUpdatedTimes[i],

                  show: true,
                  page: data.page,
                  assetType: "file",
                });
              }
            }
          }
          if (data.page === 1) {
            dispatch({ type: "CLEAR_IMAGES" });
          }
          dispatch({ type: "STACK_IMAGES", imagesArray });
          dispatch({ type: "FETCHING_IMAGES", fetching: false });
        })
        .catch((e) => {
          dispatch({ type: "FETCHING_IMAGES", fetching: false });
          return e;
        });
    } else {
      // RESETPAGE dispatch
      dispatch({ type: "RESET_PAGE" });
    }
    // }
  }, [dispatch, data.page, activeOrg, data.searchTerm, data.parentFolder]);
  useEffect(() => {
    if (activeOrg !== "") {
      setFirstcall(true);
      logAuthorizedWombatEvent("pullingDashboardContent", {});
      const cookieExists = document.cookie.split(";").some((cookie) => {
        return cookie.trim().startsWith("visited2=true");
      });

      if (!cookieExists) {
        document.cookie = "visited2=true; max-age=31536000"; // Set cookie for 1 year
      }
      dispatch({ type: "CLEAR_IMAGES" });
    }
  }, [activeOrg]);
};

// infinite scrolling with intersection observer
export const useInfiniteScroll = (scrollRef, dispatch) => {
  const [controller] = useVisionUIController();
  const { activeOrg } = controller;
  const scrollObserver = useCallback(
    (node) => {
      new IntersectionObserver((entries) => {
        entries.forEach((en) => {
          if (en.intersectionRatio > 0) {
            dispatch({ type: "ADVANCE_PAGE" });
          }
        });
      }).observe(node);
    },
    [dispatch]
  );
  useEffect(() => {
    if (scrollRef.current) {
      scrollObserver(scrollRef.current);
    }
  }, [scrollObserver, scrollRef]);

  useEffect(() => {
    if (activeOrg !== "") {
      dispatch({ type: "RESET_PAGE" });
    }
  }, [activeOrg]);
};

// lazy load images with intersection observer
export const useLazyLoading = (imgSelector, items) => {
  const imgObserver = useCallback((node) => {
    const intObs = new IntersectionObserver((entries) => {
      entries.forEach((en) => {
        if (en.intersectionRatio > 0) {
          const currentImg = en.target;
          const newImgSrc = currentImg.src;

          // only swap out the image source if the new url exists
          if (!newImgSrc) {
            console.error("Image source is invalid");
          } else {
            currentImg.src = newImgSrc;
          }
          intObs.unobserve(node); // detach the observer when done
        }
      });
    });
    intObs.observe(node);
  }, []);
  const imagesRef = useRef(null);
  useEffect(() => {
    imagesRef.current = document.querySelectorAll(imgSelector);
    if (imagesRef.current) {
      imagesRef.current.forEach((img) => imgObserver(img));
    }
  }, [imgObserver, imagesRef, imgSelector, items]);
};

export const usePermissions = () => {
  const [controller] = useVisionUIController();
  const { activeOrg } = controller;
  const [permissions, setPermissions] = useState("free");

  useEffect(() => {
    if (activeOrg !== "") {
      // fetch(`${process.env.REACT_APP_BACKEND_URL}/user/permissions`, {
      //   method: "GET",
      //   credentials: "include",
      //   headers: {
      //     "Content-Type": "application/json",
      //     "X-ORG-ID": activeOrg,
      //   },
      // })
      //   .then((data) => {
      //     if (data.status === 403) {
      //       logoutUser(navigate);
      //     } else if (data.status !== 200) {
      //       alert("Error fetching data. Please try again later.");
      //     }
      //     return data.json();
      //   })
      //   .then((permissions) => {
      //     setPermissions("plus");
      //   })
      //   .catch((e) => {
      //     console.log("error fetching permissions", e);
      //     return e;
      //   });
    }
  }, [activeOrg]);
  return permissions;
};
