import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import ImageSearchOutlinedIcon from "@mui/icons-material/ImageSearchOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import colors from "assets/theme/base/colors";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 11,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: colors.icon.background,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: colors.icon.background,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: colors.icon.background,
    borderRadius: 1,
    width: 50,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: colors.icon.background,
  color: colors.background.secondary,
  zIndex: 1,
  color: "#fff",
  width: 25,
  height: 25,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: colors.icon.background,
    color: colors.white.main,
  }),
  ...(ownerState.completed && {
    backgroundColor: colors.icon.background,
    color: colors.white.main,
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }}>
      {completed ? (
        <Check className="c_white p_2px" />
      ) : (
        <CircleOutlinedIcon className={active ? "p_2px c_white" : "p_2px c_darkbg"} />
      )}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ["", "", "", ""];

const CustomStepper = (props) => {
  return (
    <Stepper
      alternativeLabel
      activeStep={props.step}
      connector={<ColorlibConnector />}
      className="mt_0 mb_0"
    >
      {steps.map((label, index) => (
        <Step key={index} className="px-15">
          <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;
