import React from "react";
import colors from "assets/theme/base/colors";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import "./Footer.css";
import "App.css";
import "layouts/applications/home/style.css";
import VuiTypography from "components/VuiTypography/index";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { Typography } from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import GradientIcon from "components/Custom/GradientIcon/index";
const Footer = (props) => {
  return (
    <div className="footer w_100 text_white bg_offwhite">
      <div className="container text_center d_flex d_align_center d_flex_column">
        {/* <h2 className="fw_600 font_30">
          {props.headerText ? (
            <>
              Auto-generate textures with{" "}
              <span style={{ color: colors.icon.secondaryfocus }}> AI</span>
            </>
          ) : (
            "Start using Polyhive for free"
          )}
        </h2> */}
        <Typography className="c_textblack fw_600 f_xxlarge lh_xxlarge ls_1">
          Try Polyhive for free
        </Typography>
        <div className="h_btn_wrap d_flex d_justify_left d_align_center mt_5">
          {/* <Link to="/generate">
            <Button
              className="bg_quaternaryfocusdark c_white hero_btn_2 f_mid fw_500"
              endIcon={<ArrowForwardRoundedIcon />}
            >
              Start Free Trial
            </Button>
          </Link> */}
          <Link to="/contact">
            <Button
              className="bg_quaternaryfocusdark c_white hero_btn_2 f_mid fw_500"
              endIcon={<CalendarMonthOutlinedIcon />}
            >
              Contact Sales
            </Button>
          </Link>
        </div>
      </div>
      <div className="container d_flex d_justify_between">
        <div className="ft2">
          <strong style={{ fontSize: "1.1rem" }} className="c_textblack">
            Pages
          </strong>
          <div className="ft_line d_flex d_flex_column bg_orange"></div>
          <VuiTypography
            variant="body2"
            sx={{
              color: colors.icon.disabled,
              fontWeight: 400,
              fontSize: "0.9rem",
              lineHeight: "1.1rem",
            }}
          >
            <a href="https://polyhive.ai">Home</a>
            {/* <a href="https://polyhive.ai/manage/content">Dashboard</a> */}
            <a href="https://polyhive.ai/gallery">Gallery</a>
            <a href="https://polyhive.ai/contact">Contact Sales</a>
            <div className="ft_space"></div>
            {/* <a className="d_flex d_align_center" href="#">
              <span>Roadmap</span>
              <svg
                width={18}
                height={14}
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginLeft: "4px" }}
                fill="#fff"
                className="SvgIcon__StyledSvg-sc-fpco16-0 hJjXsi"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.82746 7.99581L11.0816 3.74162V6.41667H12.2483V1.75H7.58165V2.91667H10.2567L6.0025 7.17086L6.82746 7.99581ZM11.0833 11.0833V8.16667H9.91667V11.0833H2.91667V4.08333H5.83333V2.91667H2.91667C2.27233 2.91667 1.75 3.439 1.75 4.08333V11.0833C1.75 11.7277 2.27233 12.25 2.91667 12.25H9.91667C10.561 12.25 11.0833 11.7277 11.0833 11.0833Z"
                />
              </svg>
            </a>
            <a className="d_flex d_align_center" href="#">
              <span>Request Features</span>
              <svg
                width={18}
                height={14}
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginLeft: "4px" }}
                fill="#fff"
                className="SvgIcon__StyledSvg-sc-fpco16-0 hJjXsi"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.82746 7.99581L11.0816 3.74162V6.41667H12.2483V1.75H7.58165V2.91667H10.2567L6.0025 7.17086L6.82746 7.99581ZM11.0833 11.0833V8.16667H9.91667V11.0833H2.91667V4.08333H5.83333V2.91667H2.91667C2.27233 2.91667 1.75 3.439 1.75 4.08333V11.0833C1.75 11.7277 2.27233 12.25 2.91667 12.25H9.91667C10.561 12.25 11.0833 11.7277 11.0833 11.0833Z"
                />
              </svg>
            </a>
            <a className="d_flex d_align_center" href="#">
              <span>Changelog</span>
              <svg
                width={18}
                height={14}
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginLeft: "4px" }}
                fill="#fff"
                className="SvgIcon__StyledSvg-sc-fpco16-0 hJjXsi"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.82746 7.99581L11.0816 3.74162V6.41667H12.2483V1.75H7.58165V2.91667H10.2567L6.0025 7.17086L6.82746 7.99581ZM11.0833 11.0833V8.16667H9.91667V11.0833H2.91667V4.08333H5.83333V2.91667H2.91667C2.27233 2.91667 1.75 3.439 1.75 4.08333V11.0833C1.75 11.7277 2.27233 12.25 2.91667 12.25H9.91667C10.561 12.25 11.0833 11.7277 11.0833 11.0833Z"
                />
              </svg>
            </a> */}
          </VuiTypography>
        </div>

        <div className="ft2">
          <strong style={{ fontSize: "1.1rem" }} className="c_textblack">
            Resources
          </strong>
          <div className="ft_line d_flex d_flex_column bg_orange"></div>
          <VuiTypography
            variant="body2"
            sx={{
              color: colors.icon.disabled,
              fontWeight: 400,
              fontSize: "0.9rem",
              lineHeight: "1.1rem",
            }}
          >
            {/* <a href="#">Pricing</a> */}
            <a href="https://docs.polyhive.ai">Documentation</a>
            <a href="https://docs.polyhive.ai/sdk-releases">SDK Downloads</a>
          </VuiTypography>

          <div className="ft_space"></div>
          <strong style={{ fontSize: "1.1rem" }} className="c_textblack">
            Legal
          </strong>
          <div className="ft_line d_flex d_flex_column bg_orange"></div>
          <VuiTypography
            variant="body2"
            sx={{
              color: colors.icon.disabled,
              fontWeight: 400,
              fontSize: "0.9rem",
              lineHeight: "1.1rem",
              opacity: "1 !important",
            }}
          >
            <a target="_blank" href="tos.html">
              Privacy Policy
            </a>
            {/* <a href="#">Customer Terms of Service</a> */}
            <a target="_blank" href="tos.html">
              Terms of Service
            </a>
          </VuiTypography>
        </div>

        <div className="ft2">
          <strong style={{ fontSize: "1.1rem" }} className="c_textblack">
            Connect
          </strong>
          <div className="ft_line d_flex d_flex_column bg_orange"></div>
          <div className="ft_social_icons d_flex d_align_center">
            {/* <a href="https://discord.gg/GrqWkANrce" target="_blank">
              <svg
                width={24}
                height={24}
                fill="rgb(185, 185, 198)"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="SvgIcon__StyledSvg-sc-fpco16-0 ebBOza"
              >
                <path d="M18.4688 6.06542C17.279 5.51951 16.0032 5.11731 14.6693 4.88695C14.645 4.88251 14.6207 4.89362 14.6082 4.91584C14.4441 5.20767 14.2623 5.58839 14.1351 5.88764C12.7003 5.67284 11.2729 5.67284 9.86758 5.88764C9.74028 5.58174 9.55193 5.20767 9.38711 4.91584C9.3746 4.89436 9.35033 4.88325 9.32604 4.88695C7.99282 5.11657 6.71699 5.51877 5.52651 6.06542C5.5162 6.06986 5.50737 6.07728 5.5015 6.0869C3.08153 9.70229 2.41859 13.2288 2.74381 16.7116C2.74528 16.7287 2.75484 16.745 2.76809 16.7553C4.36472 17.9278 5.91133 18.6397 7.42922 19.1115C7.45351 19.1189 7.47925 19.11 7.49471 19.09C7.85377 18.5997 8.17383 18.0827 8.44826 17.539C8.46446 17.5071 8.449 17.4694 8.4159 17.4568C7.90821 17.2642 7.4248 17.0294 6.95979 16.7627C6.92301 16.7413 6.92006 16.6886 6.9539 16.6635C7.05176 16.5901 7.14964 16.5138 7.24308 16.4368C7.25998 16.4227 7.28354 16.4198 7.30341 16.4287C10.3583 17.8234 13.6656 17.8234 16.6845 16.4287C16.7044 16.419 16.728 16.422 16.7456 16.4361C16.8391 16.5131 16.9369 16.5901 17.0355 16.6635C17.0693 16.6886 17.0671 16.7413 17.0304 16.7627C16.5653 17.0346 16.0819 17.2642 15.5735 17.456C15.5404 17.4686 15.5257 17.5071 15.5419 17.539C15.8222 18.0819 16.1423 18.5989 16.4947 19.0893C16.5094 19.11 16.5359 19.1189 16.5602 19.1115C18.0854 18.6397 19.632 17.9278 21.2287 16.7553C21.2427 16.745 21.2515 16.7294 21.253 16.7124C21.6422 12.6859 20.601 9.18825 18.4931 6.08763C18.4879 6.07728 18.4791 6.06986 18.4688 6.06542ZM8.90446 14.591C7.98472 14.591 7.22688 13.7466 7.22688 12.7096C7.22688 11.6726 7.97003 10.8282 8.90446 10.8282C9.84624 10.8282 10.5967 11.68 10.582 12.7096C10.582 13.7466 9.83887 14.591 8.90446 14.591ZM15.107 14.591C14.1873 14.591 13.4295 13.7466 13.4295 12.7096C13.4295 11.6726 14.1726 10.8282 15.107 10.8282C16.0488 10.8282 16.7993 11.68 16.7846 12.7096C16.7846 13.7466 16.0488 14.591 15.107 14.591Z" />
              </svg>
            </a> */}
            <a href="https://twitter.com/PolyhiveHQ" target="_blank">
              <svg
                width={24}
                height={24}
                fill="rgb(185, 185, 198)"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="SvgIcon__StyledSvg-sc-fpco16-0 lnqVQ"
              >
                <path d="M21.6667 5.89415C20.9725 6.21538 20.2328 6.42831 19.4617 6.53169C20.255 6.032 20.8605 5.24677 21.1452 4.30031C20.4055 4.76554 19.5888 5.09415 18.7185 5.27754C18.0162 4.48862 17.0152 4 15.9232 4C13.8045 4 12.0988 5.81415 12.0988 8.03815C12.0988 8.35815 12.1245 8.66585 12.1875 8.95877C9.006 8.79508 6.19083 7.18646 4.29967 4.736C3.9695 5.34031 3.77583 6.032 3.77583 6.77662C3.77583 8.17477 4.45833 9.41415 5.47567 10.1317C4.86083 10.1194 4.25767 9.93108 3.74667 9.63446C3.74667 9.64677 3.74667 9.66277 3.74667 9.67877C3.74667 11.6406 5.07317 13.2702 6.81267 13.6455C6.50117 13.7354 6.16167 13.7785 5.80933 13.7785C5.56433 13.7785 5.317 13.7637 5.08483 13.7095C5.58067 15.3083 6.98767 16.4837 8.66067 16.5218C7.35867 17.5963 5.7055 18.2437 3.91583 18.2437C3.602 18.2437 3.301 18.2289 3 18.1883C4.69517 19.3415 6.70417 20 8.87067 20C15.9127 20 19.7627 13.8462 19.7627 8.512C19.7627 8.33354 19.7568 8.16123 19.7487 7.99015C20.5082 7.42154 21.1463 6.71138 21.6667 5.89415Z" />
              </svg>
            </a>
            <a href="https://www.linkedin.com/company/polyhive/about/" target="_blank">
              <svg
                width="24"
                height="24"
                fill="rgb(185, 185, 198)"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                class="SvgIcon__StyledSvg-sc-fpco16-0 dndktY"
              >
                <path d="M18.8461 4H5.15393C4.5166 4 4 4.5166 4 5.15393V18.8461C4 19.4834 4.5166 20 5.15393 20H18.8461C19.4834 20 20 19.4834 20 18.8461V5.15393C20 4.5166 19.4834 4 18.8461 4V4ZM9.67529 16.0938H7.72693V10.2321H9.67529V16.0938ZM8.70117 9.43164H8.68848C8.03467 9.43164 7.61182 8.98157 7.61182 8.41907C7.61182 7.84387 8.04761 7.40625 8.71411 7.40625C9.38062 7.40625 9.79077 7.84387 9.80347 8.41907C9.80347 8.98157 9.38062 9.43164 8.70117 9.43164ZM16.7007 16.0938H14.7526V12.9579C14.7526 12.1698 14.4705 11.6323 13.7655 11.6323C13.2273 11.6323 12.9067 11.9949 12.7659 12.3448C12.7144 12.4701 12.7018 12.6451 12.7018 12.8203V16.0938H10.7535C10.7535 16.0938 10.7791 10.782 10.7535 10.2321H12.7018V11.062C12.9607 10.6626 13.424 10.0945 14.4576 10.0945C15.7395 10.0945 16.7007 10.9323 16.7007 12.7327V16.0938Z"></path>
              </svg>
            </a>
            {/* <svg
              width="24"
              height="24"
              fill="rgb(185, 185, 198)"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              class="SvgIcon__StyledSvg-sc-fpco16-0 lwubA"
            >
              <path d="M18.9804 4.16366C15.6768 3.945 8.31867 3.94589 5.01958 4.16366C1.44733 4.40011 1.02658 6.49252 1 12C1.02658 17.4977 1.44367 19.599 5.01958 19.8363C8.31958 20.0541 15.6768 20.055 18.9804 19.8363C22.5527 19.5999 22.9734 17.5075 23 12C22.9734 6.5023 22.5563 4.40099 18.9804 4.16366V4.16366ZM9.25 15.5555V8.44449L16.5833 11.9938L9.25 15.5555V15.5555Z"></path>
            </svg> */}
          </div>
          <VuiTypography
            variant="body2"
            sx={{
              color: colors.icon.disabled,
              fontWeight: 400,
              fontSize: "0.9rem",
              lineHeight: "1.1rem",
            }}
          >
            <Link to="/contact">Contact Us</Link>

            {/* <a href="#">Showcase</a>
            <a href="#">Podcast</a>
            <a href="#">Careers</a>
            <a href="#">Press</a> */}
          </VuiTypography>
        </div>
      </div>
    </div>
  );
};

export default Footer;
