import React, { useEffect, forwardRef, useRef } from "react";
import PropTypes from "prop-types";

import * as OV from "painting-3d-viewer";
import envMap1 from "assets/envmaps/fishermans_bastion/posx.jpg";
import envMap2 from "assets/envmaps/fishermans_bastion/negx.jpg";
import envMap3 from "assets/envmaps/fishermans_bastion/posy.jpg";
import envMap4 from "assets/envmaps/fishermans_bastion/negy.jpg";
import envMap5 from "assets/envmaps/fishermans_bastion/posz.jpg";
import envMap6 from "assets/envmaps/fishermans_bastion/negz.jpg";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";

const Model = forwardRef((props, ref) => {
  const currentObjectRef = useRef();
  currentObjectRef.current = props.file;
  const [viewer, setViewer] = React.useState(null);
  const viewerRef = useRef(null);
  viewerRef.current = viewer;

  useEffect(() => {
    const onModelLoaded = () => {
      props.loaderCB(true);
      const viewer1 = viewerRef.current.GetViewer();
      let boundingSphere = viewer1.GetBoundingSphere((meshUserData) => {
        return true;
      });
      viewer1.AdjustClippingPlanesToSphere(boundingSphere);
      viewer1.FitSphereToWindow(boundingSphere, true);
    };
    OV.SetExternalLibLocation("../libs");

    // get the parent element of the viewer
    let parentDiv = document.getElementById("assetModelCanvas");
    // console.log("myCanvas in AssetModel Useffect", myCanvas);
    // myCanvas.getContext("webgl", { preserveDrawingBuffer: true });

    let viewer1 = new OV.EmbeddedViewer(parentDiv, {
      camera: new OV.Camera(
        new OV.Coord3D(-1.5, 2.0, 3.0),
        new OV.Coord3D(0.0, 0.0, 0.0),
        new OV.Coord3D(0.0, 1.0, 0.0),
        45.0
      ),
      backgroundColor: new OV.RGBAColor(255, 255, 255, 0),
      defaultColor: new OV.RGBColor(200, 200, 200),
      edgeSettings: new OV.EdgeSettings(false, new OV.RGBColor(0, 0, 0), 1),
      environmentSettings: new OV.EnvironmentSettings(
        [envMap1, envMap2, envMap3, envMap4, envMap5, envMap6],
        false
      ),
      onModelLoaded: onModelLoaded,
    });

    props.setRenderer(viewer1.viewer.renderer);
    props.setCamera(viewer1.viewer.camera);
    props.setScene(viewer1.viewer.scene);
    setViewer(viewer1);

    return () => {
      viewer1.Destroy();
    };
  }, []);

  useEffect(() => {
    // initialize the viewer with the parent element and some parameters
    console.log("viewer", viewer);
    if (typeof props.file.filePath !== "undefined") {
      if (
        currentObjectRef.current &&
        ["vrm", "gltf", "glb", "fbx", "obj"].includes(currentObjectRef.current.fileType)
      ) {
        props.loaderCB(false);
        console.log("props.file", props.file);
        // if props.file.fileObj is defined then load the file from the file object
        if (props.file.fileObj) {
          viewer.LoadModelFromFileList([props.file.fileObj]);
        } else {
          viewer.LoadModelFromUrlList([props.file.filePath]);
        }
      }
    }
  }, [props.file]);

  return (
    <>
      <div
        id="assetModelCanvas"
        className="inspector_viewer"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></div>
    </>
  );
});

// props are { camera,file, cb and scale}
class AssetModel extends React.Component {
  static propTypes = {
    file: PropTypes.any,
    scale: PropTypes.number,
    cb: PropTypes.func,
    loaderCB: PropTypes.func,
    handleScreenshot: PropTypes.func,
    setRenderer: PropTypes.func,
  };

  logOnClick() {
    logAuthorizedWombatEvent("assetModelClicked", {});
  }
  constructor(props) {
    super(props);
    this.state = {
      canvasState: props.file,
    };
    this.loaderCallback = this.loaderCallback.bind(this);
  }
  loaderCallback(loaded) {
    this.props.cb(loaded);
  }

  // call refreshscene when state changes
  componentDidUpdate(prevProps) {
    if (prevProps.file.filePath !== this.props.file.filePath) {
      this.refreshScene(this.props.file);
    }
  }

  refreshScene(newFile) {
    this.setState({ canvasState: newFile });
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (nextProps.file.filePath !== this.props.file.filePath) {
      return true;
    }

    // if (!nextState) {
    //   return false;
    // }
    // Only re-render the component if the state changes in a way that affects the canvas

    return this.state.canvasState !== nextState.canvasState;
  };

  render() {
    return (
      <>
        <Model
          file={this.state.canvasState}
          loaderCB={this.loaderCallback}
          startSnackbar={this.props.startSnackbar}
          setRenderer={this.props.setRenderer}
          setCamera={this.props.setCamera}
          setScene={this.props.setScene}
        />
      </>
    );
  }
}

export default AssetModel;
